import React from "react";
import { TextInputFormStyled } from "./styled";
import { iconImages } from "assets";
import { Button } from "../../../components/Button";
import { InputText } from "../../../components/InputText";
import { Field, reduxForm } from "redux-form";
import { LogoLoadingFade } from "components";

class TextInputForm extends React.Component {
  state = {
    value: null,
    loading: true,
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  };

  render() {
    const {
      question,
      type,
      placeholder,
      handleSubmit,
      handleSubmitForm,
      gateWay,
    } = this.props;
    const { loading } = this.state;

    return (
      <TextInputFormStyled>
        <div className="layout">
          <form
            onSubmit={handleSubmit(() =>
              handleSubmitForm(gateWay ? question.answers[0].id : null)
            )}
          >
            {loading ? (
              <LogoLoadingFade />
            ) : (
              <img
                className="img_logo"
                src={iconImages["logo_question.png"]}
                alt="logo well"
              />
            )}
            <div className="title_question">{question && question.label}</div>
            <div className="txtInput">
              <Field
                name="value"
                theme_normal
                component={InputText}
                input_width="100%"
                placeholder={
                  placeholder ||
                  (question &&
                    question.answers &&
                    question.answers[0].description)
                }
                type={type ? type : "text"}
              />
            </div>
            <div className="btn">
              <Button width="100%" height="40px" theme_standard btn>
                ต่อไป
              </Button>
            </div>
          </form>
        </div>
      </TextInputFormStyled>
    );
  }
}

export default reduxForm({
  form: "TextInputForm", // a unique identifier for this form
  enableReinitialize: true,
})(TextInputForm);

export const CONFIG_PROJECT = 'CONFIG_PROJECT';
export const USER_AUTHEN = 'USER_AUTHEN';
export const SUBJECT_SELECT = 'SUBJECT_SELECT';
export const SUMARY_QUESTION = 'SUMARY_QUESTION';
export const SUMARY_QUESTION_NO_SYMPTOM = 'SUMARY_QUESTION_NO_SYMPTOM';
export const COOKIE_PERMISSION = 'COOKIE_PERMISSION';

export const ORDER_PRODUCT = 'ORDER_PRODUCT';
export const ADDRESS_SHIPPING = 'ADDRESS_SHIPPING';
export const HOSPITAL_LIST = 'HOSPITAL_LIST';

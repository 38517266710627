function importAll(r) {
    let images = {}
    r.keys().map((item, index) => {
        return images[item.replace('./', '')] = r(item)
    })
    return images
}

const iconImages = importAll(require.context('./icon', false, /\.(png|jpe?g|svg)$/))
const iconImagesMale = importAll(require.context('./icon/screening/male', false, /\.(png|jpe?g|svg)$/))
const iconImagesFemale = importAll(require.context('./icon/screening/female', false, /\.(png|jpe?g|svg)$/))
const iconSelectCancer = importAll(require.context('./icon/screening/cancer', false, /\.(png|jpe?g|svg)$/))
const iconSummary = importAll(require.context('./icon/summary', false, /\.(png|jpe?g|svg)$/))
const iconBackground = importAll(require.context('./backgrounds', false, /\.(png|jpe?g|svg)$/))
const iconLogo = importAll(require.context('./logo', false, /\.(png|jpe?g|svg)$/))




export {
    iconImages,
    iconImagesMale,
    iconImagesFemale,
    iconSelectCancer,
    iconSummary,
    iconBackground,
    iconLogo
}
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { ScreeningProfileFormStyled } from "./styled";
// import { VALIDATE } from "helpers";
import { InputText, DropDownCustom, Radio, DateTime } from "components";
import { iconImages } from "assets";
import { Button } from "../../components/Button";

class ScreeningProfileForm extends React.Component {
  state = {};

  handleChangeRelation = async (e) => {
    this.props.handleChangeRelation(e);
  };

  render() {
    const {
      handleSubmit,
      handleSubmitForm,
      authRedux,
      edit_mode,
      family,
      option_relationship,
      handleClickButton,
      relationship,
      is_corporate,
    } = this.props;

    return (
      <ScreeningProfileFormStyled>
        <form onSubmit={handleSubmit(handleSubmitForm)}> 
          <>
            <div className="form_layout">
              <div className="header_layout">
                {!family && (
                  <img
                    className="avatar"
                    src={
                      authRedux.avatar
                        ? authRedux.avatar
                        : iconImages["avatar.png"]
                    }
                    alt="avatar"
                  />
                )}
                <div className="btn_header">
                  {!family && (
                    <Button
                      width="120px"
                      height="30px"
                      fontSize="14px"
                      theme_border
                      style={{ marginTop: 20, marginLeft: 20 }}
                      border="#91C5D6"
                      color="#91C5D6"
                      handleClickButton={handleClickButton}
                    >
                      อัพโหลดรูปภาพ
                    </Button>
                  )}
                  <Button
                    width="120px"
                    height="30px"
                    font="14px"
                    theme_standard
                    btn
                    style={{
                      marginTop: family ? 10 : 20,
                      marginLeft: family ? 0 : 20,
                      marginBottom: family ? 10 : 0,
                    }}
                    color="#ffffff"
                    backgroundColor={edit_mode ? "#33CC66" : "#4394B3"}
                  >
                    {edit_mode || family ? "บันทึก" : "แก้ไขโปรไฟล์"}
                  </Button>
                </div>
              </div>
              {!family && (
                <div className="user_name">
                  {authRedux.firstname} {authRedux.lastname}
                </div>
              )}
              <div className="flex_layout">
                {edit_mode ? (
                  <>
                    <div className="group_input">
                      <div className="label_name">ชื่อ</div>
                      <Field
                        theme_standard
                        name="firstname"
                        component={InputText}
                        // validate={VALIDATE.REQUIRE}
                        disabled={!edit_mode}
                      />
                    </div>
                    <div className="group_input">
                      <div className="label_name">นามสกุล</div>
                      <Field
                        theme_standard
                        name="lastname"
                        component={InputText}
                        // validate={VALIDATE.REQUIRE}
                        disabled={!edit_mode}
                      />
                    </div>
                  </>
                ) : (
                  <div className="group_input">
                    <div className="label_name">ชื่อ - นามสกุล</div>
                    <Field
                      theme_standard
                      name="name"
                      component={InputText}
                      // validate={VALIDATE.REQUIRE}
                      disabled={!edit_mode}
                    />
                  </div>
                )}
                {!family && (
                  <div className="group_input">
                    <div className="label_name">อีเมล์</div>
                    <Field
                      theme_standard
                      name="email"
                      component={InputText}
                      // validate={VALIDATE.REQUIRE}
                      disabled="true"
                    />
                  </div>
                )}
                <div className="group_input">
                  <div className="label_name">วัน/เดือน/ปี</div>
                  <Field
                    theme_standard
                    name="birthday"
                    component={DateTime}
                    // validate={VALIDATE.REQUIRE}
                    disabled={!edit_mode}
                  />
                </div>

                {family ? (
                  <div className="group_input">
                    <div className="label_name" style={{ marginLeft: 10 }}>
                      ความสัมพันธ์
                    </div>
                    <DropDownCustom
                      theme_standard_dropdown
                      label=""
                      options={option_relationship.map((e, i) => ({
                        label: e.name,
                        value: e.key,
                      }))}
                      onChange={this.handleChangeRelation}
                      alignItems="center"
                      old_Value={relationship}
                    />
                  </div>
                ) : (
                  <div className="group_input">
                    <div className="label_name">เบอร์โทร</div>
                    <Field
                      theme_standard
                      name="phone_number"
                      component={InputText}
                      // validate={VALIDATE.REQUIRE}
                      disabled={!edit_mode}
                    />
                  </div>
                )}
              </div>
              <div className="row_layout">
                <div className="radio_layout">
                  <div className="gender_layout">
                    <div className="label_name">เพศ</div>
                    <div className="gen_opt">
                      <Field
                        theme_circle
                        label="เพศ"
                        name="gender"
                        disabled={!edit_mode}
                        options={[
                          {
                            label: "เพศชาย",
                            value: "male",
                            id: "male",
                          },
                          {
                            label: "เพศหญิง",
                            value: "female",
                            id: "female",
                          },
                        ]}
                        component={Radio}
                      // validate={VALIDATE.REQUIRE}
                      />
                    </div>
                  </div>
                  <div className="layout_YN">
                    <div className="YN_left">
                      <div className="label_name">ท่านเคยสูบบุหรี่หรือไม่?</div>
                      <Field
                        theme_circle_column
                        name="is_smoke"
                        padding_left="0px"
                        margin_bottom="10px"
                        disabled={!edit_mode}
                        options={[
                          {
                            label: "ใช่",
                            value: 1,
                            id: "smoke_1",
                          },
                          {
                            label: "ไม่ใช่",
                            value: 0,
                            id: "smoke_0",
                          },
                        ]}
                        component={Radio}
                      // validate={VALIDATE.REQUIRE}
                      />
                    </div>
                    <div className="YN_right">
                      <div className="label_name">ท่านเคยดื่มสุราหรือไม่?</div>
                      <Field
                        theme_circle_column
                        name="is_alcohol"
                        padding_left
                        margin_bottom="10px"
                        disabled={!edit_mode}
                        options={[
                          {
                            label: "ใช่",
                            value: 1,
                            id: "alcohol_1",
                          },
                          {
                            label: "ไม่ใช่",
                            value: 0,
                            id: "alcohol_0",
                          },
                        ]}
                        component={Radio}
                      // validate={VALIDATE.REQUIRE}
                      />
                    </div>
                  </div>
                </div>
                <div className="input_layout">
                  {edit_mode && (
                    <>
                      <div
                        className={
                          family ? "group_input" : "group_input_weight"
                        }
                      >
                        <div className="label_name">น้ำหนัก</div>
                        <Field
                          theme_standard
                          name="weight"
                          component={InputText}
                          // validate={VALIDATE.REQUIRE}
                          disabled={!edit_mode}
                        />
                      </div>
                      <div className="group_input">
                        <div className="label_name">ส่วนสูง</div>
                        <Field
                          theme_standard
                          name="height"
                          component={InputText}
                          // validate={VALIDATE.REQUIRE}
                          disabled={!edit_mode}
                        />
                      </div>
                    </>
                  )}
                  <div className="group_input">
                    <div className="label_name">โรคประจำตัว</div>
                    <Field
                      theme_standard
                      name="disease"
                      component={InputText}
                      // validate={VALIDATE.REQUIRE}
                      disabled={!edit_mode}
                    />
                  </div>
                  {
                    is_corporate &&
                    <>
                      <div className="group_input">
                        <div className="label_name">บริษัท</div>
                        <Field
                          theme_standard
                          name="company"
                          component={InputText}
                          // validate={VALIDATE.REQUIRE}
                          disabled={true}
                        />
                      </div>
                      <div className="group_input">
                        <div className="label_name">ตำแหน่ง</div>
                        <Field
                          theme_standard
                          name="position"
                          component={InputText}
                          // validate={VALIDATE.REQUIRE}
                          disabled={true}
                        />
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </>
        </form>
      </ScreeningProfileFormStyled>
    );
  }
}

const selector = formValueSelector("RegisterForm");
ScreeningProfileForm = connect((state) => {
  const hasFirstname = selector(state, "firstname");
  const hasLastname = selector(state, "lastname");
  const hasWeight = selector(state, "weight");
  const hasHeight = selector(state, "height");
  const hasBirthday = selector(state, "birthday");
  const hasEmail = selector(state, "email");
  const hasPassword = selector(state, "password");
  return {
    hasFirstname,
    hasLastname,
    hasWeight,
    hasHeight,
    hasBirthday,
    hasEmail,
    hasPassword,
  };
})(ScreeningProfileForm);

export default reduxForm({
  form: "ScreeningProfileForm", // a unique identifier for this form
  enableReinitialize: true,
})(ScreeningProfileForm);

import React from 'react';
import ReactGA from 'react-ga';
import { NoSymptomQuestionContainerStyled } from './styled';
import { ROUTE_PATH } from 'helpers';
import { userService } from 'apiService';
import { connect } from 'react-redux';
import moment from 'moment';
import { iconImages } from 'assets';
import { setReduxSummaryQuestionNoSymptom } from 'actions';
import { Button, BoxLink, LogoLoadingFade } from 'components';
import TextInputForm from '../../../../forms/QuestionForm/TextInputForm';
import StandardNoSymptomForm from '../../../../forms/QuestionForm/StandardNoSymptomForm';
class NoSymptomQuestionContainer extends React.Component {
  state = {
    select_cancer: null,
    render_question: null,
    answer_id: null,
    answers: [],
    question: {},
    summary: {},
    type: null,
    loading: true,
    loading_question: true,
  };

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickButton = () => {
    this.setState(
      {
        loading: true,
      },
      this.fetchData
    );
  };

  fetchData = async () => {
    const { answers, type } = this.state;
    const { authRedux, setReduxSummaryQuestionNoSymptom } = this.props;
    const params = {
      type: type,
      answers: answers,
    };

    let res = await userService.POST_SCREENING_QUESTION_PART2(
      authRedux.id,
      params
    );
    if (res && res.success) {
      if (res.data.question) {
        if (res.data.question && res.data.question.key === 'age') {
          this.setState(
            {
              initialValues: {
                value: String(
                  moment().diff(this.props.authRedux.birthday, 'years', false)
                ),
              },
              question: res.data.question,
            },
            this.checkTypeQuestion
          );
        } else {
          this.setState(
            {
              question: res.data.question,
            },
            this.checkTypeQuestion
          );
        }
      } else {
        setReduxSummaryQuestionNoSymptom({ summary: res });
        this.setState(
          {
            summary: res.data,
          },
          this.handleSummary
        );
      }
    } else {
      console.log('err');
    }
  };

  handleSummary = () => {
    const { summary } = this.state;

    if (summary.type === 'hpv') {
      if (summary.level === 'LOW') {
        this.props.history.push(ROUTE_PATH.SUMMARY_NO_SYMPTOM);
      } else if (summary.level === 'HIGH') {
        this.props.history.push(ROUTE_PATH.GATEWAY_NO_SYMPTOM);
      }
    } else if (summary.type === 'breast') {
      this.props.history.push(ROUTE_PATH.GATEWAY_NO_SYMPTOM);
    } else if (summary.type === 'colorectal') {
      if (summary.is_skip_gateway) {
        this.props.history.push(ROUTE_PATH.SUMMARY_NO_SYMPTOM);
      } else {
        this.props.history.push(ROUTE_PATH.GATEWAY_NO_SYMPTOM);
      }
    } else {
      this.props.history.push(ROUTE_PATH.SUMMARY_NO_SYMPTOM);
    }

    // this.setLoadder();
    // this.setState({
    //   render_question: (
    //     <SummaryNoSymtopmContainer
    //       level={summary && summary.level}
    //       type={this.props.location.state}
    //     />
    //   ),
    // });
  };

  handleSubmitForm = () => {
    const { answers, answer_id } = this.state;
    const {
      reduxForm: { values },
    } = this.props;
    let params = {
      ...values,
      id: answer_id,
    };
    this.setState(
      {
        answers: [...answers, params],
        render_question: null,
      },
      this.fetchData
    );
  };

  handleClickAns = (value) => {
    const { answers } = this.state;
    let params = {
      id: value,
    };
    this.setState(
      {
        answers: [...answers, params],
        render_question: null,
      },
      this.fetchData
    );
  };

  checkTypeQuestion = () => {
    const { question, initialValues } = this.state;
    switch (question && question.answer_form) {
      case 'number':
        this.setLoadder();
        this.setState({
          answer_id: question.answers[0].id,
          render_question: (
            <TextInputForm
              question={question}
              type="number"
              placeholder="กรุณากรอกตัวเลข"
              handleSubmitForm={this.handleSubmitForm}
              initialValues={initialValues}
            />
          ),
        });
        break;

      case 'choice_1col':
        this.setLoadder();
        this.setState({
          render_question: (
            <StandardNoSymptomForm
              question={question}
              handleClickAns={this.handleClickAns}
              noSymptom
            />
          ),
        });
        break;

      default:
        break;
    }
  };

  componentDidMount = () => {
    this.scrollToTop();
    this.setLoadder();
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    setTimeout(() => {
      this.setState(
        {
          loading_question: false,
        },
        this.handleFirstQuestion
      );
    }, 2500);
  };

  handleFirstQuestion = () => {
    const { loading_question } = this.state;

    let startTitle = null;
    let isCase = this.props.location.state
      ? this.props.location.state
      : 'โรคมะเร็งลำไส้';

    // switch (this.props.location.state) {
    switch (isCase) {
      case 'โรคมะเร็งเต้านม':
        ReactGA.event({
          category: 'wellscreening_no_symptom',
          action: 'start_no_symptom_breast',
        });

        startTitle = dataBreast;
        this.setState({
          type: 'breast',
        });

        break;
      case 'โรคมะเร็งปากมดลูก':
        ReactGA.event({
          category: 'wellscreening_no_symptom',
          action: 'start_no_symptom_hpv',
        });
        startTitle = dataCervix;
        this.setState({
          type: 'hpv',
        });
        break;
      case 'โรคมะเร็งลำไส้':
        ReactGA.event({
          category: 'wellscreening_no_symptom',
          action: 'start_no_symptom_colorectal',
        });
        startTitle = dataColorectal;
        this.setState({
          type: 'colorectal',
        });
        break;
      default:
        break;
    }
    this.setState({
      render_question: (
        <>
          {loading_question ? (
            <LogoLoadingFade />
          ) : (
            <img
              className="img_logo"
              src={iconImages['logo_question.png']}
              alt="logo well"
            />
          )}
          <div className="title">
            {startTitle && startTitle.question[0].value}
          </div>
          <div className="description">
            {startTitle.description && startTitle.description[0].value && (
              <div>{startTitle && startTitle.description[0].value}</div>
            )}
          </div>

          <Button
            width="400px"
            // height="45px"
            padding="10px"
            font="14px"
            height="auto"
            theme_standard
            handleClickButton={this.handleClickButton}
            style={{ marginTop: 20 }}
            className="btn_bottom"
          >
            พร้อมแล้ว เริ่มได้เลย
          </Button>
        </>
      ),
    });
  };

  handleClickHome = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  handleClickBack = () => {
    const { answers } = this.state;
    let all_arr = [...answers];
    if (all_arr.length > 0) {
      all_arr.splice(all_arr?.length - 1, 1);
      this.setState(
        {
          answers: all_arr,
        },
        this.handleClickButton
      );
    } else {
      this.handleClickButton();
    }
  };

  render() {
    const { render_question, loading, answers } = this.state;
    return (
      <NoSymptomQuestionContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div className="link_back">
              <div onClick={() => this.handleClickBack()}>
                {answers?.length > 0 && (
                  <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
                )}
              </div>
              <div onClick={() => this.handleClickHome()}>
                <BoxLink theme_standard_box_link label="กลับสู่หน้าหลัก" />
              </div>
            </div>
            <div className="group_layout">
              <div className="layout_detail">{render_question}</div>
            </div>
          </>
        )}
      </NoSymptomQuestionContainerStyled>
    );
  }
}

const dataBreast = {
  question: [
    {
      type: 'โรคมะเร็งเต้านม',
      value:
        'สวัสดีค่ะ ขณะนี้คุณกำลังเข้าสู่การประเมินความเสี่ยงมะเร็งเต้านม โดย Gail Model จากสถาบันมะเร็งในสหรัฐอเมริกา จำนวน 6 ชุดคำถามค่ะ ',
    },
  ],
  description: [
    {
      value:
        'แบบประเมินนี้จะบ่งบอกถึงความเสี่ยงในการเป็นมะเร็งเต้านมในอีก 5 ปีข้างหน้า จนถึงอายุ 90 ปี โดยอ้างอิงจากอายุปัจจุบัน',
    },
  ],
  actions: [
    {
      label: 'พร้อมแล้ว เริ่มได้เลย',
    },
  ],
};

const dataCervix = {
  question: [
    {
      type: 'โรคมะเร็งปากมดลูก',
      value:
        'สวัสดีค่ะ ขณะนี้คุณกำลังเข้าสู่การประเมินความเสี่ยงมะเร็งปากมดลูก โดยเราจะใช้คำถามจากสมาคมมะเร็งนรีเวช จำนวน 8 ชุดคำถามค่ะ',
    },
  ],
  actions: [
    {
      label: 'พร้อมแล้ว เริ่มได้เลย',
    },
  ],
};

const dataColorectal = {
  question: [
    {
      type: 'โรคมะเร็งลำไส้',
      value:
        'สวัสดีค่ะ ขณะนี้คุณกำลังเข้าสู่การประเมินความเสี่ยงมะเร็งลำไส้ โดยเราจะใช้คำถามจำนวน 10 ชุดคำถาม จาก American Society of Colon & Rectal Surgeons',
    },
  ],
  actions: [
    {
      label: 'พร้อมแล้ว เริ่มได้เลย',
    },
  ],
};

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.TextInputForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxSummaryQuestionNoSymptom: (data) =>
    dispatch(setReduxSummaryQuestionNoSymptom(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoSymptomQuestionContainer);

import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const BoxDetailStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    font-size: ${FONT.SIZE_16};
    color: ${COLOR.BLACK_3};
    padding-bottom: 40px;
    border-bottom: 1px solid ${COLOR.GRAY_4};
    .title_show {
      color: ${COLOR.BLUE_4};
      font-size: ${FONT.SIZE_18};
      margin-bottom: 20px;
    }
    .group_detail {
      display: flex;
      .left_box {
        width: 50%;
        .detail_1 {
          margin-bottom: 20px;
          &.no_margin {
            margin-bottom: 0px;
          }
          .span_text {
            color: ${COLOR.BLUE_4};
          }
        }
      }
      .right_box {
        width: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 56px;
        .detail_2 {
          width: 250px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: #868686;
        }
        .group_line {
          font-size: ${FONT.SIZE_12};
          display: flex;
          margin-bottom: 10px;
          color: #868686;
          .icon_show {
            display: flex;
            align-items: center;
            margin-right: 20px;
            .img_show {
              margin-right: 5px;
              width: 24px;
            }
            &.no_margin {
              margin-right: 0px;
            }
          }
        }
        .detail_3 {
          font-size: ${FONT.SIZE_14};
          margin-bottom: 10px;
          color: #868686;
        }
        .detail_4 {
          color: ${COLOR.BLUE_4};
        }
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    // .theme_standard {

    // }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    // .theme_standard {

    // }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_standard {
      padding-bottom: 0px;
      border-bottom: none;
      .title_show {
        margin-top: 20px;
      }
      .group_detail {
        flex-direction: column;
        .left_box {
          width: 100%;
        }
        .right_box {
          margin-top: 0px;
          .detail_2 {
            display: none;
          }
          .detail_3 {
            display: none;
          }
          .detail_4 {
            display: none;
          }
          .group_line {
            display: none;
          }
        }
      }
    }
  }
`;

import styled from 'styled-components';
import { SCREEN } from 'assets';

export const RegisterContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;

  .layout_left {
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 50%;
  }
  .layout_right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
    .btn_next {
      width: 300px;
      height: 50px;
      margin-top: 10px;
      background-color: #3b96b7;

      font-size: 18px;
      border: none;
      border-radius: 30px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    justify-content: center;
    // height: 100vh;
    .layout_left {
      width: 40%;
    }
    .layout_right {
      width: 60%;
      overflow-x: hidden;
      .btn_next {
        width: 250px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    justify-content: center;
    // height: 100vh;
    .layout_left {
      width: 0px;
    }
    .layout_right {
      width: 100%;
      overflow-x: hidden;
      // margin-right:20px;
      .btn_next {
        width: 250px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`;

export const LoaderWrapStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import styled from "styled-components";
import { COLOR, FONT } from "assets";

export const BoxProduct2Styled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  height: 100%;
  .theme_standard {
    background: ${COLOR.WHITE_1};
    width: 100%;
    font-size: ${FONT.SIZE_14};
    color: ${COLOR.GRAY_13};
    border-radius: 16px;
    border: 1px solid ${COLOR.GRAY_12};
    display: flex;
    min-width: 160px;
    cursor: ${({ not_cursor }) => (not_cursor ? "none" : "pointer")};
    height: 100%;
    .left_layout {
      flex-shrink: 0;
      width: 106px;
      display: flex;
      align-items: center;
      .box_img {
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        .show_img {
          width: 100%;
          height: auto;
        }
      }
    }
    .right_layout {
      width: 70%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .right_title {
        font-size: ${FONT.SIZE_16};
        color: ${COLOR.ORANGE_1};
        font-weight: 800;
        margin-bottom: 12px;
        .label_show {
          margin-right: 10px;
          color: ${COLOR.GREEN_2};
        }
      }
      .show_detail2 {
        font-size: ${FONT.SIZE_14};
        font-weight: 400;
        color: #13131380;
        &.semibold {
          font-weight: 600;
        }
        &.mb_2 {
          margin-bottom: 2px;
        }
      }
      .show_detail3 {
        font-size: ${FONT.SIZE_12};
        font-weight: bold;
        color: ${COLOR.GRAY_5};
        margin-top: 2px;
      }
    }
  }
`;

import styled, { keyframes } from "styled-components";
import { SCREEN } from "assets";
import { fadeInUp } from "react-animations";

const bounceAnimation = keyframes`${fadeInUp}`;
export const BodyQuardrantFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .img_logo {
    width: 50px;
    height: 50px;
  }
  .btn_item {
    height: 40px;
    width: 60px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: -1px -3px 17px -11px rgba(0, 0, 0, 0.75);
  }

  .img_box {
    width: 320px;
    height: 400px;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: 2s ${bounceAnimation};
    margin-top: 20px;
    img {
      position: absolute;
      z-index: 100;
      height: 400px;
      object-fit: contain;
    }
    .box_choice {
      position: absolute;
      top: 240px;
      right: 0;
      .item_choice {
        height: 40px;
        width: 100px;
        display: flex;
        font-size: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        box-shadow: -1px -3px 17px -11px rgba(0, 0, 0, 0.75);
        background-color: #ffffff;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .item_choice_active {
        height: 40px;
        width: 100px;
        display: flex;
        font-size: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        box-shadow: -1px -3px 17px -11px rgba(0, 0, 0, 0.75);
        background-color: #1d96b5;
        color: #ffffff;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }

    .tag_right_top {
      position: absolute;
      width: 150px;
      top: 110px;
      right: 0;
      display: flex;
      height: 40px;
      justify-content: flex-end;
      align-items: center;
      background-color: #ffffff;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      box-shadow: -1px -3px 17px -11px rgba(0, 0, 0, 0.75);
    }

    .tag_right_top_active {
      position: absolute;
      width: 150px;
      top: 110px;
      right: 0;
      display: flex;
      height: 40px;
      justify-content: flex-end;
      align-items: center;
      padding: 20px;
      font-size: 12px;
      color: #ffffff;
      background-color: #1d96b5;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      box-shadow: -1px -3px 17px -11px rgba(0, 0, 0, 0.75);
    }

    .tag_right_bottom {
      position: absolute;
      width: 150px;
      top: 160px;
      right: 0;
      display: flex;
      height: 40px;
      justify-content: flex-end;
      /* padding: 20px; */
      align-items: center;
      background-color: #ffffff;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      box-shadow: -1px -3px 17px -11px rgba(0, 0, 0, 0.75);
    }

    .tag_right_bottom_active {
      position: absolute;
      width: 150px;
      top: 160px;
      right: 0;
      display: flex;
      height: 40px;
      justify-content: flex-end;
      /* padding: 20px; */
      padding: 20px;
      font-size: 12px;
      align-items: center;
      color: #ffffff;
      background-color: #1d96b5;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      box-shadow: -1px -3px 17px -11px rgba(0, 0, 0, 0.75);
    }

    .tag_left_top {
      position: absolute;
      width: 150px;
      top: 110px;
      left: 0;
      display: flex;
      height: 40px;
      justify-content: flex-start;
      /* padding: 20px; */
      align-items: center;
      background-color: #ffffff;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      box-shadow: -1px -3px 17px -11px rgba(0, 0, 0, 0.75);
    }

    .tag_left_top_active {
      position: absolute;
      width: 150px;
      top: 110px;
      left: 0;
      display: flex;
      height: 40px;
      justify-content: flex-start;
      /* padding: 20px; */
      align-items: center;
      color: #ffffff;
      background-color: #1d96b5;
      padding: 20px;
      font-size: 12px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      box-shadow: -1px -3px 17px -11px rgba(0, 0, 0, 0.75);
    }

    .tag_left_bottom {
      position: absolute;
      width: 150px;
      top: 160px;
      left: 0;
      display: flex;
      height: 40px;
      justify-content: flex-start;
      /* padding: 20px; */
      align-items: center;
      background-color: #ffffff;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      box-shadow: -1px -3px 17px -11px rgba(0, 0, 0, 0.75);
    }

    .tag_left_bottom_active {
      position: absolute;
      width: 150px;
      top: 160px;
      left: 0;
      display: flex;
      height: 40px;
      justify-content: flex-start;
      /* padding: 20px; */
      align-items: center;
      color: #ffffff;
      background-color: #1d96b5;
      padding: 20px;
      font-size: 12px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      box-shadow: -1px -3px 17px -11px rgba(0, 0, 0, 0.75);
    }
  }
  .txt_detail {
    color: #003564;
    font-size: 18px;
    margin-top: 20px;
    max-width: 400px;
  }
  .txt_detail2 {
    color: grey;
    font-size: 16px;
    margin-top: 10px;
    max-width: 400px;
    animation: 1s ${bounceAnimation};
  }
  .title {
    font-weight: 500;
    font-size: 18px;
    color: #2f4f74;
    margin: 20px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .btn_bottom {
    width: 100%;
    justify-content: center;
    padding-left: 10px;
    height: 40px;
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_standard {
      width: 90vw;
      display: flex;
      justify-content: center;
      .btn_layout {
        width: 100%;
      }
    }
  }
`;

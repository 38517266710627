import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { TrueWalletFormStyled } from './styled';
import { VALIDATE } from 'helpers';
import { Input } from 'components';

class TrueWalletForm extends React.Component {
  state = {};

  render() {
    const { handleClickSubmit, handleSubmit } = this.props;
    return (
      <TrueWalletFormStyled>
        <form onSubmit={handleSubmit(handleClickSubmit)}>
          <div className="title">กรุณากรอกเบอร์โทรศัพท์</div>
          <Field
            theme_standard_2
            name="contact_phone"
            component={Input}
            validate={[VALIDATE.REQUIRE, VALIDATE.PHONE_NUMBER]}
          />
          <button>ตกลง</button>
        </form>
      </TrueWalletFormStyled>
    );
  }
}

export default reduxForm({
  form: 'TrueWalletForm', // a unique identifier for this form
  enableReinitialize: true,
})(TrueWalletForm);

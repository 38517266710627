import React from "react";
import { Helmet } from "react-helmet";

export default function HelmetMetaData(props) {
   let {location} = props;
   let currentUrl = process.env.REACT_APP_SELF_ENDPOINT + ((location !== undefined) ? location.pathname : "/");
   let quote = props.quote !== undefined ? props.quote : "";
   let title = props.title !== undefined ? props.title : "Well | Revolutionize your healthcare journey";
   let image = props.image !== undefined ? props.image : process.env.REACT_APP_API_ENDPOINT + "/images/well.png";
   let description = props.description !== undefined ? props.description  : 'Well (เวลล์) คือเครื่องมือที่ทำให้คุณได้สำรวจสัญญาณเตือนจากร่างกาย รวมถึงปัจจัยเสี่ยงที่อาจก่อให้เกิดโรคมะเร็ง โดยการใช้ระบบ A.I.(ปัญญาประดิษฐ์) Guideline ประสบการณ์จากผู้เชี่ยวชาญทางการแพทย์ และข้อมูลสนับสนุนโดย รพ.มะเร็งอุดร';
   let hashtag = props.hashtag !== undefined ? props.hashtag : "#PinkAlert2020";
   let fb_app_id = props.fb_app_id !== undefined ? props.fb_app_id : process.env.REACT_APP_FB_APP_ID;
return (
 <Helmet>
     <title>{title}</title>
     <meta charset="utf-8" />
     <meta http-equiv="X-UA-Compatible" content="IE=edge" />
     <meta name="csrf_token" content="" />
     <meta property="type" content="website" />
     <meta property="url" content={currentUrl} />
     <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
     <meta name="msapplication-TileColor" content="#ffffff" />
     <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
     <meta name="theme-color" content="#ffffff" />
     <meta name="_token" content="" />
     <meta name="robots" content="noodp" />
     <meta property="title" content={title} />
     <meta property="quote" content={quote} />
     <meta name="description" content={description} />
     <meta property="image" content={image} />
     <meta property="og:locale" content="en_US" />
     <meta property="og:type" content="website" />
     <meta property="og:title" content={title} />
     <meta property="og:quote" content={quote} />
     <meta property="og:hashtag" content={hashtag} />
     <meta property="og:image" content={image} />
     <meta content="image/*" property="og:image:type" />
     <meta property="og:url" content={currentUrl} />
     <meta property="og:site_name" content="Pink Alert Project" />
     <meta property="og:description" content={description} />
     <meta property="fb:id" content={fb_app_id} />
</Helmet>
);
}

import styled from 'styled-components';
import { SCREEN } from 'assets';

export const ProfileScreeningContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;

  min-height: calc(100vh - 105px);
  height: auto;
  .layout_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 20px;
    .row {
      display: flex;
      width: 650px;
      .family_layout {
        width: 50%;
        .text_title {
          font-size: 18px;
          font-weight: 500;
          color: #2f4f74;
        }
        .list_layout {
          display: flex;
          align-items: center;
          margin-top: 10px;
          .text_list {
            font-size: 18px;
            font-weight: 400;
            color: #2f4f74;
            margin-right: 10px;
          }
          .text_edit {
            font-size: 14px;
            font-weight: 300;
            color: #b1b1b1;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .add_family {
          display: flex;
          align-items: center;
          margin-top: 20px;
          width: 150px;
          margin-bottom: 50px;
          cursor: pointer;
          img {
            width: 50px;
            margin-bottom: -10px;
          }
          .text_add {
            font-size: 18px;
            font-weight: 400;
            color: #2f4f74;
            margin-left: -8px;
          }
        }
      }
      .btn {
        width: 50%;
        padding-left: 25px;
        /* margin-top: -20px; */
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    justify-content: center;
    // height: 100vh;
    .layout_form {
      width: 100%;
      overflow-x: hidden;
      .row {
        width: 100vw;
        .family_layout {
          width: 40%;
          align-items: flex-start;
          margin-left: 50px;
        }
        .btn {
          width: 50%;
          padding-left: 0px;
          margin-top: 0px;
        }
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    justify-content: center;
    .layout_form {
      width: 100%;
      overflow-x: hidden;
      .row {
        flex-direction: column;
        width: 100%;
        .family_layout {
          width: 100%;
          align-items: flex-start;
          margin-left: 50px;
        }
        .btn {
          width: 100%;
          padding-left: 0px;
          margin-top: 0px;
          align-items: center;
          margin-bottom: 50px;
        }
      }
    }
  }
`;

export const LoaderWrapStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

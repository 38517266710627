import React from 'react';

export default function ArrowDown({ color = '#898989', width = "16", height = "8.727" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 8.727">
      <path id="XMLID_223_" d="M1.756,8l6.758,6.759a.727.727,0,1,1-1.029,1.028L.213,8.514a.727.727,0,0,1,0-1.028L7.486.213A.727.727,0,0,1,8.514,1.242Z" transform="translate(0 8.727) rotate(-90)" fill={color} />
    </svg>

  );
}

import styled from 'styled-components';

export const PinkAlertRedirectContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  color: red;
`;

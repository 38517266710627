import styled, { keyframes } from "styled-components";
import { SCREEN, iconImages } from "assets";
import { fadeInUp } from "react-animations";
const bounceAnimation = keyframes`${fadeInUp}`;

export const QuestionnaireContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  height: calc(100vh - 80px);

  background-image: url(${iconImages["dot_bg.png"]});
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top 0;
  overflow-y: auto;
  .question_layout {
    animation: 2s ${bounceAnimation};
  }
  .link_back {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 120px;
    margin-bottom: 20px;
  }
  .img_logo {
    /* margin-top: 50px; */
    width: 50px;
    height: 50px;
  }
  .txt_title {
    color: #003564;
    font-size: 22px;
    max-width: 400px;
    font-weight: 600;
  }
  .txt_detail {
    color: #003564;
    font-size: 16px;
    margin-top: 10px;
    max-width: 400px;
  }
  .txt_detail2 {
    color: grey;
    font-size: 14px;
    margin-top: 10px;
    max-width: 400px;
  }
  .btn_next {
    width: 350px;
    height: 40px;
    margin-top: 30px;
    background-color: #3b96b7;

    font-size: 18px;
    border: none;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }
  .btn_next_white {
    width: 350px;
    height: 45px;
    margin-top: 20px;
    outline: none;
    background-color: #f8f8f8;
    box-shadow: 2px 1px 6px 0px rgba(0, 0, 0, 0.2),
      0 4px 20px 0 rgba(0, 0, 0, 0.19);

    font-size: 18px;
    border: none;
    border-radius: 30px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .btn_next_white {
      width: 90vw;
      height: 45px;
    }
    .txt_detail {
      max-width: 90vw;
    }
  }

  @media (max-height: ${SCREEN.PHONE}) {
    justify-content: flex-start;
    overflow-y: auto;
    padding: 40px 0;
  }
`;

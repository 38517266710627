import React from 'react';
import { LogoLoadingFadeStyled } from './styled';
import { iconLogo } from 'assets';

export const LogoLoadingFade = () => {
  return (
    <LogoLoadingFadeStyled>
      <img
        className="logo_line_out"
        src={iconLogo['well_logo_circle_outer.png']}
        alt=""
      />
      <img
        className="logo_line_in"
        src={iconLogo['well_logo_circle_inner.png']}
        alt=""
      />
      <img className="log_char" src={iconLogo['well_square_transparent.png']} alt="" />
    </LogoLoadingFadeStyled>
  );
};

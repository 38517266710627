import styled from "styled-components"
 
export const PinkAlertMainLayoutStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
  height: 100%;
`
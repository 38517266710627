import React from 'react'
import ReactGA from 'react-ga';
import { connect } from 'react-redux'
import { AdviceDoctorContainerStyled } from './styled'
// import { userService } from "apiService"
// import { iconImages } from 'assets'
import { ROUTE_PATH } from 'helpers'
import {
    setReduxUserAuth,
} from 'actions'
import { BoxAdvice, BoxLink, LogoLoadingFade } from 'components'
// import Loader from "react-loader-spinner";

class AdviceDoctorContainer extends React.Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.scrollToTop();
        this.setLoadder();
        // Tracking user pageview
        ReactGA.set({ page: this.props.location.pathname });
        ReactGA.pageview(this.props.location.pathname);
    }

    setLoadder = () => {
        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 300);
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    handleClickBackFirstPage = () => {
        this.props.history.push(ROUTE_PATH.HOME_SCREENING)
    }

    handleClickBackPage = () => {
        this.props.history.push(ROUTE_PATH.HOME_SCREENING)
    }

    handleClickService = () => {
        // Tracking user event
        ReactGA.event({
          category: 'click_pyt_package',
          action: 'advice_doctor_click_pyt_package',
        });
        this.props.history.push(ROUTE_PATH.INSURANCE_PACKAGE)
    }

    render() {
        const { loading } = this.state
        return (
            <AdviceDoctorContainerStyled>
                {
                    loading ? (
                        <div className="loader_layout">
                            <LogoLoadingFade />
                            {/* <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={500}
                                className="lodding"
                            /> */}
                        </div>
                    ) :
                        (
                            <>
                                <div className="link_back" onClick={() => this.handleClickBackPage()}>
                                    <BoxLink
                                        theme_standard_box_link
                                        showArrow
                                        label="ย้อนกลับ"
                                    />
                                </div>
                                <div className="container">
                                    <BoxAdvice
                                        theme_standard
                                        handleClickBackFirstPage={() => this.handleClickBackFirstPage()}
                                        handleClickService={() => this.handleClickService()}
                                    />
                                </div>
                            </>
                        )
                }
            </AdviceDoctorContainerStyled>
        )
    }
}

const mapStateToProps = state => ({
    authRedux: state.auth
})

const mapDispatchToProps = dispatch => ({
    setReduxUserAuth: data => dispatch(setReduxUserAuth(data))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdviceDoctorContainer)

import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const BoxPaymentStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        
        font-size: ${FONT.SIZE_14};
        color: ${COLOR.BLUE_2};
        width: 100%;
        background: ${COLOR.WHITE_1};
        border: 1px solid ${COLOR.BLUE_4};
        border-radius: 8px;
        padding: 20px;
        box-shadow: 6px 7px 11px -8px rgba(88,88,88,0.32);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .close_layer {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 22px;
            right: 20px;
            cursor: pointer;
        }
        .img_show {
            background: ${COLOR.WHITE_1};
            border-radius: 50%;
            border: 1px solid ${COLOR.BLUE_7};
            height: 56px;
            width: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            .img_title {
                height: 29px;
                width: 29px;
            }
        }
        .title_show {
            font-weight: 800;
            margin-bottom: 11px;
        }
        .message_layer {
            font-size: ${FONT.SIZE_18};
            color: ${COLOR.BLUE_7};
            font-weight: 800;
            margin-bottom: 22px;
            &.no_margin {
                margin: 0px;
            }
        }
        .label2 {
            font-size: ${FONT.SIZE_12};
            font-weight: 600;
            margin-bottom: 24px;
        }
        .btn {
            padding: 8px;
            font-size: ${FONT.SIZE_14};
            font-weight: 600;
            color: ${COLOR.WHITE_1};
            width: 100%;
            background: ${COLOR.BLUE_4};
            border-radius: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-shadow: 6px 7px 11px -8px rgba(88,88,88,0.32);
        }
    }
`
import styled from 'styled-components';
import { SCREEN } from 'assets';

export const RegisterFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  input.input {
    
  }
  .form_layout {
    margin: 10px 0px;
    padding-top: 30px;
    .layout_top {
      display: flex;
      flex-direction: column;
      margin-top: 60px;
      .txt_login {
        color: #003564;
        font-size: 24px;
        margin-bottom: 10px;
      }
      .btn_fb {
        width: 300px;
        height: 50px;
        margin-top: 40px;
        background-color: #3061a1;
        border-radius: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .txt_middle {
      display: flex;
      justify-content: center;
      color: #3061a1;
      font-size: 12px;
      margin: 10px;
    }
    .group_input {
      text-align: left;
      margin-bottom: 10px;
      .label_name {
        color: #3061a1;
        
        margin: 10px 0;
        font-weight: 600;
        font-size: 13px;
        display: inline-block;
        line-height: 1;
      }
    }
    .group_input_2 {
      text-align: left;
      margin-bottom: 10px;
      margin-top: -20px;
      .label_name {
        color: #3061a1;
        
        margin: 10px 0;
        font-weight: 600;
        font-size: 13px;
        display: inline-block;
        line-height: 1;
      }
    }
    .layout_YN {
      display: flex;
      // padding-left: 30px;
      margin-bottom: 10px;
      .YN_left {
        width: 50%;
        .label_name {
          color: #3061a1;
          
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 14px;
          display: inline-block;
          line-height: 1;
        }
      }
      .YN_right {
        width: 50%;
        .label_name {
          color: #3061a1;
          
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 14px;
          display: inline-block;
          line-height: 1;
        }
      }
    }
    .gender_layout {
      display: flex;
      flex-direction: row;
      // width: 300px;
      // margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .label_name {
        color: #3061a1;
        
        margin: 1px 0;
        font-weight: 600;
        font-size: 14px;
        display: inline-block;
        line-height: 1;
      }
      .gen_opt {
        // margin-bottom: -5px;
      }
    }
  }
  .btn_next {
    width: 300px;
    height: 50px;
    margin-top: 10px;
    background: #3b96b7;
    font-size: 18px;
    border: none;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    &:disabled {
      cursor: not-allowed;
      background: ${({ theme }) => theme.COLORS.BLACK_1}40;
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    width: 100%;
    padding: 0px 30px;
    .form_layout {
      padding: 0px;
      .layout_top {
        .txt_login {
          color: #003564;
          font-size: 18px;
          margin-left: 55px;
        }
        .btn_fb {
          width: 250px;
          height: 40px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .group_input {
        width: 100%;
        .label_name {
          font-size: 13px;
          margin: 10px 0;
        }
      }
      .layout_YN {
        display: flex;
        margin: 10px 0;
        .YN_left {
          .label_name {
            font-size: 12px;
          }
        }
        .YN_right {
          .label_name {
            font-size: 12px;
          }
        }
      }
      .gender_layout {
        .label_name {
          font-size: 12px;
          // padding-left: 30px;
        }
      }
      .check_input {
        margin-left: 55px;
      }
    }
  }
`;

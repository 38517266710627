import styled from "styled-components"
import {
    COLOR,
    FONT,
    SCREEN,
} from 'assets'

export const OrderlistStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        width: 100%;
        
        font-size: ${FONT.SIZE_20};
        color: ${COLOR.BLUE_2};
        font-weight: 600;
        .title_show {
            margin-bottom: 40px;
        }
        .img_show {
            text-align: center;
            margin-bottom: 30px;
            .img_scr {
                width: 164px;
            }
        }
        .label_show {
            text-align: center;
            color: ${COLOR.BLACK_4};
        }
    }
    @media(max-width: ${SCREEN.TABLET}){
        .theme_standard {
            // background: pink;
        }
    }
    @media(max-width: ${SCREEN.MINI_TABLET}){
        .theme_standard {
            // background: red;
        }
    }
    @media(max-width: ${SCREEN.PHONE}){
        .theme_standard {
            .title_show {

            }
        }
    }
`
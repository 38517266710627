import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const OptionBoxStyled = styled.div`
/*===============================================
 Container 
===============================================*/
width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard_opt {
        width: 100%;
        cursor: pointer;
        
        font-size: ${FONT.SIZE_14};
        font-weight: 500;
        color: ${COLOR.WHITE_1};
        .opt_layout {
            padding: 12px;
            border-radius: 22px;
            border: 1px solid ${COLOR.WHITE_1};
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 14px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
    .theme_herwill {
        width: 100%;
        cursor: pointer;
        
        font-size: ${FONT.SIZE_16};
        font-weight: 500;
        color: ${COLOR.PINK_1};
        .opt_layout {
            padding: 12px;
            border-radius: 22px;
            // border: 1px solid ${COLOR.WHITE_1};
            background: ${COLOR.WHITE_1};
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 14px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
`
import styled, { keyframes } from "styled-components";
import { SCREEN } from "assets";
import { fadeInUp } from "react-animations";

const bounceAnimation = keyframes`${fadeInUp}`;
export const ImgAndButtonFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  width: 300px;
  margin-left: 10px;
  .img_logo {
    height: 50px;
    width: 50px;
  }
  .txt_detail {
    color: #003564;
    font-size: 18px;
    margin-top: 20px;
    max-width: 400px;
  }
  .txt_detail2 {
    color: grey;
    font-size: 16px;
    margin-top: 10px;
    max-width: 400px;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    color: #2f4f74;
    margin: 20px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .title2 {
    font-size: 14px;
    color: #777777;
    margin: -3px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .img_question {
    width: 100px;
    margin: 10px;
    border-radius: 50%;
    border: 6px solid #ffffff;
    animation: 2s ${bounceAnimation};
  }
  .button_layout {
    animation: 2s ${bounceAnimation};

    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .button_layout {
      .theme_standard {
        width: 200px;
      }
    }
  }
`;

import styled from "styled-components";
import { COLOR, FONT, SCREEN } from "assets";

export const CartFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  
  font-size: ${FONT.SIZE_20};
  .group_bottom {
    display: flex;
    width: 100%;
    .bottom_left_box {
      width: 44%;
      display: flex;
      .img_layer {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .product_show {
          width: 100%;
        }
      }
      .detail_layer {
        width: 50%;
        padding-left: 30px;
        line-height: 1.98;
        .title_show {
          font-size: ${FONT.SIZE_25};
          font-weight: bold;
          color: ${COLOR.BLUE_2};
        }
        .label_show {
          font-size: ${FONT.SIZE_18};
          color: ${COLOR.GRAY_6};
        }
        .group_price {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          font-size: ${FONT.SIZE_18};
          font-weight: bold;
          .price_1 {
            color: ${COLOR.GRAY_7};
            position: relative;
          }
          .price_1:after {
            content: "";
            position: absolute;
            left: 0;
            top: 64%;
            margin-top: -3px;
            width: 100%;
            height: 1px;
            background: ${COLOR.RED_3};
            -webkit-transform: rotateZ(-15deg);
            -moz-transform: rotateZ(-15deg);
          }
          .price_2 {
            color: ${COLOR.BLUE_2};
          }
        }
      }
    }
    .bottom_right_box {
      width: 50%;
      padding-left: 90px;
      .group_amount {
        .label_show {
          font-size: ${FONT.SIZE_14};
          color: ${COLOR.GRAY_6};
          margin: 10px 0px;
        }
        .box_qty {
          width: 180px;
          margin-bottom: 10px;
        }
      }
      .btn_icon {
        width: 86%;
        // background: green;
      }
    }
  }
  .show_border {
    margin-top: 40px;
    border-bottom: 1px solid ${COLOR.GRAY_4};
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .group_bottom {
      .bottom_right_box {
        .btn_icon {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .group_bottom {
      .bottom_right_box {
        .btn_icon {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    padding-bottom: 0px;
    .group_bottom {
      display: block;
      .bottom_left_box {
        width: 100%;
        .detail_layer {
          padding-left: 20px;
        }
      }
      .bottom_right_box {
        width: 100%;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .group_amount {
          width: 50%;
          padding-left: 20px;
          .box_qty {
            width: 100%;
            margin-bottom: 0px;
          }
        }
        .btn_icon {
          width: auto;
          margin-top: 28px;
        }
      }
    }
  }
  @media (max-width: 320px) {
    .group_bottom {
      display: block;
      .bottom_left_box {
        width: 100%;
        .detail_layer {
          padding-left: 20px;
          .group_price {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
`;

import React from 'react';
import { QuestionContainerStyled } from './styled';
import { ROUTE_PATH } from 'helpers';
import { connect } from 'react-redux';
// import Loader from "react-loader-spinner";
import { Button, BoxLink, LogoLoadingFade } from 'components';

class QuestionContainer extends React.Component {
  state = { loading: true };

  componentDidMount() {
    this.scrollToTop();
    this.setLoadder();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  handleClickToSelectSymptom = (select) => {
    const { authRedux, handleInterested } = this.props;
    let symptoms = [];
    let answers = {};
    let risk_factors = {
      firstname: authRedux.firstname,
      lastname: authRedux.lastname,
      birthday: authRedux.birthday,
      gender: authRedux.gender,
      weight: authRedux.weight,
      height: authRedux.height,
      is_smoke: authRedux.is_smoke,
      is_alcohol: authRedux.is_alcohol,
      disease: authRedux.disease,
    };
    if (select.key && select.key !== 'OTHER') {
      symptoms = [
        {
          key: select.key,
          area: select.area,
          value: 'yes',
          is_primary: 1,
        },
      ];
    } else {
      symptoms = [];
    }
    answers = {
      risk_factors: risk_factors,
      symptoms: symptoms,
      answer: [],
    };
    handleInterested(answers);
  };

  handleClickBackPage = () => {
    const { historyLink } = this.props;
    historyLink.push(ROUTE_PATH.HOME_SCREENING);
  };

  render() {
    const { symptom } = this.props;
    const { loading } = this.state;
    return (
      <QuestionContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div
              className="link_back"
              onClick={() => this.handleClickBackPage()}
            >
              <BoxLink theme_standard_box_link label="กลับสู่หน้าหลัก" />
            </div>
            <div className="group_layout">
              <div className="box_question">
                <div className="header">
                  <img
                    className="img_logo"
                    src={symptom && symptom.area.image_uri}
                    alt=""
                  />
                  <div className="title">
                    เลือกอาการที่ส่งสัญญาณเด่นชัด หรืออยากตรวจ?
                  </div>
                </div>
                <div className="body">
                  <div className="question">
                    {symptom &&
                      symptom.symptom_group_left.map((e, i) => (
                        <div style={{ marginBottom: '20px' }}>
                          <div className="header_question">
                            <img src={e.image_uri} alt="" />
                            <div className="test_header_question">{e.name}</div>
                          </div>
                          <div className="btn_question">
                            {e.symptoms.map((e1, i) => (
                              <Button
                                width="200px"
                                height="40px"
                                theme_standard
                                backgroundColor="#ffffff"
                                color="#000000"
                                font="14px"
                                weight="400"
                                style={{ marginTop: 10 }}
                                handleClickButton={() =>
                                  this.handleClickToSelectSymptom(e1)
                                }
                              >
                                {e1.name}
                              </Button>
                            ))}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="question">
                    {symptom &&
                      symptom.symptom_group_right.map((e, i) => (
                        <div style={{ marginBottom: '20px' }}>
                          <div className="header_question">
                            {e.image_uri && <img src={e.image_uri} alt="" />}
                            <div className="test_header_question">{e.name}</div>
                          </div>
                          <div className="btn_question">
                            {e.symptoms.map((e1, i) => (
                              <Button
                                width="200px"
                                height="40px"
                                theme_standard
                                backgroundColor="#ffffff"
                                color="#000000"
                                font="14px"
                                weight="400"
                                style={{ marginTop: 10 }}
                                handleClickButton={() =>
                                  this.handleClickToSelectSymptom(e1)
                                }
                              >
                                {e1.name}
                              </Button>
                            ))}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="btn_next">
                {/* <Button
            width="300px"
            height="45px"
            theme_standard
            style={{ marginTop: 20 }}
            handleClickButton={this.handleClickToInterested}

          >
            ต่อไป
          </Button> */}
              </div>
            </div>
          </>
        )}
      </QuestionContainerStyled>
    );
  }
}
const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

export default connect(mapStateToProps)(QuestionContainer);

import styled from "styled-components";
import { SCREEN } from "assets";

export const RadioStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_circle_column {
    display: flex;
    flex-direction: column;
    .container_wrap {
      width: 100px;
      margin-bottom: ${props => props.margin_bottom2 ? props.margin_bottom2 : "5px"};
      .container {
        display: block;
        position: relative;
        padding-left:  ${props => props.padding_left ? props.padding_left : "28px"};
        margin-bottom: 0px;
        // cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .txt_label {
          color: #3061a1;
          
          font-size: 14px;
          padding-top: ${props => props.padding_top ? props.padding_top : "3px"};
        }
      }
      .container input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        border-radius: 50%;
        width: 16px;
        height: 16px;

        border: 2px solid #3da3c1;
        margin-right: 5px;
        outline: none;
      }

      .container input:checked {
        background-color: #3da3c1;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      .container input:checked ~ .checkmark:after {
        display: block;
      }

      .container .checkmark:after {
        top: 1.5px;
        left: 1.6px;
        width: 1px;
        height: 1px;
        border-radius: 50%;
        background-color: white;
      }
    }
  }

  .theme_circle {
    .container_wrap {
      margin-bottom: ${props => props.margin_bottom2 ? props.margin_bottom2 : "5px"};
      .container {
        display: block;
        position: relative;
        padding-left: 28px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: row;
        // cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .txt_label {
          color: #3061a1;
          
          font-size: 14px;
          padding-top: ${props => props.padding_top ? props.padding_top : "3px"};
          margin-right: 20px;
        }
      }
      .container input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        border-radius: 50%;
        width: 16px;
        height: 16px;

        border: 2px solid #3da3c1;
        margin-right: 5px;
        outline: none;
      }

      .container input:checked {
        background-color: #3da3c1;
      }

      .container input:checked :before {
        border: 2px solid #ef5350;
      }

      .container input:checked :after {
        border: 2px solid #ef5350;
      }
    }
  }

  .theme_circle2 {
    .container_wrap {
      margin-bottom: ${props => props.margin_bottom2 ? props.margin_bottom2 : "5px"};
      .container {
        position: relative;
        padding-left: 28px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: row;
        // cursor: pointer;
        justify-content: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .txt_label {
          color: #3B96B7;
          
          font-size: 14px;
          font-weight: bold;
          padding-top: ${props => props.padding_top ? props.padding_top : "3px"};
          margin-right: 79px;
          margin-left: 6px;
        }
      }
      .container input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        border-radius: 50%;
        width: 16px;
        height: 16px;

        border: 2px solid #3da3c1;
        // margin-right: 5px;
        outline: none;
      }

      .container input:checked {
        background-color: #3da3c1;
      }

      .container input:checked :before {
        border: 2px solid #ef5350;
      }

      .container input:checked :after {
        border: 2px solid #ef5350;
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
  }
`;

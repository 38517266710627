import styled, { keyframes } from "styled-components";
import { SCREEN } from "assets";
import { fadeInUp } from 'react-animations';

const bounceAnimation = keyframes`${fadeInUp}`;
export const StandardFormStyled = styled.div`

  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
width:400px;
  .img_logo {
    width:50px;
    height:50px;
  }
  .txt_detail {
    color: #003564;
    font-size: 18px;
    margin-top: 20px;
    max-width: 400px;
  }
  .txt_detail2 {
    color: grey;
    font-size: 16px;
    margin-top: 10px;
    max-width: 400px;
  }
  .title {
    animation: 1s ${bounceAnimation};
    font-weight: 500;
    font-size: 18px;
    color: #2f4f74;
    margin: 20px 0 0 10px;
  }
  .title2 {
    
    font-size: 14px;
    color: #777777;
    margin: -3px 0 0 10px;
  }
  .button_layout {
    animation: 1s ${bounceAnimation};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  @media (max-width: ${SCREEN.PHONE}) {
    width: 90vw;
    .theme_standard {
      width: 90vw;
      display: flex;
      justify-content: center;
      .btn_layout {
        width: 100%;
      }
    }
  }
`;

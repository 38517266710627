import React, { useState, createContext, useContext } from 'react';
import { SplashScreenStyled } from './styled';
import { iconImages } from 'assets';

//CREATE CONTEXT
const SplashContext = createContext({});

export const SplashScreen = ({ children }) => {
  const [isShow, setIsShow] = useState(false);

  const showSplashScreen = () => {
    setIsShow(true);
  };

  const notShowSplashScreen = () => {
    console.log('notShowSplashScreen',)
    setIsShow(false);
  };

  // STORE CONTEXT
  const splashStore = {
    variable: {},
    splashAction: {
      showSplashScreen,
      notShowSplashScreen,
    },
  };

  return (
    <SplashContext.Provider value={splashStore}>
      {isShow && (
        <SplashScreenStyled>
          <img src={iconImages['logo-white.png']} alt="well logo"/>
        </SplashScreenStyled>
      )}
      {children}
    </SplashContext.Provider>
  );
};

// USE CONTEXT HOOK
export const useSplashContext = () => {
  return useContext(SplashContext);
};

//const {splashAction}=useSplashContext()
//splashAction.showSplashScreen()

// USE CONTEXT HOC COMPONENT
export { SplashContext };

// static contextType = SplashContext;
// this.context.splashAction.showSplashScreen();

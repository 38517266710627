import React from 'react';
import ReactGA from 'react-ga';
import { SelectSymptomAreaContainerStyled } from './styled';
import { ROUTE_PATH } from 'helpers';
import { connect } from 'react-redux';
import SelectAreaContainer from './SelectAreaContainer';
import QuestionContainer from './QuestionContainer';
import TextInputForm from '../../../../forms/QuestionForm/TextInputForm';

import InterestedCancerContainer from '../InterestedCancerContainer';
import { iconImagesMale, iconImagesFemale } from 'assets';
import QuestionScreeningContainer from '../QuestionScreeningContainer';
import { LogoLoadingFade } from 'components';

class SelectSymptomAreaContainer extends React.Component {
  state = {
    left: true,
    right: false,
    area: null,
    symptom: {},
    loading: true,
    first_answer: {},
  };

  componentDidMount = () => {
    this.areaSelect();
    this.scrollToTop();
    this.setLoadder();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  };

  // componentDidUpdate() {
  //   window.onpopstate = (e) => {
  //     // this.props.history.push('/');
  //   };
  // }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  handleClickButton = () => {
    this.props.history.push(ROUTE_PATH.PRE_CHECK);
  };

  handleClickLeft = () => {
    this.setState({
      left: true,
      right: false,
    });
  };

  handleClickRight = () => {
    this.setState({
      left: false,
      right: true,
    });
  };

  areaSelect = () => {
    const { subject } = this.props;
    this.setState({
      renderQuestion: (
        <SelectAreaContainer
          handleSelectArea={this.handleSelectArea}
          gender={subject && subject.gender}
          historyLink={this.props.history}
        />
      ),
    });
  };

  handleSelectArea = (area) => {
    switch (area) {
      case 'head':
        this.handleSelectHeadSymptom();
        break;
      case 'neck':
        this.handleSelectNeckSymptom();
        break;
      case 'chest':
        this.handleSelectChestSymptom();
        break;
      case 'skin':
        this.handleSelectSkinSymptom();
        break;
      case 'stomach':
        this.handleSelectStomachSymptom();
        break;
      case 'urethra':
        this.handleSelectUrineSymptom();
        break;
      case 'back':
        this.handleSelectBackSymptom();
        break;
      case 'bottom':
        this.handleSelectBottomSymptom();
        break;

      default:
        break;
    }
  };

  handleSelectHeadSymptom = () => {
    const { subject } = this.props;
    this.setState(
      {
        symptom: {
          area: {
            image_uri:
              subject.gender === 'female'
                ? iconImagesFemale['img_area_head.png']
                : iconImagesMale['img_area_head.png'],
          },
          symptom_group_left: [
            {
              name: 'ศีรษะ',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_head.png']
                  : iconImagesMale['img_symptom_head.png'],
              symptoms: [
                { key: 'FEVER', area: 'HEAD_1', name: 'มีไข้' },
                { key: 'FATIGUE', area: 'HEAD_2', name: 'อ่อนเพลีย' },
                { key: 'HEADACHE', area: 'HEAD_3', name: 'ปวดศีรษะ' },
                { key: 'DIZZINESS', area: 'HEAD_4', name: 'วิงเวียนศีรษะ' },
              ],
            },
            {
              name: 'จมูก',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_nose.png']
                  : iconImagesMale['img_symptom_nose.png'],
              symptoms: [
                { key: 'DYSPNEA', area: 'HEAD_5', name: 'หายใจลำบาก' },
                { key: 'DYSPNEA', area: 'HEAD_6', name: 'หายใจถี่เร็ว' },
              ],
            },
          ],
          symptom_group_right: [
            {
              name: 'ตา',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_eye.png']
                  : iconImagesMale['img_symptom_eye.png'],
              symptoms: [{ key: 'JAUNDICE', area: 'HEAD_7', name: 'ตาเหลือง' }],
            },
            {
              name: 'อาการอื่นๆ\nบริเวณศีรษะด้านบน',
              symptoms: [{ key: 'OTHER', name: 'ระบุเอง' }],
            },
          ],
        },
      },
      this.handleToQuestion
    );
  };

  handleSelectNeckSymptom = () => {
    const { subject } = this.props;
    this.setState(
      {
        symptom: {
          area: {
            image_uri:
              subject.gender === 'female'
                ? iconImagesFemale['img_area_neck.png']
                : iconImagesMale['img_area_neck.png'],
          },
          symptom_group_left: [
            {
              name: 'คอ',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_neck.png']
                  : iconImagesMale['img_symptom_neck.png'],
              symptoms: [
                { key: 'HOARSENESS', area: 'NECK_1', name: 'เสียงแหบ' },
                { key: 'DYSPHAGIA', area: 'NECK_2', name: 'กลืนลำบาก' },
                {
                  key: 'DYSPHAGIA',
                  area: 'NECK_3',
                  name: 'เจ็บจุกในคอหอยเวลากลืน',
                },
                { key: 'COUGH', area: 'NECK_4', name: 'ไอ' },
                { key: 'HEMOPTYSIS', area: 'NECK_5', name: 'ไอมีเลือดปน' },
                { key: 'LUMP', area: 'NECK_6', name: 'คลำได้ก้อนบริเวณคอ' },
              ],
            },
          ],
          symptom_group_right: [
            {
              name: 'ปาก',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_mouth.png']
                  : iconImagesMale['img_symptom_mouth.png'],
              symptoms: [
                { key: 'WEIGHT_LOSS', area: 'NECK_7', name: 'เบื่ออาหาร' },
                { key: 'WEIGHT_LOSS', area: 'NECK_8', name: 'กินไม่ได้' },
              ],
            },
            {
              name: 'อาการอื่นๆบริเวณ\nศีรษะด้านล่างและคอ',
              symptoms: [{ key: 'OTHER', name: 'ระบุเอง' }],
            },
          ],
        },
      },
      this.handleToQuestion
    );
  };

  handleSelectChestSymptom = () => {
    const { subject } = this.props;

    if (subject.gender === 'female') {
      this.setState(
        {
          symptom: {
            area: {
              image_uri: iconImagesFemale['img_area_cheast.png'],
            },
            symptom_group_left: [
              {
                name: 'หน้าอก',
                image_uri: iconImagesFemale['img_symptom_cheast.png'],
                symptoms: [
                  { key: 'CHEST_PAIN', area: 'CHEST_1', name: 'เจ็บหน้าอก' },
                  { key: 'DYSPNEA', area: 'CHEST_2', name: 'หอบ, เหนื่อยง่าย' },
                  { key: 'LUMP', area: 'CHEST_3', name: 'มีก้อนบริเวณอก' },
                ],
              },
              {
                name: 'เต้านม',
                image_uri: iconImagesFemale['img_symptom_breast.png'],
                symptoms: [
                  { key: 'LUMP', area: 'CHEST_4', name: 'มีก้อนที่เต้านม' },
                  {
                    key: 'BREAST_SKIN_CHANGE',
                    area: 'CHEST_5',
                    name: 'ผิวหนังบริเวณเต้านม\nมีการเปลี่ยนแปลง',
                  },
                  { key: 'BREAST_PAIN', area: 'CHEST_6', name: 'เจ็บเต้านม' },
                ],
              },
            ],
            symptom_group_right: [
              {
                name: 'หัวนม',
                image_uri: iconImagesFemale['img_symptom_nipple.png'],
                symptoms: [
                  {
                    key: 'DISCHARGE',
                    area: 'CHEST_7',
                    name: 'มีน้ำเหลืองหรือ\nเลือดออกจากหัวนม',
                  },
                ],
              },
              {
                name: 'อาการอื่นๆ\nบริเวณหน้าอก',
                symptoms: [{ key: 'OTHER', name: 'ระบุเอง' }],
              },
            ],
          },
        },
        this.handleToQuestion
      );
    } else {
      this.setState(
        {
          symptom: {
            area: {
              image_uri: iconImagesMale['img_area_cheast.png'],
            },
            symptom_group_left: [
              {
                name: 'หน้าอก',
                image_uri: iconImagesMale['img_symptom_cheast.png'],
                symptoms: [
                  { key: 'CHEST_PAIN', area: 'CHEST_1', name: 'เจ็บหน้าอก' },
                  { key: 'DYSPNEA', area: 'CHEST_2', name: 'หอบ, เหนื่อยง่าย' },
                  { key: 'LUMP', area: 'CHEST_3', name: 'มีก้อนบริเวณอก' },
                ],
              },
            ],
            symptom_group_right: [
              {
                name: 'อาการอื่นๆ\nบริเวณหน้าอก',
                symptoms: [{ key: 'OTHER', name: 'ระบุเอง' }],
              },
            ],
          },
        },
        this.handleToQuestion
      );
    }
  };

  handleSelectSkinSymptom = () => {
    const { subject } = this.props;
    this.setState(
      {
        symptom: {
          area: {
            image_uri:
              subject.gender === 'female'
                ? iconImagesFemale['img_area_skin.png']
                : iconImagesMale['img_area_skin.png'],
          },
          symptom_group_left: [
            {
              name: 'ผิวหนัง',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_skin.png']
                  : iconImagesMale['img_symptom_skin.png'],
              symptoms: [
                { key: 'JAUNDICE', area: 'SKIN_1', name: 'ตัวเหลือง' },
              ],
            },
          ],
          symptom_group_right: [
            {
              name: 'อาการอื่นๆ\nบริเวณผิวหนัง',
              symptoms: [{ key: 'OTHER', area: 'SKIN_1', name: 'ระบุเอง' }],
            },
          ],
        },
      },
      this.handleToQuestion
    );
  };

  handleSelectStomachSymptom = () => {
    const { subject } = this.props;
    this.setState(
      {
        symptom: {
          area: {
            image_uri:
              subject.gender === 'female'
                ? iconImagesFemale['img_area_stomach.png']
                : iconImagesMale['img_area_stomach.png'],
          },
          symptom_group_left: [
            {
              name: 'ท้อง',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_stomach.png']
                  : iconImagesMale['img_symptom_stomach.png'],
              symptoms: [
                { key: 'ABDOMINAL_PAIN', area: 'STOMACH_1', name: 'ปวดท้อง' },
                {
                  key: 'ABDOMINAL_PAIN',
                  area: 'STOMACH_2',
                  name: 'อึดอัด แน่นท้อง',
                },
                {
                  key: 'CONSTIPATION',
                  area: 'STOMACH_3',
                  name: 'ท้องผูก ถ่ายลำบาก',
                },
                {
                  key: 'LUMP',
                  area: 'STOMACH_4',
                  name: 'คลำได้ก้อนบริเวณท้อง',
                },
                { key: 'DIARRHEA', area: 'STOMACH_5', name: 'ท้องเสีย' },
                {
                  key: 'N_V',
                  area: 'STOMACH_6',
                  name: 'คลื่นไส้ (ท้องส่วนบน)',
                },
                { key: 'WEIGHT_LOSS', area: 'STOMACH_7', name: 'น้ำหนักลด' },
              ],
            },
          ],
          symptom_group_right: [
            {
              name: 'เอว',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_hip.png']
                  : iconImagesMale['img_symptom_hip.png'],
              symptoms: [
                { key: 'HIP_PAIN', area: 'STOMACH_8', name: 'ปวดเอว' },
              ],
            },
            {
              name: 'อาการอื่นๆ\nบริเวณท้องและเอว',
              symptoms: [{ key: 'OTHER', name: 'ระบุเอง' }],
            },
          ],
        },
      },
      this.handleToQuestion
    );
  };

  handleSelectBottomSymptom = () => {
    const { subject } = this.props;
    this.setState(
      {
        symptom: {
          area: {
            image_uri:
              subject.gender === 'female'
                ? iconImagesFemale['img_area_bottom.png']
                : iconImagesMale['img_area_bottom.png'],
          },
          symptom_group_left: [
            {
              name: 'ทวารหนัก',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_bottom.png']
                  : iconImagesMale['img_symptom_bottom.png'],
              symptoms: [
                {
                  key: 'RECTAL_BLEEDING',
                  area: 'BOTTOM_1',
                  name: 'ถ่ายเป็นเลือดสีแดงหรือสีดำ',
                },
                {
                  key: 'RECTAL_BLEEDING',
                  area: 'BOTTOM_2',
                  name: 'มีภาวะเลือดออกจากทวารหนัก',
                },
                {
                  key: 'CONSTIPATION',
                  area: 'BOTTOM_3',
                  name: 'ถ่ายลำบาก',
                },
              ],
            },
          ],
          symptom_group_right: [
            {
              name: 'ก้น',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_bottom.png']
                  : iconImagesMale['img_symptom_bottom.png'],
              symptoms: [
                { key: 'PERI_ANAL_SYMPTOMS', area: 'BOTTOM_4', name: 'ปวดก้น' },
              ],
            },
            {
              name: 'อาการอื่นๆ\nบริเวณก้นและทวารหนัก',
              symptoms: [{ key: 'OTHER', name: 'ระบุเอง' }],
            },
          ],
        },
      },
      this.handleToQuestion
    );
  };

  handleSelectBackSymptom = () => {
    const { subject } = this.props;
    this.setState(
      {
        symptom: {
          area: {
            image_uri:
              subject.gender === 'female'
                ? iconImagesFemale['img_area_back.png']
                : iconImagesMale['img_area_back.png'],
          },
          symptom_group_left: [
            {
              name: 'หลัง',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_back.png']
                  : iconImagesMale['img_symptom_back.png'],
              symptoms: [
                { key: 'BACK_PAIN', area: 'BACK_1', name: 'ปวดหลัง' },
                {
                  key: 'BACK_PAIN',
                  area: 'BACK_2',
                  name: 'ปวดเจ็บที่หลังเป็นพักๆ\nหรือตลอดเวลา',
                },
              ],
            },
          ],
          symptom_group_right: [
            {
              name: 'เอว',
              image_uri:
                subject.gender === 'female'
                  ? iconImagesFemale['img_symptom_hip.png']
                  : iconImagesMale['img_symptom_hip.png'],
              symptoms: [{ key: 'HIP_PAIN', area: 'BACK_3', name: 'ปวดเอว' }],
            },
            {
              name: 'อาการอื่นๆบริเวณหลัง',
              symptoms: [{ key: 'OTHER', name: 'ระบุเอง' }],
            },
          ],
        },
      },
      this.handleToQuestion
    );
  };

  handleSelectUrineSymptom = () => {
    const { subject } = this.props;
    if (subject.gender === 'female') {
      this.setState(
        {
          symptom: {
            area: {
              image_uri: iconImagesFemale['img_area_urine.png'],
            },
            symptom_group_left: [
              {
                name: 'ท่อปัสสาวะ',
                image_uri: iconImagesFemale['img_symptom_urine.png'],
                symptoms: [
                  { key: 'HEMATURIA', area: 'URINE_3', name: 'ปัสสาวะสีแดง' },
                  { key: 'DYSURIA', area: 'URINE_4', name: 'ปัสสาวะแสบขัด' },
                  {
                    key: 'AMBER_URINE',
                    area: 'URINE_5',
                    name: 'ปัสสาวะสีเหลืองเข้ม ',
                  },
                ],
              },
            ],
            symptom_group_right: [
              {
                name: 'อวัยวะเพศ',
                image_uri: iconImagesFemale['img_symptom_urine.png'],
                symptoms: [
                  {
                    key: 'DISCHARGE',
                    area: 'URINE_1',
                    name: 'มีสารคัดหลั่งออกจากช่องคลอด',
                  },
                  {
                    key: 'ABNORMAL_VAGINAL_BLEEDING',
                    area: 'URINE_2',
                    name: 'เลือดออกทางช่องคลอด',
                  },
                ],
              },
              {
                name: 'อาการอื่นๆ\nบริเวณอวัยวะเพศ',
                symptoms: [{ key: 'OTHER', name: 'ระบุเอง' }],
              },
            ],
          },
        },
        this.handleToQuestion
      );
    } else {
      this.setState(
        {
          symptom: {
            area: {
              image_uri: iconImagesMale['img_area_urine.png'],
            },
            symptom_group_left: [
              {
                name: 'ท่อปัสสาวะ',
                image_uri: iconImagesMale['img_symptom_urine.png'],
                symptoms: [
                  { key: 'HEMATURIA', area: 'URINE_3', name: 'ปัสสาวะสีแดง' },
                  { key: 'DYSURIA', area: 'URINE_4', name: 'ปัสสาวะแสบขัด' },
                  {
                    key: 'AMBER_URINE',
                    area: 'URINE_5',
                    name: 'ปัสสาวะสีเหลืองเข้ม ',
                  },
                ],
              },
            ],
            symptom_group_right: [
              {
                name: 'อาการอื่นๆ\nเกี่ยวกับท่อปัสสาวะ',
                symptoms: [{ key: 'OTHER', name: 'ระบุเอง' }],
              },
            ],
          },
        },
        this.handleToQuestion
      );
    }
  };

  handleToQuestion = () => {
    const { symptom } = this.state;
    this.setState({
      renderQuestion: (
        <QuestionContainer
          symptom={symptom}
          handleInterested={this.handleInterested}
          historyLink={this.props.history}
        />
      ),
    });
  };

  handleInterested = (answer) => {
    if (answer && answer.symptoms.length < 1) {
      this.setState({
        first_answer: answer,
        renderQuestion: (
          <TextInputForm
            handleSubmitForm={this.handleSubmitForm}
            question={{ label: 'โปรดระบุอาการอื่นๆของคุณ' }}
          />
        ),
      });
    } else {
      this.setState({
        first_answer: answer,
        renderQuestion: (
          <InterestedCancerContainer
            handleClickNext={this.handleToQuestionScreening}
            historyLink={this.props.history}
          />
        ),
      });
    }
  };

  handleSubmitForm = () => {
    this.handleToQuestionScreening();
  };

  handleToQuestionScreening = () => {
    const { first_answer } = this.state;
    this.setState({
      renderQuestion: (
        <QuestionScreeningContainer
          first_answer={first_answer}
          historyLink={this.props.history}
        />
      ),
    });
  };

  render() {
    const { renderQuestion, loading } = this.state;

    return (
      <SelectSymptomAreaContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>{renderQuestion}</>
        )}
      </SelectSymptomAreaContainerStyled>
    );
  }
}
const mapStateToProps = (state) => ({
  authRedux: state.auth,
  subject: state.subject,
});

export default connect(mapStateToProps)(SelectSymptomAreaContainer);

import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';
// import { iconImages } from 'assets';

export const HomeContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background-color: #f8f8f8;
  min-height: calc(100% - 87px);
  padding: 30px 0px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  background-position: bottom left;
  background-size: auto 450px;
  background-repeat: no-repeat;
  /* opacity: 0.6; */
  .bg_top_right {
    opacity: 0.6;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 300px;
    margin-top: 83px;
  }
  .bg_bottom_left {
    opacity: 0.6;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 300px;
  }
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img_well {
    margin-bottom: 20px;
    width: 129px;
  }
  .banner_layout {
    margin-top: 15px;
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .bg_layout {
    .group_header {
      .text_header1 {
        font-size: 16px;
        color: #2f4f74;
        text-align: center;
      }
      .text_header2 {
        font-weight: 500;
        font-size: 20px;
        color: #2f4f74;
        text-align: center;
      }
      .text_header3 {
        font-weight: 500;
        font-size: 16px;
        color: #2f4f74;
        margin-top: 15px;
        margin-bottom: 10px;
        text-align: center;
      }
    }
    .body_detail {
      display: flex;
      margin-left: 100px;
      margin-top: 20px;

      .box_layout {
        width: auto;
        height: auto;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid ${COLOR.GRAY_12};
        padding: 18px;
        .sub_title {
          font-weight: bold;
          font-size: 24px;
          color: #2f4f74;
          margin-bottom: 28px;
        }
        .img_dot {
          width: 10px;
          object-fit: contain;
          margin: 0px 10px 0px 0px;
          &.adj_margin {
            margin-right: 20px;
          }
        }
        .des_detail {
          font-size: ${FONT.SIZE_14};
          color: ${COLOR.GRAY_15};
          text-align: center;
          margin-top: 10px;
          .bold_title {
            font-weight: bold;
          }
        }
        .hr {
          width: 300px;
          height: 2px;
          background-color: #e9e9e9;
          margin-top: 15px;
        }
        .bottom_box {
          display: flex;
          flex-direction: column;
          width: 300px;
          .txt_bottom {
            font-weight: 500;
            font-size: 14px;
            color: #2f4f74;
            margin-top: 10px;
          }
        }
      }
      .service_layout {
        padding: 40px 20px;
        width: 353px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .service_title {
          font-weight: bold;
          font-size: 14px;
          color: #2f4f74;
          margin-bottom: 27px;
          text-align: center;
        }
        .group_box_service {
          width: 280px;
          .line_opt {
            margin-bottom: 10px;
            &.no_margin {
              margin-bottom: 0px;
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1250px) {
    background-size: auto 350px;

    .bg_top_right {
      width: 200px;
      height: 300px;
    }
    .bg_bottom_left {
      width: 200px;
      height: 350px;
    }
    .bg_layout {
      .body_detail {
        margin-left: 100px;
      }
    }
  }
  @media (max-width: 1060px) {
    background-size: 0 0;
    .img_well {
      display: none;
    }
    .bg_top_right {
      display: none;
    }
    .bg_bottom_left {
      display: none;
    }
    .bg_layout {
      .body_detail {
        margin-left: 100px;
      }
    }
  }

  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .img_well {
      display: none;
    }
    .bg_layout {
      background-size: cover;
      /* min-height: calc(100vh + 350px); */
      .group_header {
        /* margin-top: -150px; */
      }
      .body_detail {
        margin-left: 50px;
      }
    }
  }
  @media (max-width: 750px) {
    padding: 10px 0px;
    .img_well {
      display: none;
    }
    .bg_layout {
      /* background-size: cover; */
      .group_header {
        margin-top: 0px;
      }
      .body_detail {
        display: block;
        margin-left: 0px;

        .service_layout {
          padding: 20px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    padding: 10px 0px;
    .img_well {
      display: none;
    }
    .bg_layout {
      background-size: cover;
      /* min-height: calc(100vh + 250px); */
      .group_header {
        margin-top: 0px;
      }
      .body_detail {
        display: block;
        .service_layout {
          padding: 20px;
        }
      }
    }
  }

  @media (max-width: ${SCREEN.MINI_PHONE}) {
    padding: 10px 0px;
    .img_well {
      display: none;
    }
    .bg_layout {
      background-size: cover;
      /* min-height: calc(100vh + 300px); */
      .group_header {
        margin-top: 0px;
      }
      .body_detail {
        display: block;
        .box_layout {
          width: 320px;
        }
        .service_layout {
          padding: 20px;
          width: 320px;
        }
      }
    }
  }
`;

import React from "react";
import ReactGA from 'react-ga';
import { TermOfUseContainerStyled } from "./styled";
// import { userService } from 'apiService';
import { ROUTE_PATH } from "helpers";
import { iconImages } from "assets";
import { BoxLink, Button, LogoLoadingFade } from "components";
// import Loader from "react-loader-spinner";

class TermOfUseContainer extends React.Component {
  state = {
    isAcceptConditon: false,
    loading: true,
  };

  componentDidMount() {
    this.scrollToTop();
    this.setLoadder();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickNext = () => {
    const { isAcceptConditon } = this.state;
    if (isAcceptConditon) {
      this.setState({
        loading: true,
      });
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        if (this.props.location.state === "Symptom") {
          this.props.history.push(ROUTE_PATH.SELECT_SUBJECT);
        } else if (this.props.location.state === "NoSymptom") {
          this.props.history.push(ROUTE_PATH.SUSPECTED_CANCER);
        }
      }, 1000);
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  render() {
    const { isAcceptConditon, loading } = this.state;
    return (
      <TermOfUseContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
            {/* <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            /> */}
          </div>
        ) : (
            <>
              <div
                className="link_back"
                onClick={() => this.handleClickBackFirstPage()}
              >
                <BoxLink
                  theme_standard_box_link
                  // showArrow
                  label="กลับสู่หน้าหลัก"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="box_layout">
                  <div className="title">ข้อตกลงการให้บริการ</div>
                  <div className="content_container">
                    <div className="desciption">
                      1. Well A.I. database ใช้รูปแบบการถามคำถามจาก 29 อาการ
                      ที่คนทั่วไปมักมองข้าม ซึ่งมักจะนำไปสู่การ
                      ตรวจหาความเสี่ยงที่ช้าเกินไป
                  </div>
                    <div className="desciption">
                      2. โดยพวกเราได้ big data จาก UK research framework
                      ที่จัดทำโดยผู้เชี่ยวชาญทางโรคมะเร็ง
                      เรียนรู้เพิ่มเติ่มเกี่ยวกับ method ของเรา
                  </div>
                    <div className="desciption">
                      3. ใน Version ปัจจุบัน Well ยังจำกัดการให้คำแนะนำ ได้เพียง 6
                      มะเร็งหลัก ในประเทศไทยเท่านั้น
                  </div>
                    <img
                      className=" icon_term"
                      src={iconImages["pic_term.png"]}
                      alt="logo term"
                    />
                    <div className="desciption" >
                      โปรดอ่าน : มีเพียง 2 ใน 10 คนเท่านั้น ที่ผลการประเมิน
                      ออกมาว่ามีความเสี่ยง (เร่งด่วน) ให้ปรึกษาแพทย์มะเร็ง
                      และได้รับการวินิจฉัยว่าเป็นมะเร็งในภายหลัง
                  </div>
                    <div className="accept_container">
                      <div className="input_group">
                        <input
                          id="accept_id"
                          type="checkbox"
                          value={isAcceptConditon}
                          onChange={(e) => {
                            this.setState({
                              isAcceptConditon: !isAcceptConditon,
                            });
                          }}
                        />
                        <label for="accept_id">ฉันยอมรับข้อตกลง</label>
                      </div>
                    </div>
                  </div>
                  <div className="show_btn_term">
                    <div className="btn_term">
                      <Button
                        width="100%"
                        theme_standard
                        disable={!isAcceptConditon}
                        font="14px"
                        height="auto"
                        handleClickButton={this.handleClickNext}
                        padding="10px"
                      >
                        รับทราบและยินยอม
                    </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </TermOfUseContainerStyled>
    );
  }
}

export default TermOfUseContainer;

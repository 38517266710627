const convertDateShow = (datetime) => {
  let dateObj = datetime ? new Date(datetime) : new Date();
  let month =
    dateObj.getMonth() + 1 < 10
      ? '0' + (dateObj.getMonth() + 1)
      : dateObj.getMonth() + 1; //months from 1-12
  let day =
    dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate();
  let year = dateObj.getFullYear();
  return day + '/' + month + '/' + (year + 543);
};

const convertDateDatShow = (datetime) => {
  let dateObj = datetime ? new Date(datetime) : new Date();
  let month =
    dateObj.getMonth() + 1 < 10
      ? '0' + (dateObj.getMonth() + 1)
      : dateObj.getMonth() + 1; //months from 1-12
  let day =
    dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate();
  let year = dateObj.getFullYear();
  return year + '-' + month + '-' + day;
};

const convertDateShow2 = (datetime) => {
  let dateObj = datetime ? new Date(datetime) : new Date();
  let month =
    dateObj.getMonth() + 1 < 10
      ? '0' + (dateObj.getMonth() + 1)
      : dateObj.getMonth() + 1; //months from 1-12
  let day =
    dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate();
  let year = dateObj.getFullYear();
  return day + '/' + month + '/' + year;
};

const RenderMonth = (e) => {
  switch (e) {
    case 1:
      return 'มกราคม';
    case 2:
      return 'กุมภาพันธ์';
    case 3:
      return 'มีนาคม';
    case 4:
      return 'เมษายน';
    case 5:
      return 'พฤษภาคม';
    case 6:
      return 'มิถุนายน';
    case 7:
      return 'กรกฎาคม';
    case 8:
      return 'สิงหาคม';
    case 9:
      return 'กันยายน';
    case 10:
      return 'ตุลาคม';
    case 11:
      return 'พฤศจิกายน';
    case 12:
      return 'ธันวาคม';
    default:
      break;
  }
};

export { convertDateShow, convertDateDatShow, convertDateShow2, RenderMonth };

import styled from 'styled-components';
import { SCREEN } from 'assets';

export const InsurancePlanContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  padding: 20px 0;
  position: relative;
  min-height: calc(100% - 87px);
  background-color: #f8f8f8;
  z-index: 0;
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_back_left {
    display: flex;
    justify-content: flex-start;
    padding: 0px 20px;
  }
  .link_back {
    display: flex;
    justify-content: flex-end;
    padding: 0px 60px;
    margin-top: -40px;
  }
  .container {
    max-width: 800px;
    margin: auto;
  }
  .pc_show {
    .bg_img {
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }
  }
  .mb_show {
    display: none;
    .bg_img {
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
  .dot_img {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  .popup_container {
    margin: 0 10px;
    max-width: 728px;
    width: 100%;
    border: 1px solid #3b96b7;
    border-radius: 20px;
    background: white;
    padding: 8px 25px 16px 25px;
    position: relative;
    .icon_close {
      position: absolute;
      top: 14px;
      right: 11px;
      cursor: pointer;
      padding: 5px 10px;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .logo_box {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .figture {
        width: 252px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .logo_campain {
      text-align: center;
      margin-bottom: 42px;
      img {
        width: 172px;
        height: auto;
      }
    }
    .desktop_row {
      display: flex;
      .left_col {
        width: 50%;
        margin-right: 14px;
      }
      .right_col {
        width: 50%;
        margin-left: 14px;
      }
    }

    .mobile_row {
      display: none;
    }

    .gif_voucher_img_wrap {
      width: 100%;
      margin: auto;
      margin-top: -5px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .txt_line_1 {
      font-size: 15px;
      font-weight: bold;
      color: #235079;
      margin-bottom: 4px;
    }
    .txt_line_2 {
      font-size: 15px;
      font-weight: bold;
      color: #9e9e9e;
      margin-bottom: 4px;
    }
    .txt_line_3 {
      font-size: 15px;
      font-weight: bold;
      color: #9e9e9e;
      margin-bottom: 21px;
      .txt_hightlight {
        color: #3b96b7;
      }
    }
    .buy_btn_wrap {
      margin-bottom: 16px;
    }
    .date_txt {
      margin-top: 2px;
      font-size: 12px;
      font-weight: 500;
      color: #9e9e9e;
      text-align: center;
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .pc_show {
      display: none;
    }
    .mb_show {
      display: block;
    }
    .link_back {
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
  }
  @media (max-width: 700px) {
    padding: 20px 0px;
    .link_back {
      display: none;
    }
    .container {
      padding: 0px 20px;
    }
    .popup_container {
      overflow: auto;
      max-height: 90vh;
      .logo_box {
        margin-bottom: 64px;
        .figture {
          width: 150px;
        }
      }
      .logo_campain {
        margin-bottom: 27px;
        img {
          width: 182px;
        }
      }
      .desktop_row {
        display: none;
      }
      .mobile_row {
        display: block;
      }
      .txt_line_1 {
        text-align: center;
      }
      .txt_line_2 {
        text-align: center;
      }
      .txt_line_3 {
        text-align: center;
        margin-bottom: 26 px;
      }
      .gif_voucher_img_wrap {
        margin: auto;
        margin-bottom: 38px;
        width: 211px;
      }
      .buy_btn_wrap {
        margin-bottom: 14px;
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .pc_show {
      display: none;
    }
    .mb_show {
      display: block;
    }
  }
`;

import React from 'react';
import { AddressNowStyled } from './styled';
import cx from 'classnames';
import { Icons } from 'components';
import { MdModeEdit } from 'react-icons/md';

export const AddressNow = ({
  theme_standard,
  province,
  amphoe,
  isEdit,
  handleClickEditAddress,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <AddressNowStyled>
      <div className={customClass}>
        <div className="address_container">
          <div className="top">
            <Icons.Location />
            <div className="txt">ที่อยู่ปัจจุบันของคุณ</div>
          </div>
          {!province || !amphoe ? (
            <div>ระบุตำแหน่งปัจจุบันของคุณไม่ได้</div>
          ) : (
            <div className="bottom">
              <div className="content_group">
                <div className="value">{province}</div>
              </div>
              <div className="content_group">
                <div className="value">{amphoe}</div>
              </div>
              {isEdit && (
                <div
                  className="icon_edit_wrap"
                  onClick={handleClickEditAddress}
                >
                  <MdModeEdit fontSize="1.25rem "/>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </AddressNowStyled>
  );
};

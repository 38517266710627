import React from "react";
import { ButtonStyled } from "./styled";
import cx from "classnames";

export const Button = ({
  width,
  height,
  backgroundColor,
  children,
  color,
  border,
  disable,
  font,
  weight,
  active,
  fontSize,
  padding,
  btn,
  borderRadius,
  theme_gradient,
  theme_disable,
  theme_border,
  theme_standard,
  theme_standard_2,
  theme_well_button,
  theme_well_button_2,
  theme_well_login,
  theme_herwill_button,
  theme_cancer,
  theme_gray,
  theme_well_login_border,
  handleClickButton,
  theme_border_question,
  borderColor,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_well_button: theme_well_button,
    theme_well_button_2: theme_well_button_2,
    theme_herwill_button: theme_herwill_button,
    theme_well_login: theme_well_login,
    theme_gradient: theme_gradient,
    theme_disable: theme_disable,
    theme_border: theme_border,
    theme_cancer: theme_cancer,
    theme_gray: theme_gray,
    theme_border_question: theme_border_question,
    theme_well_login_border: theme_well_login_border
  });

  const handleClick = () => {
    handleClickButton();
  };
  return (
    <ButtonStyled
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      color={color}
      border={border}
      font={font}
      weight={weight}
      active={active}
      borderRadius={borderRadius}
      fontSize={fontSize}
      padding={padding}
      borderColor={borderColor}
      {...props}
    >
      <div
        className={disable ? "theme_disable" : customClass}
        onClick={handleClickButton ? handleClick : null}
      >
        {btn ? (
          <button className="btn_layout">{children}</button>
        ) : (
          <div className="btn_layout">{children}</div>
        )}
      </div>
    </ButtonStyled>
  );
};

import React from "react";
import { connect } from "react-redux";
import { IntroductionContainerStyled } from "./styled";
import { setReduxUserAuth } from "actions";
import Loader from "react-loader-spinner";
class IntroductionContainer extends React.Component {
  state = {
    loading: false,
    scheme: this.props.configProjectRedux.scheme === 'herwill' && 'herwill'
  };

  handleClickQuestionStart = () => {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
      this.props.handleChangeProcess(1);
    }, Math.floor(Math.random() * 500) + 100);
  };

  render() {
    const { authRedux } = this.props;
    const { loading, scheme } = this.state;
    return (
      <IntroductionContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            />
          </div>
        ) : (
          <>
            <div>
              <div className="txt_title">สวัสดีคุณ</div>
              <div
                className="txt_title"
                style={({ fontWeight: 700 }, { fontSize: "30px" })}
              >
                {authRedux && authRedux.firstname}
              </div>
              <div className="txt_detail">
                เราขอถามเกี่ยวกับอาการ พฤติกรรม
                และปัจจัยเสี่ยงมะเร็งเต้านมคุณประมาณ 6 ข้อ
              </div>
              <div className="txt_detail2">
                (การบริการนี้ ไม่ใช่การวินิจฉัยโรค หรือ แทนที่คำแนะนำจากแพทย์)
              </div>
              <button
                className={`btn_next ${scheme}`}
                onClick={this.handleClickQuestionStart}
              >
                เริ่มการตรวจคัดกรองมะเร็งเต้านม
              </button>
            </div>
          </>
        )}
      </IntroductionContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntroductionContainer);

import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const BoxMemberStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    font-size: ${FONT.SIZE_14};
    color: ${COLOR.BLUE_2};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title_show {
      font-size: ${FONT.SIZE_18};
      font-weight: 600;
      margin-bottom: 24px;
    }
    .detail_show {
      width: 100%;
      padding: 18px;
      background: ${COLOR.GRAY_10};
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      line-height: 1.8;
      .show_name {
        font-weight: bold;
      }
      .group_detail {
        display: flex;
        width: 100%;
        .group_detail_left {
          width: 50%;
          .group_label {
            .label {
              font-size: ${FONT.SIZE_14};
              font-weight: bold;
            }
          }
        }
        .group_detail_right {
          width: 50%;
          .group_label {
            .label {
              font-size: ${FONT.SIZE_14};
              font-weight: bold;
            }
          }
        }
      }
      .btn {
        padding: 8px;
        font-size: ${FONT.SIZE_18};
        font-weight: bold;
        color: ${COLOR.WHITE_1};
        width: 100%;
        background: ${COLOR.BLUE_4};
        border-radius: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 18px;
        box-shadow: 6px 7px 11px -8px rgba(88, 88, 88, 0.32);
      }
    }
  }
`;

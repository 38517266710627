import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { HealthInsurancerContainerStyled } from './styled';
import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { Icons, BackBtn, LogoLoadingFade, InsuranceItem } from 'components';
import { userService } from 'apiService';
import { SCREEN } from 'assets';

class HealthInsurancerContainer extends React.Component {
  state = {
    healthData: [],
    pagination: {
      offset: 0,
      per_page: 5,
    },
    isDesktop: window.matchMedia(`(max-width: ${SCREEN.MINI_TABLET})`).matches
      ? false
      : true,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    this.setState({
      loading: true,
    });
    this.handleDetectScreen();
    this.fetchHealthInsurance();
    window.addEventListener('resize', this.handleDetectScreen, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleDetectScreen, true);
  }

  handleDetectScreen = () => {
    const setDevice = (e) => {
      this.setState({
        isDesktop: e,
      });
    };
    if (window.matchMedia(`(max-width: ${SCREEN.MINI_TABLET})`).matches) {
      setDevice(false);
    } else {
      setDevice(true);
    }
  };

  fetchHealthInsurance = async () => {
    let res = await userService.GET_HEALTH_INSURANCE_SERVICE_LIST();
    // console.log('res', res)
    if (res && res.success) {
      this.setState({
        loading: false,
        healthData: res.data,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleClickBack = () => {
    this.props.history.goBack();
  };

  handleClickLandingPage = () => {
    this.props.history.goBack();
  };

  render() {
    const { healthData, loading } = this.state;
    return (
      <HealthInsurancerContainerStyled>
        <div className={`loader_layout ${loading ? 'active' : ''}`}>
          <LogoLoadingFade />
        </div>
        {/* {isDesktop ? ( */}
        <div className="is_desktop">
          <img
            className="mb_bg"
            src={iconImages['insurance-bg.png']}
            alt="img"
          />
          <div className="btn_back_wrap" onClick={this.handleClickBack}>
            <BackBtn
              theme_gray
              txt="ย้อนกลับ"
              icon={<Icons.Back color="#6F7070" />}
            />
          </div>
          <div className="btn_mb_back_wrap" onClick={this.handleClickBack}>
            <BackBtn txt="ย้อนกลับ" icon={<Icons.Back color="white" />} />
          </div>
          <div className="location_container">
            <div className="title">ประกันสุขภาพที่ให้บริการ</div>
            <div className="box_show">
              <div
                className="bg_show"
                style={{
                  backgroundImage: `url(${iconImages['bg_3.jpg']})`,
                }}
              >
                <div className="box_label_show">
                  <div className="bText">บริการประกันสุขภาพสำหรับคุณ</div>
                  <div>
                    วางแผนประกันสุขภาพล่วงหน้าเพื่อช่วยค่าใช้จ่าย
                    และสิทธิ์การรักษาของคุณในอนาคต
                  </div>
                </div>
              </div>
            </div>
            <div className="box_mobile_show">
              <div className="bms_left_col">
                <img src={iconImages['Insurance-cuate.png']} alt="insurance" />
              </div>
              <div className="bms_right_col">
                <div className="bms_title">บริการประกันสุขภาพสำหรับคุณ</div>
                <div className="bms_sub_title">
                  วางแผนประกันสุขภาพล่วงหน้าเพื่อช่วยค่า ใช้จ่าย
                  และสิทธิ์การรักษาของคุณในอนาคต
                </div>
              </div>
            </div>
            <div className="title_sec_2">ประกันสุขภาพที่เข้าร่วมโครงการ</div>
            {/* <div className="row_2_col">
              {healthData &&
                healthData.map((e, i) => (
                  <div className="item_wrap" key={i}>
                    <HealthItemType2 data={e} />
                  </div>
                ))}
              {healthData && healthData.length % 2 !== 0 && (
                <div className="item_wrap"></div>
              )}
            </div> */}
            <div className="insurance_row">
              {healthData &&
                healthData.map((e, i) => (
                  <div key={i} className="insurance_col">
                    <InsuranceItem
                      imgUri={e.image_uri}
                      redirectUril={e.redirect_uri}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* ) : (
          <div className="is_mobile">
            <div
              className="bg_top"
              style={{
                backgroundImage: `url(${iconImages['bg_health.jpg']})`,
              }}
            />
            <div className="container">
              <div className="m_btn_back_wrap" onClick={this.handleClickBack}>
                <BackBtn txt="ย้อนกลับ" icon={<Icons.Back />} />
              </div>
              <div className="m_title">ประกันสุขภาพที่ให้บริการ</div>
              <div className="m_box_show">
                <img
                  className="box_image"
                  src={iconImages['health_insu.svg']}
                  alt="icon show"
                />
                <div className="group_label">
                  <div className="bText">บริการประกันสุขภาพสำหรับคุณ</div>
                  <div>
                    วางแผนประกันสุขภาพล่วงหน้าเพื่อช่วยค่าใช้จ่าย
                    และสิทธิ์การรักษาของคุณในอนาคต
                  </div>
                </div>
              </div>
              <div className="m_title_sec_2">
                ประกันสุขภาพที่เข้าร่วมโครงการ
              </div>
              <div className="m_row">
                {healthData &&
                  healthData.map((e, i) => (
                    <div key={i} className="m_item_wrap">
                      <HealthItem data={e} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )} */}
        <div className="btn_layout">
          <button className="btn_end" onClick={this.handleClickLandingPage}>
            กลับสู่หน้าหลัก
          </button>
        </div>
      </HealthInsurancerContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthInsurancerContainer);

import React from 'react';
import { LoadingStyled } from './styled';
import Loader from 'react-loader-spinner';

export const Loading = () => {
  return (
    <LoadingStyled>
      <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        // timeout={500}
        className="lodding"
      />
    </LoadingStyled>
  );
};

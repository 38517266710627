import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { LineContainerStyled } from './styled';
import { userService } from 'apiService';
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth } from 'actions';
import { Loading } from 'components';
// import url from 'url';
import { toast } from 'react-toastify';
import { LineLogin } from 'reactjs-line-login';
import 'reactjs-line-login/dist/index.css';
import sessionStorage from 'redux-persist/es/storage/session';

class LineContainer extends React.Component {
  state = {};

  responseLine = async (e) => {
    if (!e || !e.access_token) {
      return false;
    }
    let params = {
      provider: 'line',
      access_token: e.access_token,
    };
    this.setState({
      loading: true,
    });
    const res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      ReactGA.event({
        category: 'login',
        action: 'login_success_line',
      });
      sessionStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: true })
      );
      localStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: true })
      );
      toast.success('ล็อคอินสำเร็จ');
      this.fetchProfileData('line');
    }
  };

  fetchProfileData = async (loginWith) => {
    let res = await userService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      this.setState({
        initialValues: { ...res.data, ...res.data.profile },
      });
      let params = {
        ...res.data,
        ...res.data.profile,
        loginWith: loginWith,
      };
      delete params.profile;
      this.props.setReduxUserAuth({ ...params });
      this.handleCheckProfile();
    }
  };

  handleCheckProfile = () => {
    const { authRedux } = this.props;
    if (authRedux.id && authRedux.firstname) {
      // Set userId for existing users
      ReactGA.set({
        userId: authRedux.id,
      });
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        // this.props.history.push(this.props.configProjectRedux.home_uri);
        if (this.props.configProjectRedux.is_corporate && !authRedux.company) {
          if (this.props.configProjectRedux.is_pinkalert) {
            this.props.history.push(`${ROUTE_PATH.TERM_OF_USE}`);
          } else {
            this.props.history.push(`${ROUTE_PATH.WELL_TERM_OF_USE_REGISTRY}`);
          }
        } else {
          this.props.history.push(this.props.configProjectRedux.home_uri);
        }
      }, Math.floor(Math.random() * 800) + 200);
    } else {
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        this.props.history.push(ROUTE_PATH.WELL_TERM_OF_USE_REGISTRY);
      }, Math.floor(Math.random() * 800) + 200);
    }
  };

  render() {
    return (
      <LineContainerStyled>
        <Loading />
        <div className="line_wrap">
          <LineLogin
            clientID={process.env.REACT_APP_LINE_APP_ID}
            clientSecret="b758b60836fde18fdc37e275e54574b2"
            state="b41c8fd15b895f0fc28bfwb9d7da89054d931e7s"
            nonce="d78a51235f6ee189e831q9c68523cfa336917ada"
            // redirectURI={window.location.hostname + '?provider=line'}
            redirectURI={'https://' + window.location.hostname + '/login/line'}
            // redirectURI={'https://localhost:3000/login/line'}
            scope="profile openid email"
            setPayload={this.responseLine}
            setIdToken={(idToken) => console.log('idToken:', idToken)}
          />
        </div>
      </LineContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LineContainer);

import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { SuccessOrderContainerStyled } from './styled';
import { userService } from 'apiService';
import { iconImages } from 'assets';
import moment from 'moment';
import { setReduxUserAuth, setReduxAddressShipping } from 'actions';
import { BoxLink, ButtonIcon, Modal, LogoLoadingFade } from 'components';
import { ROUTE_PATH, RenderCommaMoney, RenderMonth } from 'helpers';
import { PaymentPopup } from 'widgets/PaymentsSystem';
import queryString from 'query-string';
import { PaymentSuccess, PaymentFaile } from './Modal';

class SuccessOrderContainer extends React.Component {
  state = { orderStatus: 'ยังไม่ได้ชำระเงิน', isLoading: true };

  componentDidMount() {
    const { payment_id, from } = queryString.parse(this.props.location.search);
    this.scrollToTop();
    this.fecthDetailOrder();
    if (payment_id && (!from || from !== 'transactionhistory')) {
      this.handleCheckOrderPaymentStatus();
    }

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  componentDidUpdate() {
    window.onpopstate = (e) => {
      this.props.history.push('/');
    };
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fecthDetailOrder = async () => {
    const { authRedux } = this.props;
    const { orderID } = this.props.match.params;
    let res = await userService.GET_PRODUCT_ORDER_DETAIL(
      authRedux?.id,
      orderID
    );
    if (res && res.success) {
      this.setState({
        data: {
          profile: authRedux,
          orders: res.data,
        },
        isLoading: false,
      });
      this.handleRenderActionByOrderStatus(res.data.current_status);
      this.renderOrderStatus(res.data.current_status);
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  renderOrderStatus = (e) => {
    switch (e) {
      case 'order_new':
        this.setState({
          orderStatus: 'ยังไม่ได้ชำระเงิน',
        });
        break;

      case 'payment_pending':
        this.setState({
          orderStatus: 'รอยืนยันสถานะการชำระเงิน',
        });
        break;

      case 'order_completed':
        this.setState({
          orderStatus: 'ชำระเงินเสร็จสิ้น',
        });
        break;

      default:
        break;
    }
  };

  handleRenderActionByOrderStatus = (status) => {
    const { data } = this.state;
    switch (status) {
      case 'order_new':
        this.setState(
          {
            actionBtnActionRender: (
              <div style={{ width: '100%', marginTop: 20, marginBottom: -10 }}>
                <PaymentPopup
                  ref={(ref) => (this.paymentRef = ref)}
                  invoiceID={data?.orders?.id}
                  amount={data?.orders?.total_price}
                  onSuccess={this.handlePaymentSuccess}
                >
                  <div className="btn_payment_wrap">
                    <ButtonIcon
                      theme_normal
                      lable1Size="16px"
                      label1="ชำระเงิน"
                    />
                  </div>
                </PaymentPopup>
              </div>
            ),
          },
          () => {
            this.paymentRef.clickPayment();
          }
        );
        break;
      case 'payment_pending':
        this.setState({
          actionBtnActionRender: (
            <div style={{ width: '100%', marginTop: 20, marginBottom: -10 }}>
              <PaymentPopup
                ref={(ref) => (this.paymentRef = ref)}
                invoiceID={data?.orders?.id}
                amount={data?.orders?.total_price}
                onSuccess={this.handlePaymentSuccess}
              >
                <div className="btn_payment_wrap">
                  <ButtonIcon
                    theme_normal
                    lable1Size="16px"
                    label1="ชำระเงินด้วยช่องทางอื่น"
                  />
                </div>
              </PaymentPopup>
            </div>
          ),
        });
        break;
      default:
        this.setState({
          actionBtnActionRender: false,
        });
        break;
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  handlePaymentSuccess = (paymentID) => {
    this.handleCheckOrderPaymentStatus(paymentID);
  };

  handleCheckOrderPaymentStatus = async (paymentIDByCallback) => {
    const { orderID } = this.props.match.params;
    const { payment_id } = queryString.parse(this.props.location.search);
    let res = await userService.GET_CHECK_PAYMENT(
      orderID,
      paymentIDByCallback || payment_id
    );
    if (res && res.success) {
      if (paymentIDByCallback) {
        this.setState({
          isLoading: true,
        });
        this.fecthDetailOrder();
      }
      this.handlePaymentByStatus(res.data);
    }
  };

  handlePaymentByStatus = (key) => {
    const { orderID } = this.props.match.params;
    switch (key) {
      case 'SUCCESS':
        this.setState({
          isModal: true,
          renderModal: (
            <PaymentSuccess
              orderID={orderID}
              handleClickCloseModalCustom={this.handleClickCloseModalCustom}
            />
          ),
        });
        break;
      case 'ERROR':
        this.setState({
          isModal: true,
          renderModal: (
            <PaymentFaile
              handleClickCloseModalCustom={this.handleClickCloseModalCustom}
            />
          ),
        });
        break;
      default:
        break;
    }
  };

  // MODAL PROCESS
  //===========================
  //===========================
  handleClickCloseModalCustom = () => {
    this.setState({
      isModal: false,
    });
  };

  render() {
    const {
      data,
      actionBtnActionRender,
      isModal,
      renderModal,
      orderStatus,
      isLoading,
    } = this.state;
    const { from } = queryString.parse(this.props.location.search);
    return (
      <SuccessOrderContainerStyled>
        {isLoading && (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        )}
        <div className="container_body">
          {(from === 'cart' || from === 'transactionhistory') && (
            <div
              className="link_back"
              onClick={() => this.handleClickBackFirstPage()}
            >
              <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
            </div>
          )}
          <div className="link_layout" onClick={this.handleClickBackFirstPage}>
            <BoxLink theme_standard_box_link label="กลับสู่หน้าหลัก" />
          </div>
          <div
            className="bg_layout"
            style={{ backgroundImage: `url(${iconImages['bg1.png']})` }}
          >
            <div className="title">ขอบคุณที่ใช้บริการ</div>
            <div className="group_box">
              <div className="top_layer">รายละเอียดการจัดส่ง</div>
              <div className="group_label">
                {'ชื่อ : '}
                <div className="bold_font">
                  {data && `${data.profile.firstname} ${data.profile.lastname}`}
                </div>
              </div>
              <div className="group_label">
                {`หมายเลขโทรศัพท์ : `}
                <div className="bold_font">
                  {data && data.orders.phone_number}
                </div>
              </div>
              <div className="group_label">{`ที่อยู่ในการจัดส่ง : `}</div>
              <div className="group_label font_bold">
                {data && data.orders.address}
              </div>
            </div>
            <div className="group_box">
              <div className="top_layer">รายละเอียดการชำระเงิน</div>
              <div className="group_label">
                {`จำนวนสินค้า : `}
                <div className="bold_font">
                  {data &&
                    `${data.orders.product.name} ${data.orders.amount} ชุด`}
                </div>
              </div>
              <div className="group_label">
                {`ราคารวมสินค้า : `}
                <div className="bold_font">
                  {data && `${RenderCommaMoney(data.orders.total_price)} บาท`}
                </div>
              </div>
              <div className="group_label">
                {`หมายเลขคำสั่งซื้อ : `}
                <div className="bold_font">{data && data.orders.number}</div>
              </div>
              <div className="group_label">
                {`เวลาที่สั่งซื้อ : `}
                <div className="bold_font">
                  {data &&
                    `${moment(data.orders.created_at).format(
                      'DD'
                    )} ${RenderMonth(
                      Number(moment(data.orders.created_at).format('MM'))
                    )} ${
                      Number(moment(data.orders.created_at).format('YYYY')) +
                      543
                    } ; ${moment(data.orders.created_at).format('HH:mm')}`}
                </div>
              </div>
              <div className="group_label">
                {`เวลาที่ชำระเงิน : `}
                <div className="bold_font">
                  {data && data.orders.paid_at
                    ? `${moment(data.orders.paid_at).format(
                        'DD'
                      )} ${RenderMonth(
                        Number(moment(data.orders.paid_at).format('MM'))
                      )} ${
                        Number(moment(data.orders.paid_at).format('YYYY')) + 543
                      } ; ${moment(data.orders.paid_at).format('HH:mm')}`
                    : '-'}
                </div>
              </div>
              <div className="group_label">
                {`สถานะการชำระเงิน : `}
                <div className="bold_font">{orderStatus}</div>
              </div>
            </div>
            <div className="show_address">
              <div className="detail_2">
                แอปพลิเคชั่น Well by Invitrace เป็นเพียงช่องทางการจัดจำหน่าย
              </div>
              <div className="detail_2">
                หากท่านไม่ได้รับสินค้าภายใน 7 วัน หรือสินค้ามีปัญหาใดๆ
                โปรดติดต่อ
              </div>
              <div className="detail_2">
                <div className="group_line">
                  <div className="icon_show">
                    <img
                      className="img_show"
                      src={iconImages['phone_gray.png']}
                      alt="icon show"
                    />
                    061-717-1222
                  </div>
                  <div className="icon_show no_margin">
                    <img
                      className="img_show"
                      src={iconImages['line_icon.png']}
                      alt="icon show"
                    />
                    @medlinecheckup
                  </div>
                </div>
              </div>
              <div className="detail_3">(เวลาทำการ จ. - ศ. :7.00 - 17.00)</div>
              <div className="btn2">
                <ButtonIcon
                  theme_gray
                  lable1Size="16px"
                  label1="กลับสู่หน้าแรก"
                  handleOnClick={this.handleClickBackFirstPage}
                />
              </div>
            </div>
            {actionBtnActionRender}
          </div>
        </div>
        <Modal
          theme_modal_standard
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModalCustom}
        >
          {renderModal}
        </Modal>
      </SuccessOrderContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  addressShippingRedux: state.addressShipping,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxAddressShipping: (data) => dispatch(setReduxAddressShipping(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessOrderContainer);

import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const HerWillContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .pop_div {
    width: 956px;
    .box_img_show {
      width: 100%;
      position: relative;
      .pop_img {
        width: 100%;
      }
      .close_x {
        width: 19px;
        height: 19px;
        cursor: pointer;
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
    .group_btn {
      display: flex;
      column-gap: 13px;
      justify-content: center;
      margin-top: 27px;
      .btn_live {
        cursor: pointer;
        background: ${COLOR.PINK_1};
        font-size: ${FONT.SIZE_21};
        font-weight: bold;
        color: ${COLOR.WHITE_1};
        border-radius: 20px;
        text-align: center;
        padding: 10px 30px;
        a {
          color: ${COLOR.WHITE_1};
        }
      }
      .btn_web {
        cursor: pointer;
        background: ${COLOR.WHITE_1};
        font-size: ${FONT.SIZE_21};
        font-weight: bold;
        color: ${COLOR.PINK_1};
        border-radius: 20px;
        text-align: center;
        border: 1px solid ${COLOR.PINK_1};
        padding: 10px 30px;
        a {
          color: ${COLOR.PINK_1};
        }
      }
    }
  }
  .top_page {
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
    .left_layout {
      flex: 1;
      padding: 56px;
      z-index: 2;

      flex-shink: 0;
      .logo_show {
      }
      .group_text {
        margin-top: 49px;
        width: 100%;
        font-size: ${FONT.SIZE_20};
        color: ${COLOR.BLACK_3};
        .body_detail {
          background: ${COLOR.WHITE_1};
          margin-top: 50px;
          border-radius: 24px;
          border: 1px solid rgb(253, 184, 196, 0.33);
          padding: 36px 62px;
          font-size: ${FONT.SIZE_20};
          color: ${COLOR.BLACK_9};
          .group_1 {
            .h1 {
              font-weight: 700;
              color: ${COLOR.PINK_1};
              padding-left: 30px;
              padding-right: 5px;
            }
          }
          .group_2 {
            color: ${COLOR.BLACK_6};
            margin-top: 8px;
            .h1 {
              padding-left: 30px;
              padding-right: 5px;
            }
          }
        }
        .btn_show {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          .body_btn {
            width: 300px;
          }
        }
        .label_bottom_detail {
          margin-top: 16px;
          text-align: center;
          font-size: ${FONT.SIZE_18};
          font-weight: 300;
        }
      }
    }
    .right_layout {
      flex: 1;
      .logo_right {
        width: 425px;
      }
    }
  }
  .bottom_page {
    background: #ededed;
    .layer_one {
      padding: 106px 64px;
      background: linear-gradient(
        0deg,
        rgba(255, 245, 245, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      padding-bottom: 84px;
      column-gap: 124px;
      display: flex;
      justify-content: center;
      .left_box {
        .bottom_label {
          text-align: center;
          font-size: ${FONT.SIZE_23};
          &.heightlight {
            font-size: ${FONT.SIZE_45};
            font-weight: bold;
            color: ${COLOR.PINK_1};
            margin: 10px 0px;
          }
        }
      }
      .right_box {
        display: flex;
        column-gap: 32px;
        .box_mission {
          .top_title {
            font-size: ${FONT.SIZE_24};
            font-weight: bold;
            color: ${COLOR.PINK_1};
            text-align: center;
            margin-bottom: 18px;
          }
          .box_image {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 169px;
            height: 169px;
            background: linear-gradient(
              0deg,
              rgba(255, 236, 248, 1) 0%,
              rgba(243, 191, 217, 1) 100%
            );
            border-radius: 8px;
            padding: 18px;
            cursor: pointer;
          }
          .box_label {
            margin-top: 15px;
            .button_show {
              cursor: pointer;
              background: ${COLOR.PINK_1};
              font-size: ${FONT.SIZE_20};
              font-weight: bold;
              color: ${COLOR.WHITE_1};
              border-radius: 22px;
              text-align: center;
              padding: 9px;
              margin-bottom: 13px;
              a {
                color: ${COLOR.WHITE_1};
              }
            }
            .text_show {
              text-align: center;
              font-size: ${FONT.SIZE_22};
              text-align: center;
            }
          }
        }
      }
    }
    .layer_two {
      margin-top: 22px;
      background: #fff6f6;
      // padding: 35px 60px;
      padding: 35px 0px;
      .box_top_new {
        display: flex;
        justify-content: space-between;
        .itele_layout {
          width: 44%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .img_show {
            border-radius: 8px;
            width: 100%;
            max-width: 402px;
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            padding: 58px 22px 29px 16px;
            .img_itele {
              width: 34px;
              height: 12.87px;
              position: absolute;
              top: 16px;
              right: 9px;
            }
            .label_top_show {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .group_text {
                .text1 {
                  font-size: ${FONT.SIZE_22};
                  font-weight: bold;
                  color: ${COLOR.GREEN_3};
                  &.fs_18 {
                    font-size: ${FONT.SIZE_18};
                  }
                }
                .text2 {
                  font-size: ${FONT.SIZE_16};
                  color: ${COLOR.BLACK_3};
                }
              }
            }
            .box_btn {
              margin-top: 43px;
              padding: 0px 10%;
              .btn_itele {
                cursor: pointer;
                background: ${COLOR.PINK_1};
                font-size: ${FONT.SIZE_20};
                font-weight: bold;
                color: ${COLOR.WHITE_1};
                border-radius: 22px;
                text-align: center;
                padding: 9px;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon_btn {
                  margin-right: 6px;
                }
                a {
                  color: ${COLOR.WHITE_1};
                }
              }
            }
          }
          .service_box {
            margin-top: 61px;
            .service_title {
              margin-bottom: 30px;
              font-size: ${FONT.SIZE_20};
              font-weight: bold;
              color: ${COLOR.BLACK_3};
              text-align: center;
            }
            .service_icon {
              display: flex;
              column-gap: 64px;
              justify-content: center;
              &.mt {
                margin-top: 25px;
                justify-content: flex-start;
              }
              .box_service_detail {
                width: 169px;
                .box_image {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-shrink: 0;
                  width: 169px;
                  height: 169px;
                  background: linear-gradient(
                    0deg,
                    rgba(255, 236, 248, 1) 0%,
                    rgba(243, 191, 217, 1) 100%
                  );
                  border-radius: 8px;
                  padding: 18px;
                  cursor: pointer;
                }
                .box_label {
                  margin-top: 15px;
                  .button_show {
                    cursor: pointer;
                    background: ${COLOR.PINK_1};
                    font-size: ${FONT.SIZE_16};
                    font-weight: bold;
                    color: ${COLOR.WHITE_1};
                    border-radius: 22px;
                    text-align: center;
                    padding: 9px;
                    margin-bottom: 13px;
                    a {
                      color: ${COLOR.WHITE_1};
                    }
                  }
                  .text_show {
                    text-align: center;
                    font-size: ${FONT.SIZE_16};
                    font-weight: 300;
                  }
                }
              }
            }
          }
          .line_service_box {
            display: none;
            width: 100%;
            height: 1px;
            background: #ffebf7;
            margin-top: 25px;
            margin-bottom: 21px;
          }
        }
        .right_detail {
          width: 50%;
          .social_block {
            .sb_title {
              text-align: center;
              font-size: 33px;
              font-weight: bold;
              color: #575959;
              margin-bottom: 30px;
            }
            .sb_row_2_col {
              display: flex;
              justify-content: center;
              column-gap: 94px;
              .sb_item {
                max-width: 156px;
                width: 100%;
                .icon_wrap {
                  text-align: center;
                  margin-bottom: 16px;
                }
                .btn_social {
                  cursor: pointer;
                  background: ${COLOR.PINK_1};
                  font-size: ${FONT.SIZE_20};
                  font-weight: bold;
                  color: ${COLOR.WHITE_1};
                  border-radius: 22px;
                  text-align: center;
                  padding: 9px;
                  margin-bottom: 9px;
                  a {
                    color: ${COLOR.WHITE_1};
                  }
                }
                .lb_social {
                  font-size: 16px;
                  color: #575959;
                  text-align: center;
                }
              }
            }
          }
          .mobile_hightlight {
            display: none;
          }
          .line {
            width: 100%;
            height: 1px;
            background: #ffebf7;
            margin-top: 38px;
            margin-bottom: 21px;
          }
          .left_box_social {
            .group_label {
              .label_show {
                font-size: ${FONT.SIZE_25};
                text-align: center;
                &.h1 {
                  font-size: ${FONT.SIZE_33};
                  font-weight: bold;
                  color: ${COLOR.PINK_1};
                  margin-bottom: 18px;
                }
                &.h2 {
                  font-size: ${FONT.SIZE_113};
                  font-weight: bold;
                  color: ${COLOR.PINK_1};
                }
              }
            }
            .sub_detail {
              margin-top: 25px;
              font-size: ${FONT.SIZE_19};
              color: ${COLOR.PINK_1};
              display: flex;
              flex-direction: column;
              align-items: center;
              line-height: 2;
              .group_sub {
                .h1 {
                  font-weight: bold;
                  padding-right: 5px;
                }
              }
            }
          }
        }
      }
      .video_show {
        // margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .line_video {
          display: none;
          width: 100%;
          height: 1px;
          background: #ffebf7;
          margin-top: 38px;
          margin-bottom: 21px;
        }
        .video_text {
          margin-bottom: 57px;
          font-size: ${FONT.SIZE_30};
          color: ${COLOR.PINK_1};
          font-weight: bold;
          text-align: center;
        }
        .box_video {
          width: 100%;
          height: 855px;
        }
      }
      .hightlight_show {
        margin-top: 35px;
        .gray_line {
          height: 22px;
          background: #EDEDED;
          margin-bottom: 49px;
        }
        .text_hightlight {
          margin-bottom: 57px;
          font-size: ${FONT.SIZE_30};
          color: ${COLOR.PINK_1};
          font-weight: bold;
          text-align: center;
        }
        .detail_hightlight {
          .img_hightlight {
            width: 100%;
          }
          .btn_group_highlight {
            margin-top: 64px;
            justify-content: center;
            display: flex;
            align-items: center;
            .time_show {
              display: flex;
              column-gap: 46px;
              .group_line_show {
                display: flex;
                align-items: center;
                font-size: ${FONT.SIZE_24};
                color: ${COLOR.BLACK_1};
                font-weight: 400;
                .img_hightlight {
                  width: 52.83px;
                  height: 58.74px;  
                  margin-right: 16px;
                }
              }
            }
            .btn_live_box {
              margin-left: 63px;
              .btn_live_display {
                cursor: pointer;
                background: ${COLOR.PINK_1};
                font-size: ${FONT.SIZE_21};
                font-weight: bold;
                color: ${COLOR.WHITE_1};
                border-radius: 20px;
                text-align: center;
                padding: 10px 30px;
                a {
                  color: ${COLOR.WHITE_1};
                }
              }
            }
          }
        }
      }
      .line_row {
        width: 100%;
        height: 2px;
        background: #ffebf7;
        margin-top: 35px;
        margin-bottom: 27px;
      }
      .cp_2_row {
        display: flex;
        justify-content: center;
        column-gap: 122px;
        padding: 0px 10px;
        .left_box {
          display: flex;
          align-items: center;
          justify-content: center;
          .box_logo {
            display: flex;
            justify-content: center;
            .logo_show {
            }
          }
        }
        .right_box {
          .grop_text {
            margin-top: 20px;
            font-size: ${FONT.SIZE_15};
            .title_text {
              font-size: ${FONT.SIZE_24};
              font-weight: bold;
              justify-content: center;
              margin-bottom: 20px;
              color: ${COLOR.PINK_3};
            }
          }
          .box_img_label {
            display: flex;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0px;
            }
            .box_img {
              width: 94px;
              .opt_img {
                max-width: 94px;
              }
            }
            .opt_label {
              font-size: ${FONT.SIZE_20};
              color: ${COLOR.PINK_3};
              margin-left: 10px;
              a {
                color: ${COLOR.PINK_1};
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    .layer_three {
      display: none;
      background: #fff6f6;
    }
  }
  .band_layout {
    padding: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .top_img {
      .band_imb {
        &.mr {
          margin-right: 40px;
          width: 116px;
        }
        &.sm {
          margin-right: 40px;
          width: 80px;
        }
        &.sm2 {
          width: 100px;
        }
        &.w_140 {
          width: 140px;
        }
      }
    }
    .band_label {
      margin-top: 15px;
      font-size: ${FONT.SIZE_20};
      color: ${COLOR.PINK_1};
      align-items: center;
    }
    .code {
      position: absolute;
      right: 4px;
      bottom: 4px;
      font-size: 8px;
      color: #a5a5a5;
    }
  }
  .md_container {
    background: #ffffff;
    min-width: 344px;
    width: 100%;
    text-align: center;
    padding: 30px 20px;
    border-radius: 8px;
    .close_wrap {
      cursor: pointer;
      position: absolute;
      top: 9px;
      right: 8px;
    }
    .md_title {
      font-weight: bold;
      font-size: 20px;
      color: #cf1a71;
      margin-bottom: 20px;
    }
    .logo_share {
      margin-bottom: 35px;
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .pop_div {
      width: 740px;
      .box_img_show {
        width: 100%;
        .pop_img {
          width: 100%;
        }
      }
    }
    .top_page {
      .left_layout {
        padding: 15px;
        .group_text {
          width: 100%;
        }
      }
      .right_layout {
        position: absolute;
        z-index: 0;
        right: 0;
        top: 6%;
        .logo_right {
          width: 100%;
          height: auto;
        }
      }
    }
    .layer_one {
      flex-wrap: wrap;
      row-gap: 30px;
      padding-bottom: 30px;
    }
    .layer_two {
      
    }
    .band_layout {
      padding: 7px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .top_img {
        display: flex;
        align-items: center;
        .band_imb {
          max-width: 77px;
          height: auto;
          &.mr {
            margin-right: 15px;
          }
          &.sm {
            margin-right: 15px;
          }
        }
      }
      .band_label {
        font-size: ${FONT.SIZE_14};
        font-weight: 500;
        color: ${COLOR.GRAY_16};
      }
    }
  }

  @media (max-width: 1368px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 773px;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 720px;
          }
        }
      }
    }
  }

  @media (max-width: 1180) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 667px;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 577px;
          }
        }
      }
    }
  }

  @media (max-width: 915px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 517px;
          }
        }
      }
    }
  }

  @media (max-width: 914px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 517px;
          }
        }
      }
    }
  }

  @media (max-width: 912px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 515px;
          }
        }
      }
    }
  }

  @media (max-width: 912px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 463px;
          }
        }
      }
    }
  }

  @media (max-width: 820px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 463px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 434px;
          }
        }
      }
    }
  }

  @media (max-width: 740px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 419px;
          }
        }
      }
    }
  }

  @media (max-width: 720px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 406px;
          }
        }
      }
    }
  }

  @media (max-width: 653px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 370px;
          }
        }
      }
    }
  }

  @media (max-width: 540px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .line_video {
            display: block;
          }
          .video_text {
            font-size: ${FONT.SIZE_18};
            margin-bottom: 15px;
          }
          .box_video {
            height: 305px;
          }
        }
      }
    }
  }

  @media (max-width: 414px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 234px;
          }
        }
      }
    }
  }

  @media (max-width: 393px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 224px;
          }
        }
      }
    }
  }

  @media (max-width: 390px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 221px;
          }
        }
      }
    }
  }

  @media (max-width: 375px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 210px;
          }
        }
      }
    }
  }

  @media (max-width: 360px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 203px;
          }
        }
      }
    }
  }

  @media (max-width: 280px) {
    .bottom_page {
      .layer_two {
        .video_show {
          .box_video {
            height: 158px;
          }
        }
      }
    }
  }

  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .pop_div {
      width: 956px;  
      .box_img_show {
        width: 100%;
        .pop_img {
          width: 100%;
        }
        .close_x {
          width: 10px;
          height: 10px;
        }
      }
    }
    .top_page {
      .left_layout {
        .group_text {
          width: 100%;
        }
      }
      .right_layout {
        position: absolute;
        z-index: 0;
        top: 6%;
        text-align: right;
        .logo_right {
          width: 50%;
          height: auto;
        }
      }
    }
    .bottom_page {
      // padding: 36px;
      .layer_one {
        flex-wrap: wrap;
        row-gap: 30px;
        padding-bottom: 30px;
      }
      .layer_two {
        .box_top_new {
          flex-direction: column;
          .itele_layout {
            width: 100%;
            .service_box {
              margin-top: 18px;
            }
            .line_service_box {
              display: block;
            }
          }
          .right_detail {
            width: 100%;
          }
        }
        .cp_2_row {
          flex-flow: wrap;
        }
      }
    }
    .band_layout {
      padding: 7px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .top_img {
        display: flex;
        align-items: center;
        .band_imb {
          max-width: 57px;
          height: auto;
          &.mr {
            margin-right: 15px;
          }
          &.sm {
            margin-right: 15px;
          }
        }
      }
      .band_label {
        font-size: ${FONT.SIZE_14};
        font-weight: 500;
        color: ${COLOR.GRAY_16};
      }
    }
  }




  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .pop_div {
      width: 500px;
      .box_img_show {
        width: 100%;
        .pop_img {
          width: 100%;
        }
        .close_x {
          width: 10px;
          height: 10px;
        }
      }
    }
    .top_page {
      padding: 15px;
      .left_layout {
        .group_text {
          width: 100%;
          margin-top: 0px;
          .body_detail {
            margin-top: 40px;
            padding: 16px;
            font-size: ${FONT.SIZE_14};
            font-weight: normal;
            .group_label {
            }
          }
          .btn_show {
          }
          .label_bottom_detail {
            font-size: ${FONT.SIZE_15};
          }
        }
      }
      .right_layout {
        top: 40px;
        right: 5px;
        .logo_right {
          width: 140px;
          height: auto;
        }
      }
    }
    .bottom_page {
      .layer_one {
        padding-top: 22px;
        padding: 22px 16px 22px 16px;
        .left_box {
          .bottom_label {
            font-size: ${FONT.SIZE_15};
            &.heightlight {
              font-size: ${FONT.SIZE_29};
            }
          }
        }
        .right_box {
          column-gap: 15px;
          .box_mission {
            .box_image {
              width: 104px;
              height: 104px;
            }
            .top_title {
              font-size: ${FONT.SIZE_15};
              font-weight: bold;
            }
            .box_label {
              .button_show {
                font-size: ${FONT.SIZE_16};
                font-weight: bold;
                padding: 4px;
              }
              .text_show {
                font-size: ${FONT.SIZE_14};
                font-weight: 400;
              }
            }
          }
        }
      }
      .layer_two {
        padding: 0px;
        margin: 0px;
        .box_top_new {
          .itele_layout {
            .img_show {
              border-radius: 0px;
              width: 413px;
              height: 224px;
              .label_top_show {
                .group_text {
                  .img_itele {
                  }
                  .text1 {
                    font-size: ${FONT.SIZE_18};
                  }
                  .text2 {
                    font-size: ${FONT.SIZE_16};
                  }
                }
              }
              .box_btn {
                margin-top: 18px;
                .btn_itele {
                  font-size: ${FONT.SIZE_18};
                }
              }
            }
            .service_box {
              padding: 0px 16px;
              margin-top: 0px;
              .service_title {
                font-size: ${FONT.SIZE_14};
                margin-bottom: 11px;
                margin-top: 18px;
              }
              .service_icon {
                column-gap: 40px;
                .box_service_detail {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 147px;
                  .box_image {
                    width: 104px;
                    height: 104px;
                  }
                  .box_label {
                    width: 100%;
                    .button_show {
                      font-size: ${FONT.SIZE_16};
                    }
                    .text_show {
                      font-size: ${FONT.SIZE_14};
                    }
                  }
                }
              }
            }
          }
          .right_detail {
            padding: 0px;
            .social_block {
              .sb_title {
                font-size: 18px;
              }
              .sb_row_2_col {
                column-gap: 38px;
                .sb_item {
                  .icon_wrap {
                  }
                  .btn_social {
                    font-size: 12px;
                  }
                  .lb_social {
                    font-size: 12px;
                  }
                }
              }
            }
            .mobile_hightlight {
              display: block;
              margin-bottom: 28px;
              .gray_line {
                height: 8px;
                background: #FFEBF7;
                margin-top: 27px;
                // margin-bottom: 49px;
              }
              .text_hightlight {
                margin-bottom: 15px;
                font-size: ${FONT.SIZE_18};
                color: ${COLOR.PINK_1};
                font-weight: bold;
                text-align: center;
              }
              .detail_hightlight {
                .img_hightlight {
                  width: 100%;
                }
                .btn_group_highlight {
                  margin-top: 21px;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                  .time_show {
                    display: flex;
                    flex-direction: column;
                    row-gap: 18px;
                    .group_line_show {
                      display: flex;
                      align-items: center;
                      font-size: ${FONT.SIZE_12};
                      color: ${COLOR.BLACK_1};
                      font-weight: 400;
                      .img_hightlight {
                        width: 19.35px;
                        height: 21.51px;  
                        margin-right: 7px;
                      }
                    }
                  }
                  .btn_live_box {
                    margin-left: 63px;
                    .btn_live_display {
                      cursor: pointer;
                      background: ${COLOR.PINK_1};
                      font-size: ${FONT.SIZE_14};
                      font-weight: bold;
                      color: ${COLOR.WHITE_1};
                      border-radius: 20px;
                      text-align: center;
                      padding: 10px 30px;
                      a {
                        color: ${COLOR.WHITE_1};
                      }
                    }
                  }
                }
              }
            }
            .line {
              margin-top: 26px;
              margin-bottom: 28px;
            }
            .left_box_social {
              .group_label {
                .label_show {
                  font-size: ${FONT.SIZE_14};
                  font-weight: 400;
                  &.h1 {
                    font-size: ${FONT.SIZE_18};
                    font-weight: bold;
                    margin-bottom: 5px;
                  }
                  &.h2 {
                    font-size: ${FONT.SIZE_60};
                    font-weight: bold;
                  }
                }
              }
              .sub_detail {
                display: none;
              }
            }
          }
        }
        .hightlight_show {
          display: none;
        }
        .line_row {
          display: none;
        }
        .cp_2_row {
          .left_box {
            .box_logo {
              display: none;
            }
          }
          .right_box {
            padding: 0px 13px;
            .box_logo {
              display: none;
              .logo_show {
              }
            }
            .grop_text {
              margin-top: 0px;
              .title_text {
                font-size: ${FONT.SIZE_16};
                font-weight: bold;
                margin: 13px 0px;
                text-align: center;
              }
            }
            .box_img_label {
              display: flex;
              align-items: center;
              margin-bottom: 13px;
              .box_img {
                width: 61px;
                .opt_img {
                  max-width: 61px;
                }
              }
              .opt_label {
                font-size: ${FONT.SIZE_14};
                font-weight: 400;
              }
            }
          }
        }
      }
      .layer_three {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 36px;
        background: #fff6f6;
        .box_img {
          .opt_img {
            width: 300px;
          }
        }
        .group_three_label {
          margin-top: 10px;
          line-height: 2;
          font-size: ${FONT.SIZE_14};
          font-weight: 400;
          color: ${COLOR.PINK_1};
          .group_sub {
            display: flex;
            justify-content: center;
            .h1 {
              font-weight: bold;
              padding-right: 5px;
            }
          }
        }
      }
    }
    .band_layout {
      padding: 7px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .top_img {
        display: flex;
        align-items: center;
        .band_imb {
          max-width: 37px;
          height: auto;
          &.mr {
            margin-right: 15px;
          }
          &.sm {
            margin-right: 15px;
          }
        }
      }
      .band_label {
        font-size: ${FONT.SIZE_14};
        font-weight: 500;
        color: ${COLOR.GRAY_16};
      }
    }
  }



  @media (max-width: ${SCREEN.PHONE}) {
    .pop_div {
      width: 327px;
      .box_img_show {
        width: 100%;
        .pop_img {
          width: 100%;
        }
        .close_x {
          width: 10px;
          height: 10px;
        }
      }
      .group_btn {
        display: flex;
        column-gap: 9px;
        justify-content: center;
        margin-top: 12px;
        .btn_live {
          cursor: pointer;
          background: ${COLOR.PINK_1};
          font-size: ${FONT.SIZE_14};
          font-weight: bold;
          color: ${COLOR.WHITE_1};
          border-radius: 20px;
          text-align: center;
          padding: 8px 10px;
          a {
            color: ${COLOR.WHITE_1};
          }
        }
        .btn_web {
          cursor: pointer;
          background: ${COLOR.WHITE_1};
          font-size: ${FONT.SIZE_14};
          font-weight: bold;
          color: ${COLOR.PINK_1};
          border-radius: 20px;
          text-align: center;
          border: 1px solid ${COLOR.PINK_1};
          padding: 8px 10px;
          a {
            color: ${COLOR.PINK_1};
          }
        }
      }
    }
    .box_cookie {
      width: 363px;
      flex-direction: column;
      .cookie_label {
        .t_cookie {
          margin-bottom: 5px;
        }
      }
      .btn_cookie {
        width: 100%;
        margin-top: 10px;
      }
    }
    .bottom_page {
      .layer_two {
        .box_top_new {
          .itele_layout {
            .img_show {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

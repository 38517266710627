import styled from 'styled-components';

export const NotificationStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .Toastify__toast {
    border-radius: 15px;
    border: 1px solid ${({ theme }) => theme.COLORS.WHITE_1};
    
    font-size: ${({ theme }) => theme.FONTS.SIZE_3};
    padding: 0 13px;
    .Toastify__close-button.Toastify__close-button--error {
      display: none;
    }
    .Toastify__close-button.Toastify__close-button--success {
      display: none;
    }
    .Toastify__close-button.Toastify__close-button--info {
      display: none;
    }

    &.Toastify__toast--error {
      background: ${({ theme }) => theme.COLORS.RED_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &:after {
        position: absolute;
        
        content: 'x';
        font-size: 20px;
        right: 13px;
        top: 14px;
      }
    }
    &.Toastify__toast--success {
      background: ${({ theme }) => theme.COLORS.GREEN_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      &:after {
        position: absolute;
        
        content: 'x';
        font-size: 20px;
        right: 13px;
        top: 18px;
      }
    }
    &.Toastify__toast--info {
      &:after {
        position: absolute;
        
        content: 'x';
        font-size: 20px;
        right: 13px;
        top: 18px;
      }
    }
  }
`;

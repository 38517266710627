import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ProfileScreeningContainerStyled, LoaderWrapStyled } from './styled';
import { userService } from 'apiService';
import { iconImages } from 'assets';
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth } from 'actions';
import { ScreeningProfileForm } from 'forms';
// import { WellRegisterForm } from 'forms/WellAppScreeningForm';
// import { toast } from "react-toastify";
// import Loader from "react-loader-spinner";
// import moment from "moment";
import { Button, LogoLoadingFade } from 'components';

class ProfileScreeningContainer extends React.Component {
  state = {
    initialValues: {},
    edit_mode: false,
    families: [],
    loading: true,
  };

  componentDidMount() {
    this.fetchData();
    this.fetchDataFamilies();
    this.scrollToTop();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    const { authRedux } = this.props;
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 300);
    this.setState({
      initialValues: {
        firstname: authRedux.firstname,
        lastname: authRedux.lastname,
        weight: authRedux.weight,
        height: authRedux.height,
        name: authRedux.firstname + ' ' + authRedux.lastname,
        email: authRedux.email,
        birthday: authRedux.birthday,
        disease: authRedux.disease,
        gender: authRedux.gender,
        is_alcohol: authRedux.is_alcohol,
        is_smoke: authRedux.is_smoke,
        phone_number: authRedux.phone_number,
        position: authRedux.position,
        company: authRedux.company && authRedux.company.name,
      },
    });
  };

  fetchDataFamilies = async () => {
    const res = await userService.GET_PROFILE_FAILIES();
    if (res && res.success) {
      this.setState({
        families: res.data,
      });
    }
  };

  handleSubmitForm = async () => {
    const { edit_mode } = this.state;
    const {
      reduxForm: { values },
      authRedux,
    } = this.props;
    let params = {
      ...values,
      avatar: authRedux.avatar,
      families_count: authRedux.families_count,
      id: authRedux.id,
    };
    if (edit_mode) {
      const res = await userService.PUT_PROFILE_SCREENING(params);
      if (res && res.success) {
        // Tracking user event
        ReactGA.event({
          category: 'profile',
          action: 'edit_my_profile',
        });
        this.props.setReduxUserAuth({ ...params });
        this.fetchData();
      }
    }
    this.props.history.push(ROUTE_PATH.WELL_REGISTER);
    // this.setState({
    //   edit_mode: !edit_mode,
    // });
  };

  handleAddFamily = () => {
    this.props.history.push(ROUTE_PATH.PROFILE_FAMILY);
  };

  handleClickBuyHistory = () => {
    this.props.history.push(ROUTE_PATH.BUY_PRODUCT_HISTORY);
  };

  handleClickServiceHistory = () => {
    // this.props.history.push(ROUTE_PATH.SUMMARY_SERVICE_HISTORY);
    this.props.history.push(ROUTE_PATH.SUMMARY_HISTORY);
  };

  handleEditProfile = (family_profile) => {
    this.props.history.push(
      ROUTE_PATH.PROFILE_FAMILY + '?section=edit',
      family_profile
    );
  };

  handleClickButton = () => {
    this.fileInput.click();
  };

  onRefInput = (e) => {
    this.fileInput = e;
  };

  fileSelectedHandler = (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = () => {
      this.uploadImgProfile(reader.result);
    };
  };

  uploadImgProfile = async (img) => {
    const { authRedux } = this.props;
    const param = { avatar_base64: img };
    const res = await userService.PATCH_PROFILE(param);
    if (res && res.success) {
      let params = {
        ...authRedux,
        avatar: res.data.avatar,
      };
      this.props.setReduxUserAuth({ ...params });
    }
  };

  render() {
    const { initialValues, edit_mode, loading, families } = this.state;
    const { authRedux, configProjectRedux } = this.props;
    return loading ? (
      <LoaderWrapStyled>
        <LogoLoadingFade />
      </LoaderWrapStyled>
    ) : (
      <ProfileScreeningContainerStyled>
        <div className="layout_form">
          <ScreeningProfileForm
            initialValues={initialValues}
            handleSubmitForm={this.handleSubmitForm}
            authRedux={authRedux}
            edit_mode={edit_mode}
            handleClickButton={this.handleClickButton}
            is_corporate={configProjectRedux.is_corporate}
          />
          <div className="row">
            <div className="family_layout">
              <div className="text_title">สมาชิก</div>
              {families.map((e, i) => (
                <div className="list_layout" key={i}>
                  <div className="text_list">
                    {e.firstname} {e.lastname}
                  </div>
                  <div
                    className="text_edit"
                    onClick={() => this.handleEditProfile(e)}
                  >
                    แก้ไข
                  </div>
                </div>
              ))}
              <div className="add_family" onClick={this.handleAddFamily}>
                <img src={iconImages['add.png']} alt="add" />
                <div className="text_add">เพิ่มสมาชิก</div>
              </div>
            </div>
            <div className="btn">
              <Button
                width="300px"
                height="40px"
                fontSize="14px"
                theme_border
                // style={{ marginTop: 20 }}
                border="#66A7C0"
                color="#66A7C0"
                handleClickButton={this.handleClickServiceHistory}
              >
                ดูประวัติการประเมินความเสี่ยง
              </Button>
              <Button
                width="300px"
                height="40px"
                theme_standard_2
                style={{ marginTop: 20 }}
                backgroundColor="#F8F8F8"
                color="#000000"
                font="14px"
                handleClickButton={this.handleClickBuyHistory}
              >
                ประวัติคำสั่งซื้อสินค้าและบริการ
              </Button>
            </div>
          </div>
          <input
            type="file"
            hidden
            ref={this.onRefInput}
            multiple
            onChange={this.fileSelectedHandler}
          />
        </div>
      </ProfileScreeningContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.ScreeningProfileForm,
  // reduxForm: state.form.WellRegisterForm,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileScreeningContainer);

import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { CartContainerStyled } from './styled';
import { userService } from 'apiService';
// import { iconImages } from 'assets'
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth } from 'actions';
import { Orderlist, PenddingList, BoxLink, LogoLoadingFade } from 'components';

class CartContainer extends React.Component {
  state = {
    showType: 2,
    loading: true,
  };

  componentDidMount() {
    this.fetchOrder();
    this.scrollToTop();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchOrder = async () => {
    const { authRedux } = this.props;
    let res = await userService.GET_PRODUCT_ORDER_LIST_BY_STATUS(
      authRedux.id,
      'pending'
    );
    if (res && res.success) {
      this.setState({
        data: res.data,
        loading: false,
        showType: res.data.length > 0 ? 2 : 1,
      });
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.EXAMINE_TEST_KIT);
  };

  handleClickOrderItemNext = (id) => {
    ReactGA.event({
      category: 'view_product_order',
      action: 'cart_view_product_order',
    });
    this.props.history.push(
      `${ROUTE_PATH.SUCCESS_ORDER}/${id}?payment_id=latest&from=cart`
    );
  };

  render() {
    const { showType, data, loading } = this.state;
    return (
      <CartContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
            {/* <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            /> */}
          </div>
        ) : (
            <>
              <div
                className="link_back"
                onClick={() => this.handleClickBackFirstPage()}
              >
                <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
              </div>
              <div className="container">
                {showType === 1 ? (
                  <div className="showType1">
                    <Orderlist theme_standard />
                  </div>
                ) : (
                    <div className="showType2">
                      <PenddingList
                        theme_standard
                        data={data}
                        handleClickNext={this.handleClickOrderItemNext}
                      />
                    </div>
                  )}
              </div>
            </>
          )
        }
      </CartContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);

import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { HospitalChooseContainerStyled } from './styled';
import { userService } from 'apiService';
import { setReduxUserAuth, setReduxHospitalList } from 'actions';
import { HospitalList, BoxLink, LogoLoadingFade } from 'components';
import { ROUTE_PATH } from 'helpers';
import { iconImages } from 'assets';

class HospitalChooseContainer extends React.Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.fetchDataCheckUp();
    this.scrollToTop();
    this.disableLoader();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchDataCheckUp = async () => {
    let res = await userService.GET_SERVICE_CATEGORIES_HER_WELL_SERVICE_LIST();
    // console.log('service', res);

    if (res && res.success) {
      this.setState({
        dataCheckup: res.data,
      });
      this.props.setReduxHospitalList(res.data)
    }
  };

  disableLoader = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  handleClickServiceOrder = async (e) => {
    ReactGA.event({
      category: 'herwill_cancer_hospital',
      action: 'click_chooseHospital_' + e.id,
    });
    this.props.history.push(`${ROUTE_PATH.PINK_INSURANCE_PACKAGE}?id=${e.id}`);
  };

  handleClickBackFirstPage = () => {
    // this.props.history.push(ROUTE_PATH.HOME_SCREENING);
    // this.props.history.push('/');
    this.props.history.goBack();
  };

  handleClickBackPage = () => {
    this.props.history.goBack();
  };

  render() {
    const { dataCheckup, loading } = this.state;
    return (
      <HospitalChooseContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div
              className="bg_top_share"
              style={{ backgroundImage: `url(${iconImages['head_bg2.jpg']})` }}
            />
            <div className='group_share'>
              <div
                className="link_back"
                onClick={() => this.handleClickBackPage()}
              >
                <div className='destop'>
                  <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
                </div>
                <div className='mobile'>
                  <BoxLink theme_white_box_link showArrow label="ย้อนกลับ" />
                </div>
              </div>
              <div className="container_share">
                <div className='title_page'>
                  จองตรวจคัดกรองมะเร็งเต้านม
                </div>
                <div className='sub_title_page'>
                  โรงพยาบาลที่เข้าร่วมโครงการในราคาพิเศษ
                </div>
                <HospitalList
                  theme_standard
                  handleClickBackFirstPage={() => this.handleClickBackFirstPage()}
                  dataCheckup={dataCheckup}
                  handleClickServiceOrder={this.handleClickServiceOrder}
                />
              </div>
            </div>
          </>
        )}
      </HospitalChooseContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxHospitalList: (data) => dispatch(setReduxHospitalList(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HospitalChooseContainer);

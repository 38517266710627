import styled, { keyframes } from 'styled-components';
import { COLOR, FONT } from 'assets';
import { fadeInUp } from 'react-animations';
const bounceAnimation = keyframes`${fadeInUp}`;

export const WellRegisterFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  width: 100%;
  font-size: ${FONT.SIZE_11};
  .move_show {
    animation: 1s ${bounceAnimation};
    color: ${COLOR.BLACK_6};
    .label_question {
      color: ${COLOR.BLACK_6};
    }
    .group_input {
      text-align: left;
      color: ${COLOR.BLACK_6};
      margin-bottom: 10px;
      &.margin_bottom1 {
        margin-bottom: 82px;
      }
      &.tm {
        margin-bottom: 0px;
        margin-top: 24px;
      }
      .label_name {
        margin: 10px 0;
        font-weight: 600;
        display: inline-block;
        line-height: 1;
        font-size: ${FONT.SIZE_18};
        color: ${COLOR.BLACK_7};
      }
      &.text_center {
        text-align: center;
        font-size: ${FONT.SIZE_13};
      }
      .label_show {
        font-size: ${FONT.SIZE_18};
      }
      .input_box {
        display: flex;
        align-items: flex-end;
        .back_label {
          font-size: ${FONT.SIZE_16};
          margin-left: 20px;
        }
      }
    }
    .btn_layer {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      .show_button {
        min-width: 224px;
      }
      &.no_margin_top {
        margin-top: 0px;
      }
    }
    .btn_layer2 {
      display: flex;
      margin-top: 103px;
      justify-content: flex-end;
      .show_button {
        min-width: 224px;
        &.full_width {
          width: 100%;
        }
      }
    }
    .btn_layer3 {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      .show_button {
        min-width: 224px;
      }
      &.no_margin_top {
        margin-top: 0px;
      }
    }
    .btn_layer4 {
      margin-top: 40px;
      display: flex;
      justify-content: flex-end;
      .show_button {
        min-width: 224px;
      }
    }
    .btn_layer5 {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      .show_button {
        min-width: 224px;
      }
      &.no_margin_top {
        margin-top: 0px;
      }
    }
  }

  .pinkalert {
    .move_show {
      color: ${COLOR.WHITE_1};
      .label_question {
        color: ${COLOR.WHITE_1};
      }
      .group_input {
        color: ${COLOR.WHITE_1};
        .label_name {
          color: ${COLOR.WHITE_1};
        }
      }
    }
  }
`;

import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ServiceContainerStyled } from './styled';
// import { userService } from "apiService"
import { ROUTE_PATH } from 'helpers';
import { iconImages, iconBackground } from 'assets';
import { setReduxUserAuth } from 'actions';
import { ButtonIcon, BoxIconLabel, BoxLink, LogoLoadingFade } from 'components';

class ServiceContainer extends React.Component {
  state = { loading: true };

  componentDidMount() {
    this.scrollToTop();
    this.setLoadder();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 300);
  }

  renderSeviceHighlight = (key) => {
    switch (key) {
      case 'cancer_checkup':
        return (
          <ButtonIcon
            theme_standard
            src={iconImages['calendar_blue2.png']}
            label1="จองคิวตรวจคัดกรองมะเร็ง"
            label2="กับแพทย์ในเครือโรงพยาบาลพันธมิตรของเรา"
            lable1Size="18px"
            handleOnClick={() => this.handleClickService('insurance_package')}
          />
        );
      case 'cancer_insurance':
        return (
          <ButtonIcon
            theme_standard
            src={iconImages['toffy2.png']}
            iconBackground="none"
            label1="ปรึกษาการตรวจ Genetic"
            lable1Size="18px"
            handleOnClick={() => this.handleClickService('consult_genetic')}
          />
        );
      case 'hpv_test_kit':
        return (
          <ButtonIcon
            theme_standard
            src={iconImages['testkit_white2.png']}
            iconBackground="none"
            label1="รับชุดตรวจ Testkit"
            lable1Size="18px"
            handleOnClick={() => this.handleClickService('testkit')}
          />
        );
      case 'genetic_appointment':
        return (
          <ButtonIcon
            theme_standard
            src={iconImages['fwd4.png']}
            iconBackground="none"
            label1="วางแผนประกันด้านมะเร็ง"
            lable1Size="18px"
            handleOnClick={() => this.handleClickService('insurance_plan')}
          />
        );
      // case 'cancer_appointments':
      //   return (
      //     <ButtonIcon
      //       theme_standard
      //       src={iconImages['check1.png']}
      //       iconBackground="none"
      //       label1="ปรึกษาการตรวจคัดกรองมะเร็ง"
      //       label2="กับผู้เชี่ยวชาญของเรา"
      //       lable1Size="18px"
      //       handleOnClick={() => this.handleClickService('consult_cencer')}
      //     />
      //   );
      default:
        break;
    }
  };

  renderServiceETCPriority = (key) => {
    switch (key) {
      case 'cancer_checkup':
        return (
          <BoxIconLabel
            theme_standard
            handleClick={() => this.handleClickService('insurance_package')}
            src={iconImages['toffy2.png']}
            label="จองคิวตรวจคัดกรองมะเร็ง"
          />
        );
      case 'cancer_insurance':
        return (
          <BoxIconLabel
            theme_standard
            handleClick={() => this.handleClickService('consult_genetic')}
            src={iconImages['toffy2.png']}
            label="ปรึกษาการตรวจ Genetic"
          />
        );
      case 'hpv_test_kit':
        return (
          <BoxIconLabel
            theme_standard
            handleClick={() => this.handleClickService('testkit')}
            src={iconImages['testkit_white2.png']}
            label="รับชุดตรวจ Testkit"
          />
        );
      case 'genetic_appointment':
        return (
          <BoxIconLabel
            theme_standard
            handleClick={() => this.handleClickService('insurance_plan')}
            src={iconImages['fwd4.png']}
            label="วางแผนประกันด้านมะเร็ง"
          />
        );
      default:
        break;
    }
  };

  handleClickService = (key) => {
    switch (key) {
      case 'consult_genetic':
        ReactGA.event({
          category: 'click_genetic_appointment',
          action: 'wellscreening_service_click_genetic_appointment',
        });

        this.props.history.push(ROUTE_PATH.CONSULT_GENETIC);
        break;
      case 'consult_cencer':
        ReactGA.event({
          category: 'click_genetic_cencer',
          action: 'wellscreening_service_click_genetic_cencer',
        });

        this.props.history.push(ROUTE_PATH.CONSULT_CANCER);
        break;
      case 'testkit':
        ReactGA.event({
          category: 'click_testkit',
          action: 'wellscreening_service_click_testkit',
        });

        this.props.history.push(ROUTE_PATH.EXAMINE_TEST_KIT);
        break;
      case 'insurance_plan':
        ReactGA.event({
          category: 'click_fwd_insurrance',
          action: 'wellscreening_service_click_fwd_insurrance',
        });

        this.props.history.push(ROUTE_PATH.INSURANCE_PLAN);
        break;
      case 'insurance_package':
        ReactGA.event({
          category: 'click_pyt_package',
          action: 'wellscreening_service_click_pyt_package',
        });

        this.props.history.push(ROUTE_PATH.INSURANCE_PACKAGE);
        break;
      default:
        break;
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  render() {
    const { configProjectRedux } = this.props;
    const { loading } = this.state
    return (
      <ServiceContainerStyled>
        {
          loading ? (
            <div className="loader_layout">
              <LogoLoadingFade />
              {/* <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={500}
                className="lodding"
              /> */}
            </div>
          ) :
            (
              <>
                <img
                  className="dot_img"
                  src={iconBackground['bg-dot.jpg']}
                  alt="dot-bg"
                />
                <div
                  className="link_back"
                  onClick={() => this.handleClickBackFirstPage()}
                >
                  <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
                </div>
                <div className="content_container">
                  <div className="top_layout">
                    <div className="top_left">
                      <div className="div1">หากคุณมีความกังวลใจใดๆ</div>
                      <div className="div2">สามารถเลือกรับบริการด้านมะเร็ง</div>
                      <div className="div2">
                        ที่
                <img
                          className="show_logo"
                          src={iconImages['well_logo.png']}
                          alt="logo show"
                        />
                คัดสรรมาเพื่อคุณได้ทันที
              </div>
                    </div>
                    <div className="top_right">
                      <div className="button_layout">
                        {this.renderSeviceHighlight(
                          configProjectRedux?.services?.main[0]
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="bottom_layout">
                    <div className="group_label">
                      <div className="dot" />
                      <div className="label1">บริการอื่นๆ</div>
                      <div className="label2">
                        บริการทางการแพทย์ของท่านจากการวิเคราะห์ปัจจัยเสี่ยง
                      </div>
                    </div>
                  </div>
                  <div className="group_otp">
                    <div className="left_otp">
                      <div className="top_left_otp">
                        <div className="button_layout">
                          {this.renderServiceETCPriority(
                            configProjectRedux?.services?.other[0]
                          )}
                        </div>
                      </div>
                      <div className="bottom_left_otp">
                        <div className="button_layout">
                          {this.renderServiceETCPriority(
                            configProjectRedux?.services?.other[1]
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="right_otp">
                      <div className="button_layout">
                        <div className="top_right_otp">
                          <div className="button_layout">
                            {this.renderServiceETCPriority(
                              configProjectRedux?.services?.other[2]
                            )}
                          </div>
                        </div>
                        <div className="bottom_right_otp">
                          <div className="button_layout">
                            {this.renderServiceETCPriority(
                              configProjectRedux?.services?.other[3]
                            )}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="btn_show">
                    <div className="btn_back">
                      <ButtonIcon
                        theme_gray
                        lable1Size="14px"
                        label1="กลับสู่หน้าแรก"
                        handleOnClick={() => this.handleClickBackFirstPage()}
                      />
                    </div>
                  </div>
                </div>
                <img
                  className="bg_img"
                  src={iconBackground['bg_service.png']}
                  alt="background"
                />
              </>
            )
        }
      </ServiceContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceContainer);

import styled, { keyframes } from "styled-components";
import { SCREEN, COLOR } from "assets";
import { fadeInUp } from "react-animations";

const bounceAnimation = keyframes`${fadeInUp}`;

export const ImgChoiceFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  width: 420px;
  margin-left: 10px;
  .img_logo {
    width: 50px;
    height: 50px;
  }
  .txt_detail {
    color: #003564;
    font-size: 18px;
    margin-top: 20px;
    max-width: 400px;
  }
  .txt_detail2 {
    color: grey;
    font-size: 16px;
    margin-top: 10px;
    max-width: 400px;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    color: #2f4f74;
    margin: 20px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .title2 {
    font-size: 14px;
    color: #2f4f74;
    margin: -3px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .img_layout {
    display: flex;
    justify-content: center;
    animation: 2s ${bounceAnimation};
    margin-top: 20px;

    .img_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .text_img {
        font-size: 16px;
        font-weight: 700;
      }
      .img_question {
        width: 100px;
        height: 100px;
        margin: 10px;
        border-radius: 50%;
        border: 10px solid #ffffff;
      }
      .img_question_select {
        width: 100px;
        height: 100px;
        margin: 10px;
        border-radius: 50%;
        border: 10px solid #0f8bae;
        &.herwill {
          border-color: ${COLOR.PINK_1};
        }
      }
    }
  }

  .button_layout {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    animation: 2s ${bounceAnimation};
  }
  .btn_bottom {
    height: 100px;
    display: flex;
    justify-content: center;
  }
  @media (max-width: ${SCREEN.PHONE}) {
    width: 300px;
    .theme_standard {
      width: 300px;
      display: flex;
      justify-content: center;
      .btn_layout {
        width: 100%;
      }
    }
  }
`;

import styled from 'styled-components';
import { SCREEN, COLOR, FONT } from 'assets';

export const LoginContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
  height: calc(100vh - 60px);

  .layout_left {
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 50%;
    height: calc(100vh - 60px);
    background: ${COLOR.ORANGE_1};
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .top_left_layer {
      border-radius: 10px;
      border: 1px solid ${COLOR.GRAY_5};
      height: 160px;
      width: 344px;
      background: ${COLOR.GRAY_10};
      .img_show {
        width: 100%;
        height: 100%;
      }
    }
    .bottom_left_layer {
      color: ${COLOR.BLACK_5};
      font-size: ${FONT.SIZE_18};
      border-radius: 10px;
      border: 1px solid ${COLOR.GRAY_5};
      width: 344px;
      margin-top: 56px;
      padding: 25px;
      background: ${COLOR.WHITE_1};
      .title_show {
        color: ${COLOR.ORANGE_1};
        font-size: ${FONT.SIZE_24};
        font-weight: 800;
        text-align: center;
        margin-bottom: 24px;
      }
      .detail_show {
        margin-bottom: 10px;
        text-align: center;
        &.no_margin {
          margin-bottom: 0px;
        }
      }
    }
  }
  .layout_right {
    width: 50%;
    padding: 2.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${({ loading }) => (loading ? 'center' : 'none')};
    &.center_mode {
      width: 100%;
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    height: auto;
    .layout_left {
      width: 40%;
      height: calc(100vh - 60px);
      width: 100%;
      justify-content: flex-start;
      .top_left_layer {
      }
      .bottom_left_layer {
      }
    }
    .layout_right {
      width: 60%;
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    width: 100%;
    height: auto;
    padding: 0 30px;
    flex-direction: column;
    .layout_left {
      width: 100%;
      height: auto;
      justify-content: flex-start;
      .top_left_layer {
        width: 100%;
      }
      .bottom_left_layer {
        width: 100%;
      }
    }
    .layout_right {
      width: 100%;
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    height: auto;
    .layout_left {
      width: 100%;
      height: auto;
      justify-content: flex-start;
      .top_left_layer {
      }
      .bottom_left_layer {
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    height: auto;
    .layout_left {
      width: 100%;
      justify-content: flex-start;
      height: auto;
      .top_left_layer {
        width: 100%;
      }
      .bottom_left_layer {
        width: 100%;
      }
    }
  }
`;

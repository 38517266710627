import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const AppointmentFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    width: 100%;
    
    font-size: ${FONT.SIZE_16};
    color: ${COLOR.BLUE_2};
    font-weight: 600;
    .title {
        font-size: ${FONT.SIZE_18};
        font-weight: bold;
        margin-bottom: 27px;
    }
    .group_body {
        display: flex;
        .left_layout {
            width: 50%;
            padding-right: 10px;
            .top_left {
                margin-bottom: 26px;
            }
            .box_profile {
                margin-bottom: 14px;
                .title_profile {
                    margin-bottom: 10px;
                }
            }
            .box_input {
                .group_input {
                    margin-bottom: 20px;
                    .label {
                         margin-bottom: 5px;
                         font-weight: 600;
                    }
                    &.no_margin {
                        margin-bottom: 0px;
                    }
                }
            }
        }
        .right_layout {
            width: 50%;
            padding-left: 10px;
            display: flex;
            align-items: flex-end;
        }
    }
`
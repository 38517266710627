import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ShareContainerStyled } from './styled';
// import { userService } from 'apiService';
import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import {} from 'components';
import Loader from 'react-loader-spinner';
import { HerWillContainer } from './SubProjects';
import { ShareGroup } from 'widgets';

class ShareContainer extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  handleClickToHome = (scheme) => {
    // Tracking user event
    ReactGA.event({
      category: scheme + '_exit',
      action: 'click_goHome',
    });
    this.setState({
      loading: true,
    });
    this.clearToken();
    setTimeout(() => {
      this.setState({
        loading: false,
      });
      this.props.history.push('/');
    }, Math.floor(Math.random() * 500) + 100);
  };

  handleClickToPinkAlert = () => {
    // Tracking user event
    ReactGA.event({
      category: 'pinkalert_exit',
      action: 'click_goPinkAlert',
    });

    window.location = process.env.REACT_APP_PINKALERT_FB_URI;
  };

  copyFunction = () => {
    var copyText = document.createElement('textarea');
    copyText.value = process.env.REACT_APP_SELF_ENDPOINT;
    document.body.append(copyText);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    copyText.remove();
    alert('Copied the URL: ' + copyText.value);
  };

  clearToken = () => {
    sessionStorage.clear();
    localStorage.clear();
    this.props.setReduxUserAuth(false);
  };

  renderBody = (key) => {
    switch (key) {
      case 'herwill':
        return (
          <HerWillContainer
            propsHitory={this.props.history}
            handleClickNext={this.handleClickNext}
            handleClickHome={() => this.handleClickToHome(key)}
          />
        );
      case 'pinkalert':
        const { authRedux } = this.props;
        return (
          <>
            <div className="bg_layout">
              <img
                className="icon_logo"
                src={iconImages['logo_pink.png']}
                alt="logo well"
              />
            </div>
            <div className="group_box">
              <div className="box_layout">
                <div className="txt_center">
                  <div
                    className="txt_normal"
                    style={({ fontWeight: 700 }, { fontSize: '18px' })}
                  >
                    ขอบคุณ
                  </div>
                  <div className="txt_pink">
                    คุณ{authRedux && authRedux.firstname}
                  </div>
                </div>
                <div className="txt_center">
                  <div className="txt_normal">คุณได้เข้าร่วมเป็น 1 ใน</div>
                  <div className="txt_pink">
                    {authRedux && authRedux.surveyResults.order_id}
                  </div>
                </div>
                <div className="txt_center">
                  <div className="txt_normal">
                    ของผู้ได้รับการตรวจคัดกรองมะเร็งเต้านม
                  </div>
                </div>
                <div className="txt_center">
                  <div className="txt_navy">
                    แชร์ลิงค์ ชวนเพื่อนมาเช็คมะเร็งเต้านม
                  </div>
                </div>
                <ShareGroup.ShareBtnPinkAlert />
                {/* <div className="layout_share">
                  <FacebookShareButton
                    url={'https://pinkalert.wellcancer.com'}
                    quote={
                      'PINK Alert : Check & Share Project : "รู้-เพราะ-รัก" รู้ทัน เพราะมะเร็งเต้านม รักษาได้'
                    }
                    hashtag="#PinkAlert2020"
                  >
                    <img
                      className="icon_share icon_share_logo"
                      src={iconImages['share_facebook.png']}
                      alt="logo well"
                    />
                  </FacebookShareButton>

                  <LineShareButton
                    url={'https://pinkalert.wellcancer.com'}
                    title={
                      'PINK Alert : Check & Share Project : "รู้-เพราะ-รัก" รู้ทัน เพราะมะเร็งเต้านม รักษาได้'
                    }
                  >
                    <img
                      className="icon_share icon_share_logo"
                      src={iconImages['line_share.png']}
                      alt="logo well"
                    />
                  </LineShareButton>

                  <TwitterShareButton
                    url={'https://pinkalert.wellcancer.com'}
                    title={
                      'PINK Alert : Check & Share Project : "รู้-เพราะ-รัก" รู้ทัน เพราะมะเร็งเต้านม รักษาได้'
                    }
                    hashtags={['PinkAlert2020', 'รู้เพราะรัก']}
                  >
                    <img
                      className="icon_share icon_share_logo"
                      src={iconImages['twit_share.png']}
                      alt="logo well"
                    />
                  </TwitterShareButton>

                  <div className="icon_share">
                    <input
                    type="text"
                    value={process.env.REACT_APP_SELF_ENDPOINT}
                    id="myInput"
                    hidden
                  />
                    <img
                      onClick={this.copyFunction}
                      className=" icon_share_logo"
                      src={iconImages['link_share.png']}
                      alt="logo well"
                    />
                  </div>
                </div> */}

                <button
                  className="btn_end"
                  onClick={() => this.handleClickToHome(key)}
                >
                  <div className="txt_btn">กลับสู่หน้าหลัก</div>
                </button>

                <button
                  className="btn_pink"
                  onClick={this.handleClickToPinkAlert}
                >
                  <div className="txt_btn">เยี่ยมชมโครงการ Pink Alert</div>
                </button>
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };

  render() {
    const { loading } = this.state;
    const { configProjectRedux } = this.props;
    const { scheme } = configProjectRedux;
    return (
      <ShareContainerStyled src={loading ? '' : iconImages['bg_pink.png']}>
        {loading ? (
          <div className="loader_layout">
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            />
          </div>
        ) : (
          this.renderBody(scheme)
        )}
      </ShareContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareContainer);

import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const BoxIconLabel2Styled = styled.div`
/*===============================================
 Container 
===============================================*/
    width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        cursor: pointer;
        
        display: flex;
        border: 1px solid  ${COLOR.BLUE_1};
        border-radius: 23px;
        .icon_layout {
            width: 20%;
            padding: 19px;
            border-radius: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-color: ${COLOR.BLUE_1};
            display: flex;
            background-image: ${COLOR.LINEAR_BLUE2};   
            .icon_btn {
                width: 100%;
            } 
        }
        .label_layout {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            display: flex;
            font-size: ${FONT.SIZE_16};
            font-weight: 800;
            color: ${COLOR.BLUE_2};
            // background: ${COLOR.GRAY_1};
            padding: 5px 10px;
            border-top-right-radius: 23px;
            border-bottom-right-radius: 23px;
        }
    }
`
import React from 'react';
// import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { SummaryServiceHistoryContainerStyled } from './styled';
import { iconSummary, iconImages } from 'assets';
import { ROUTE_PATH } from 'helpers';
import { BoxLink, Button, LogoLoadingFade, DropDownCustom } from 'components';
import { userService } from 'apiService';
import moment from 'moment';

class SummaryServiceHistoryContainer extends React.Component {
  state = { loading: true, dataSelect: [] };

  componentDidMount() {
    this.scrollToTop();
    this.fetchData();
  }

  fetchData = async () => {
    const { authRedux } = this.props;
    const { dataSelect } = this.state;
    const res = await userService.GET_SCREENINGS_SUMMARY_LIST(authRedux.id);
    if (res && res.success) {
      res.data.map((e, i) =>
        dataSelect.push({
          label: moment(e.created_at)
            .add(543, 'year')
            .locale('th')
            .format('LL H:mm'),
          value: e.id,
        })
      );
      this.handleSelectData(res.data[0].id);
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  levelItem = (level) => {
    switch (level) {
      case 'HIGH':
        return 'สูง';
      case 'MEDIUM':
        return 'ปานกลาง';
      case 'LOW':
        return 'ต่ำ';
      default:
        break;
    }
  };

  imgItem = (level) => {
    // console.log(level, Math.floor((level * 100) / 5));
    switch (Math.floor((level * 100) / 5)) {
      case 0:
        return 'low_5.png';
      case 1:
        return 'low_5.png';
      case 2:
        return 'low_10.png';
      case 3:
        return 'low_15.png';
      case 4:
        return 'medium_20.png';
      case 5:
        return 'medium_25.png';
      case 6:
        return 'medium_30.png';
      case 7:
        return 'medium_35.png';
      case 8:
        return 'medium_40.png';
      case 9:
        return 'medium_45.png';
      case 10:
        return 'high_50.png';
      case 11:
        return 'high_55.png';
      case 12:
        return 'high_60.png';
      case 13:
        return 'high_65.png';
      case 14:
        return 'high_70.png';
      case 15:
        return 'high_75.png';
      case 16:
        return 'high_80.png';
      case 17:
        return 'high_85.png';
      case 18:
        return 'high_90.png';
      case 19:
        return 'high_95.png';
      default:
        return '';
    }
  };

  colorItem = (level) => {
    switch (level) {
      case 'HIGH':
        return '#F59E06';
      case 'MEDIUM':
        return '#51B2CA';
      case 'LOW':
        return '#7AAA4A';
      default:
        break;
    }
  };

  descriptionItem = (level) => {
    switch (level) {
      case 'HIGH':
        return 'ควรแจ้งแพทย์ ถ้าคุณมีโรคประจำตัวเหล่านี้ ไขมันพอกตับ เบาหวาน โรคอ้วน ตับแข็ง ไวรัสตับอักเสบบี หรือ ซี เรื้อรัง ';
      case 'MEDIUM':
        return 'แนะนำให้ตรวจเพิ่มเติมด้วยวิธี  stool occult blood, Barrium enema, colonoscopy';
      case 'LOW':
        return 'แม้ผลการสรุปการวิเคราะห์ของคุณแสดงถึงแนวโน้มของอาการที่มีความเสี่ยงระดับน้อย แต่คุณไม่ควรละเลยและควรดูแลสุขภาพของตนเองต่อไป';
      default:
        break;
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.PROFILE_SCREENING);
  };

  handleSelectData = async (e) => {
    const { loading } = this.state;
    const { authRedux } = this.props;
    if (loading !== true) {
      this.setState({
        loading_inner: true,
      });
    }
    const res = await userService.GET_SCREENINGS_SUMMARY_DETAIL(
      authRedux.id,
      e
    );
    if (res && res.success) {
      this.setState({
        dataSummary: res.data,
        loading: false,
        loading_inner: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { loading, dataSelect, dataSummary, loading_inner } = this.state;
    return (
      <SummaryServiceHistoryContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div className="container">
              <div className="title_header">
                สรุปผลการวิเคราะห์สัญญาณอาการที่อาจบ่งชี้
                และเกี่ยวข้องกับอาการของมะเร็ง
              </div>
              <div
                className="link_layout"
                onClick={this.handleClickBackFirstPage}
              >
                <BoxLink theme_standard_box_link label="ย้อนกลับ" />
              </div>
              <div className="title_header2">Early Sign Report</div>
              <div className="show_dorpdown">
                <div className="label_name">ผลการตรวจของวันที่ :</div>
                <div className="drop_layout">
                  <DropDownCustom
                    theme_normal_dropdown
                    label=""
                    options={dataSelect}
                    onChange={(e) => this.handleSelectData(e)}
                    alignItems="center"
                    width="100%"
                  />
                </div>
              </div>
              {loading_inner ? (
                <div className="loader_layout">
                  <LogoLoadingFade />
                </div>
              ) : (
                <div className="box_layout">
                  {dataSummary &&
                    dataSummary.cancers &&
                    dataSummary.cancers.map((e, i) => (
                      <div className="box">
                        <div
                          className="img_box"
                          style={{ color: this.colorItem(e.level) }}
                        >
                          <img
                            className="img_level"
                            src={iconSummary[this.imgItem(e.score)]}
                            alt=""
                          />
                          <div className="text_logo">
                            <img
                              className="img_logo"
                              src={iconImages['w_logo.png']}
                              alt=""
                            />

                            <div>เกี่ยวข้อง</div>
                            <div className="text_score">
                              {this.levelItem(e.level)}
                            </div>
                          </div>
                        </div>
                        <div className="text_box">
                          <div className="text_grey">
                            คุณมีความเกี่ยวข้อง{this.levelItem(e.level)}
                            กับอาการของ
                          </div>
                          <div className="text_blue">{e.name} </div>
                          <div className="text_grey">
                            {this.descriptionItem(e.level)}
                          </div>
                        </div>
                      </div>
                    ))}
                  {dataSelect.length > 0 && (
                    <div className="box_text">
                      <div className="text_title">สรุปอาการของคุณ</div>
                      {dataSummary &&
                        dataSummary.symptoms &&
                        dataSummary.symptoms
                          .filter(
                            (item) =>
                              !(item.value !== 'yes' || !item.is_primary)
                          )
                          .map((e, i) => (
                            <div className="row">
                              <img
                                className="img_logo"
                                src={iconImages['img_dot_circle.png']}
                                alt=""
                              />
                              <div className="text_list">{e.name}</div>
                            </div>
                          ))}
                      <div className="text1">
                        คำตอบดังกล่าวนี้ ถูกคำนวนโดย A.I. ร่วมกับประวัติ
                        พฤติกรรม ปัจจัยเสี่ยงและอาการร่วมอื่นๆที่เกี่ยวข้อง
                      </div>
                      <div className="text2" style={{ marginTop: '10px' }}>
                        ผลสรุปนี้ ไม่ใช่การวินิฉัย
                      </div>
                      <div className="text2">หรือแทนคำแนะนำของแพทย์</div>
                      <div className="text3">
                        อาการที่เกี่ยวข้องกับมะเร็งสูงไม่ได้แปลว่าคุณเป็นมะเร็ง
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="btn_back">
                <Button
                  width="400px"
                  height="55px"
                  theme_gray
                  borderRadius="20px"
                  handleClickButton={this.handleClickBackFirstPage}
                >
                  ย้อนกลับ
                </Button>
              </div>
            </div>
          </>
        )}
      </SummaryServiceHistoryContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  summary: state.summary,
  authRedux: state.auth,
});

export default connect(mapStateToProps)(SummaryServiceHistoryContainer);

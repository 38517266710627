import React from "react";
import { GridFormStyled } from "./styled";
import { iconImages } from "assets";
import { Button } from "../../../components/Button";
import { LogoLoadingFade } from "components";
class GridForm extends React.Component {
  state = {
    value: null,
    answer: [],
    answerPage1: [],
    loading: true,
    quardrant: false,
    startQuestion: 10,
    endQuestion: 14,
    refresh: true,
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  };

  handleClickButton = (e) => {
    const { answer, quardrant } = this.state;
    const { question, handleSubmitAns } = this.props;
    let arr_select = [...answer];
    if (quardrant) {
      if (e.is_unknown && arr_select.find((item) => item.id !== e.id)) {
        arr_select = [{ id: e.id }];
      } else {
        if (
          arr_select.find((item) =>
            question.answers.find(
              (item2) => item2.is_unknown && item.id === item2.id
            )
          )
        ) {
          arr_select = [];
        }
        if (arr_select.find((item) => item.id === e.id)) {
          for (let i = 0; i < arr_select.length; i++) {
            if (arr_select[i].id === e.id) {
              arr_select.splice(i, 1);
            }
          }
        } else {
          arr_select = [...arr_select, { id: e.id }];
        }
      }
    } else {
      arr_select = [{ id: e.id }];
    }
    this.setState({
      answer: arr_select,
    });
    if (!question.is_multiple) {
      handleSubmitAns([{ id: e.id }]);
    }
  };

  handleSubmit = () => {
    const { answer, answerPage1 } = this.state;
    const { handleSubmitAns } = this.props;
    handleSubmitAns([...answerPage1, ...answer]);
  };

  handleToQuardrant = () => {
    const { answer } = this.state;

    this.setState({
      quardrant: true,
      startQuestion: 0,
      endQuestion: 10,
      answerPage1: answer,
      answer: [],
      refresh: false,
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        refresh: true,
      });
    }, 100);
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  };

  render() {
    const { question } = this.props;
    const { answer, quardrant, loading, startQuestion, endQuestion, refresh } =
      this.state;

    return (
      <>
        {refresh && (
          <GridFormStyled
            quardrant={quardrant}
            style={{ marginTop: quardrant ? 0 : 150 }}
          >
            {question && question.answers && (
              <>
                {loading ? (
                  <LogoLoadingFade />
                ) : (
                  <img
                    className="img_logo"
                    src={iconImages["logo_question.png"]}
                    alt="logo well"
                  />
                )}
              </>
            )}
            <div className="title">{question.label}</div>
            {quardrant && (
              <img
                className="img_question"
                src={iconImages["9quardrant.png"]}
                alt="logo well"
              />
            )}
            <div className="button_layout">
              {question &&
                question.answers &&
                question.answers
                  .slice(startQuestion, endQuestion)
                  .map((e, i) => (
                    <div key={i} style={{ marginTop: 20 }}>
                      <div style={{ marginRight: 20 }}>
                        <Button
                          theme_border_question
                          width="200px"
                          font={e.label.length < 15 ? "14px" : "11px"}
                          active={
                            answer.find((item) => item.id === e.id)
                              ? true
                              : false
                          }
                          handleClickButton={() => this.handleClickButton(e)}
                        >
                          {e.label}
                        </Button>
                      </div>
                    </div>
                  ))}
            </div>

            <div className="btn_bottom">
              {answer.length > 0 && (
                <Button
                  width="300px"
                  height="40px"
                  theme_standard
                  style={{ marginTop: 30 }}
                  handleClickButton={
                    !quardrant &&
                    answer.find((item) =>
                      question.answers.find(
                        (item2) => item2.is_unknown && item.id === item2.id
                      )
                    )
                      ? () => this.handleToQuardrant()
                      : () => this.handleSubmit()
                  }
                >
                  ต่อไป
                </Button>
              )}
            </div>
          </GridFormStyled>
        )}
      </>
    );
  }
}

export default GridForm;

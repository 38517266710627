import React from 'react';

export default function Back({ color = '#fff' }) {
  return (
    <svg
      id="Component_13_5"
      data-name="Component 13 – 5"
      xmlns="http://www.w3.org/2000/svg"
      width="7.778"
      height="12.778"
      viewBox="0 0 7.778 12.778"
    >
      <rect
        id="Rectangle_1"
        data-name="Rectangle 1"
        width="9"
        height="2"
        rx="1"
        transform="translate(1.414 5) rotate(45)"
        fill={color}
      />
      <rect
        id="Rectangle_2"
        data-name="Rectangle 2"
        width="9"
        height="2"
        rx="1"
        transform="translate(0 6.364) rotate(-45)"
        fill={color}
      />
    </svg>
  );
}

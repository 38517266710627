import React from "react";
import { BoxDetailStyled } from "./styled";
import cx from "classnames";
import { iconImages } from "assets";

export const BoxDetail = ({ theme_standard, data }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <BoxDetailStyled>
      <div className={customClass}>
        {/* <td dangerouslySetInnerHTML={{ __html: data }} /> */}
        {/* <div className="title_show">
                    มะเร็งปากมดลูกป้องกันได้
                </div> */}
        <div className="group_detail">
          <div className="left_box">
            {/* <div className="detail_1">
                            iCheck Test; HPV self-collection Test เป็นการตรวจหาเชื้อไวรัสเอชพีวี (HPV DNA Virus) ที่เป็นสาเหตุของการเกิดมะเร็งปากมดลูกโดยตรง
                        </div>
                        <div className="detail_1 no_margin">
                            ด้วยอุปกรณ์พิเศษที่เรียกว่า <span className="span_text">แปรงเอวาลีน</span> ทำให้คุณสามารถเก็บสิ่งส่งตรวจในช่องคลอดได้ด้วยตัวเองที่บ้าน แล้วส่งตัวอย่างที่ได้ไปที่โรงพยาบาลหรือศูนย์ตรวจทางการแพทย์ เพื่อตรวจหาเชื้อไวรัส HPV แล้วรอรับผลตรวจ
                            ที่บ้านหรือตามที่อยู่ที่ระบุ ภายใน 7 - 10 วัน
                        </div> */}
            <div dangerouslySetInnerHTML={{ __html: data }} />
          </div>
          <div className="right_box">
            <div className="detail_2">
              <div>หากท่านไม่ได้รับสินค้า ภายใน 7 วัน </div>
              <div>หรือสินค้ามีปัญหาใดๆ โปรดติดต่อ</div>
              <div>บริษัท อินไวเทรส จำกัด</div>
            </div>
            <div className="group_line">
              <div className="icon_show">
                <img
                  className="img_show"
                  src={iconImages["phone_gray.png"]}
                  alt="icon show"
                />
                061-717-1222
              </div>
              <div className="icon_show no_margin">
                <img
                  className="img_show"
                  src={iconImages["line_icon.png"]}
                  alt="icon show"
                />
                @medlinecheckup
              </div>
            </div>
            <div className="detail_3">(เวลาทำการ จ. - ศ. :7.00 - 17.00)</div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.tgcsthai.com/expertise.php?code=CG"
            >
              <div className="detail_4" style={{ cursor: "pointer" }}>
                ดูรายชื่อแพทย์มะเร็งนรีเวชใกล้คุณได้ที่นี่
              </div>
            </a>
          </div>
        </div>
      </div>
    </BoxDetailStyled>
  );
};

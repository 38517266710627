import React, { useState, useEffect } from 'react';
import { NavbarStyled } from './styled';
import { iconImages } from 'assets';
import { useHistory } from 'react-router-dom';
import { setReduxUserAuth } from 'actions';
import { useSelector, useDispatch } from 'react-redux';
import { userService } from 'apiService';
import { ROUTE_PATH } from 'helpers/constant';

export const Navbar = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const authRedux = useSelector((state) => state.auth);
  const configProjectRedux = useSelector((state) => state.configProjectRedux);
  const [isMbMenuOpen, setIsMbMenuOpen] = useState();
  const [hasToken, setHasToken] = useState();

  useEffect(() => {
    if (authRedux && authRedux.id) {
      setHasToken(true);
    } else {
      setHasToken(false);
    }
  }, [authRedux]);

  const handleClickHamburger = () => {
    setIsMbMenuOpen(!isMbMenuOpen);
  };

  const handleClickMenu = (key) => {
    setIsMbMenuOpen(!isMbMenuOpen);
    setTimeout(() => {
      switch (key) {
        case 'sign_up':
          history.push(ROUTE_PATH.WELL_SIGNUP);
          break;
        case 'login':
          history.push(ROUTE_PATH.WELL_LOGIN);
          break;

        default:
          break;
      }
    }, 200);
  };

  const handleClickLogOut = async () => {
    await userService.GET_LOGOUT();
    sessionStorage.clear();
    localStorage.removeItem('tokenList');
    dispatch(setReduxUserAuth(false));
    history.push('/');
  };

  const handleClickProfile = () => {
    setIsMbMenuOpen(false);
    history.push(ROUTE_PATH.PROFILE_SCREENING);
  };

  const handleClickHome = () => {
    history.push(configProjectRedux.home_uri);
  };

  return (
    <NavbarStyled isMbMenuOpen={isMbMenuOpen}>
      <div className="web_menu_layout">
        <div className="item_left">
          <div onClick={handleClickHome}>
            <div className="logo_navbar">
              <img
                className="icon_logo"
                src={iconImages['well_logo_6.png']}
                alt="logo well"
              />
              <div className="text_logo">
                Revolutionize your healthcare journey
              </div>
            </div>
          </div>
          {/* <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wellcancer.com"
          >
            <div className="logo_navbar">
              <img
                className="icon_logo"
                src={iconImages["well_logo.png"]}
                alt="logo well"
              />

              <div className="text_logo">
                Revolutionize your healthcare journey
              </div>
            </div>
          </a> */}
          <div className="menu_layout">
            <div className="txt_menu">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.wellcancer.com"
              >
                {/* Home */}
                หน้าหลัก
              </a>
            </div>
            <div className="txt_menu">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.wellcancer.com/copy-of-invitrace"
              >
                {/* Feature */}
                คุณลักษณะ
              </a>
            </div>
            <div className="txt_menu">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.wellcancer.com/legal"
              >
                {/* Legal */}
                กฎหมาย
              </a>
            </div>
            <div className="txt_menu">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.wellcancer.com/invitrace"
              >
                {/* Invitrace */}
                เกี่ยวกับเรา
              </a>
            </div>
          </div>
        </div>
        <div className="login_menu">
          {!hasToken ? (
            <>
              <div
                className="txt_login"
                onClick={() => handleClickMenu('sign_up')}
              >
                ลงทะเบียน
              </div>
              <div
                className="txt_login"
                style={{ color: '#3b96b7' }}
                onClick={() => handleClickMenu('login')}
              >
                เข้าสู่ระบบ
              </div>
            </>
          ) : (
            <>
              <img
                className="avatar"
                src={
                  authRedux.avatar ? authRedux.avatar : iconImages['avatar.png']
                }
                alt="avatar"
                onClick={() => handleClickProfile()}
              />
              <div className="show_name" onClick={() => handleClickProfile()}>
                {authRedux &&
                  authRedux.firstname &&
                  authRedux.lastname &&
                  `${authRedux.firstname}  ${authRedux.lastname}`}
              </div>
              <div
                className="txt_login"
                style={{ color: '#3b96b7' }}
                onClick={handleClickLogOut}
              >
                ออกจากระบบ
              </div>
            </>
          )}
          <button className="btn_navbar"> Download </button>
        </div>
      </div>
      <div className="mobile_menu_layout">
        <div className="logo_wrap" onClick={handleClickHome}>
          <img
            className="img_header"
            src={iconImages['well_logo_6.png']}
            alt=""
          />
          <div className="text_logo">Revolutionize your healthcare journey</div>
        </div>
        {/* <div className="logo_wrap">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wellcancer.com"
          >
            <img
              className="img_header"
              src={iconImages["well_logo.png"]}
              alt=""
            />
            <div className="text_logo">
              Revolutionize your healthcare journey
            </div>
          </a>
        </div> */}
        <div className="avatar" onClick={handleClickHamburger}>
          <img className="hamburger" src={iconImages['hamburger.png']} alt="" />
        </div>

        {isMbMenuOpen && (
          <div
            className={
              isMbMenuOpen
                ? 'sideber_mobile w3-animate-right'
                : 'sideber_mobile w3-animate-left'
            }
          >
            <div className="sub_menu" onClick={handleClickHamburger}>
              <img className="hamburger" src={iconImages['close.png']} alt="" />
            </div>
            <div>
              <a
                className="link_css"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.wellcancer.com"
              >
                Home
              </a>
            </div>
            <div className="sub_menu">
              <a
                className="link_css"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.wellcancer.com/copy-of-invitrace"
              >
                Feature
              </a>
            </div>
            <div className="sub_menu">
              <a
                className="link_css"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.wellcancer.com/legal"
              >
                Legal
              </a>
            </div>
            <div className="sub_menu">
              <a
                className="link_css"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.wellcancer.com/invitrace"
              >
                Invitrace
              </a>
            </div>
            {!hasToken ? (
              <>
                <div
                  className="sub_menu"
                  onClick={() => handleClickMenu('sign_up')}
                >
                  ลงทะเบียน
                </div>
                <div onClick={() => handleClickMenu('login')}>เข้าสู่ระบบ</div>
              </>
            ) : (
              <>
                <div
                  className="sub_menu no_margin"
                  onClick={() => handleClickProfile()}
                >
                  โปรไฟล์
                </div>
                <div className="sub_menu no_margin" onClick={handleClickLogOut}>
                  ออกระบบ
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </NavbarStyled>
  );
};

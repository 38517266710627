import styled from 'styled-components';
import { SCREEN } from 'assets';

export const ConsultDoctorContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .loading {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.RED_1};
    margin: 0px;
  }

  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    align-items: center;
    justify-content: center;
    background: white;
    width: 100%;
    height: 100vh;
    z-index: 99;
    &.active {
      display: flex;
    }
  }

  .is_desktop {
    padding: 16px;
    padding-bottom: 0px;
    position: relative;
    .btn_back_wrap {
      max-width: 920px;
      width: 100%;
      margin: auto;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .location_container {
      max-width: 704px;
      width: 100%;
      margin: auto;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #131313c0;
        margin-bottom: 5px;
      }
      .address_wrap {
        position: relative;
        margin-bottom: 22px;
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: contain;
        height: 128px;
        display: flex;
        justify-content: flex-end;
        border-radius: 8px;
        // box-shadow: 9px 11px 8px -3px rgb(0 0 0 / 15%);
        box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.3);
        .img_itele {
          width: 29.15px;
          height: 10.85px;
          position: absolute;
          top: 6px;
          right: 6px;
        }
        .obj_text {
          display: flex;
          align-items: center;
          margin-right: 30px;
          .ob_label {
            font-size: 31px;
            font-weight: bold;
            color: #007d69;
          }
          .group_text {
            margin-left: 16px;
            padding-top: 5px;
            .text1 {
              font-size: 18px;
              font-weight: bold;
              color: #007d69;
            }
            .text2 {
              font-size: 18px;
              font-weight: 500;
              color: #131313;
            }
          }
        }
      }
      .title_sec_2 {
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 14px;
      }
      .row_2_col {
        display: flex;
        flex-wrap: wrap;
        column-gap: 24px;
        row-gap: 36px;
        margin-bottom: 53px;
        .item_wrap {
          flex: 1;
        }
      }
      .no_service {
        margin-bottom: 48px;
        font-size: 18px;
        font-weight: bold;
        color: #898989;
        .title_service {
          margin-bottom: 27px;
          display: flex;
          align-items: center;
          .round {
            margin-right: 6px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: #898989;
          }
        }
        .btn_show {
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
          background: #ebebeb;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 20px;
          padding: 8px;
          margin-bottom: 37px;
        }
        .hide_box {
          display: none;
          &.hide {
            display: block;
          }
          .hide_box_title {
            font-size: 18px;
            font-weight: bold;
            color: #2f2f2f;
            margin-bottom: 19px;
          }
          .row_2_col_no_service {
            display: flex;
            flex-wrap: wrap;
            column-gap: 24px;
            row-gap: 36px;
            margin-bottom: 53px;
            .item_wrap {
              flex: 1;
            }
          }
        }
      }
      // .btn_end {
      //   cursor: pointer;
      //   height: 48px;
      //   width: 100%;
      //   border-radius: 22px;
      //   background-color: #e8e8e8;
      //   border: none;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   outline: none;
      //   box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2),
      //     0 4px 20px 0 rgba(0, 0, 0, 0.19);
      //   color: #000000;
      //   font-size: 16px;
      //   font-weight: bold;
      //   margin-top: 20px;
      // }
    }
  }

  .is_mobile {
    display: none;
    // height: 100vh;
    position: relative;
    .bg_top {
      position: absolute;
      z-index: -1;
      height: 209px;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }
    .container {
      padding: 16px;
      padding-bottom: 0px;
      height: 100%;
      .m_btn_back_wrap {
        margin-bottom: 94px;
      }
      .m_title {
        font-size: 20px;
        font-weight: bold;
        color: #131313c0;
        margin-bottom: 17px;
        text-align: center;
      }
      .m_address_wrap {
        position: relative;
        margin-bottom: 25px;
        background: #e6e9f0;
        padding: 15px 8px;
        border-radius: 8px;
        border: 1px solid #8e8e8e19;
        display: flex;
        align-items: center;
        .img_itele {
          width: 26.43px;
          height: 10px;
          position: absolute;
          top: 5px;
          right: 5px;
        }
        .img_doctor_mb {
          width: 92px;
          height: auto;
        }
        .group_text {
          margin-left: 10px;
          .text_style {
            font-size: 16px;
            font-weight: bold;
            color: #007c68;
            &.fs_18 {
              font-size: 18px;
            }
            &.small {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
      .m_no_service {
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: bold;
        color: #898989;
        .title_service {
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          .round {
            margin-right: 6px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: #898989;
          }
        }
        .btn_show {
          font-size: 14px;
          font-weight: 400;
          background: #ebebeb;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 20px;
          padding: 8px;
          margin-bottom: 37px;
        }
        .hide_box {
          display: none;
          &.hide {
            display: block;
          }
          .hide_box_title {
            font-size: 18px;
            font-weight: bold;
            color: #2f2f2f;
            margin-bottom: 19px;
          }
          .row_2_col_no_service {
            display: flex;
            flex-wrap: wrap;
            column-gap: 24px;
            row-gap: 36px;
            // margin-bottom: 53px;
            .item_wrap {
              flex: 1;
            }
          }
        }
      }
      .m_title_sec_2 {
        padding-left: 10px;
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 14px;
        // min-height: calc(100vh - 373px);
      }
      .m_row {
        margin-bottom: 24px;
      }
    }
  }
  .btn_layout {
    padding: 15px 32%;
    .btn_end {
      cursor: pointer;
      height: 48px;
      width: 100%;
      border-radius: 22px;
      background-color: #e8e8e8;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2),
        0 4px 20px 0 rgba(0, 0, 0, 0.19);
      color: #000000;
      font-size: 16px;
      font-weight: bold;
      margin-top: 20px;
    }
  }

  .md_container {
    width: 312px;
    background: #fff;
    // border: 1px solid #cf1a71;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo_show {
      width: 96px;
    }
    .text_1 {
      color: #131313c0;
      font-size: 14px;
      font-weight: 500;
      margin: 16px 0px;
    }
    .hopital_logo_show {
      width: 80%;
      height: 100px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      object-fit: contain;
      margin-bottom: 24px;
    }
    .btn_action {
      marggin-top: 19px;
      margin-bottom: 12px;
      width: 200px;
      border-radius: 22px;
      background: #cf1a71;
      border: 1px solid #cf1a71;
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
      padding: 8px;
      text-align: center;
      cursor: pointer;
      &.offline {
        background: #8e8e8e;
        color: white;
        border: none;
        cursor: unset;
      }
      &.white {
        background: #ffffff;
        color: #cf1a71;
      }
    }
    .back {
      margin-bottom: 21px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      color: #7b7b7b;
      text-decoration: underline;
    }
    .note {
      text-align: center;
    }
  }

  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .is_desktop {
      display: none;
    }
    .is_mobile {
      display: block;
    }
    .btn_layout {
      padding: 15px 9%;
      .btn_end {
        cursor: pointer;
        height: 48px;
        width: 100%;
        border-radius: 22px;
        background-color: #e8e8e8;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2),
          0 4px 20px 0 rgba(0, 0, 0, 0.19);
        color: #000000;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .is_desktop {
      display: block;
    }
    .is_mobile {
      display: none;
    }
  }
`;

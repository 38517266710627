import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { InsurancePackageContainerStyled } from './styled';
import { userService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import { PackageList2, BoxLink, LogoLoadingFade } from 'components';
import queryString from 'query-string';
import { iconImages } from 'assets';
// import { ROUTE_PATH } from 'helpers';

class InsurancePackageContainer extends React.Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.fetchDataCheckUp();
    this.scrollToTop();
    this.disableLoader();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchDataCheckUp = async () => {
    const { id } = queryString.parse(this.props.location.search);
    let filterIndexArrSelect = id
      ? this.props.hospitalList.findIndex((x) => x.id === Number(id))
      : 0;
    this.setState({
      dataCheckup: this.arraymove(this.props.hospitalList, filterIndexArrSelect, 0),
      indexArrayData: 0,
    });
  };

  arraymove = (arr, fromIndex, toIndex) => {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  };

  disableLoader = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  handleClickServiceOrder = async (service_package_id) => {
    const { authRedux } = this.props;
    let params = {
      service_package_id: service_package_id,
    };
    let profile_id = authRedux ? authRedux.id : 0

    let res = await userService.POST_SERVICE_ORDER(profile_id, params);
    if (res && res.success) {
      // Tracking user event
      let hospital = this.state.dataCheckup &&
        this.state.dataCheckup.find((hospital) => {
          return hospital.packages.find((pkg) => pkg.id === service_package_id);
        });
      let pkg = hospital?.packages.find((pkg) => pkg.id === service_package_id);
      ReactGA.event({
        category: 'service_package',
        action: 'confirm_service_package_' + service_package_id,
        label: service_package_id,
        value: pkg && parseInt(pkg.final_price),
      });
      window.open(res.data.redirect_uri, '_blank') ||
        window.location.assign(res.data.redirect_uri);
    }
  };

  handleClickBackFirstPage = () => {
    // this.props.history.push(ROUTE_PATH.HOME_SCREENING);
    this.props.history.push('/');
  };

  handleClickBackPage = () => {
    this.props.history.goBack();
  };

  render() {
    const { dataCheckup, loading, indexArrayData } = this.state;
    return (
      <InsurancePackageContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div
              className="bg_top_package"
              style={{ backgroundImage: `url(${iconImages['head_bg3.png']})` }}
            />
            <div className='group_pack'>
              <div
                className="link_back"
                onClick={() => this.handleClickBackPage()}
              >
                <div className='destop'>
                  <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
                </div>
                <div className='mobile'>
                  <BoxLink theme_white_box_link showArrow label="ย้อนกลับ" />
                </div>
              </div>
              <div className="container">
                <PackageList2
                  theme_standard_pink
                  handleClickBackFirstPage={() => this.handleClickBackFirstPage()}
                  dataCheckup={dataCheckup}
                  indexArrayData={indexArrayData}
                  handleClickServiceOrder={(e) => this.handleClickServiceOrder(e)}
                />
              </div>
            </div>
          </>
        )}
      </InsurancePackageContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  hospitalList: state.hospitalList,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsurancePackageContainer);

import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const BoxAdviceStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  height: 100%;
  /*===============================================
Theme 
===============================================*/

  .theme_standard {
    height: 100%;
    font-size: ${FONT.SIZE_18};
    color: ${COLOR.BLUE_2};
    .bg_layout {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: calc(100vh - 150px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .logo_well {
        text-align: center;
        margin-bottom: 30px;
        .logo_show {
          width: 96px;
        }
      }
      .label1 {
        font-weight: bold;
        text-align: center;
      }
      .label2 {
        font-size: ${FONT.SIZE_14};
        font-weight: 600;
        margin-bottom: 14px;
        margin-top: 21px;
        color: ${COLOR.BLUE_4};
        text-align: center;
      }
      .btn_layout {
      }
      .btn2 {
        width: 100%;
        display: none;
        margin-top: 12px;
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .theme_standard {
      .bg_layout {
        height: calc(100vh - 158px);
        .logo_well {
          display: none;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .theme_standard {
      .bg_layout {
        height: calc(100vh - 159px);
      }
    }
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .theme_standard {
      .bg_layout {
        height: calc(100vh - 278px);
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_standard {
      .bg_layout {
        background-size: cover;
        height: calc(100vh);
        padding: 0px 19px;
        .btn_layout {
          width: 100%;
          margin-top: 128px;
        }
        .btn2 {
          display: block;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    .theme_standard {
      .bg_layout {
        background-size: cover;
        height: calc(100vh);
        padding: 0px 19px;
        .btn_layout {
          width: 100%;
          margin-top: 128px;
        }
        .btn2 {
          display: block;
        }
      }
    }
  }
`;

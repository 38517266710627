import React from 'react'
import { ConfirmOrderStyled } from './styled'
import cx from "classnames";
import { BoxMember2, ShowDetailOrder } from 'components';
// import { ConfirmOrderForm } from 'forms';
import { RenderCommaMoney, calAge } from 'helpers';
import moment from 'moment';

export const ConfirmOrder = ({ theme_standard, handleClick,  dataProfile, dataOrderProduct }) => {
    const customClass = cx({
        "theme_standard": theme_standard
    })
    return (
        <ConfirmOrderStyled>
            <div className={customClass}>
                <div className="title">
                    สรุปยอดคำสั่งซื้อ
                </div>
                <div className="body_layout">
                    <div className="left_layout">
                        <div className="left_top">
                            <ShowDetailOrder
                                theme_standard
                                amount={dataOrderProduct?.amount}
                                price={dataOrderProduct && RenderCommaMoney(Number(dataOrderProduct.amount) * Number(dataOrderProduct.final_price))}
                            />
                        </div>
                        <BoxMember2
                            theme_standard_1
                            name={dataProfile && `${dataProfile.firstname} ${dataProfile.lastname}`}
                            sex={dataProfile?.gender}
                            birthday={dataProfile && moment(dataProfile.birthday).format('DD-MM-YYYY')}
                            age={dataProfile && calAge(dataProfile.birthday)}
                            showButton
                            handleClick={handleClick}
                        />
                    </div>
                    {/* <div className="display_form">
                        <ConfirmOrderForm
                            handleClickConfirmPayment={handleClickConfirmPayment}
                        />
                    </div> */}
                </div>
            </div>
        </ConfirmOrderStyled>
    )
}
import React from "react";
import { connect } from "react-redux";
import { PreCheckContainerStyled } from "./styled";
import { iconImages } from "assets";
import { Button } from "../../../../components/Button";
import { ROUTE_PATH } from "helpers";

class PreCheckContainer extends React.Component {
  state = {
    question: {
      title: "คุณกำลังสงสัยว่าตนเองตั้งครรภ์หรือไม่",
      btn1: "ใช่",
      btn2: "ไม่ใช่",
      state: 1,
    },
  };

  componentDidMount = () => {
    const {subject} = this.props;
    this.scrollToTop();
    if (subject.gender === "male") {
      this.props.history.push(ROUTE_PATH.SELECT_SYMPTOM);
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  handleClickButtonYes = () => {
    this.setState({
      question: {
        title:
          "Well ต้องขออภัยตอนนี้คุณกำลังตั้งครรภ์ และ Well ไม่สามารถให้บริการคุณได้",
        title2: "(เพราะอาจเกิดความคลาดเคลื่อนในการหาแนวโน้ม)",
        btn1: "ยืนยัน",
        state: 2,
      },
    });
  };

  handleClickToHome = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  handleClickToSelectSymptom = () => {
    this.props.history.push(ROUTE_PATH.SELECT_SYMPTOM);
  };

  render() {
    const { question } = this.state;
    return (
      <PreCheckContainerStyled>
        <div className="layout_detail">
          <img
            className="img_logo"
            src={iconImages["logo_question.png"]}
            alt="logo well"
          />
          <div className="title">{question.title}</div>
          {question.title2 && <div className="title2">{question.title2}</div>}
          {question.state === 1 ? (
            <>
              <Button
                width="400px"
                height="45px"
                theme_standard
                style={{ marginTop: 20 }}
                handleClickButton={this.handleClickButtonYes}
              >
                {question.btn1}
              </Button>
              <Button
                width="400px"
                height="45px"
                theme_standard
                backgroundColor="#ffffff"
                color="#000000"
                style={{ marginTop: 20 }}
                handleClickButton={this.handleClickToSelectSymptom}
              >
                {question.btn2}
              </Button>
            </>
          ) : (
            <Button
              width="400px"
              height="45px"
              theme_standard
              style={{ marginTop: 20 }}
              handleClickButton={this.handleClickToHome}
            >
              {question.btn1}
            </Button>
          )}
        </div>
      </PreCheckContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  subject:state.subject
});

export default connect(mapStateToProps)(PreCheckContainer);

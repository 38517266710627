import React from 'react';
import { NotificationStyled } from './styled';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Notification = () => {
  return (
    <NotificationStyled>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        style={{ top: 50, right: 5 }}
      ></ToastContainer>
    </NotificationStyled>
  );
};

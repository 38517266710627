import styled from 'styled-components';
import { SCREEN } from 'assets';
import { iconImages } from 'assets';

export const SelectAreaContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .area_select {
    background-color: #f8f8f8;
    min-height: calc(100vh - 87px);

    background-image: url(${iconImages['dot_bg.png']});
    background-repeat: repeat;
    background-repeat-y: no-repeat;
    background-position: top 0;
    padding: 20px 0px;
    overflow-y: auto;
    .link_back {
      max-width: 80%;
      margin: auto;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
    .group_layout {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .left_layout {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 100px;
        .text_title {
          font-weight: 500;
          font-size: 20px;
          color: #2f4f74;
          width: 350px;
          text-align: center;
        }
      }
      .right_layout {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img_box {
          width: 280px;
          height: 500px;
          /* border: 1px solid #000000; */
          .head {
            position: absolute;
            width: 120px;
            height: 50px;
            margin-left: 105px;
            margin-top: -3px;
            .head_tab {
              width: 100%;
              height: 100%;
              z-index: 1;
              border-bottom-right-radius: 20px;
              border-top-right-radius: 20px;
              background-image: linear-gradient(to right, #ffffff, #ecdb89);
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              .text {
                font-size: 11px;
                color: #2f4f74;
                margin-left: 80px;
                width: 50px;
              }
            }
            .click {
              position: absolute;
              width: 150px;
              height: 100%;
              z-index: 10;
            }
            .head_curcle {
              position: absolute;
              border-radius: 50%;
              width: 50px;
              height: 100%;
              z-index: 10;
              border-style: dashed;
              border-color: #cfbc4d;
            }
          }
          .neck {
            position: absolute;
            width: 120px;
            height: 50px;
            margin-left: 0px;
            margin-top: 50px;
            .neck_tab {
              width: 100%;
              height: 100%;
              z-index: 1;
              border-bottom-left-radius: 20px;
              border-top-left-radius: 20px;
              background-image: linear-gradient(to right, #ecdb89, #ffffff);
              display: flex;
              flex-direction: column;
              padding-bottom: 5px;
              padding-top: 10px;
              .text {
                font-size: 11px;
                color: #2f4f74;
                margin-left: 10px;

                width: 50px;
              }
            }
            .click {
              position: absolute;
              width: 150px;
              height: 100%;
              z-index: 10;
            }
            .neck_curcle {
              position: absolute;
              border-radius: 50%;
              width: 50px;
              height: 100%;
              z-index: 10;
              margin-left: 105px;
              border-style: dashed;
              border-color: #cfbc4d;
            }
          }
          .chest {
            position: absolute;
            width: 140px;
            height: 60px;
            margin-left: 130px;
            margin-top: 95px;
            .chest_tab {
              width: 100%;
              height: 100%;
              z-index: 1;
              border-bottom-right-radius: 25px;
              border-top-right-radius: 25px;
              background-image: linear-gradient(to right, #ffffff, #ecdb89);
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              padding-bottom: 5px;
              .text {
                font-size: 11px;
                color: #2f4f74;
                margin-left: 90px;
                width: 50px;
              }
            }
            .click {
              position: absolute;
              width: 150px;
              height: 100%;
              z-index: 10;
            }
            .chest_curcle {
              position: absolute;
              border-radius: 50%;
              width: 60px;
              height: 100%;
              z-index: 10;
              border-style: dashed;
              border-color: #cfbc4d;
            }
          }
          .skin {
            position: absolute;
            width: 120px;
            height: 50px;
            margin-left: -35px;
            margin-top: 140px;
            .skin_tab {
              width: 100%;
              height: 100%;
              z-index: 1;
              border-bottom-left-radius: 20px;
              border-top-left-radius: 20px;
              background-image: linear-gradient(to right, #ecdb89, #ffffff);
              display: flex;
              align-items: center;
              .text {
                font-size: 11px;
                color: #2f4f74;
                margin-left: 10px;
              }
            }
            .click {
              position: absolute;
              width: 150px;
              height: 100%;
              z-index: 10;
            }
            .skin_curcle {
              position: absolute;
              border-radius: 50%;
              width: 50px;
              height: 100%;
              z-index: 10;
              margin-left: 70px;
              border-style: dashed;
              border-color: #cfbc4d;
            }
          }
          .stomach {
            position: absolute;
            width: 190px;
            height: 50px;
            margin-left: 105px;
            margin-top: 170px;
            .stomach_tab {
              width: 100%;
              height: 100%;
              z-index: 1;
              border-bottom-right-radius: 20px;
              border-top-right-radius: 20px;
              background-image: linear-gradient(to right, #ffffff, #ecdb89);
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              padding-bottom: 5px;
              .text {
                font-size: 11px;
                color: #2f4f74;
                margin-left: 150px;
                width: 50px;
              }
            }
            .click {
              position: absolute;
              width: 150px;
              height: 100%;
              z-index: 10;
            }
            .stomach_curcle {
              position: absolute;
              border-radius: 50%;
              width: 50px;
              height: 100%;
              z-index: 10;
              border-style: dashed;
              border-color: #cfbc4d;
            }
          }
          .urethra {
            position: absolute;
            width: 250px;
            height: 60px;
            margin-left: -95px;
            margin-top: 230px;
            .urethra_tab {
              width: 100%;
              height: 100%;
              z-index: 1;
              border-bottom-left-radius: 20px;
              border-top-left-radius: 20px;
              background-image: linear-gradient(to right, #ecdb89, #ffffff);
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              padding-bottom: 15px;
              .text {
                font-size: 11px;
                color: #2f4f74;
                margin-left: 10px;
                width: 100px;
              }
            }
            .click {
              position: absolute;
              width: 150px;
              height: 100%;
              z-index: 10;
            }
            .urethra_curcle {
              position: absolute;
              border-radius: 50%;
              width: 60px;
              height: 100%;
              z-index: 10;
              margin-left: 195px;
              border-style: dashed;
              border-color: #cfbc4d;
            }
          }
          .bottom {
            position: absolute;
            width: 120px;
            height: 60px;
            margin-left: -30px;
            margin-top: 200px;
            .bottom_tab {
              width: 100%;
              height: 100%;
              z-index: 1;
              border-bottom-left-radius: 20px;
              border-top-left-radius: 20px;
              background-image: linear-gradient(to right, #ecdb89, #ffffff);
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              padding-bottom: 15px;

              .text {
                font-size: 11px;
                color: #2f4f74;
                margin-left: 10px;
                width: 100px;
              }
            }
            .click {
              position: absolute;
              width: 250px;
              height: 100%;
              z-index: 10;
            }
            .bottom_curcle {
              position: absolute;
              border-radius: 50%;
              width: 100px;
              height: 100px;
              z-index: 10;
              margin-left: 105px;
              border-style: dashed;
              border-color: #cfbc4d;
            }
          }
          .back {
            position: absolute;
            width: 140px;
            height: 60px;
            margin-left: 65px;
            margin-top: 60px;
            .back_tab {
              width: 100%;
              height: 100%;
              z-index: 1;
              border-bottom-right-radius: 25px;
              border-top-right-radius: 25px;
              background-image: linear-gradient(to right, #ffffff, #ecdb89);
              display: flex;
              justify-content: center;
              flex-direction: column;
              padding-bottom: 5px;
              margin-left: 50px;
              margin-top: 30px;
              .text {
                font-size: 11px;
                color: #2f4f74;
                margin-left: 100px;
                width: 50px;
              }
            }
            .click {
              position: absolute;
              width: 200px;
              height: 100%;
              z-index: 10;
            }
            .back_curcle {
              position: absolute;
              border-radius: 50%;
              width: 120px;
              height: 120px;
              z-index: 10;
              border-style: dashed;
              border-color: #cfbc4d;
            }
          }
          img {
            position: absolute;
            width: 260px;
            height: 500px;
            z-index: 2;
          }
        }

        .button {
          width: 310px;
          height: 50px;
          border-radius: 10px;
          background-color: #e8e8ea;
          margin-top: 30px;
          display: flex;
          padding: 5px;
          justify-content: space-between;
          align-items: center;
          .btn_item {
            width: 145px;
            height: 40px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .text_btn {
              font-weight: 500;
              font-size: 16px;
              color: #3b96b7;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .area_select {
      flex-direction: column;
      height: auto;
      .group_layout {
        .left_layout {
          width: 100%;
          justify-content: center;
          padding-right: 0px;
          .text_title {
            font-weight: 500;
            font-size: 14px;
            color: #2f4f74;
            width: 70%;
            text-align: center;
            margin: 30px 0;
          }
        }
        .right_layout {
          width: 100%;
          .img_box {
            width: 250px;
            height: 500px;
            img {
              width: 220px;
              height: 500px;
              margin-left: 15px;
            }
            .head {
              .head_curcle {
                margin-left: -4px;
              }
            }
            .neck {
              .neck_curcle {
                margin-left: 100px;
              }
            }
            .stomach {
              width: 150px;
              .stomach_tab {
                .text {
                  margin-left: 110px;
                }
              }
            }
            .skin {
              width: 120px;
              margin-left: -20px;
              .skin_tab {
                .text {
                  margin-left: 10px;
                }
              }
            }
            .urethra {
              width: 150px;
              margin-left: -95px;
              .urethra_tab {
                margin-left: 65px;
                margin-top: 15px;
                .text {
                  margin-left: 5px;
                  font-size: 9px;
                }
              }
            }
            /* .bottom {
              width: 150px;
              margin-left: -95px;
              .bottom_tab {
                margin-left: 65px;
                margin-top: 15px;
                .text {
                  margin-left: 5px;
                  font-size: 9px;
                }
              }
            } */
          }
        }
        .button {
          margin: 30px 0;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .area_select {
      .group_layout {
        flex-direction: column;
        .right_layout {
          .button {
            width: 60%;
            .btn_item {
              width: 49%;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
  }
`;

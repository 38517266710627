import React from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { QuestionProcessContainerStyled } from "./styled";
import { setReduxUserAuth } from "actions";
import {
  GateWayQuestionContainer,
  IntroductionContainer,
  QuestionnaireContainer,
  SurveyResultsContainer,
} from "./Step";

class QuestionProcessContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.handleProcessRender(0);

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  handleProcessRender = (key, value) => {
    const { authRedux } = this.props;
    switch (key) {
      case 0:
        this.setState({
          processRender: (
            <IntroductionContainer
              handleChangeProcess={this.handleChangeProcess}
            />
          ),
        });
        break;
      case 1:
        this.setState({
          processRender: (
            <QuestionnaireContainer
              handleChangeProcess={this.handleChangeProcess}
              handleRespondResult={this.handleRespondResult}
            />
          ),
        });
        break;
      case 2:
        this.setState({
          processRender: (
            <GateWayQuestionContainer
              handleChangeProcess={this.handleChangeProcess}
              surveyResults={value}
            />
          ),
        });
        break;
      case 3:
        this.props.setReduxUserAuth({ ...authRedux, surveyResults: value });
        this.setState({
          surveyResults: value,
          processRender: (
            <SurveyResultsContainer
              surveyResults={value}
              handleChangeProcess={this.handleChangeProcess}
              handleChangePath={this.handleChangePath}
            />
          ),
        });
        break;
      default:
        break;
    }
  };

  handleChangeProcess = (e, value) => {
    this.handleProcessRender(e, value);
  };

  handleChangePath = (e) => {
    this.props.history.push(e);
  };

  render() {
    const { processRender } = this.state;
    return (
      <QuestionProcessContainerStyled>
        {processRender}
      </QuestionProcessContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionProcessContainer);

import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const ButtonIconStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    .btn_end {
      box-shadow: 6px 7px 11px -8px rgba(88, 88, 88, 0.32);
      cursor: pointer;
      padding: 9px 24px;
      border-radius: 23px;
      background-image: ${COLOR.LINEAR_BLUE1};
      border: none;
      display: flex;
      align-items: center;
      outline: none;
      width: 100%;
      .icon_layout {
        padding: 7px;
        background-color: ${({ iconBackground }) =>
    iconBackground ? iconBackground : COLOR.WHITE_1};
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon_btn {
          width: ${({ iconBackground }) => (iconBackground ? '44px' : '30px')};
          resize: cover;
        }
      }
      .txt_btn {
        color: ${COLOR.WHITE_1};
        line-height: 1.5;
        font-size: ${(props) =>
    props.lable1Size ? props.lable1Size : FONT.SIZE_16};
        margin-left: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .label_1 {
          font-weight: bold;
        }
        .label_2 {
          font-size: ${(props) =>
    props.lable2Size ? props.lable2Size : FONT.SIZE_12};
        }
      }
    }
  }
  .theme_normal {
    .btn_end {
      box-shadow: 6px 7px 11px -8px rgba(88, 88, 88, 0.32);
      justify-content: center;
      cursor: pointer;
      padding: 9px 24px;
      border-radius: 23px;
      background-image: ${COLOR.LINEAR_BLUE1};
      border: none;
      display: flex;
      align-items: center;
      outline: none;
      width: 100%;
      &.disabled {
        background: #D9D9D9;
        .txt_btn {
          .label_1 {
            color: #FFFFFF;
          }
        }
      }
      .icon_layout {
        // width: 48px;
        // height: 48px;
        padding: 10px;
        background-color: ${COLOR.WHITE_1};
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon_btn {
          // width: 30px;
          resize: cover;
        }
      }
      .txt_btn {
        color: ${COLOR.WHITE_1};
        line-height: 1.5;
        font-size: ${(props) =>
    props.lable1Size ? props.lable1Size : FONT.SIZE_16};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .label_1 {
          font-weight: bold;
        }
        .label_2 {
          font-size: ${(props) =>
    props.lable2Size ? props.lable2Size : FONT.SIZE_12};
        }
      }
    }
  }
  .theme_gray {
    .btn_end {
      box-shadow: 6px 7px 11px -8px rgba(88, 88, 88, 0.32);
      justify-content: center;
      cursor: pointer;
      padding: 9px 24px;
      border-radius: 23px;
      background: ${COLOR.GRAY_9};
      border: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
      outline: none;
      width: 100%;
      .icon_layout {
        // width: 48px;
        // height: 48px;
        padding: 10px;
        background-color: ${COLOR.WHITE_1};
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon_btn {
          // width: 30px;
          resize: cover;
        }
      }
      .txt_btn {
        color: ${(props) =>
    props.fontColor ? props.fontColor : COLOR.BLACK_3};
        line-height: 1.5;
        font-size: ${(props) =>
    props.lable1Size ? props.lable1Size : FONT.SIZE_16};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .label_1 {
          font-weight: bold;
        }
        .label_2 {
          font-size: ${(props) =>
    props.lable2Size ? props.lable2Size : FONT.SIZE_12};
        }
      }
    }
  }

  .theme_blue {
    .btn_end {
      box-shadow: 6px 7px 11px -8px rgba(88, 88, 88, 0.32);
      justify-content: center;
      cursor: pointer;
      padding: 9px 24px;
      border-radius: 23px;
      background: #3b96b7;
      border: none;
      display: flex;
      align-items: center;
      outline: none;
      width: 100%;
      .icon_layout {
        padding: 10px;
        background-color: ${COLOR.WHITE_1};
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon_btn {
          resize: cover;
        }
      }
      .txt_btn {
        color: ${COLOR.WHITE_1};
        line-height: 1.5;
        font-size: ${(props) =>
    props.lable1Size ? props.lable1Size : FONT.SIZE_16};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .label_1 {
          font-weight: 500;
        }
        .label_2 {
          font-size: ${(props) =>
    props.lable2Size ? props.lable2Size : FONT.SIZE_12};
        }
      }
    }
  }

  .theme_pink {
    .btn_end {
      box-shadow: 6px 7px 11px -8px rgba(88, 88, 88, 0.32);
      cursor: pointer;
      padding: 5px;
      border-radius: 20px;
      background: ${COLOR.PINK_1};
      border: none;
      display: flex;
      align-items: center;
      outline: none;
      width: 100%;
      .icon_layout {
        background-color: ${({ iconBackground }) =>
    iconBackground ? iconBackground : COLOR.WHITE_1};
        height: 48px;
        width: 48px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        .icon_btn {
          width: ${({ iconBackground }) => (iconBackground ? '44px' : '30px')};
          resize: cover;
        }
      }
      .txt_btn {
        color: ${COLOR.WHITE_1};
        line-height: 1.5;
        font-size: ${(props) =>
    props.lable1Size ? props.lable1Size : FONT.SIZE_16};
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .label_1 {
          font-weight: bold;
        }
        .label_2 {
          font-size: ${(props) =>
    props.lable2Size ? props.lable2Size : FONT.SIZE_12};
        }
      }
    }
  }

  .theme_pink_2 {
    .btn_end {
      box-shadow: 6px 7px 11px -8px rgba(88, 88, 88, 0.32);
      cursor: pointer;
      padding: 8px;
      border-radius: 20px;
      background: ${COLOR.PINK_1};
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      width: 100%;
      .icon_layout {
        background-color: ${({ iconBackground }) =>
    iconBackground ? iconBackground : COLOR.WHITE_1};
        height: 48px;
        width: 48px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        .icon_btn {
          width: ${({ iconBackground }) => (iconBackground ? '44px' : '30px')};
          resize: cover;
        }
      }
      .txt_btn {
        color: ${COLOR.WHITE_1};
        line-height: 1.5;
        font-size: ${(props) =>
    props.lable1Size ? props.lable1Size : FONT.SIZE_16};
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .label_1 {
          font-weight: bold;
        }
        .label_2 {
          font-size: ${(props) =>
    props.lable2Size ? props.lable2Size : FONT.SIZE_12};
        }
      }
    }
  }
`;

import styled from "styled-components"
import {
    COLOR,
    // FONT
} from 'assets'

export const IconCartStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        width: 48px;
        height: 48px;
        background: ${COLOR.WHITE_1};
        border-radius: 17px;
        border: 1px solid ${COLOR.GRAY_4};
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        .icon_btn {
            width: 74%;
        }
        .dot_show {
            position: absolute;
            top: -8px;
            right: -10px;
            background: red;
            width: 16px;
            height: 16px;
            background: ${COLOR.RED_1};
            border-radius: 50%;
            border: 2px solid ${COLOR.WHITE_1};
        }
    }
`
import React from 'react';
import ReactGA from 'react-ga';
import { TermsOfUseRegistryContainerStyled } from './styled';
// import { userService } from 'apiService';
import { ROUTE_PATH } from 'helpers';
// import { iconImages } from "assets";
import { BoxLink, Button, LogoLoadingFade } from 'components';
// import Loader from "react-loader-spinner";

class TermsOfUseRegistryContainer extends React.Component {
  state = {
    isAcceptConditon: false,
    loading: true,
  };

  componentDidMount() {
    this.scrollToTop();
    this.setLoadder();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 300);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickNext = () => {
    const { isAcceptConditon } = this.state;
    if (isAcceptConditon) {
      this.props.history.push(ROUTE_PATH.WELL_REGISTER);
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.WELL_LOGIN);
  };

  render() {
    const { isAcceptConditon, loading } = this.state;
    return (
      <TermsOfUseRegistryContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div
              className="link_back"
              onClick={() => this.handleClickBackFirstPage()}
            >
              <BoxLink theme_standard_box_link label="กลับสู่หน้าหลัก" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="box_layout">
                <div className="title">เงื่อนไขในการใช้งาน</div>
                <div className="sub_title">Terms of use</div>
                <div className="content_container">
                  <div className="desciption bold">
                    ข้อกำหนดและเงื่อนไขการใช้งาน
                  </div>
                  <div className="desciption">
                    <div className="sub_des">บริษัท อินไวเทรส จำกัด</div>
                    "Well Health" "Well" "เวลล์" ให้บริการโดยบริษัท อินไวเทรส
                    จำกัด (ซึ่งต่อไปนี้ใช้แทนคำว่า อินไวเทรส "เรา" "พวกเรา")
                    ต่อผู้รับบริการ (ซึ่งต่อไปนี้ใช้แทนคำว่า ผู้รับบริการ
                    "ผู้ใช้บริการ" "ผู้ใช้บริการ" "คุณ" "คุณ")
                    โปรดอ่านข้อกำหนดและเงื่อนไขของบริการ Well cancer
                    self-screening (ข้อกำหนดและบริการ)
                    โดยรายละเอียดการเข้าใช้บริการนี้ ถือว่า
                    คุณตกลงที่จะปฏิบัติตามข้อกำหนดและเงื่อไข
                    รวมถึงข้อปฏิบัติต่าง ๆ
                    ที่เกี่ยวข้องกับข้อกำหนดและเงื่อนไขต่อไปนี้
                  </div>
                  <div className="desciption">
                    <div className="sub_des">จุดประสงค์ของ Well</div>
                    Well cancer self-screening
                    ถูกสร้างมาเพื่อช่วยเหลือคุณให้สามารถ "รับรู้" "ตรวจสอบ"
                    "ประเมิน" ความเสี่ยง
                    หรือแนวโน้มด้านมะเร็งของคุณพร้อมให้บริการเกี่ยวกับมะเร็งอาทิ
                    บริการปรึกษาผู้เชี่ยวชาญด้านมะเร็งแบบออนไลน์ , บริการ Test
                    kit , ปรึกษาอดีตผู้ป่วยมะเร็งแบบออนไลน์ เป็นต้น
                  </div>
                  <div className="desciption">
                    โดย Well ไม่ใช่บริการวิจัยหรือแทนคำแนะนำของแพทย์
                  </div>
                  <div className="desciption">
                    <div>1. บริการ</div>
                    เครื่องมือที่ทำให้คุณได้สำรวจ สัญญาณเตือนร่างกาย ติดตามอาการ
                    รวมถึงความเสี่ยงที่ทำให้เกิดโรคมะเร็ง
                    เพื่อทำให้คุณเข้าสู่ระบบการตรวจ คัดกรองได้เร็วขึ้น
                  </div>
                  <div className="desciption">
                    โดยใช้ระบบ A.I. (ปัญญาประดิษฐ์) ที่ได้รับการพัฒนาจาก
                    Guideline, ประวัติผู้ป่วยมากกว่า 3000 คน
                    และประสบการณ์จากผู้เชี่ยวชาญทางการแพทย์
                  </div>
                  <div className="desciption">
                    <div>2. คุณสมบัติของผู้ใช้บริการ</div>
                    2.1 คุณจะต้องมีอายุตั้งแต่ 16 ปีบริบูรณ์
                    และมีความสามารถในการเข้าทำนิติกรรม
                    และปฏิบัติตามข้อกำหนดและเงื่อนไข ภายใต้กฎหมายไทย
                  </div>
                  <div className="accept_container">
                    <div className="input_group">
                      <input
                        id="accept_id"
                        type="checkbox"
                        value={isAcceptConditon}
                        onChange={(e) => {
                          this.setState({
                            isAcceptConditon: !isAcceptConditon,
                          });
                        }}
                      />
                      <label for="accept_id">ฉันยอมรับข้อตกลง</label>
                    </div>
                    <div className="group_label">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={'https://www.invitracehealth.com/privacy-policy'}
                      >
                        <div className="label_show right_margin">
                          นโยบายความเป็นส่วนตัว
                        </div>
                      </a>
                      และ
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={'https://www.invitracehealth.com/terms-of-use-1'}
                      >
                        <div className="label_show left_margin">
                          เงื่อนไขการใช้งาน
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="show_btn_term">
                  <div className="btn_term">
                    <Button
                      width="100%"
                      theme_standard
                      disable={!isAcceptConditon}
                      font="14px"
                      height="auto"
                      handleClickButton={this.handleClickNext}
                      padding="10px"
                    >
                      รับทราบและยินยอม
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </TermsOfUseRegistryContainerStyled>
    );
  }
}

export default TermsOfUseRegistryContainer;

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { LoginFormStyled } from './styled';
import { VALIDATE } from 'helpers';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { InputText, CheckBox } from 'components';
import { iconImages } from 'assets';
import { BrowserView, MobileView } from 'react-device-detect';

class LoginForm extends React.Component {
  state = {};

  render() {
    const {
      configProjectRedux,
      responseFacebook,
      handleSubmit,
      handleClickLoginEmail,
    } = this.props;
    return (
      <LoginFormStyled>
        <form onSubmit={handleSubmit(handleClickLoginEmail)}>
          <div className="form_layout">
            <div className="layout_top">
              <div className="txt_login">เข้าสู่ระบบ</div>
              <BrowserView>
                <FacebookLogin
                  autoLoad={false}
                  appId={process.env.REACT_APP_FB_APP_ID}
                  callback={responseFacebook}
                  redirectUri={
                    // process.env.REACT_APP_SELF_ENDPOINT + '/page/login'
                    '/login/facebook'
                  }
                  render={(renderProps) => (
                    <div className="btn_fb" onClick={renderProps.onClick}>
                      <img
                        className="img_facebook"
                        src={iconImages['icon_face.png']}
                        alt="logo facebook"
                      />
                      ลงทะเบียนด้วย Facebook
                    </div>
                  )}
                />
              </BrowserView>
              <MobileView>
                <FacebookLogin
                  autoLoad={false}
                  isMobile={true}
                  disableMobileRedirect={true}
                  appId={process.env.REACT_APP_FB_APP_ID}
                  callback={responseFacebook}
                  redirectUri={
                    // process.env.REACT_APP_SELF_ENDPOINT + '/page/login'
                    configProjectRedux?.pinkalert_uri +
                    configProjectRedux?.login_uri
                  }
                  render={(renderProps) => (
                    <div className="btn_fb" onClick={renderProps.onClick}>
                      <img
                        className="img_facebook"
                        src={iconImages['icon_face.png']}
                        alt="logo facebook"
                      />
                      ลงทะเบียนด้วย Facebook
                    </div>
                  )}
                />
              </MobileView>
            </div>
            <div className="txt_middle"> หรือ </div>
            <div className="group_input">
              <div className="label_name">อีเมล์</div>
              <Field
                theme_standard
                name="email"
                width="100%"
                placeHolder="you@example.com"
                component={InputText}
                validate={VALIDATE.EMAIL}
              />
            </div>
            <div className="group_input">
              <div className="label_name">Password</div>
              <Field
                type="password"
                theme_standard
                width="100%"
                name="password"
                placeHolder="Password"
                component={InputText}
                validate={VALIDATE.REQUIRE}
              />
            </div>
            <div className="check_input">
              <Field
                theme_standard
                name="isRemember"
                label="จดจำบัญชีของฉัน"
                component={CheckBox}
              />
            </div>
          </div>
          <button className="btn_next" type="submit">
            ยืนยัน
          </button>
        </form>
      </LoginFormStyled>
    );
  }
}

export default reduxForm({
  form: 'LoginForm', // a unique identifier for this form
  enableReinitialize: true,
})(LoginForm);

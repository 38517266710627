import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { HomeContainerStyled } from './styled';
import { ROUTE_PATH } from 'helpers';
import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { Button, BoxIconLabel, LogoLoadingFade, HelmetMetaData } from 'components';
import { BannerPartner } from 'widgets';

class HomeContainer extends React.Component {
  state = { loading: true };

  componentDidMount() {
    this.scrollToTop();
    this.setLoadder();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  handleClickButton = (mode) => {
    if (mode === 'Symptom') {
      // Tracking user event
      ReactGA.event({
        category: 'wellscreening_start',
        action: 'wellscreening_start_has_symptom',
      });
      this.props.history.push(ROUTE_PATH.TERM, 'Symptom');
    } else {
      // Tracking user event
      ReactGA.event({
        category: 'wellscreening_start',
        action: 'wellscreening_start_no_symptom',
      });
      this.props.history.push(ROUTE_PATH.SUSPECTED_CANCER);
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickService = (key) => {
    switch (key) {
      case 'cancer_genetic':
        ReactGA.event({
          category: 'click_cancer_appointment',
          action: 'wellscreening_home_click_cancer_appointment',
        });

        this.props.history.push(ROUTE_PATH.CONSULT_CANCER);
        break;

      case 'consult_genetic':
        ReactGA.event({
          category: 'click_genetic_appointment',
          action: 'wellscreening_home_click_genetic_appointment',
        });

        this.props.history.push(ROUTE_PATH.CONSULT_GENETIC);
        break;
      case 'testkit':
        ReactGA.event({
          category: 'click_testkit',
          action: 'wellscreening_home_click_testkit',
        });

        this.props.history.push(ROUTE_PATH.EXAMINE_TEST_KIT);
        break;
      case 'insurance_plan':
        ReactGA.event({
          category: 'click_fwd_insurrance',
          action: 'wellscreening_home_click_fwd_insurrance',
        });

        this.props.history.push(ROUTE_PATH.INSURANCE_PLAN);
        break;
      case 'insurance_package':
        ReactGA.event({
          category: 'click_pyt_package',
          action: 'wellscreening_home_click_pyt_package',
        });

        this.props.history.push(ROUTE_PATH.INSURANCE_PACKAGE);
        break;
      default:
        break;
    }
  };

  renderServiceOther = (key) => {
    const ObjectServiceOther = {
      cancer_checkup: (
        <BoxIconLabel
          theme_mini
          handleClick={() => this.handleClickService('insurance_package')}
          src={iconImages['calendar_white.png']}
          label="จองคิวตรวจคัดกรองมะเร็ง"
        />
      ),
      // cancer_appointment: (
      //   <BoxIconLabel
      //     theme_mini
      //     handleClick={() => this.handleClickService('cancer_genetic')}
      //     src={iconImages['check1.png']}
      //     label="ปรึกษาการตรวจคัดกรองมะเร็ง"
      //   />
      // ),
      cancer_insurance: (
        <BoxIconLabel
          theme_mini
          handleClick={() => this.handleClickService('insurance_plan')}
          src={iconImages['fwd4.png']}
          label="วางแผนประกันด้านมะเร็ง"
        />
      ),
      hpv_test_kit: (
        <BoxIconLabel
          theme_mini
          handleClick={() => this.handleClickService('testkit')}
          src={iconImages['testkit_white2.png']}
          label="ชุดตรวจ Testkit"
        />
      ),
      genetic_appointment: (
        <BoxIconLabel
          theme_mini
          handleClick={() => this.handleClickService('consult_genetic')}
          src={iconImages['toffy2.png']}
          label="บริการตรวจรหัสพันธุกรรม"
        />
      ),
    };
    return ObjectServiceOther[key];
  };

  render() {
    const { authRedux, configProjectRedux } = this.props;
    const { loading } = this.state;
    const dataSetting = [
      ...configProjectRedux.services.main,
      ...configProjectRedux.services.other,
    ];

    return (
      <HomeContainerStyled
        style={{ backgroundImage: `url(${iconImages['bg_bottom.png']})` }}
      >
        <HelmetMetaData />
        <img
          className="bg_top_right"
          src={iconImages['bg_top.png']}
          alt="logo term"
        />
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <img
              className="img_well"
              src={iconImages['well_logo_6.png']}
              alt="logo term"
            />
            <div className="banner_layout">
              <BannerPartner />
            </div>
            <div className="bg_layout">
              <div className="group_header">
                <div className="text_header1">
                  สวัสดี, คุณ {authRedux.firstname}
                </div>
                <div className="text_header2">วันนี้คุณสบายดีไหม ?</div>
              </div>
              <div className="body_detail">
                <div className="box_layout">
                  <div className="sub_title">
                    เริ่มต้นการตรวจความเสี่ยงมะเร็ง
                  </div>
                  <Button
                    width="300px"
                    height="50px"
                    theme_gradient
                    fontSize="16px"
                    handleClickButton={() => this.handleClickButton('Symptom')}
                  >
                    <img
                      className="img_dot"
                      src={iconImages['img_dot_circle.png']}
                      alt=""
                    />
                    ตรวจจากอาการไม่สบายของคุณ
                  </Button>
                  <div className="des_detail">
                    <div className="bold_title">
                      เหมาะกับ: ผู้ที่ปัจจุบันมีอาการทางร่างกาย
                    </div>
                    <div>ระบบจะถามคำถามอาการต่าง ๆ เป็นแกนหลัก</div>
                    <div>และนำมาวิเคราะห์กับ อายุ เพศ พฤติกรรม</div>
                  </div>
                  <Button
                    width="300px"
                    height="50px"
                    theme_gradient
                    fontSize="16px"
                    style={{ marginTop: 20 }}
                    handleClickButton={() =>
                      this.handleClickButton('NoSymptom')
                    }
                  >
                    <img
                      className="img_dot"
                      src={iconImages['img_dot_circle.png']}
                      alt=""
                    />
                    ตรวจโดยที่ยังไม่มีอาการใดๆ
                  </Button>
                  <div className="des_detail">
                    <div className="bold_title">
                      เหมาะกับ: บุคคลทั่วไปที่อยากคัดกรองเบื้องต้น
                    </div>
                    <div>ระบบจะถามคำถามปัจจัยเสี่ยง 6-10 คำถามเป็น</div>
                    <div>แกนหลักและนำมาวิเคราะห์กับ อายุ เพศ พฤติกรรม</div>
                  </div>
                </div>
                <div className="service_layout">
                  <div className="service_title">
                    บริการทั้งหมดเกี่ยวกับมะเร็ง
                  </div>
                  <div className="group_box_service">
                    {dataSetting &&
                      dataSetting.map((e, i) => (
                        <div className="line_opt" key={i}>
                          {this.renderServiceOther(e)}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </HomeContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);

import styled from 'styled-components';
import { SCREEN, FONT, COLOR } from 'assets';

export const NavbarStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  .web_menu_layout {
    display: flex;
    flex-direction: row;
    padding: 16px 20px;
    align-items: center;
    justify-content: space-between;
    .item_left {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 55%;
      .logo_navbar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        .icon_logo {
          width: 80px;
          height: auto;
          margin: auto;
        }
        .text_logo {
          font-size: 10px;
          // width: 180px;
          text-align: center;
        }
      }
      .menu_layout {
        // margin-left: 50px;
        display: flex;
        flex-direction: row;
        width: 55%;
        .txt_menu {
          // margin-right: 50px;
          width: 25%;
          text-align: center;
          a {
            color: black;
            text-decoration: none;
          }
        }
      }
    }
    .login_menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 45%;
      .txt_login {
        min-width: 100px;
        cursor: pointer;
      }
      .avatar {
        width: 29px;
        height: 29px;
        border-radius: 50%;
        border: 2px solid #ffffff;
        margin: 0 10px;
        cursor: pointer;
      }
      .show_name {
        font-size: ${FONT.SIZE_14};
        font-weight: 600;
        color: ${COLOR.BLACK_1};
        margin-right: 32px;
        cursor: pointer;
      }
      .btn_navbar {
        border-radius: 30px;
        height: 40px;
        width: 200px;
        background-color: #3b96b7;
        color: white;
        box-shadow: 2px 1px 6px 0px rgba(0, 0, 0, 0.2),
          0 4px 20px 0 rgba(0, 0, 0, 0.19);
        border: none;
      }
    }
  }
  .mobile_menu_layout {
    background: white;
    width: calc(100% - 37px);
    min-height: 70px;
    display: none;
    padding: 13.5px 20px;
    display: none;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    .logo_wrap {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      height: 60px;
      width: 100px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      .img_header {
        width: 80px;
        height: auto;
        margin-left: 20px;
      }
      .text_logo {
        font-size: 10px;
        width: 185px;
        margin-left: -15px;
        margin-top: 5px;
      }
    }
    .hamburger {
      width: 25px;
    }
  }
  .sideber_mobile {
    display: block;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 100;
    border: 1px solid white;
    border-radius: 5px;
    background-color: white;
    width: 80vw;
    height: 107vh;
    text-align: end;
    padding-right: 30px;
    font-size: 18px;

    line-height: 2;
    padding-top: 25px;
    box-shadow: 2px 1px 6px 0px rgba(0, 0, 0, 0.2),
      0 4px 20px 0 rgba(0, 0, 0, 0.19);
    .header_mb_item {
      display: block;
      min-width: 200px;
      padding: 5px 10px;
      cursor: pointer;
    }
    a {
      color: black;
      text-decoration: none;
    }
    .sub_menu {
      margin-bottom: 4px;
      font-size: 18px;
      color: rgb(0, 0, 0, 0.8);
      &.no_margin {
        margin-bottom: 0px;
      }
      .link_css {
        color: rgb(0, 0, 0, 0.8);
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .web_menu_layout {
      display: none;
    }
    .mobile_menu_layout {
      display: flex;
      width: 100%;
    }
  }

  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .mobile_menu_layout {
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .web_menu_layout {
      display: none;
    }
    .mobile_menu_layout {
      height: 25vw;
      display: flex;
    }
  }

  @media (max-width: ${SCREEN.MINI_PHONE}) {
    .mobile_menu_layout {
    }
  }
`;

import styled from "styled-components";
import { SCREEN } from "assets";
import { iconImages } from "assets";

export const GateWayQuestionContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background-color: #f8f8f8;
  min-height: calc(100vh - 87px);
  background-image: url(${iconImages["dot_bg.png"]});
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top 0;
  overflow-y: auto;
  padding: 20px;
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_back {
    max-width: 80%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .group_layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    .layout_detail {
      /* width: 400px; */
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .link_back {
      max-width: 100%;
    }
    .group_layout {
      height: auto;
      .layout_detail {
        width: 320px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
      .theme_standard {
        max-width: 300px;
        display: flex;
        justify-content: center;
        .btn_layout {
          max-width: 300px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
  }
`;

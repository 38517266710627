import React from "react";
import { ImgChoiceFormStyled } from "./styled";
import { iconImages, COLOR } from "assets";
import { Button } from "../../../components/Button";
import { LogoLoadingFade } from "components";

class ImgChoiceForm extends React.Component {
  state = {
    answer: [],
    loading: true,
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  };

  handleClickImg = (e) => {
    const { answer } = this.state;
    const { question } = this.props;
    let arr_select = [...answer];
    if (question.is_multiple) {
      if (arr_select.find((item) => item.id === e.id)) {
        for (let i = 0; i < arr_select.length; i++) {
          if (arr_select[i].id === e.id) {
            arr_select.splice(i, 1);
          }
        }
      } else {
        arr_select = [...arr_select, { id: e.id }];
      }
    } else {
      arr_select = [{ id: e.id }];
    }
    this.setState({
      answer: arr_select,
    });
  };

  handleSubmit = () => {
    const { answer } = this.state;
    const { handleSubmitAns } = this.props;
    handleSubmitAns(answer);
  };

  render() {
    const { question, scheme } = this.props;
    const { answer, loading } = this.state;
    return (
      <ImgChoiceFormStyled>
        {question && question.answers && (
          <>
            {loading ? (
              <LogoLoadingFade />
            ) : (
              <img
                className="img_logo"
                src={iconImages["logo_question.png"]}
                alt="logo well"
              />
            )}
          </>
        )}
        <div className="title">{question && question.label}</div>
        {question && question.is_multiple && (
          <div className="title2">(เลือกได้หลายตัวเลือก)</div>
        )}

        <div className="img_layout">
          {question &&
            question.answers &&
            question.answers.map((e, i) => (
              <div key={i} className="img_item">
                <img
                  className={
                    answer.find((item) => item.id === e.id)
                      ? `img_question_select ${scheme}`
                      : "img_question"
                  }
                  src={e.image_uri}
                  alt="pic"
                  onClick={() => this.handleClickImg(e)}
                />
                <div
                  className="text_img"
                  style={{
                    color: answer.find((item) => item.id === e.id)
                      ? "#348BAE"
                      : "#000000",
                  }}
                >
                  {e.label}
                </div>
              </div>
            ))}
        </div>
        <div className="btn_bottom">
          {answer.length > 0 && (
            <Button
              width="300px"
              height="40px"
              theme_standard
              style={{ marginTop: 30 }}
              handleClickButton={() => this.handleSubmit()}
              backgroundColor={scheme && COLOR.PINK_1}
            >
              ต่อไป
            </Button>
          )}
        </div>
      </ImgChoiceFormStyled>
    );
  }
}

export default ImgChoiceForm;

import styled from 'styled-components';
import { SCREEN } from 'assets';

export const NavbarHerWillStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  .web_menu_layout {
    height: 6vw;
    // display: flex;
    display: none;
    justify-content: center;
    flex-direction: row;
    padding: 10px 20px;
    align-items: center;
    .item_left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .logo_navbar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .icon_logo {
          margin: auto;
        }
        .text_logo {
          font-size: 10px;
          // width: 180px;
        }
      }
    }
  }
  .mobile_menu_layout {
    height: 8vw;
    min-height: 70px;
    background-color: white;
    display: none;
    padding: 10px 15px;
    display: none;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .logo_wrap {
      position: relative;
      // margin-left: auto;
      // margin-right: auto;
      height: 60px;
      // width: 100px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      .img_header {
        // margin-left: 20px;
      }
      .text_logo {
        font-size: 10px;
        min-width: 185px;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
  .sideber_mobile {
    display: block;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 100;
    border: 1px solid white;
    border-radius: 5px;
    background-color: white;
    width: 80vw;
    height: 107vh;
    text-align: end;
    padding-right: 30px;
    font-size: 18px;
    
    line-height: 2;
    padding-top: 25px;
    box-shadow: 2px 1px 6px 0px rgba(0, 0, 0, 0.2),
      0 4px 20px 0 rgba(0, 0, 0, 0.19);
    .header_mb_item {
      display: block;
      min-width: 200px;
      padding: 5px 10px;
      cursor: pointer;
    }
    a {
      color: black;
      text-decoration: none;
    }
    .sub_menu {
      margin-bottom: 4px;
      font-size: 18px;
      color: rgb(0, 0, 0, 0.8);
      &.no_margin {
        margin-bottom: 0px;
      }
      .link_css {
        color: rgb(0, 0, 0, 0.8);
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .web_menu_layout {
      display: none;
    }
    .mobile_menu_layout {
      height: 10vw;
      // display: flex;
      display: none;
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .web_menu_layout {
      display: none;
    }
    .mobile_menu_layout {
      height: 25vw;
      // display: flex;
      display: none;
    }
  }
`;

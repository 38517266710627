import styled from 'styled-components';
// import {
//     COLOR,
//     FONT
// } from 'assets'

export const InsuranceItemStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  /*===============================================
Theme 
===============================================*/
  .insurance_img {
    margin-bottom: 26px;
    width: 100%;
    height: auto;
  }
  .btn_call {
    max-width: 170px;
    margin: auto;
    background: #cf1a71;
    cursor: pointer;
    display: flex;
    min-width: 88px;
    border-radius: 22px;
    padding: 4px 26px;
    align-items: center;
    justify-content: center;
    .btn_txt {
      font-size: 17px;
      font-weight: bold;
      color: white;
    }
  }

  .theme_standard {
  }
`;

export const COLOR = {
  WHITE_1: '#ffffff',
  BLACK_1: '#000000',
  BLACK_2: '#3C3C3C',
  BLACK_3: '#131313',
  BLACK_4: 'rgb(0, 0, 0, 0.3)',
  BLACK_5: 'rgb(19, 19, 19, 0.5)',
  BLACK_6: '#575959',
  BLACK_7: '#8E8E8E',
  BLACK_8: '#383838',
  BLACK_9: '#0D0D0D',
  LINEAR_BLUE1: 'linear-gradient(to right, #0253a3, #2db5b8)',
  LINEAR_BLUE2: 'linear-gradient(to right bottom, #0253a3, #0064ac, #0075b2, #0084b5, #2e93b8)',
  BLUE_1: "rgb(59, 150, 183, 0.3)",
  BLUE_2: "#003564",
  BLUE_3: " rgb(12, 150, 183, 0.04)",
  BLUE_4: "#3B96B7",
  BLUE_5: "rgb(59, 150, 183, 0.3)",
  BLUE_6: "#F0F4F7",
  BLUE_7: "#0C96B7",
  BLUE_8: "#B5D1DB",
  BLUE_9: "#205072",
  GRAY_1: '#FAFAFA',
  GRAY_2: '#EDEDED',
  GRAY_3: '#8080800a',
  GRAY_4: 'rgb(112, 112, 112, 0.15)',
  GRAY_5: '#707070',
  GRAY_6: '#909090',
  GRAY_7: '#85888D',
  GRAY_8: '#9B9B9B',
  GRAY_9: "#F8F8F8",
  GRAY_10: "#F7F7F7",
  GRAY_11: "#989898",
  GRAY_12: "rgb(152, 152, 152, 0.1)", //border 
  GRAY_13: "rgb(19, 19, 19 ,0.5)",
  GRAY_14: '#8080800d', //background container
  GRAY_15: '#858585',
  GRAY_16: '#7C7C7C',
  RED_1: '#F35162',
  RED_2: 'rgb(233, 56, 56,0.93)',
  RED_3: '#D0021B',
  RED_4: '#FF0000',
  YELLOW_1: '#F7C807',
  GREEN_1: '#007D68',
  GREEN_2: '#162F28',
  GREEN_3: '#007D69',
  ORANGE_1: '#E87723',
  PINK_1: '#CF1A71',
  PINK_2: '#F395BE',
  PINK_3: '#74083C',
};

export const FONT = {
  SIZE_7: '7px',
  SIZE_8: '8px',
  SIZE_10: '10px',
  SIZE_11: '11px',
  SIZE_12: '12px',
  SIZE_13: '13px',
  SIZE_14: '14px',
  SIZE_15: '15px',
  SIZE_16: '16px',
  SIZE_18: '18px',
  SIZE_19: '19px',
  SIZE_20: '20px',
  SIZE_22: '22px',
  SIZE_23: '23px',
  SIZE_24: '24px',
  SIZE_25: '25px',
  SIZE_29: '29px',
  SIZE_30: '30px',
  SIZE_32: '32px',
  SIZE_33: '33px',
  SIZE_37: '37px',
  SIZE_45: '45px',
  SIZE_60: '60px',
  SIZE_113: '113px',
};

export const SCREEN = {
  LABTOP: '1366px',
  TABLET: '1024px',
  MINI_TABLET: '800px',
  SURFACE_DUO: '540px',
  PHONE: '500px',
  MINI_PHONE: '320px',
};
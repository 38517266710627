import React from 'react';
import { PromtPayStyled, PromptpayQRContainer } from './styled';
import { userService } from 'apiService';
// import { iconImages } from 'assets';

export const PromtPay = (props) => {
  const {
    children,
    invoiceID,
    amount,
    handleRenderCustom,
    handleClickCloseModalCustom,
    onSuccess,
  } = props;
  const handleClickPayment = () => {
    const { Omise } = window;
    Omise.setPublicKey(process.env.REACT_APP_OMISE_PUBLIC_KEY);
    Omise.createSource(
      'promptpay',
      {
        amount: Number(amount) * 100,
        currency: 'THB',
        capture: true,
        livemode: false,
      },
      function (statusCode, response) {
        console.log('res', response);
        handleSubmit(response);
      }
    );
  };

  const handleSubmit = async (token) => {
    let params = {
      gateway: 'omise',
      type: 'promptpay',
      token: token.id,
      redirect_app: 'web',
    };
    let res = await userService.POST_ORDER_PAYMENT(invoiceID, params);
    if (res && res.success) {
      console.log('res', res);
      handleRenderCustom(
        <PromptpayQRContainer>
          <div className="show_qr">
            <div className="group_obj">
              {/* <div className="top_qr">
                <img
                  className="qr_payment_top"
                  src={iconImages['qr2.jpg']}
                  alt="qr"
                />
              </div>
              <div className="middle_qr">
                <img
                  className="qr_payment_middle"
                  src={iconImages['qr1.jpg']}
                  alt="qr"
                />
              </div> */}
              <div className="bottom_qr">
                <img className="qr_payment" src={res.data.qr_uri} alt="qr" />
              </div>
              <div
                className="btn_payment"
                onClick={() => handlePyamentSuccess(res.data.id)}
              >
                ยืนยันการชำระเงิน
              </div>
            </div>
          </div>
        </PromptpayQRContainer>
      );
    }
  };

  const handlePyamentSuccess = async (paymentID) => {
    onSuccess(paymentID);
    handleClickCloseModalCustom();
  };

  return (
    <PromtPayStyled>
      <div onClick={handleClickPayment}>{children}</div>
    </PromtPayStyled>
  );
};

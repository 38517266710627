import styled, { keyframes } from "styled-components";
import { SCREEN } from "assets";
import { iconImages } from "assets";

import { fadeInUp } from "react-animations";
const bounceAnimation = keyframes`${fadeInUp}`;
export const NoSymptomQuestionContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  background-color: #f8f8f8;
  padding: 20px 0px;
  min-height: calc(100vh - 87px);

  background-image: url(${iconImages["dot_bg.png"]});
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top 0;
  overflow-y: auto;
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_back {
    max-width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .group_layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    .layout_detail {
      max-width: 400px;
      .title {
        font-weight: 500;
        font-size: 18px;
        color: #2f4f74;
        margin: 20px 0 10px 10px;
        animation: 1s ${bounceAnimation};
      }
      .description {
        font-size: 16px;
        color: #2f4f74;
        min-height: 50px;
        margin: 20px 0 60px 10px;
        animation: 1s ${bounceAnimation};
      }
      .btn_bottom {
        animation: 1s ${bounceAnimation};
      }
      .img_logo {
        height: 50px;
        width: 50px;
      }
      .txt_detail {
        color: #003564;
        font-size: 18px;
        margin-top: 20px;
        max-width: 400px;
      }
      .txt_detail2 {
        color: grey;
        font-size: 16px;
        margin-top: 10px;
        max-width: 400px;
      }
      .header_layout {
        width: 90vw;
      }
      .btn_next {
        width: 350px;
        height: 45px;
        margin-top: 30px;
        background-color: #3b96b7;

        font-size: 18px;
        border: none;
        border-radius: 30px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn_next_white {
        width: 350px;
        height: 45px;
        margin-top: 20px;
        background-color: #f8f8f8;
        box-shadow: 2px 1px 6px 0px rgba(0, 0, 0, 0.2),
          0 4px 20px 0 rgba(0, 0, 0, 0.19);

        font-size: 18px;
        border: none;
        border-radius: 30px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .link_back {
      max-width: 90%;
    }
    .group_layout {
      height: auto;

      .layout_detail {
        width: 90vw;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
      .theme_standard {
        width: 90vw;
        display: flex;
        justify-content: center;
        .btn_layout {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
  }
`;

import React from 'react';
import { BoxOrderStyled } from './styled';
import cx from 'classnames';

export const BoxOrder = ({
  theme_standard_1,
  title,
  amount,
  orderDate,
  price,
  status,
  src,
  showButton,
  label1,
  label2,
  typeShow,
  id,
  handleClickNext,
}) => {
  const customClass = cx({
    theme_standard_1: theme_standard_1,
  });
  return (
    <BoxOrderStyled>
      <div className={customClass}>
        <div className="top_box">
          <div className="left_top">
            <div className="box_img">
              <img className="logo_show" src={src} alt="logo show" />
            </div>
            <div className="title_label">{title}</div>
          </div>
          <div className="right_top">
            {amount && (
              <React.Fragment>
                <div className="label1">จำนวน</div>
                <div className="amount_label">{amount}</div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="layer_1">
          <div className="left_layer_1">{`${label1} : ${orderDate}`}</div>
          <div className="right_layer_1">
            {typeShow === false ? (
              <React.Fragment>
                <div className="show_time">ช่วงเวลา</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="group_price">
                  <div className="show_price">{price}</div>
                  <div className="currency_show">฿</div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="layer_1">
          <div className="left_layer_1">{`${label2} : ${status}`}</div>
          <div className="right_layer_1">
            {typeShow === false && (
              <React.Fragment>
                <div className="show_time2">{price}</div>
              </React.Fragment>
            )}
          </div>
        </div>
        {showButton && (
          <div className="btn" onClick={() => handleClickNext(id)}>
            ดำเนินการต่อ
          </div>
        )}
      </div>
    </BoxOrderStyled>
  );
};

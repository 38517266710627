import styled from 'styled-components';
import { SCREEN, COLOR } from 'assets';

export const BuyProductHistoryContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  min-height: calc(100% - 87px);

  background: ${COLOR.GRAY_9};
  padding: 40px 0px;
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_back {
    display: flex;
    padding: 0px 60px;
  }
  .container {
    max-width: 55%;
    margin: auto;
    // margin-top: 30px;
    .title {
    }
    .body_show {
      margin-top: 10px;
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .container {
      max-width: 75%;
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .container {
      max-width: 80%;
    }
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .container {
      max-width: 80%;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .container {
      max-width: 80%;
      .title {
        text-align: center;
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    .container {
      max-width: 90%;
      .title {
        text-align: center;
      }
    }
  }
`;

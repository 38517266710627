import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const AvatarShowStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        display: flex;
        align-items: center;
        font-size: ${FONT.SIZE_14};
        font-weight: 600;
        color: ${COLOR.BLACK_1};
        .avatar_show {
            margin-right: 10px;
            .avatr_s {
                width: 25px;
                height: 25px;
            }
        }
    }
`
import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { InsurancePlanContainerStyled } from './styled';
import { userService } from 'apiService';
import { iconBackground, iconImages } from 'assets';
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth } from 'actions';
import {
  Insurance,
  BoxLink,
  LogoLoadingFade,
  Modal,
  ButtonIcon,
} from 'components';

class InsurancePlanContainer extends React.Component {
  state = {
    loading: true,
    isModal: false,
  };

  componentDidMount() {
    this.fetchData();
    this.scrollToTop();
    this.disableLoader();
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  disableLoader = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    let res = await userService.GET_CANCER_INSURANCE_SERVICE_LIST();
    if (res && res.success) {
      this.setState({
        data: res.data[0],
      });
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  handleSelectPackage = async (package_id) => {
    const { authRedux } = this.props;
    let params = {
      service_package_id: package_id,
    };
    let profile_id = authRedux ? authRedux.id : 0

    let res = await userService.POST_SERVICE_ORDER(profile_id, params);
    if (res && res.success) {
      // Tracking user event
      ReactGA.event({
        category: 'fwd_insurrance',
        action: 'confirm_fwd_insurrance',
        label: package_id,
        value: 1,
      });
      window.open(res.data.redirect_uri, '_blank');
    }
  };

  handleClickBackPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  handleClickModal = () => {
    this.setState({
      isModal: false,
    });
  };

  render() {
    const { data, loading, isModal } = this.state;
    return (
      <InsurancePlanContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div className="pc_show">
              <img
                className="bg_img"
                src={iconBackground['bg-fwd.jpg']}
                alt="background"
              />
            </div>
            <div className="mb_show">
              <img
                className="bg_img"
                src={iconBackground['bg-fwd2.png']}
                alt="background"
              />
            </div>
            <div
              className="link_back_left"
              onClick={() => this.handleClickBackPage()}
            >
              <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
            </div>
            <div
              className="link_back"
              onClick={() => this.handleClickBackPage()}
            >
              <BoxLink theme_standard_box_link label="กลับสู่หน้าหลัก" />
            </div>

            <div className="container">
              <Insurance
                theme_standard_1
                handleClickBackFirstPage={() => this.handleClickBackFirstPage()}
                data={data && data}
                handleSelectPackage={(e) => this.handleSelectPackage(e)}
              />
            </div>
            <Modal
              theme_modal_light
              isShow={isModal}
              handleClickCloseModal={this.handleClickCloseModalCustom}
            >
              <div className="popup_container">
                <div className="icon_close" onClick={this.handleClickModal}>
                  <img src={iconImages['close_gray.png']} alt="icon close" />
                </div>
                <div className="logo_box">
                  <div className="figture">
                    <img
                      src={iconImages['logo-popup-group.png']}
                      alt="fwd well"
                    />
                  </div>
                </div>
                <div className="logo_campain">
                  <img
                    src={iconImages['cancer-day-2021.png']}
                    alt="world cancer 2021"
                  />
                </div>
                <div className="desktop_row">
                  <div className="left_col">
                    <div className="gif_voucher_img_wrap">
                      <img
                        src={iconImages['campaign-img.png']}
                        alt="well gift voucher"
                      />
                    </div>
                  </div>
                  <div className="right_col">
                    <div className="txt_line_1">
                      มะเร็ง! ภัยร้ายคร่าชีวิตคนไทยอันดับ 1
                    </div>
                    <div className="txt_line_2">
                      สมัครวันนี้ คุ้มครองทันทีทุกมะเร็ง จ่ายจริงทุกระยะ
                    </div>
                    <div className="txt_line_3">
                      รับฟรี! บัตรกำนัล เซ็นทรัล{' '}
                      <span className="txt_hightlight">สูงสุด 800 บาท</span>
                    </div>
                    <div className="buy_btn_wrap">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={data && data.redirect_uri}
                      >
                        <ButtonIcon
                          theme_blue
                          lable1Size="16px"
                          label1="ซื้อประกันออนไลน์"
                        />
                      </a>
                    </div>
                    <div className="date_txt">
                      รับประกันโดย บริษัท เอฟดับบลิวดี ประกันชีวิต จำกัด (มหาชน)
                    </div>
                  </div>
                </div>
                <div className="mobile_row">
                  <div className="txt_line_1">
                    มะเร็ง! ภัยร้ายคร่าชีวิตคนไทยอันดับ 1
                  </div>
                  <div className="txt_line_2">
                    สมัครวันนี้ คุ้มครองทันทีทุกมะเร็ง จ่ายจริงทุกระยะ
                  </div>
                  <div className="txt_line_3">
                    รับฟรี! บัตรกำนัล เซ็นทรัล{' '}
                    <span className="txt_hightlight">สูงสุด 800 บาท</span>
                  </div>
                  <div className="gif_voucher_img_wrap">
                    <img
                      src={iconImages['campaign-img.png']}
                      alt="well gift voucher"
                    />
                  </div>
                  <div className="buy_btn_wrap">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={data && data.redirect_uri}
                    >
                      <ButtonIcon
                        theme_blue
                        lable1Size="16px"
                        label1="ซื้อประกันออนไลน์"
                      />
                    </a>
                  </div>
                  <div className="date_txt">
                    รับประกันโดย บริษัท เอฟดับบลิวดี ประกันชีวิต จำกัด (มหาชน)
                  </div>
                </div>
              </div>
            </Modal>
          </>
        )}
      </InsurancePlanContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsurancePlanContainer);

import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const HistoryServiceStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard_history {
    width: 100%;
    
    font-size: ${FONT.SIZE_20};
    color: ${COLOR.BLUE_2};
    font-weight: 600;
    .title {
      margin-bottom: 20px;
    }
    .show_order {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: -4px;
      .box_show {
        cursor: pointer;
        width: 49%;
        margin: 5px 0px;
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .theme_standard_history {
      .show_order {
        .box_show {
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .theme_standard_history {
      .show_order {
        .box_show {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_standard_history {
      .show_order {
        .box_show {
        }
      }
    }
  }
  @media (max-width: 700px) {
    .theme_standard_history {
      .show_order {
        .box_show {
          width: 100%;
        }
      }
    }
  }
`;

import React from 'react'
import { BoxQAStyled } from './styled'
import cx from "classnames";
// import { iconImages } from 'assets'

export const BoxQA = ({ theme_standard, data }) => {
    const customClass = cx({
        "theme_standard": theme_standard
    })
    return (
        <BoxQAStyled>
            <div className={customClass}>
                <div className="title_show">
                    วิธีใช้ / คำถามที่พบบ่อย
                </div>
                <div className="body_show">
                    <div style={{width: '100%'}} dangerouslySetInnerHTML={{ __html: data }} />
                    {/* <div className="section_1">
                        <div className="title_section_1">
                            1. VDO สอนการใช้งาน
                        </div>
                        <div className="img_layer">
                            <img
                                className="img_show"
                                src={iconImages['logo2.png']}
                                alt="img show"
                            />
                        </div>
                    </div>
                    <div className="section_1">
                        <div className="title_section_1">
                            2. ข้อควรระวัง / ข้อห้ามการใช้
                        </div>
                        <div className="detail_show">
                            <span className="span_show">- ห้ามใช้ หากบรรจุภัณฑ์แปรงเอวาลีน ได้รับความเสียหาย/ชำรุด หรือเลยวันหมดอายุ</span>
                            <span className="span_show">- ห้ามใช้ระหว่างมีรอบเดือน</span>
                            <span className="span_show">- ห้ามใช้ระหว่างช่วงการตั้งครรภ์หรือหลังตั้งครรภ์ 3 เดือน</span>
                            <span className="span_show">- ห้ามใช้ผลิตภัณฑ์ทางช่องคลอดชนิดอื่นๆ เป็นเวลาอย่างน้อย 2 วัน ก่อนใช้แปรงเอวาลีน
การใช้วิธีคุมกำเนิดทางช่องคลอด,ถุงยางอนามัย และน้ำยาหล่อลื่นแบบน้ำ สามารถใช้ได้ตามปกติ</span>
                            <span className="span_show">- สำหรับการใช้งานเพียงครั้งเดียวเท่านั้น</span>
                            <span className="span_show">- การนำไปใช้ซ้ำอาจก่อให้เกิดการติดเชื้อหรือการวินิจฉัยที่ผิดพลาดได้</span>
                            <span className="span_show">- กรุณาตรวจสอบว่าหมายเลขประจำตัวของคุณบนบรรจุภัณฑ์ตรงกันกับที่ระบุในแบบฟอร์มการส่งตรวจ</span>
                            <span className="span_show">- กรุณาเขียนชื่อและวันที่ลงในป้ายแสดงตัวตนที่ได้แนบไว้ให้ แล้วนำไปติดที่ครอบหุ้มใสของแปรงเอวาลีน</span>
                            <span className="span_show">- นำอุปกรณ์ที่ได้ เก็บตัวอย่างในช่องคลอดด้วยตัวเองที่บ้าน แล้วนำตัวอย่างที่ได้ บรรจุลงกล่องพัสดุ EMS แล้วนำส่งไปรษณีย์
                            ถึงศูนย์ตรวจฯตามที่ระบุบนกล่องภายใน 7 วัน จากนั้นตัวอย่างจะเข้าสู่กระบวนการตรวจทางห้องปฏิบัติการ และรอรับผลตรวจ
ส่งกลับมาที่บ้านของคุณ ภายใน 7 - 10 วัน</span>
                            <span className="red_show">
                                *ถ้าผลตรวจเป็นบวกจะได้รับคำแนะนำให้ไปพบ สูตินรีแพทย์ หรือสูตินรีแพทย์มะเร็งนรีเวช ต่อไป *
                            </span>
                        </div>
                    </div>
                    <div className="section_1">
                        <div className="title_section_1">
                            3. ฉันจะได้รับชุดตรวจเมื่อไหร่ ?
                        </div>
                        คุณจะได้รับชุดตรวจภายใน 3 วัน หลังชำระเงินในกรณีที่ผลตรวจเป็นบวก คุณจะได้รับเงินคืนจาก ichecktest 1000 บาท
หากไปพบแพทย์มะเร็งนรีเวชในเครือข่าย
                    </div> */}
                </div>
            </div>
        </BoxQAStyled>
    )
}
import React from 'react'
import { BoxLinkStyled } from './styled'
import cx from "classnames";

export const BoxLink = ({ theme_standard_box_link, theme_white_box_link, showArrow, label }) => {
    const customClass = cx({
        "theme_standard_box_link": theme_standard_box_link,
        "theme_white_box_link": theme_white_box_link,
    })
    return (
        <BoxLinkStyled>
            <div className={customClass}>
                {
                    showArrow &&
                    <div className="arrow_layer">
                        {`<`}
                    </div>
                }
                <div className="show_label">
                    {label}
                </div>
            </div>
        </BoxLinkStyled>
    )
}
import styled from 'styled-components';
// import {
//     COLOR,
//     FONT
// } from 'assets'

export const BackBtnStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  // width: 100%;
  /*===============================================
Theme 
===============================================*/
  .btn_container {
    display: flex;
    column-gap: 8.4px;
    cursor: pointer;
    .icon {
    }
    .txt {
      font-size: 14px;
      color: white;
    }
  }

  .theme_standard {
  }

  .theme_gray {
    .btn_container {
      .txt {
        color: #757575;
      }
    }
  }
`;

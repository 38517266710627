import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const DateTimeStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    .react-datepicker-wrapper {
      width: 100%;
      input {
        width: 100%;
        height: 45px;
        border-radius: 15px;
        box-shadow: 2px 1px 6px 0px rgba(61, 163, 193, 0.2),
          0 4px 20px 0 rgba(61, 163, 193, 0.19);
        border: none;
        outline: none;
        background-color: white;
        padding: 0 10px;
      }
    }
  }
  .theme_line {
    .react-datepicker-wrapper {
      width: 100%;
      input {
        height: 35px;
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 2px solid ${COLOR.WHITE_1};
        color: ${COLOR.WHITE_1};
        font-size: ${FONT.SIZE_14};
        outline: none;
        padding: 0 10px;
      }
    }
  }
  .theme_line_herwill {
    .react-datepicker-wrapper {
      width: 100%;
      input {
        height: 35px;
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 2px solid ${COLOR.BLACK_6};
        color: ${COLOR.BLACK_6};
        font-size: ${FONT.SIZE_23};
        font-weight: bold;
        outline: none;
        padding: 0 10px;
      }
    }
  }
`;

import React from 'react';
import { connect } from 'react-redux';
import { RegisterContainerStyled } from './styled';
import { setReduxSubjectSelect, setReduxUserAuth } from 'actions';
import { userService } from 'apiService';
import { ROUTE_PATH } from 'helpers';
import { iconImages } from 'assets';
import { RegisterForm } from 'forms';
import { toast } from 'react-toastify';
import moment from 'moment';
// import { LogoLoadingFade2, Icons } from 'components';
// import styled from 'styled-components';

class RegisterContainer extends React.Component {
  state = {
    step: 1,
    loading: true,
    disable: false,
    scheme: this.props.configProjectRedux.scheme === 'herwill' && 'herwill',
  };

  componentDidMount() {
    this.fetchDataProfile();
    this.scrollToTop();
    this.setLoadder();
  }

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1560);
  };

  setLoadder2 = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1560);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchDataProfile = async () => {
    const tokenCheck =
      sessionStorage.getItem('tokenList') || localStorage.getItem('tokenList');

    if (tokenCheck) {
      let res = await userService.GET_PROFILE_DETAIL();
      if (res && res.success) {
  
        this.setState({
          initialValues: {
            ...res.data,
            ...res.data.profile,
            company_id: res.data.profile.company?.id,
          },
          isOldUser: true,
        });
      }
    }
  };

  handleChangeStep = () => {
    const {
      reduxForm: { values, syncErrors },
    } = this.props;
    const { step } = this.state;
    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลด้วย');
    } else {
      this.setState({
        registerName: values.firstname + ' ' + (values.lastname || ''),
      });
      this.setState(
        {
          step: step + 1,
          loading: true,
        },
        () => this.setLoadder2()
      );
    }
  };

  handleSetStep = (step) => {
    this.setState({
      step: step,
    });
  };

  handleBackStep = () => {
    const { step } = this.state;
    const { configProjectRedux } = this.props;

    if (configProjectRedux.scheme === 'herwill') {
      if (step === 4) {
        this.setState(
          {
            step: 2,
            loading: true,
            disable: false,
          },
          () => this.setLoadder2()
        );
      } else {
        this.setState(
          {
            step: step - 1,
            loading: true,
            disable: false,
          },
          () => this.setLoadder2()
        );
      }
    } else {
      this.setState(
        {
          step: step === 5 ? step - 2 : step - 1,
          loading: true,
          disable: false,
        },
        () => this.setLoadder2()
      );
    }
  };

  // EMAIL PROCESS REGISTER
  // -====================================
  // -====================================
  handleSuccesRegister = async () => {
    this.setState({
      disable: true,
    });
    let sesstionToken =
      sessionStorage.getItem('tokenList') || localStorage.getItem('tokenList');
    if (sesstionToken) {
      this.handleSubmitProfile();
    } else {
      if (localStorage.getItem('tokenList')) {
        this.handleSubmitProfile();
      } else {
        const {
          reduxForm: { values },
        } = this.props;
        let params = {
          email: values.email,
          password: values.password,
          confirm_password: values.confirm_password,
          provider: 'local',
          birthday: moment(values.birthday).format('YYYY-MM-DD'),
        };

        let res = await userService.POST_SING_UP(params);
        if (res && res.success) {
          this.setState({
            isRegisEmailSuccess: true,
          });
          this.handleClickLoginEmail();
        } else {
          this.setState({
            loading: false,
          });
          toast.error('อีเมล์ถูกใช้แล้ว');
        }
      }
    }
  };

  handleClickLoginEmail = async () => {
    const {
      reduxForm: { values },
    } = this.props;
    let params = {
      email: values.email,
      password: values.password,
    };
    let res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      localStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: true })
      );
      this.handleSubmitProfile();
    } else {
      this.setState({
        loading: false,
      });
      toast.error('ข้อมูลไม่ตรงในระบบ');
    }
  };

  handleSubmitProfile = async () => {
    const {
      reduxForm: { values },
      configProjectRedux,
    } = this.props;
    let params = {
      ...values,
      is_alcohol: values.is_alcohol.value,
      is_smoke: values.is_smoke.value,
      gender: values.gender.value,
      birthday: moment(values.birthday).format('YYYY-MM-DD'),
    };
    if (configProjectRedux.scheme === 'herwill') {
      delete params.birthday
    }

    let res = await userService.PUT_DOCTOR_NOTE_ADD(params);
    if (res && res.success) {
      toast.success('อัพเดตสำเร็จ');
      this.props.setReduxUserAuth(res.data);
      this.setState({
        loading: true,
      });
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        this.handleClickNext();
      }, Math.floor(Math.random() * 800) + 200);
    } else {
      this.setState({
        loading: false,
      });
      toast.error('อัพเดตไม่สำเร็จ');
    }
  };

  handleClickNext = () => {
    this.props.history.replace(ROUTE_PATH.HOME_PATH);
  };

  render() {
    const { step, registerName, loading, scheme, disable, initialValues } =
      this.state;
    const { configProjectRedux } = this.props;

    return (
      <RegisterContainerStyled
        style={
          configProjectRedux?.scheme === 'herwill'
            ? {
              backgroundImage: `url(${iconImages['bg_pink.svg']})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
            : {}
        }
      >
        <div className="loading_move">
          <div className="group_form">
            {step > 1 && (
              <div
                className={`back_layout ${configProjectRedux?.scheme}`}
                onClick={this.handleBackStep}
              >
                {`< ย้อนกลับ`}
              </div>
            )}
            <div className="layout_detail">
              <div className="body_show">
                <RegisterForm
                  initialValues={initialValues}
                  step={step}
                  handleChangeStep={this.handleChangeStep}
                  handleSuccesRegister={this.handleSuccesRegister}
                  registerName={registerName}
                  handleSetStep={this.handleSetStep}
                  loading={loading}
                  scheme={scheme}
                  schemeB={configProjectRedux?.scheme}
                  disable={disable}
                  is_corporate={configProjectRedux.is_corporate}
                />
              </div>
            </div>
          </div>
        </div>
      </RegisterContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  subject: state.subject,
  reduxForm: state.form.WellRegisterForm,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxSubjectSelect: (data) => dispatch(setReduxSubjectSelect(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);

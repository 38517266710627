import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const BoxLinkStyled = styled.div`
/*===============================================
 Container 
===============================================*/
// width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard_box_link {
        // width: 100%;
        
        font-size: ${FONT.SIZE_14};
        color: ${COLOR.GRAY_13};
        display: flex;
        cursor: pointer;
        .arrow_layer {
            margin-right: 5px;
            font-size: ${FONT.SIZE_22};
            font-weight: bold;
        }
        .show_label {
            display: flex;
            align-items: center;
        }
    }

    .theme_white_box_link {
        font-size: ${FONT.SIZE_14};
        color: ${COLOR.WHITE_1};
        display: flex;
        cursor: pointer;
        .arrow_layer {
            margin-right: 5px;
            font-size: ${FONT.SIZE_22};
            font-weight: bold;
        }
        .show_label {
            display: flex;
            align-items: center;
        }
    }
`
import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const AssessmentFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  width: 100%;
  font-size: ${FONT.SIZE_18};
  color: ${COLOR.BLUE_2};
  .title {
    margin-bottom: 24px;
    .show_logo {
      width: 56px;
      margin-right: 5px;
      vertical-align: baseline;
    }
    .show_text {
      vertical-align: baseline;
    }
  }
  .body_question {
    font-size: ${FONT.SIZE_14};
    font-weight: bold;
    .group_question {
      margin-bottom: 15px;
      .label_name {
        margin-bottom: 10px;
      }
      &.no_margin {
        margin-bottom: 0px;
      }
    }
    .btn {
      margin-top: 40px;
    }
    .btn2 {
      display: none;
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    .body_question {
      .btn2 {
        margin-top: 15px;
        display: block;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .body_question {
      .btn2 {
        margin-top: 15px;
        display: block;
      }
    }
  }
`;

import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const BoxHospitalStyled = styled.div`
  /*===============================================
 Container
===============================================*/
  width: 100%;
  /*===============================================
Theme
===============================================*/
  .theme_standard_2 {
    .is_desktop {
      .container {
        min-height: 120px;
        width: 100%;
        font-size: ${FONT.SIZE_14};
        color: ${COLOR.BLUE_2};
        background: #ffffff;
        display: flex;
        border: 1px solid #f395be;
        border-radius: 20px;
        padding: 22px 30px 26px 22px;
        column-gap: 15px;
        .left_layout {
          // width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;
          .box_img {
            width: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
          }
        }
        .right_layout {
          width: 70%;
          .detail {
            .name_title {
              font-weight: 800;
            }
            .detail_sub {
            }
          }
          .btn {
            width: 100%;
            max-width: 168px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .is_mobile {
      display: none;
      .container {
        // min-height: 120px;
        width: 100%;
        font-size: ${FONT.SIZE_14};
        color: ${COLOR.BLUE_2};
        display: flex;
        .left_layout {
          // width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;
          .box_img {
            width: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 62px;
            height: 62px;
          }
        }
        .right_layout {
          width: 90%;
          padding-left: 10px;
          display: flex;
          .detail {
            flex: 1;
            .name_title {
              font-weight: 800;
            }
            .detail_sub {
            }
          }
          .btn {
            flex-shrink: 0;
            padding-left: 8px;
            min-width: 88px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    @media (max-width: ${SCREEN.MINI_TABLET}) {
      .is_desktop {
        display: none;
      }
      .is_mobile {
        display: block;
      }
    }
  }
`;

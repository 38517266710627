import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const InsuranceStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  /*===============================================
Theme 
===============================================*/
  width: 100%;
  font-size: ${FONT.SIZE_18};
  font-weight: 600;
  color: ${COLOR.GREEN_2};
  .title_obj {
    margin-bottom: 40px;
  }
  .title_sec_2 {
    margin-bottom: 16px;
  }
  .group_obj {
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    &.no_margin {
      margin-bottom: 0px;
    }
    .left_obj {
      width: 50%;
      padding-right: 15px;
      .show_obj {
        margin-bottom: 16px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .right_obj {
      width: 50%;
      padding-left: 15px;
      .campaign_container {
        border: 1px solid #3b96b7;
        padding: 9px 16px 16px 16px;
        border-radius: 10px;
        height: 100%;
        background: #ffffff;
        .campagin_row {
          display: flex;
          align-items: center;
          &.between {
            justify-content: space-between;
          }
          &.mb_16 {
            margin-bottom: 16px;
          }
          .campaign_icon_wrap {
            display: flex;
            align-items: center;
            .campaign_icon {
              width: 19px;
              height: auto;
              margin-right: 5px;
            }
            .txt_title {
              font-size: 14px;
              font-weight: semi-bold;
              color: #235079;
            }
          }
          .txt_date {
            font-size: 12px;
            font-weighht: 500;
            color: #9e9e9e;
          }

          .campaign_left_col {
            flex-shrink: 0;
            width: 50%;
            margin-right: 13.5px;
            width: 133px;
            margin: auto;
            .campaign_img {
              width: 100%;
              height: auto;
            }
          }
          .campaign_right_col {
            width: 50%;
            margin-left: 13.5px;
            .campagin_txt {
              font-size: 14px;
              font-weight: bold;
              color: #3b96b7;
              &.mb_11 {
                margin-bottom: 11px;
              }
            }
            .btn_wrap {
            }
          }
        }
      }
      .right_col {
        margin-bottom: 22px;
      }
      .btn_home_wrap {
        display: none;
        margin-top: 20px;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    max-width: 400px;
    margin: auto;
    .title_obj {
      margin-top: 68px;
      margin-bottom: 7px;
    }
    .group_obj {
      flex-direction: column;
      align-items: center;
      .left_obj {
        width: 100%;
        padding: 0;
        margin-bottom: 23px;
      }
      .right_obj {
        width: 100%;
        padding: 0;
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .group_obj {
      .right_obj {
        .btn_home_wrap {
          display: block;
        }
      }
    }
  }
`;

import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const QtyBoxStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        
        font-size: ${FONT.SIZE_16};
        // background: ${COLOR.WHITE_1};
        color: ${COLOR.BLACK_2};
        display: flex;
        align-items: center;
        .decrease {
            cursor: pointer;
            padding: 5px;
            width: 45px;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            font-weight: bold;
            text-align: center;
            background: ${COLOR.GRAY_2};
            border: 1px solid ${COLOR.GRAY_3};
        }
        .input_layer {
            padding: 5px;
            width: 40px;
            border-top: 1px solid ${COLOR.GRAY_3};
            border-bottom: 1px solid ${COLOR.GRAY_3};
            background: ${COLOR.WHITE_1};
            .input_number {
                background: ${COLOR.WHITE_1};
                border: none;
                width: 100%;
                box-sizing: border-box;
                text-align: center;
            }
        }
        .increase {
            cursor: pointer;
            padding: 5px;
            width: 45px;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            font-weight: bold;
            text-align: center;
            background: ${COLOR.GRAY_2};
            border: 1px solid ${COLOR.GRAY_3};
        }
    }
`
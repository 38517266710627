import styled from 'styled-components';

export const DropDownCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard_dropdown {
    display: flex;
    align-items: ${({ alignItems }) =>
    alignItems ? alignItems : 'flex-start'};

    select {
      width: ${({ width }) => (width ? width : '300px')};
      border-radius: 15px;
      box-shadow: 2px 1px 6px 0px rgba(61, 163, 193, 0.2),
        0 4px 20px 0 rgba(61, 163, 193, 0.19);
      border: none;
      outline: none;
      background-color: white;
    }
    .label_layer {
      font-weight: 700;
      font-size: ${({ theme }) => theme.FONTS.SIZE_2_5};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      margin-right: 10px;
    }
    img {
      width: 21px;
      height: 22px;
      margin-right: 5px;
    }
    select {
      height: 46px;
      min-width: 222px;
      text-align-last: center;
      span {
        margin-left: 10px;
      }
    }
  }
  .theme_normal_dropdown {
    display: flex;
    align-items: ${({ alignItems }) =>
    alignItems ? alignItems : 'flex-start'};

    select {
      width: ${({ width }) => (width ? width : '300px')};
      border-radius: 15px;
      // box-shadow: 2px 1px 6px 0px rgba(61, 163, 193, 0.2),
      //   0 4px 20px 0 rgba(61, 163, 193, 0.19);
      border: none;
      outline: none;
      background-color: white;
    }
    .label_layer {
      font-weight: 700;
      font-size: ${({ theme }) => theme.FONTS.SIZE_2_5};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      margin-right: 10px;
    }
    img {
      width: 21px;
      height: 22px;
      margin-right: 5px;
    }
    select {
      height: 46px;
      // min-width: 222px;
      text-align-last: center;
      span {
        margin-left: 10px;
      }
    }
  }
  .theme_herwill {
    display: flex;
    align-items: ${({ alignItems }) =>
    alignItems ? alignItems : 'flex-start'};
    select {
      width: ${({ width }) => (width ? width : '300px')};
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      border-bottom: 2px solid #003564;
      border-radius: 0;
      outline: none;
      background: none;
      font-size: ${({ fontSize }) => (fontSize ? fontSize : '23px')};
      font-weight: bold;
    }
    select option {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
    .label_layer {
      font-weight: 700;
      font-size: ${({ theme, fontSize }) =>
    fontSize ? fontSize : theme.FONTS.SIZE_2_5};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      margin-right: 10px;
    }
    img {
      width: 21px;
      height: 22px;
      margin-right: 5px;
    }
    select {
      height: 46px;
      min-width: 222px;
      text-align-last: left;
      span {
        margin-left: 10px;
      }
    }
  }
  .them_pink {
    display: flex;
    align-items: ${({ alignItems }) =>
    alignItems ? alignItems : 'flex-start'};
    select {
      width: ${({ width }) => (width ? width : '300px')};
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='mediumvioletred'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
      background-size: 12px;
      background-position: calc(100% - 15px) 19px;
      background-repeat: no-repeat;
      background-color: #ffffff;
      border: 1px solid #cf1a71;
      outline: none;
      padding: 5px 10px;
      border-radius: 16px;
        font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
      font-weight: bold;
    }
    select option {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
    .label_layer {
      font-weight: 700;
      font-size: ${({ theme, fontSize }) =>
    fontSize ? fontSize : theme.FONTS.SIZE_2_5};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      margin-right: 10px;
    }
    img {
      width: 21px;
      height: 22px;
      margin-right: 5px;
    }
    select {
      height: 46px;
      min-width: 222px;
      text-align-last: left;
      span {
        margin-left: 10px;
      }
    }
  }
  .theme_well {
    display: flex;
    align-items: ${({ alignItems }) =>
    alignItems ? alignItems : 'flex-start'};
    select {
      width: ${({ width }) => (width ? width : '300px')};
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      border-bottom: 2px solid #ffffff;
      border-radius: 0;
      outline: none;
      background: none;
      font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
      font-weight: bold;
    }
    select option {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
    .label_layer {
      font-weight: 700;
      font-size: ${({ theme, fontSize }) =>
    fontSize ? fontSize : theme.FONTS.SIZE_2_5};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      margin-right: 10px;
    }
    img {
      width: 21px;
      height: 22px;
      margin-right: 5px;
    }
    select {
      height: 46px;
      min-width: 222px;
      text-align-last: left;
      span {
        margin-left: 10px;
      }
    }
  }
`;

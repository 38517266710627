import styled from 'styled-components';

export const AutoLoginStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
 
`;

import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { RegisterFormStyled } from './styled';
import { VALIDATE } from 'helpers';
import { InputText, CheckBox, Radio, DateTime } from 'components';
import { COLOR } from 'assets';

class RegisterForm extends React.Component {
  state = {};

  render() {
    const {
      handleSubmit,
      handleSubmitForm,
      notEmailForm,
      hasFirstname,
      hasLastname,
      hasWeight,
      hasHeight,
      hasBirthday,
      hasEmail,
      hasPassword,
      scheme,
      handleClickProfileEdit,
    } = this.props;
    return (
      <RegisterFormStyled>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          {notEmailForm ? (
            <>
              <div className="form_layout">
                <div className="group_input">
                  <div className="label_name">ชื่อ</div>
                  <Field
                    theme_standard
                    name="firstname"
                    component={InputText}
                    validate={VALIDATE.REQUIRE}
                    width="100%"
                  />
                </div>
                <div className="group_input">
                  <div className="label_name">นามสกุล</div>
                  <Field
                    theme_standard
                    name="lastname"
                    component={InputText}
                    validate={VALIDATE.REQUIRE}
                    width="100%"
                  />
                </div>
                <div className="group_input">
                  <div className="label_name">น้ำหนัก</div>
                  <Field
                    theme_standard
                    name="weight"
                    component={InputText}
                    validate={[VALIDATE.REQUIRE, VALIDATE.NUMBER_ONLY]}
                    width="100%"
                  />
                </div>
                <div className="group_input">
                  <div className="label_name">ส่วนสูง</div>
                  <Field
                    theme_standard
                    name="height"
                    component={InputText}
                    width="100%"
                    validate={[VALIDATE.REQUIRE, VALIDATE.NUMBER_ONLY]}
                  />
                </div>
                <div className="group_input">
                  <div className="label_name">วัน/เดือน/ปี</div>
                  <Field
                    theme_standard
                    name="birthday"
                    component={DateTime}
                    validate={VALIDATE.REQUIRE}
                  />
                </div>
                <div className="gender_layout">
                  <div className="label_name">เพศ</div>
                  <div className="gen_opt">
                    <Field
                      theme_circle
                      label="เพศ"
                      name="gender"
                      options={[
                        {
                          label: 'เพศชาย',
                          value: 'male',
                        },
                        {
                          label: 'เพศหญิง',
                          value: 'female',
                        },
                      ]}
                      component={Radio}
                      // validate={VALIDATE.REQUIRE}
                    />
                  </div>
                </div>
                <div className="layout_YN">
                  <div className="YN_left">
                    <div className="label_name">ท่านเคยสูบบุหรี่หรือไม่?</div>
                    <Field
                      theme_circle_column
                      name="is_alcohol"
                      padding_left="0px"
                      margin_bottom="10px"
                      options={[
                        {
                          label: 'ใช่',
                          value: true,
                        },
                        {
                          label: 'ไม่ใช่',
                          value: false,
                        },
                      ]}
                      component={Radio}
                      // validate={VALIDATE.REQUIRE}
                    />
                  </div>
                  <div className="YN_right">
                    <div className="label_name">ท่านเคยดื่มสุราหรือไม่?</div>
                    <Field
                      theme_circle_column
                      name="is_smoke"
                      padding_left
                      margin_bottom="10px"
                      options={[
                        {
                          label: 'ใช่',
                          value: true,
                        },
                        {
                          label: 'ไม่ใช่',
                          value: false,
                        },
                      ]}
                      component={Radio}
                      // validate={VALIDATE.REQUIRE}
                    />
                  </div>
                </div>
                <div className="group_input_2">
                  <div className="label_name">โรคประจำตัว</div>
                  <Field
                    theme_standard
                    name="disease"
                    component={InputText}
                    width="100%"
                  />
                </div>
                <div className="group_input">
                  <Field
                    theme_standard
                    label="ฉันยินยอม "
                    label_link="ข้อตกลงการใช้งานเงื่อนไขการใช้งาน"
                    link="https://www.invitracehealth.com/terms-of-use-1"
                    name="is_confirm1"
                    component={CheckBox}
                    fontWeight="700"
                    width_container="auto"
                    // validate={VALIDATE.REQUIRE}
                  />
                </div>
                <div className="group_input">
                  <Field
                    theme_standard
                    label="ฉันยินยอม"
                    label_link=" ข้อตกลงความเป็นส่วนตัว "
                    link="https://www.invitracehealth.com/privacy-policy"
                    name="is_confirm2"
                    fontWeight="700"
                    component={CheckBox}
                    width_container="auto"
                    // validate={VALIDATE.REQUIRE}
                  />
                </div>
              </div>
              <button
                className={`btn_next ${scheme}`}
                onClick={handleClickProfileEdit}
              >
                แก้ไขโปรไฟล์
              </button>
              <button
                className={`btn_next ${scheme}`}
                style={{ background: scheme && COLOR.PINK_1 }}
                type="submit"
                disabled={
                  !(
                    hasFirstname &&
                    hasLastname &&
                    hasWeight &&
                    hasHeight &&
                    hasBirthday
                  )
                }
              >
                ยืนยัน
              </button>
            </>
          ) : (
            <>
              <div className="form_layout">
                <div className="group_input">
                  <div className="label_name">ชื่อ</div>
                  <Field
                    theme_standard
                    name="firstname"
                    component={InputText}
                    validate={VALIDATE.REQUIRE}
                    input_width="100%"
                  />
                </div>
                <div className="group_input">
                  <div className="label_name">นามสกุล</div>
                  <Field
                    theme_standard
                    name="lastname"
                    component={InputText}
                    validate={VALIDATE.REQUIRE}
                    input_width="100%"
                  />
                </div>
                <div className="group_input">
                  <div className="label_name">น้ำหนัก</div>
                  <Field
                    theme_standard
                    type="number"
                    name="weight"
                    component={InputText}
                    validate={[VALIDATE.REQUIRE, VALIDATE.NUMBER_ONLY]}
                    input_width="100%"
                  />
                </div>
                <div className="group_input">
                  <div className="label_name">ส่วนสูง</div>
                  <Field
                    theme_standard
                    type="number"
                    name="height"
                    component={InputText}
                    validate={[VALIDATE.REQUIRE, VALIDATE.NUMBER_ONLY]}
                    input_width="100%"
                  />
                </div>
                <div className="group_input">
                  <div className="label_name">วันเกิด(วัน/เดือน/ปี)</div>
                  <Field
                    theme_standard
                    name="birthday"
                    component={DateTime}
                    validate={VALIDATE.REQUIRE}
                  />
                </div>
                <div className="group_input">
                  <div className="label_name">อีเมล์</div>
                  <Field
                    theme_standard
                    name="email"
                    placeHolder="you@example.com"
                    component={InputText}
                    validate={VALIDATE.EMAIL}
                    input_width="100%"
                  />
                </div>
                <div className="group_input">
                  <div className="label_name">Password</div>
                  <Field
                    type="password"
                    theme_standard
                    name="password"
                    placeHolder="Password"
                    component={InputText}
                    validate={VALIDATE.REQUIRE}
                    input_width="100%"
                  />
                </div>
                <div className="group_input">
                  <div className="label_name">Confirm Password</div>
                  <Field
                    type="password"
                    theme_standard
                    name="confirm_password"
                    placeHolder="Password"
                    component={InputText}
                    validate={[VALIDATE.REQUIRE, VALIDATE.PASSWORD_MATCH]}
                    input_width="100%"
                  />
                </div>
                <div className="gender_layout">
                  <div className="label_name">เพศ</div>
                  <div className="gen_opt">
                    <Field
                      theme_circle
                      label="เพศ"
                      name="gender"
                      options={[
                        {
                          label: 'เพศชาย',
                          value: 'male',
                        },
                        {
                          label: 'เพศหญิง',
                          value: 'female',
                        },
                      ]}
                      component={Radio}
                      // validate={VALIDATE.REQUIRE}
                    />
                  </div>
                </div>
                <div className="layout_YN">
                  <div className="YN_left">
                    <div className="label_name">ท่านเคยสูบบุหรี่หรือไม่?</div>
                    <Field
                      theme_circle_column
                      name="is_smoke"
                      padding_left="0px"
                      margin_bottom="10px"
                      options={[
                        {
                          label: 'ใช่',
                          value: true,
                        },
                        {
                          label: 'ไม่ใช่',
                          value: false,
                        },
                      ]}
                      component={Radio}
                      // validate={VALIDATE.REQUIRE}
                    />
                  </div>
                  <div className="YN_right">
                    <div className="label_name">ท่านเคยดื่มสุราหรือไม่?</div>
                    <Field
                      theme_circle_column
                      name="is_alcohol"
                      padding_left
                      margin_bottom="10px"
                      options={[
                        {
                          label: 'ใช่',
                          value: true,
                        },
                        {
                          label: 'ไม่ใช่',
                          value: false,
                        },
                      ]}
                      component={Radio}
                      // validate={VALIDATE.REQUIRE}
                    />
                  </div>
                </div>
                <div className="group_input_2">
                  <div className="label_name">โรคประจำตัว</div>
                  <Field
                    theme_standard
                    name="disease"
                    component={InputText}
                    // validate={VALIDATE.REQUIRE}
                    input_width="100%"
                  />
                </div>
                <div className="group_input">
                  <Field
                    theme_standard
                    label="ฉันยินยอม "
                    label_link="ข้อตกลงการใช้งานเงื่อนไขการใช้งาน"
                    link="https://www.invitracehealth.com/terms-of-use-1"
                    name="is_confirm1"
                    component={CheckBox}
                    fontWeight="700"
                    width_container="auto"
                    // validate={VALIDATE.REQUIRE}
                  />
                </div>
                <div className="group_input">
                  <Field
                    theme_standard
                    label="ฉันยินยอม"
                    label_link=" ข้อตกลงความเป็นส่วนตัว "
                    link="https://www.invitracehealth.com/privacy-policy"
                    name="is_confirm2"
                    fontWeight="700"
                    component={CheckBox}
                    width_container="auto"
                    // validate={VALIDATE.REQUIRE}
                  />
                </div>
              </div>

              <button
                className={`btn_next ${scheme}`}
                onClick={handleClickProfileEdit}
              >
                แก้ไขโปรไฟล์
              </button>
              <button
                className={`btn_next ${scheme}`}
                type="submit"
                disabled={
                  !(
                    hasFirstname &&
                    hasLastname &&
                    hasWeight &&
                    hasHeight &&
                    hasBirthday &&
                    hasEmail &&
                    hasPassword
                  )
                }
              >
                ยืนยัน
              </button>
            </>
          )}
        </form>
      </RegisterFormStyled>
    );
  }
}

const selector = formValueSelector('RegisterForm');
RegisterForm = connect((state) => {
  const hasFirstname = selector(state, 'firstname');
  const hasLastname = selector(state, 'lastname');
  const hasWeight = selector(state, 'weight');
  const hasHeight = selector(state, 'height');
  const hasBirthday = selector(state, 'birthday');
  const hasEmail = selector(state, 'email');
  const hasPassword = selector(state, 'password');
  return {
    hasFirstname,
    hasLastname,
    hasWeight,
    hasHeight,
    hasBirthday,
    hasEmail,
    hasPassword,
  };
})(RegisterForm);

export default reduxForm({
  form: 'RegisterForm', // a unique identifier for this form
  enableReinitialize: true,
})(RegisterForm);

import styled from 'styled-components';
import { SCREEN, COLOR } from 'assets';
export const QuestionBeforeBuyContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background: ${COLOR.GRAY_9};
  min-height: calc(100% - 87px);

  padding: 20px 0;
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_back {
    padding: 0px 60px 10px 60px;
  }
  .container {
    max-width: 29%;
    margin: auto;
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .link_back {
    }
    .container {
      max-width: 39%;
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .link_back {
    }
    .container {
      max-width: 52%;
    }
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .link_back {
      padding: 0px 30px 10px 30px;
    }
    .container {
      max-width: 88%;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .link_back {
      padding: 0px 20px 10px 20px;
    }
    .container {
      max-width: 88%;
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    .link_back {
      padding: 0px 20px 10px 20px;
    }
    .container {
      max-width: 88%;
    }
  }
`;

import apiService from '../apiService';

const apiPath = '/api';

export const userService = {
  POST_LOGIN_WITH_TOKEN: (params) => {
    return apiService.post(`${apiPath}/loginWithToken`, params);
  },

  POST_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login`, params);
  },
  GET_LOGOUT: () => {
    return apiService.get(`${apiPath}/logout`);
  },
  POST_SING_UP: (params) => {
    return apiService.post(`${apiPath}/users`, params);
  },

  GET_PROFILE_DETAIL: () => {
    return apiService.get(`${apiPath}/users/me`);
  },

  PATCH_PROFILE: (params) => {
    return apiService.patch(`${apiPath}/users/me`, params);
  },

  PUT_DOCTOR_NOTE_ADD: (params) => {
    return apiService.put(`${apiPath}/profiles/me`, params);
  },

  // GET_PROFILE_ME: () => {
  //   return apiService.get(`${apiPath}/profiles/me`);
  // },

  // PinkAlert Campaign
  GET_PINKALERT_QUESTION_LIST: () => {
    return apiService.get(`${apiPath}/campaigns/pinkalert/questions`);
  },

  POST_PINKALERT_SEND_QUESTION: (params) => {
    return apiService.post(
      `${apiPath}/profiles/me/campaigns/pinkalert/screenings`,
      params
    );
  },

  // Herwill Campaign
  GET_HERWILL_QUESTION_LIST: () => {
    return apiService.get(`${apiPath}/campaigns/herwill/questions`);
  },

  POST_HERWILL_SEND_QUESTION: (params) => {
    return apiService.post(
      `${apiPath}/profiles/me/campaigns/herwill/screenings`,
      params
    );
  },

  GET_HERWILL_LANDING_COUNT: () => {
    return apiService.get(`${apiPath}/campaigns/herwill/landing_count`);
  },
  GET_HERWILL_SCREEN_COUNT: () => {
    return apiService.get(`${apiPath}/campaigns/herwill/screening_count`);
  },

  GET_HERWILL_SUMMARY: (summary_id) => {
    return apiService.get(
      `${apiPath}/profiles/me/campaigns/herwill/screenings/${summary_id}`
    );
  },

  //put screening question
  PUT_SCREENING_QUESTION_PART1: (params) => {
    return apiService.put(`/api/screenings/dummy/answers`, params);
  },

  POST_SCREENING_QUESTION_PART1: (profile_id, params) => {
    return apiService.post(`/api/profiles/${profile_id}/screenings`, params);
  },

  //get Symptom Screening
  GET_SYMPTOM_SCREENING_BY_ID: (profile_id, screening_id) => {
    return apiService.get(
      `/api/profiles/${profile_id}/screenings/${screening_id}`
    );
  },

  //get No Symptom Screening
  GET_NO_SYMPTOM_SCREENING_BY_ID: (profile_id, screening_id) => {
    return apiService.get(
      `/api/profiles/${profile_id}/nosymptom_screenings/${screening_id}`
    );
  },

  //edit profile
  PUT_PROFILE_SCREENING: (params) => {
    return apiService.put(`/api/profiles/me`, params);
  },

  //get profile families
  GET_PROFILE_FAILIES: () => {
    return apiService.get(`/api/users/me/families`);
  },

  //get screenings summary list
  GET_SCREENINGS_SUMMARY_LIST: (profiles_id) => {
    return apiService.get(`/api/profiles/${profiles_id}/screenings`);
  },

  GET_SCREENINGS_SUMMARY_DETAIL: (profiles_id, screening_id) => {
    return apiService.get(
      `/api/profiles/${profiles_id}/screenings/${screening_id}`
    );
  },

  //add profile families
  POST_PROFILE_FAILIES: (params) => {
    return apiService.post(`/api/profiles`, params);
  },

  RELATIONSHIP_KEY: () => {
    return apiService.get(`/api/relationships`);
  },

  PUT_EDIT_FAMILY: (profile_id, params) => {
    // console.log(profile_id, params);
    return apiService.put(`/api/profiles/${profile_id}`, params);
  },

  // PRODUCT CATEGORIES
  GET_HPV_TESTKIT_PRODUCT_LIST: () => {
    return apiService.get(
      `${apiPath}/product_categories/hpv_test_kit/products`
    );
  },

  // PRODUCT ORDER
  GET_PRODUCT_ORDER_LIST_BY_STATUS: (profile_id, status) => {
    return apiService.get(
      `${apiPath}/profiles/${profile_id}/product_orders?status=${status}`
    );
  },
  GET_PRODUCT_ORDER_DETAIL: (profile_id, product_order_id) => {
    return apiService.get(
      `${apiPath}/profiles/${profile_id}/product_orders/${product_order_id}`
    );
  },
  POST_PRODUCT_ORDER: (profile_id, params) => {
    return apiService.post(
      `${apiPath}/profiles/${profile_id}/product_orders`,
      params
    );
  },

  // SERVICE CATEGORIES
  GET_CANCER_INSURANCE_SERVICE_LIST: () => {
    return apiService.get(
      `${apiPath}/service_categories/cancer_insurance/services`
    );
  },
  GET_CANCER_CHECKUP_LIST: () => {
    return apiService.get(
      `${apiPath}/service_categories/cancer_checkup/services`
    );
  },
  GET_SERVICE_CATEGORIES_HER_WELL_SERVICE_LIST: () => {
    return apiService.get(
      `${apiPath}/service_categories/cancer_checkup_herwill/services`
    );
  },

  // SERVICE ORDER
  POST_SERVICE_ORDER: (profile_id, params) => {
    return apiService.post(
      `${apiPath}/profiles/${profile_id}/service_orders`,
      params
    );
  },

  //GENETIC APPOINTMENT
  POST_GENETIC_APPOINTMENT: (profile_id, params) => {
    return apiService.post(
      `${apiPath}/profiles/${profile_id}/genetic_appointments`,
      params
    );
  },
  GET_GENETIC_APPOINTMENT_LIST: (profile_id) => {
    return apiService.get(
      `${apiPath}/profiles/${profile_id}/genetic_appointments`
    );
  },

  GET_SETTING_CONFIG_BY_DOMAIN: (domain) => {
    return apiService.get(`${apiPath}/settings/${domain}`);
  },

  POST_SCREENING_QUESTION_PART2: (profile_id, params) => {
    return apiService.post(
      `${apiPath}/profiles/${profile_id}/nosymptom_screenings`,
      params
    );
  },

  //PAYMENT
  POST_ORDER_PAYMENT: (product_order_id, params) => {
    return apiService.post(
      `${apiPath}/users/me/product_orders/${product_order_id}/payments`,
      params
    );
  },
  GET_CHECK_PAYMENT: (product_order_id, payment_id) => {
    return apiService.get(
      `${apiPath}/users/me/product_orders/${product_order_id}/payments/${payment_id}`
    );
  },

  GET_GATEWAY: (gateway_type, queryString) => {
    return apiService.get(
      `${apiPath}/gateways/${gateway_type}${queryString ? queryString : ''}`
    );
  },

  POST_GETWAY: (profile_id, answer) => {
    return apiService.post(
      `${apiPath}/profiles/${profile_id}/gateway_screenings`,
      answer
    );
  },

  GET_CANCER_APPOINMENT: (profile_id) => {
    return apiService.get(
      `${apiPath}/profiles/${profile_id}/cancer_appointments`
    );
  },
  POST_CANCER_APPOINTMENT: (profile_id, params) => {
    return apiService.post(
      `${apiPath}/profiles/${profile_id}/cancer_appointments`,
      params
    );
  },

  //ADDRESS
  GET_CHANGWAT_LIST: () => {
    return apiService.get(`${apiPath}/changwats`);
  },
  GET_AMPHOES_LIST: (changwat_id) => {
    return apiService.get(`${apiPath}/changwats/${changwat_id}/amphoes`);
  },
  GET_AMPHOES_NEAREST_LIST: (lat, long) => {
    return apiService.get(
      `${apiPath}/amphoes/nearest?latitude=${lat}&longitude=${long}`
    );
  },

  //COMPANY
  GET_COMPANY_LIST: () => {
    return apiService.get(`${apiPath}/companies`);
  },

  GET_MAMMOGRAM_CENTERS_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/mammogram_centers${queryString ? queryString : ''}`
    );
  },

  POST_HERWILL_SEND_EMAIL: (screening_id, params) => {
    return apiService.post(
      `${apiPath}/profiles/me/campaigns/herwill/screenings/${screening_id}/shares`,
      params
    );
  },

  POST_WELL_SCREENING_SEND_EMAIL: (profile_id, screening_id, params) => {
    return apiService.post(
      `${apiPath}/profiles/${profile_id}/screenings/${screening_id}/shares`,
      params
    );
  },

  GET_HEALTH_INSURANCE_SERVICE_LIST: () => {
    return apiService.get(
      `${apiPath}/service_categories/health_insurance_herwill/services`
    );
  },
};

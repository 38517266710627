import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { FBContainerStyled } from './styled';
import { userService } from 'apiService';
import { iconImages } from 'assets';
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth } from 'actions';
import { Loading } from 'components';
import url from 'url';
import { toast } from 'react-toastify';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

class FBContainer extends React.Component {
  state = {};

  responseFacebook = async (e) => {
    // work around / skip on LINE callback
    var urlParts = url.parse(window.location.href, true);
    var query = urlParts.query;
    var hasProviderProperty = Object.prototype.hasOwnProperty.call(
      query,
      'provider'
    );
    var hasErrorProperty = Object.prototype.hasOwnProperty.call(query, 'error');
    if (hasProviderProperty && query.provider === 'line' && !hasErrorProperty) {
      return false;
    }

    let params = {
      provider: 'facebook',
      access_token: e.accessToken,
    };
    this.setState({
      loading: true,
    });
    const res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      ReactGA.event({
        category: 'login',
        action: 'login_success_facebook',
      });
      sessionStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: true })
      );
      localStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: true })
      );
      toast.success('ล็อคอินสำเร็จ');
      this.fetchProfileData('facebook');
    }
  };

  fetchProfileData = async (loginWith) => {
    let res = await userService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      this.setState({
        initialValues: { ...res.data, ...res.data.profile },
      });
      let params = {
        ...res.data,
        ...res.data.profile,
        loginWith: loginWith,
      };
      delete params.profile;
      this.props.setReduxUserAuth({ ...params });
      this.handleCheckProfile();
    }
  };

  handleCheckProfile = () => {
    const { authRedux } = this.props;
    if (authRedux.id && authRedux.firstname) {
      // Set userId for existing users
      ReactGA.set({
        userId: authRedux.id,
      });
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        // this.props.history.push(this.props.configProjectRedux.home_uri);
        if (this.props.configProjectRedux.is_corporate && !authRedux.company) {
          if (this.props.configProjectRedux.is_pinkalert) {
            this.props.history.push(`${ROUTE_PATH.TERM_OF_USE}`);
          } else {
            this.props.history.push(`${ROUTE_PATH.WELL_TERM_OF_USE_REGISTRY}`);
          }
        } else {
          this.props.history.push(this.props.configProjectRedux.home_uri);
        }
      }, Math.floor(Math.random() * 800) + 200);
    } else {
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        this.props.history.push(ROUTE_PATH.WELL_TERM_OF_USE_REGISTRY);
      }, Math.floor(Math.random() * 800) + 200);
    }
  };

  render() {
    return (
      <FBContainerStyled>
        <Loading />
        <div className="fb_wrap">
          <FacebookLogin
            autoLoad={false}
            isMobile={true}
            disableMobileRedirect={false}
            appId={process.env.REACT_APP_FB_APP_ID}
            callback={this.responseFacebook}
            // redirectUri={process.env.REACT_APP_SELF_ENDPOINT}
            redirectUri={'https://localhost:3000/login/facebook'}
            render={(renderProps) => (
              <div className="btn_fb" onClick={renderProps.onClick}>
                <img
                  className="img_facebook"
                  src={iconImages['icon_face.png']}
                  alt="logo facebook"
                />
                ลงทะเบียนด้วย Facebook
              </div>
            )}
          />
        </div>
      </FBContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FBContainer);

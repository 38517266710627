import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const CookiePermissionWidgetStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 10px;
  z-index: 2;
  .box_cookie {
    margin: auto;
    width: 600px;
    background: #ffffff;
    border: 1px solid #edeaea;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cookie_label {
      font-size: ${FONT.SIZE_14};
      .t_cookie {
        font-weight: bold;
      }
    }
    .btn_cookie {
      cursor: pointer;
      background: ${COLOR.PINK_1};
      font-size: ${FONT.SIZE_14};
      font-weight: bold;
      color: ${COLOR.WHITE_1};
      border-radius: 8px;
      text-align: center;
      padding: 9px;
      a {
        color: ${COLOR.WHITE_1};
      }
    }
  }
`;

import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { SummaryContainerStyled } from './styled';
import { iconSummary, iconImages } from 'assets';
import { ROUTE_PATH } from 'helpers';
import { userService } from 'apiService';
import { BoxLink, Button, LogoLoadingFade, DropDownCustom, Modal, Icons } from 'components';
import moment from 'moment';
import { SendEmailWellForm } from 'forms';
import { setReduxUserAuth } from 'actions';

class SummaryContainer extends React.Component {
  state = { loading: true, summaryHisOptions: [] };

  componentDidMount() {
    this.scrollToTop();
    this.setLoadder();
    if (this.props.location.pathname === ROUTE_PATH.SUMMARY_HISTORY) {
      this.handleSummaryHistory();
    } else {
      this.handleSummaryNow();
    }

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  componentDidUpdate() {
    window.onpopstate = (e) => {
      this.props.history.push('/');
    };
    // console.log('dfdf', this.props); 
  }

  handleSummaryNow = () => {
    const { summary } = this.props;

    if (summary) {
      this.setState({
        screening_id: summary.summary.data.id,
      })
    }

    this.fetchData(summary.summary.data.id);
  };

  fetchData = async (screeningID) => {
    const { authRedux } = this.props;
    const res = await userService.GET_SYMPTOM_SCREENING_BY_ID(
      authRedux.id,
      screeningID
    );
    // console.log('resssssss', res)
    if (res && res.success) {
      this.setState({
        cancersItem: res.data.cancers,
        symptomsItem: res.data.symptoms,
        data: res.data,
        loading: false
      });
    }
  };

  handleSummaryHistory = () => {
    this.fetchDataHistoryList();
  };

  fetchDataHistoryList = async () => {
    const { authRedux } = this.props;
    const { summaryHisOptions } = this.state;
    const res = await userService.GET_SCREENINGS_SUMMARY_LIST(authRedux.id);
    if (res && res.success) {
      // console.log('res', res);
      res.data.map((e, i) =>
        summaryHisOptions.push({
          label: moment(e.created_at)
            .add(543, 'year')
            .locale('th')
            .format('LL H:mm'),
          value: e.id,
        })
      );
      this.fetchData(res.data[0].id);
      this.setState({
        screening_id: res.data[0].id,
        selectDate: {
          key: res.data[0].id,
          name: moment(res.data[0].created_at)
            .add(543, 'year')
            .locale('th')
            .format('LL H:mm'),
        }
      })
    }
  };

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  levelItem = (level) => {
    switch (level) {
      case 'HIGH':
        return 'สูง';
      case 'MEDIUM':
        return 'ปานกลาง';
      case 'LOW':
        return 'ต่ำ';
      default:
        break;
    }
  };

  imgItem = (level) => {
    // console.log(level, Math.floor((level * 100) / 5));
    switch (Math.floor((level * 100) / 5)) {
      case 0:
        return 'low_5.png';
      case 1:
        return 'low_5.png';
      case 2:
        return 'low_10.png';
      case 3:
        return 'low_15.png';
      case 4:
        return 'medium_20.png';
      case 5:
        return 'medium_25.png';
      case 6:
        return 'medium_30.png';
      case 7:
        return 'medium_35.png';
      case 8:
        return 'medium_40.png';
      case 9:
        return 'medium_45.png';
      case 10:
        return 'high_50.png';
      case 11:
        return 'high_55.png';
      case 12:
        return 'high_60.png';
      case 13:
        return 'high_65.png';
      case 14:
        return 'high_70.png';
      case 15:
        return 'high_75.png';
      case 16:
        return 'high_80.png';
      case 17:
        return 'high_85.png';
      case 18:
        return 'high_90.png';
      case 19:
        return 'high_95.png';
      default:
        return '';
    }
  };

  colorItem = (level) => {
    switch (level) {
      case 'HIGH':
        return '#F59E06';
      case 'MEDIUM':
        return '#51B2CA';
      case 'LOW':
        return '#7AAA4A';
      default:
        break;
    }
  };

  descriptionItem = (level) => {
    switch (level) {
      case 'HIGH':
        return 'ควรแจ้งแพทย์ ถ้าคุณมีโรคประจำตัวเหล่านี้ ไขมันพอกตับ เบาหวาน โรคอ้วน ตับแข็ง ไวรัสตับอักเสบบี หรือ ซี เรื้อรัง ';
      case 'MEDIUM':
        return 'แนะนำให้ตรวจเพิ่มเติมด้วยวิธี  stool occult blood, Barrium enema, colonoscopy';
      case 'LOW':
        return 'แม้ผลการสรุปการวิเคราะห์ของคุณแสดงถึงแนวโน้มของอาการที่มีความเสี่ยงระดับน้อย แต่คุณไม่ควรละเลยและควรดูแลสุขภาพของตนเองต่อไป';
      default:
        break;
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  handleClickBackHistory = () => {
    this.props.history.push(ROUTE_PATH.PROFILE_SCREENING);
  };

  handleSelectDateHistory = (e) => {
    const { summaryHisOptions } = this.state

    let result = summaryHisOptions.filter((summary) => {
      return Number(summary.value) === Number(e)
    })

    this.setState({
      loading: true,
      screening_id: e,
      selectDate: {
        key: result[0].value,
        name: result[0].label
      }
    })

    this.fetchData(e)
  }

  handleClickSendEmail = async () => {
    this.setState({
      isModal: true,
      renderModal: (
        <div className="md_container">
          <div className="close_wrap" onClick={this.handleClickCloseModal}>
            <Icons.CloseHerWill />
          </div>
          <SendEmailWellForm handleClickSendEmail={this.sendEmail} />
        </div>
      ),
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  sendEmail = async (callback) => {
    // const { surveyResults } = this.props;
    const { authRedux } = this.props;
    const { values } = this.props.reduxForm;
    const { screening_id } = this.state;

    let params = {
      ...values,
    };

    let res = await userService.POST_WELL_SCREENING_SEND_EMAIL(
      authRedux.id,
      screening_id,
      params
    );

    if (res && res.success) {
      callback(res.data);
      this.handleClickCloseModal();
    } else {
      callback(false);
    }
  };

  render() {
    const { loading, cancersItem, symptomsItem, data, summaryHisOptions, selectDate, isModal, renderModal } = this.state;
    const { pathname } = this.props.location;
    const isHistory = pathname === ROUTE_PATH.SUMMARY_HISTORY;
    return (
      <SummaryContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div className="container">
              <div className="title_header">
                สรุปผลการวิเคราะห์สัญญาณอาการที่อาจบ่งชี้
                และเกี่ยวข้องกับอาการของมะเร็ง
              </div>
              {!isHistory ? (
                <div
                  className="link_layout"
                  onClick={this.handleClickBackFirstPage}
                >
                  <BoxLink
                    theme_standard_box_link
                    // showArrow
                    label="กลับสู่หน้าหลัก"
                  />
                </div>
              ) : (
                <div
                  className="link_layout"
                  onClick={this.handleClickBackHistory}
                >
                  <BoxLink
                    theme_standard_box_link
                    // showArrow
                    label="ย้อนกลับ"
                  />
                </div>
              )}

              <div className="title_header2">Early Sign Report</div>
              {isHistory && (
                <div className="show_dorpdown">
                  <div className="label_name">ผลการตรวจของวันที่ :</div>
                  <div className="drop_layout">
                    <DropDownCustom
                      theme_normal_dropdown
                      label=""
                      options={summaryHisOptions}
                      onChange={(e) => this.handleSelectDateHistory(e)}
                      alignItems="center"
                      width="100%"
                      old_Value={selectDate}
                    />
                  </div>
                </div>
              )}
              <div className="box_layout">
                {cancersItem &&
                  cancersItem.map((e, i) => (
                    <div className="box">
                      <div
                        className="img_box"
                        style={{ color: this.colorItem(e.level) }}
                      >
                        <img
                          className="img_level"
                          src={iconSummary[this.imgItem(e.score)]}
                          alt=""
                        />
                        <div className="text_logo">
                          <img
                            className="img_logo"
                            src={iconImages['w_logo.png']}
                            alt=""
                          />

                          <div>เกี่ยวข้อง</div>
                          <div className="text_score">
                            {this.levelItem(e.level)}
                          </div>
                        </div>
                      </div>
                      <div className="text_box">
                        <div className="text_grey">
                          คุณมีความเกี่ยวข้อง{this.levelItem(e.level)}
                          กับอาการของ
                        </div>
                        <div className="text_blue">{e.name} </div>
                        <div className="text_grey">
                          {this.descriptionItem(e.level)}
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="box_text">
                  <div className="text_title">สรุปอาการของคุณ</div>
                  {symptomsItem &&
                    symptomsItem
                      .filter(
                        (item) => !(item.value !== 'yes' || !item.is_primary)
                      )
                      .map((e, i) => (
                        <div className="row">
                          <img
                            className="img_logo"
                            src={iconImages['img_dot_circle.png']}
                            alt=""
                          />
                          <div className="text_list">{e.name}</div>
                        </div>
                      ))}

                  <div className="result_detail">
                    {data?.gateway?.answers?.length > 0 && (
                      <div>
                        <div className="txt_title_3">
                          อาการนำของคุณเบื้องต้น
                        </div>
                        {data.gateway.answers.map((e, i) => (
                          <div key={i} className="item_list">
                            <img
                              className="img_logo"
                              src={iconImages['img_dot_circle.png']}
                              alt=""
                            />
                            <div className="text_list">
                              {e?.answer_prefix + ' ' + e?.answer_label}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="text1">
                    คำตอบดังกล่าวนี้ ถูกคำนวนโดย A.I. ร่วมกับประวัติ พฤติกรรม
                    ปัจจัยเสี่ยงและอาการร่วมอื่นๆที่เกี่ยวข้อง
                  </div>
                  <div className="text2" style={{ marginTop: '10px' }}>
                    ผลสรุปนี้ ไม่ใช่การวินิฉัย
                  </div>
                  <div className="text2">หรือแทนคำแนะนำของแพทย์</div>
                  <div className="text3">
                    อาการที่เกี่ยวข้องกับมะเร็งสูงไม่ได้แปลว่าคุณเป็นมะเร็ง
                  </div>
                </div>
                {
                  isHistory &&
                  <div className='btn_action_show'>
                    <button className={`btn_pop_email`} onClick={this.handleClickSendEmail}>
                      ส่งไปที่ E-mail ของคุณ
                    </button>
                  </div>
                }
              </div>
              {!isHistory && (
                <>
                  <div className="txt_bottom">
                    <img
                      className="img_logo"
                      src={iconImages['img_dot_circle.png']}
                      alt=""
                    />
                    <div className="text_list">
                      Cancer and Genetic services{' '}
                    </div>
                  </div>
                  <div className="btn_wrap">
                    <Button
                      width="400px"
                      height="55px"
                      theme_gradient
                      borderRadius="20px"
                      handleClickButton={() =>
                        this.props.history.push(ROUTE_PATH.SERVICE)
                      }
                    >
                      บริการทั้งหมดเกี่ยวกับมะเร็ง
                    </Button>
                  </div>
                  <div className='btn_wrap'>
                    <button className={`btn_email`} onClick={this.handleClickSendEmail}>
                      ส่งไปที่ E-mail ของคุณ
                    </button>
                  </div>
                </>
              )}
              {!isHistory ? (
                <div className="btn_back">
                  <Button
                    width="400px"
                    height="55px"
                    theme_gray
                    borderRadius="20px"
                    handleClickButton={this.handleClickBackFirstPage}
                  >
                    กลับสู่หน้าหลัก
                  </Button>
                </div>
              ) : (
                <div className="btn_back">
                  <Button
                    width="400px"
                    height="55px"
                    theme_gray
                    borderRadius="20px"
                    handleClickButton={this.handleClickBackHistory}
                  >
                    ย้อนกลับ
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
        <Modal
          theme_modal_standard
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          {renderModal}
        </Modal>
      </SummaryContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  summary: state.summary,
  reduxForm: state.form.SendEmailWellForm,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryContainer);

import React from 'react';

export default function Call({ color = '#4E5B7E' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.707"
      height="21.794"
      viewBox="0 0 20.707 21.794"
    >
      <g id="phone-receiver-silhouette" transform="translate(3.008) rotate(9)">
        <path
          id="Path_12654"
          data-name="Path 12654"
          d="M16.784,13.382c-1.162-.993-2.34-1.6-3.488-.6l-.685.6c-.5.435-1.433,2.469-5.036-1.676S6.117,6.918,6.619,6.487l.689-.6c1.141-.994.711-2.246-.113-3.534l-.5-.78C5.871.287,4.971-.558,3.827.435l-.618.54A5.8,5.8,0,0,0,.946,4.816c-.413,2.73.889,5.856,3.872,9.286s5.9,5.155,8.658,5.125a5.819,5.819,0,0,0,4.116-1.7l.621-.541c1.141-.992.432-2-.73-3Z"
          transform="translate(-0.872 0)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

import React from 'react';
import { BannerShowStyled } from './styled';
import { iconImages } from 'assets';

export const BannerShow = (props) => {
  return (
    <BannerShowStyled {...props}>
      <div className="col_left">
        <div className="img_wrap">
          <img src={iconImages['logo_question.png']} alt="well logo" />
        </div>
      </div>
      <div className="col_right">
        <div className="title">Well Screening</div>
        <div className="sub_title">
          A.I. อัจฉริยะตรวจปัจจัยเสี่ยงทางด้านมะเร็ง
        </div>
        <div className="sub_title">
          Revolutionize your cancer journey on-hand.
        </div>
      </div>
    </BannerShowStyled>
  );
};

import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { QuestionBeforeBuyContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import { BoxLink, LogoLoadingFade } from 'components';
import { AssessmentForm } from 'forms';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'helpers';

class QuestionBeforeBuyContainer extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.scrollToTop();
    this.setLoadder();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 300);
  };

  handleClickTest = () => {
    const {
      reduxForm: { values, syncErrors },
    } = this.props;

    if (typeof syncErrors === 'object') {
      toast.error('กรุณาตอบคำถามให้ครบทุกข้อ');
    } else {
      if (Object.values(values).includes('true')) {
        ReactGA.event({
          category: 'product_order',
          action: 'not_qualified_product_order',
        });

        this.props.history.push(ROUTE_PATH.ADVICE_DOCTOR);
      } else {
        ReactGA.event({
          category: 'product_order',
          action: 'qualified_product_order',
        });

        this.props.history.push(ROUTE_PATH.BUY_PRODUCT);
      }
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  handleClickBackPage = () => {
    this.props.history.push(ROUTE_PATH.EXAMINE_TEST_KIT);
  };

  render() {
    const { loading } = this.state;

    return (
      <QuestionBeforeBuyContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div
              className="link_back"
              onClick={() => this.handleClickBackPage()}
            >
              <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
            </div>
            <div className="container">
              <AssessmentForm
                handleClickTest={() => this.handleClickTest()}
                handleClickBackFirstPage={() => this.handleClickBackFirstPage()}
              />
            </div>
          </>
        )}
      </QuestionBeforeBuyContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.AssessmentForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionBeforeBuyContainer);

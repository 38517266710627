import styled from 'styled-components';
import { SCREEN } from 'assets';

export const CampaignContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  background-color: #f8f8f8;
  height: 108vh;
  .bg_layout {
    margin-top: -50px;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon_logo {
      margin-top: 100px;
    }
  }
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box_layout {
    margin-top: -120px;
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    // width: 100%;
    min-height: 350px;
    background-color: white;
    border: 2px solid rgba(155, 155, 155, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px;
    .txt_center {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 600px;
      margin-bottom: 2.5rem;
      .txt_normal {
        color: black;
        font-size: 14px;
      }
      .txt_pink {
        color: #e96ea5;
        font-size: 30px;
        width: 520px;
      }
    }
    .btn_end {
      height: 60px;
      width: 350px;
      border-radius: 20px;
      background-image: linear-gradient(to right, #0253a3, #2db5b8);
      border: none;
      display: flex;
      align-items: center;
      outline: none;
      .icon_layout {
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        .icon_btn {
          width: 30px;
          resize: cover;
        }
      }
      .txt_btn {
        color: white;
        font-size: 18px;
        margin-left: 50px;
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    height: 108vh;
    .bg_layout {
      margin-top: -40px;
      .icon_logo {
        margin-top: 70px;
        width: 150px;
      }
    }
    .box_layout {
      width: 70%;
      // width: 100%;
      margin-top: -200px;
      // height: 500px;
      text-align: center;
      .txt_center {
        width: 90%;
        .txt_normal {
          font-size: 12px;
          width: 90%;
        }
        .txt_pink {
          font-size: 22px;
          width: 80%;
        }
      }
      .btn_end {
        width: 250px;
        height: 45px;
        .icon_layout {
          width: 35px;
          height: 35px;
          background-color: white;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          .icon_btn {
            width: 20px;
            resize: cover;
          }
        }
        .txt_btn {
          margin-left: 20px;
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    height: 108vh;
    .bg_layout {
      margin-top: -40px;
      .icon_logo {
        margin-top: 70px;
        width: 150px;
      }
    }
    .box_layout {
      // max-width: 330px;
      width: 100%;
      margin-top: -200px;
      text-align: center;
      .txt_center {
        // width: 260px;
        .txt_normal {
          font-size: 10px;
          // width:240px;
        }
        .txt_pink {
          font-size: 16px;
          width: 220px;
        }
      }
      .btn_end {
        width: 220px;
        height: 45px;
        .icon_layout {
          width: 35px;
          height: 35px;
          background-color: white;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          .icon_btn {
            width: 20px;
            resize: cover;
          }
        }
        .txt_btn {
          margin-left: 20px;
          font-size: 12px;
        }
      }
    }
  }
`;

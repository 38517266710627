import styled from 'styled-components';

export const BannerShowStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  align-items: center;
  padding: 1rem 1.75rem;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border: 1px solid ${({ theme }) => theme.COLORS.BLACK_1}12;
  border-radius: 0.5rem;
  .col_left {
    margin-right: 1rem;
    .img_wrap {
      width: 50px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .col_right {
    .title {
      font-weight: 500;
    }
    .sub_title {
      font-weight: medium;
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    padding: 1rem 0.75rem;
  }
`;

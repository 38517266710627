import styled, { keyframes } from "styled-components";
import { SCREEN } from "assets";
import { fadeInUp } from "react-animations";

const bounceAnimation = keyframes`${fadeInUp}`;
export const TextInputFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  margin-left:10px;

  .layout {
    width: 300px;
    animation: 1s ${bounceAnimation};
    .img_logo {
      width:50px;
      height:50px;
    }
    .txtInput {
      margin-top: 20px;
      margin-bottom: 100px;
      animation: 2s ${bounceAnimation};
    }
    .title_question {
      font-weight: 500;
      font-size: 18px;
      color: #2f4f74;
      margin-top: 40px;
      animation: 1s ${bounceAnimation};
    }
    .btn {
      animation: 2.5s ${bounceAnimation};
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .layout {
      width: 300px;
      .theme_standard {
        width: 300px;
        display: flex;
        justify-content: center;
        .btn_layout {
          width: 300px;
        }
      }
    }
  }
`;

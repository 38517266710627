import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const HospitalListStyled = styled.div`
  /*===============================================
 Container
===============================================*/
  width: 100%;

  /*===============================================
Theme
===============================================*/
  .theme_standard {
    width: 100%;
    font-size: ${FONT.SIZE_16};
    color: ${COLOR.BLUE_2};
    .group_package {
      width: 100%;
      .title {
        font-size: ${FONT.SIZE_16};
        font-weight: 600;
        margin-bottom: 10px;
      }
      .list_package {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 17px;
        .body_package {
          width: 49%;
        }
      }
      .btn2 {
        max-width: 298px;
        width: 100%;
        margin: auto;
        margin-top: 30px;
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .theme_standard {
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .theme_standard {
      .title {
        text-align: center;
      }
      .group_package {
        .title {
          text-align: left;
        }
        .list_package {
          .body_package {
            width: 100%;
            border-bottom: 1px solid rgb(0, 53, 100, 0.2);
            &:last-child {
              border: none;
            }
          }
        }
        .btn2 {
          display: block;
          max-width: unset;
          margin-top: 20px;
        }
      }
    }
    .theme_standard_pink {
      .title {
        text-align: center;
      }
      .group_package {
        .title {
          text-align: left;
        }
        .list_package {
          .body_package {
            width: 100%;
          }
        }
        .btn2 {
          display: block;
          margin-top: 20px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    //   .theme_standard {
    //     .title {
    //       text-align: center;
    //     }
    //     .group_package {
    //       .title {
    //         text-align: left;
    //       }
    //       .list_package {
    //         .body_package {
    //           width: 100%;
    //           border-bottom: 1px solid rgb(0, 53, 100, 0.2);
    //           &:last-child {
    //             border: none;
    //           }
    //         }
    //       }
    //       .btn2 {
    //         display: block;
    //         max-width: unset;
    //         margin-top: 20px;
    //       }
    //     }
    //   }
    //   .theme_standard_pink {
    //     .title {
    //       text-align: center;
    //     }
    //     .group_package {
    //       .title {
    //         text-align: left;
    //       }
    //       .list_package {
    //         .body_package {
    //           width: 100%;
    //         }
    //       }
    //       .btn2 {
    //         display: block;
    //         margin-top: 20px;
    //       }
    //     }
    //   }
  }
`;

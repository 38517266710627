import React from 'react';
import { OrderlistStyled } from './styled';
import cx from 'classnames';
import { iconImages } from 'assets';

export const Orderlist = ({ theme_standard }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <OrderlistStyled>
      <div className={customClass}>
        <div className="title_show">รายการสินค้ารอการชำระ</div>
        <div className="img_show">
          <img
            className="img_scr"
            src={iconImages['bag_gray.png']}
            alt="bag show"
          />
        </div>
        <div className="label_show">ท่านยังไม่มีรายการค้างชำระ ในขณะนี้</div>
      </div>
    </OrderlistStyled>
  );
};

import React from 'react';
import { PackageListStyled } from './styled';
import cx from 'classnames';
import { BoxProduct, BoxPackage, ButtonIcon } from 'components';
import { RenderCommaMoney } from 'helpers';

export const PackageList = ({
  theme_standard,
  theme_standard_pink,
  handleClickBackFirstPage,
  dataCheckup,
  handleClickServiceOrder,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_pink: theme_standard_pink,
  });
  return (
    <PackageListStyled>
      <div className={customClass}>
        <div className="title">แพ็คเกจตรวจคัดกรองมะเร็ง</div>
        <div className="package_show">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={dataCheckup && dataCheckup.redirect_uri}
          >
            <BoxPackage theme_standard_1 dataCheckup={dataCheckup} />
          </a>
        </div>
        <div className="group_package">
          <div className="title">แพ็คเกจทั้งหมด</div>
          <div className="list_package">
            {dataCheckup &&
              dataCheckup.packages.map((e, i) => (
                <div
                  key={i}
                  className="body_package"
                  onClick={() => handleClickServiceOrder(e.id)}
                >
                  <BoxProduct
                    theme_standard_2
                    src={e.image_uri}
                    title={e.name}
                    percent={e.discount_message}
                    price1={`฿ ${RenderCommaMoney(Number(e.full_price))}`}
                    price2={`฿ ${RenderCommaMoney(Number(e.final_price))}`}
                    btnTheme={{ theme_normal: true }}
                  />
                </div>
              ))}

            {/* {
                                data.map((e, i) => (
                                    <div key={i} className="body_package">
                                        <BoxProduct
                                            theme_standard
                                            src={e.src}
                                            title={e.title}
                                            percent={e.percent}
                                            price1={e.price1}
                                            price2={e.price2}
                                        />
                                    </div>
                                ))
                            } */}
          </div>
          <div className="btn2">
            <ButtonIcon
              theme_gray
              lable1Size="16px"
              label1="กลับสู่หน้าแรก"
              handleOnClick={handleClickBackFirstPage}
            />
          </div>
        </div>
      </div>
    </PackageListStyled>
  );
};

// const data = [
//     {
//         src: iconImages['product3.png'],
//         title: "ตรวจคัดกรองมะเร็งปากมดลูกด้วยวิธี Thin prep",
//         percent: "30%",
//         price1: "1,500",
//         price2: "1,290"
//     },
//     {
//         src: iconImages['product5.png'],
//         title: "Ultrasound เต้านมทั้ง 2 ข้าง",
//         percent: "40%",
//         price1: "3,600",
//         price2: "2,000"
//     },
//     {
//         src: iconImages['product6.png'],
//         title: "ตรวจคัดกรองมะเร็งเต้านมและ Ultrasound ทั้ง 2 ข้าง ",
//         percent: "50%",
//         price1: "9,855",
//         price2: "4,500"
//     },
//     {
//         src: iconImages['product7.png'],
//         title: "ตรวจ X-ray ปอดแบบเจาะลึก Low-Dose CT Screening",
//         percent: "30%",
//         price1: "9,030",
//         price2: "6,200"
//     },
//     {
//         src: iconImages['product8.png'],
//         title: "ตรวจค้นหามะเร็งตับ",
//         percent: "40%",
//         price1: "15,755",
//         price2: "8,900"
//     },
//     {
//         src: iconImages['product9.png'],
//         title: "ส่องกล้องลำไส้ใหญ่แบบหลับลึกโดยวิสัญญีแพทย์",
//         percent: "40%",
//         price1: "41,856",
//         price2: "23,500"
//     }
// ]

import styled from 'styled-components';
import { SCREEN, iconImages, COLOR } from 'assets';

export const SummaryNoSymtopmContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background-color: #f8f8f8;
  min-height: calc(100vh - 80px);

  height: auto;
  background-image: url(${iconImages['dot_bg.png']});
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top 0;
  // overflow-y: auto;
  padding: 50px;
  padding-bottom: 0px;
  .loader_layout {
    height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title_header {
    font-weight: 500;
    font-size: 18px;
    color: #2f4f74;
    width: 382px;
    text-align: center;
  }
  .title_header2 {
    font-weight: 300;
    font-size: 18px;
    color: #2f4f74;
    width: 382px;
    text-align: center;
  }
  .box_layout {
    display: flex;
    width: 382px;
    flex-wrap: wrap;
    justify-content: center;
    .box_text {
      width: 382px;
      min-height: 169px;
      height: auto;
      margin: 10px;
      .text_title {
        font-weight: 500;
        font-size: 24px;
        color: #2f4f74;
      }
      .text1 {
        font-weight: 300;
        font-size: 14px;
        color: #2f4f74;
        margin-top: 10px;
      }
      .text2 {
        color: #3b96b7;
        font-weight: 500;
        font-size: 14px;
      }
      .text3 {
        color: #3b96b7;
        font-weight: 300;
        font-size: 14px;
      }
      .row {
        display: flex;
        img {
          width: 10px;
          object-fit: contain;
          margin-right: 10px;
        }
        .text_list {
          font-weight: 300;
          font-size: 14px;
          color: #2f4f74;
        }
      }
    }

    .box {
      width: 382px;
      height: 350px;
      border-radius: 10px;
      border: 2px solid #e7e7e7;
      background-color: #f8f8f8;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
      padding-top: 20px;
      margin: 10px;
      .img_box {
        width: 160px;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .text_header_box {
          color: #2f4f74;
          font-size: 14px;
          width: 250px;
          margin-bottom: 10px;
          margin-top: 30px;

          text-align: center;
        }
        .text_logo {
          font-weight: 300;
          font-size: 18px;
          text-align: center;
          /* position: absolute; */
          width: 160px;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          .text_score {
            font-weight: 500;
            font-size: 19px;
            margin-top: -160px;
            width: 110px;
          }
        }

        .img_level {
          width: 150px;
          object-fit: contain;
        }
      }
      .text_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 70px;
        .text_grey {
          color: #858585;
          font-size: 14px;
          width: 250px;
          text-align: center;
        }
        .text_blue {
          color: #2f4f74;
          font-size: 24px;
          font-weight: 500;
        }
      }
    }
  }
  .txt_grey {
    color: #bebebe;
    font-size: 13px;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #f0f0f0;
    width: 280px;
  }
  .txt_grey2 {
    color: #bebebe;
    font-size: 12px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .txt_bottom {
    display: flex;
    margin: 20px 0 10px 0;
    align-items: center;
    width: 310px;
    img {
      width: 10px;
      object-fit: contain;
      margin-right: 10px;
    }
    .text_list {
      font-weight: 400;
      font-size: 14px;
      color: #2f4f74;
    }
  }
  .result_detail {
    margin: 16px 0px;
    max-width: 382px;
    .txt_title_3 {
      color: #2f4f74;
      font-weight: bold;
      font-size: 1.3rem;
    }
    .text_list {
      font-weight: 400;
      font-size: 14px;
      color: #2f4f74;
    }
    .item_list {
      display: flex;
      margin: 5px 0 10px 0;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 10px;
        height: 10px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
  .btn_service {
    display: flex;
    width: 320;
    align-items: center;
    .icon_btn {
      width: 45px;
      height: 45px;
      background-color: #ffffff;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 25px;
        height: 25px;
      }
    }
    .txt_service {
      width: 238px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .txt_xs {
        font-size: 12px;
      }
    }
  }
  .btn_healthy {
    display: flex;
    width: 382px;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-top: 30px;
    .box_btn {
      width: 72%;
    }

    .btn_right {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 27px;
        margin-bottom: 5px;
      }
      .txt {
        color: #000000;
        font-size: 10px;
      }
    }
  }
  .share {
    position: sticky;
    bottom: 0px;
    flex-shrink: 0;
    width: 382px;
    background-image: ${COLOR.LINEAR_BLUE2};
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    height: 200px;
    padding: 27px 16px;
    font-family: 'Sukhumvit Set';
    font-size: 16px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn_share_show {
      margin-top: 24px;
      display: flex;
      border-radius: 28px;
      border: 1px solid #fff;
      padding: 12px;
      width: 100%;
      color: #3da3c1;
      background: #ffff;
      font-family: 'SukhumvitSet-Bold';
      font-size: 18px;
      justify-content: center;
      cursor: pointer;
      .share_pic {
        flex-shrink: 0;
        width: 33px;
        height: 30px;
        margin-right: 6px;
      }
    }
  }
  .share_panel {
    display: flex;
    .body_icon {
      margin-right: 20px;
      &.no_margin {
        margin-right: 0px;
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .box_layout {
      flex-direction: column;
      width: auto;
    }
    .btn_healthy {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (max-width: ${SCREEN.MINI_TABLET}) {
  }

  @media (max-width: ${SCREEN.PHONE}) {
    width: 100%;
    padding: 20px;
    justify-content: center;
    .box_layout {
      width: 100%;
      .box {
        width: auto;
      }
    }
    .theme_gradient {
      .btn_layout {
        width: 300px;
        height: 60px;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    .btn_healthy {
      width: 100%;
    }
    .share {
      // background: red;
      width: 100vw;
    }
  }
`;

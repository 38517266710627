import React from "react";
import { ColumnButtonAndTextFormStyled } from "./styled";
import { iconImages, COLOR } from "assets";
import { Button } from "../../../components/Button";
import { InputText } from "../../../components/InputText";
import { Field, reduxForm } from "redux-form";
import { LogoLoadingFade } from "components";

class ColumnButtonAndTextForm extends React.Component {
  state = {
    value: null,
    answer: [],
    show_txt_input: false,
    error_text: false,
    text_other: "",
    loading: true,
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  };

  handleClickButton = (e) => {
    const { answer, text_other, show_txt_input } = this.state;
    const { question, handleSubmitAns, type } = this.props;
    let arr_select = [...answer];
    if (question.is_multiple) {
      if (e.is_unknown && arr_select.find((item) => item.id !== e.id)) {
        arr_select = [{ id: e.id }];
      } else {
        if (
          arr_select.find((item) =>
            question.answers.find(
              (item2) => item2.is_unknown && item.id === item2.id
            )
          )
        ) {
          arr_select = [];
        }
        if (arr_select.find((item) => item.id === e.id)) {
          for (let i = 0; i < arr_select.length; i++) {
            if (arr_select[i].id === e.id) {
              arr_select.splice(i, 1);
              if (e.is_unknown) {
                this.setState({
                  error_text: false,
                });
              }
            }
          }
        } else {
          if (e.is_unknown && type === "choice_text") {
            this.setState({
              show_txt_input: !show_txt_input,
              error_text: true,
            });
            arr_select = [...arr_select, { id: e.id, value: text_other }];
          } else {
            arr_select = [...arr_select, { id: e.id }];
          }
        }
      }
    } else {
      if (arr_select && arr_select[0] && arr_select[0].id) {
        arr_select = [];
      } else {
        arr_select = [{ id: e.id }];
      }

    }
    if (e.is_unknown && type === "choice_text") {
      this.setState({
        show_txt_input: !show_txt_input,
      });
    }
    this.setState({
      answer: arr_select,
    });
    if (!question.is_multiple && !(e.is_unknown && type === "choice_text")) {
      handleSubmitAns([{ id: e.id }]);
    }
  };

  handleSubmit = () => {
    const { answer } = this.state;
    const { handleSubmitAns } = this.props;
    handleSubmitAns(answer);
  };

  handleChangeText = (text) => {
    const { answer } = this.state;
    const { question } = this.props;

    let ans = [...answer];
    for (let index = 0; index < ans.length; index++) {
      if (
        question.answers.find(
          (item) => ans[index].id === item.id && item.is_unknown
        )
      ) {
        ans[index] = { id: ans[index].id, value: text };
      }
    }
    if (text.length > 0) {
      this.setState({
        error_text: false,
      });
    } else {
      this.setState({
        error_text: true,
      });
    }
    this.setState({
      answer: ans,
      text_other: text,
    });
  };

  renderQuestion = (e) => {
    const { question, type, scheme } = this.props;
    const { answer } = this.state;
    var render = null;
    
    switch (type) {
      case "choice_2col":
        render = (
          <Button
            theme_border_question
            // width="140px"
            font={e.label.length < 15 ? "14px" : "11px"}
            // width="widthbtn"
            active={answer.find((item) => item.id === e.id) ? true : false}
            handleClickButton={() => this.handleClickButton(e)}
            borderColor={scheme && COLOR.PINK_1}
          >
            {e.label}
          </Button>
        );
        break;

      case "choice_1col":
        render = (
          <Button
            width="200px"
            // height="45px"
            theme_standard={
              answer.find((item) => item.id === e.id) && question.is_multiple
                ? false
                : true
            }
            theme_border_question={
              answer.find((item) => item.id === e.id) && question.is_multiple
                ? true
                : false
            }
            active={true}
            font={e.label.length < 15 ? "14px" : "11px"}
            height="auto"
            padding="10px"
            style={{ marginTop: 10 }}
            handleClickButton={() => this.handleClickButton(e)}
            backgroundColor={e.is_unknown ? "#ffffff" : (scheme ? COLOR.PINK_1 : '')}
            borderColor={scheme && COLOR.PINK_1}
            color={e.is_unknown ? "#000000" : ""}
            weight={e.is_unknown ? "" : "500"}
          >
            {e.label}
          </Button>
        );
        break;

      case "choice_text":
        render = (
          <div
            style={{
              // width: "300px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              theme_border_question
              width="200px"
              font={e.label.length < 15 ? "14px" : "11px"}
              active={answer.find((item) => item.id === e.id) ? true : false}
              borderColor={scheme && COLOR.PINK_1}
              handleClickButton={() => this.handleClickButton(e)}
            >
              {e.label}
            </Button>
          </div>
        );
        break;

      case "choice_1col_wide":
        render = (
          <Button
            theme_border_question
            // width="140px"
            font={e.label.length < 15 ? "14px" : "11px"}
            // width="widthbtn"
            active={answer.find((item) => item.id === e.id) ? true : false}
            handleClickButton={() => this.handleClickButton(e)}
            borderColor={scheme && COLOR.PINK_1}
          >
            {e.label}
          </Button>
        );
        break;
      default:
        break;
    }

    return render;
  };

  render() {
    const { question, type, scheme } = this.props;
    const { answer, show_txt_input, loading, error_text } = this.state;

    return (
      <ColumnButtonAndTextFormStyled type={type}>
        {question && question.answers && (
          <>
            {loading ? (
              <LogoLoadingFade />
            ) : (
              <img
                className="img_logo"
                src={iconImages["logo_question.png"]}
                alt="logo well"
              />
            )}
          </>
        )}
        <div className="title">{question && question.label}</div>
        {question && question.is_multiple && (
          <div className="title2">(เลือกได้หลายตัวเลือก)</div>
        )}
        <div
          className={
            type === "choice_2col"
              ? "button_layout_row"
              : type === "choice_1col"
                ? "button_layout"
                : "button_layout_start"
          }
        >
          {question &&
            question.answers &&
            question.answers.map((e, i) => (
              <div key={i} style={{ marginTop: 10 }}>
                {this.renderQuestion(e)}
              </div>
            ))}
        </div>
        {show_txt_input && (
          <>
            <div style={{ marginTop: 30 }}>
              <Field
                theme_normal
                component={InputText}
                input_width="100%"
                placeholder="โปรดระบุ"
                onChange={(e) => this.handleChangeText(e.target.value)}
              />
            </div>
            {error_text && (
              <div className="txt_error">กรุณากรอกข้อมูลให้ครบถ้วน</div>
            )}
          </>
        )}
        <div className="btn_bottom">
          {((question.is_multiple && answer.length > 0 && !error_text) || (answer.find((item) => item.value))) && (
            <Button
              width="300px"
              height="40px"
              theme_standard
              style={{ marginTop: 30 }}
              handleClickButton={() => this.handleSubmit()}
              backgroundColor={scheme && COLOR.PINK_1}
            >
              ต่อไป
            </Button>
          )}
        </div>
      </ColumnButtonAndTextFormStyled>
    );
  }
}

export default reduxForm({
  form: "ColumnButtonAndTextForm", // a unique identifier for this form
  enableReinitialize: true,
})(ColumnButtonAndTextForm);

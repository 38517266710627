import React from 'react';
import { RadioCustomStyled } from './styled';
import cx from 'classnames';

export const RadioCustom = ({
  theme_circle2,
  options,
  input,
  disabled,
  padding_left,
  margin_bottom,
  padding_top,
  margin_bottom2,
}) => {
  const customClass = cx({
    theme_circle2: theme_circle2,
  });
  return (
    <RadioCustomStyled
      padding_left={padding_left}
      padding_top={padding_top}
      margin_bottom2={margin_bottom2}
    >
      <div className={customClass}>
        <div className="container_wrap">
          <div className="container">
            {options.map((e, i) => (
              <label
                key={i}
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  marginBottom: margin_bottom ? margin_bottom : '0px',
                  alignItems: 'center',
                }}
              >
                <input
                  type="radio"
                  id={e.value}
                  {...input}
                  value={e.value}
                  checked={
                    e.value.toString() === { ...input }.value.toString()
                      ? true
                      : false
                  }
                  disabled={disabled}
                />
                <div className="txt_label">{e.label}</div>
              </label>
            ))}
          </div>
        </div>
      </div>
    </RadioCustomStyled>
  );
};

import React from 'react';
import { BoxSelectDateStyled, CustomInput } from './styled';
import cx from 'classnames';
import DatePicker from 'react-datepicker';

export const BoxSelectDate = ({ theme_standard, input, minDate }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const ExampleCustomInput = ({ value, onClick }) => (
    <CustomInput onClick={onClick}>
      <div className="top_left">
        <div className={`arrow ${value && `select`}`} />
        <div className='label_date'>
          เลือกวันที่
        </div>
        <div className={`arrow ${value && `select`}`} />
      </div>
      <div className="top_right">
        <div>
          {value || 'กรุณาเลือกวันที่'}
        </div>
        <div className='arrow' />
      </div>
    </CustomInput>
  );

  return (
    <BoxSelectDateStyled>
      <div className={customClass}>
        <DatePicker
          inputClassName="my-custom-input"
          dateFormat="dd/MM/yyyy"
          selected={input.value}
          onChange={input.onChange}
          minDate={minDate}
          customInput={<ExampleCustomInput />}
        />
      </div>
    </BoxSelectDateStyled>
  );
};

import styled from "styled-components";
import { iconImages} from "assets";
export const SelectSymptomAreaContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background-color: #f8f8f8;
  height: calc(100vh - 80px);

  background-image: url(${iconImages['dot_bg.png']});
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top 0;
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

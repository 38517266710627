import React, { useEffect, useRef, useState } from 'react';
import { PackageList2Styled } from './styled';
import cx from 'classnames';
import { BoxProduct, BoxPackage, ButtonIcon } from 'components';
import { RenderCommaMoney } from 'helpers';
import Carousel from 'react-multi-carousel';
// import "./WithScrollbar.css";
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const PackageList2 = ({
  theme_standard,
  theme_standard_pink,
  handleClickBackFirstPage,
  dataCheckup,
  handleClickServiceOrder,
  indexArrayData,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_pink: theme_standard_pink,
  });

  const CarouselRef = useRef();
  const [_dataDetail, _setDataDetail] = useState();
  // const [_isLoading, _setIsLoading] = useState(true);

  useEffect(() => {
    if (dataCheckup && CarouselRef && CarouselRef.current) {
      CarouselRef.current.goToSlide(indexArrayData, true);
      _setDataDetail(dataCheckup[indexArrayData]);
      // _setIsLoading(false);
    }
  }, [dataCheckup, CarouselRef, indexArrayData]);

  const selectData = (e) => {
    _setDataDetail(dataCheckup[e]);
  };

  // const CustomDot = ({ index, onClick, active }) => {
  //   return (
  //     <button
  //       onClick={(e) => {
  //         onClick();
  //         e.preventDefault();
  //       }}
  //       className={cx('custom-dot', {
  //         'custom-dot--active': active,
  //       })}
  //     >
  //       {React.Children.toArray(dataCheckup)[index]}
  //     </button>
  //   );
  // };
  const CustomDot = ({ onClick, active, index, carouselState }) => {
    // const { currentSlide } = carouselState;
    return (
      <li>
        <button
          style={{
            borderTopLeftRadius: index === 0 || active ? 4 : 0,
            borderBottomLeftRadius: index === 0 || active ? 4 : 0,
            borderTopRightRadius:
              index === dataCheckup.length - 1 || active ? 4 : 0,
            borderBottomRightRadius:
              index === dataCheckup.length - 1 || active ? 4 : 0,
            height: 8,
            border: 0,
            background: active ? '#CF1A71' : '#CDD5D8',
          }}
          onClick={() => onClick()}
        ></button>
      </li>
    );
  };

  return (
    <PackageList2Styled>
      <div className={customClass}>
        <div className="title">แพ็คเกจตรวจคัดกรองมะเร็ง</div>
        <div className="package_show">
          {dataCheckup && (
            <Carousel
              // infinite
              responsive={responsive}
              ref={CarouselRef}
              showDots
              customDot={<CustomDot />}
              afterChange={(previousSlide, { currentSlide, onMove }) => {
                selectData(currentSlide);
              }}
            >
              {dataCheckup &&
                dataCheckup.map((e, i) => (
                  <div
                    className="box_package_wrapF"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={dataCheckup && dataCheckup.redirect_uri}
                  >
                    <BoxPackage theme_standard_1 dataCheckup={e} />
                  </div>
                ))}
            </Carousel>
          )}
        </div>
        <div className="group_package">
          <div className="title">แพ็คเกจทั้งหมด</div>
          <div className="list_package">
            {_dataDetail &&
              _dataDetail.packages.map((e, i) => (
                <div
                  key={i}
                  className="body_package"
                  onClick={() => handleClickServiceOrder(e.id)}
                >
                  <BoxProduct
                    theme_standard_pink
                    src={e.image_uri}
                    title={e.name}
                    percent={e.discount_message}
                    price1={`฿ ${RenderCommaMoney(Number(e.full_price))}`}
                    price2={`฿ ${RenderCommaMoney(Number(e.final_price))}`}
                    btnTheme={{ theme_pink_2: true }}
                  />
                </div>
              ))}
          </div>
          <div className="btn2">
            <ButtonIcon
              theme_gray
              lable1Size="16px"
              label1="กลับสู่หน้าแรก"
              handleOnClick={handleClickBackFirstPage}
            />
          </div>
        </div>
      </div>
    </PackageList2Styled>
  );
};

// const data = [
//     {
//         src: iconImages['product3.png'],
//         title: "ตรวจคัดกรองมะเร็งปากมดลูกด้วยวิธี Thin prep",
//         percent: "30%",
//         price1: "1,500",
//         price2: "1,290"
//     },
//     {
//         src: iconImages['product5.png'],
//         title: "Ultrasound เต้านมทั้ง 2 ข้าง",
//         percent: "40%",
//         price1: "3,600",
//         price2: "2,000"
//     },
//     {
//         src: iconImages['product6.png'],
//         title: "ตรวจคัดกรองมะเร็งเต้านมและ Ultrasound ทั้ง 2 ข้าง ",
//         percent: "50%",
//         price1: "9,855",
//         price2: "4,500"
//     },
//     {
//         src: iconImages['product7.png'],
//         title: "ตรวจ X-ray ปอดแบบเจาะลึก Low-Dose CT Screening",
//         percent: "30%",
//         price1: "9,030",
//         price2: "6,200"
//     },
//     {
//         src: iconImages['product8.png'],
//         title: "ตรวจค้นหามะเร็งตับ",
//         percent: "40%",
//         price1: "15,755",
//         price2: "8,900"
//     },
//     {
//         src: iconImages['product9.png'],
//         title: "ส่องกล้องลำไส้ใหญ่แบบหลับลึกโดยวิสัญญีแพทย์",
//         percent: "40%",
//         price1: "41,856",
//         price2: "23,500"
//     }
// ]

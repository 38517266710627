import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ConsultGeneticContainerStyled } from './styled';
import { userService } from 'apiService';
import { ROUTE_PATH, calAge } from 'helpers';
import { setReduxUserAuth } from 'actions';
import { setReduxSubjectSelect } from 'actions';
import { ConsultGeneticForm } from 'forms';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  ModalCustom,
  BoxMember,
  BoxLink,
  ButtonIcon,
  LogoLoadingFade,
} from 'components';

class ConsultGeneticContainer extends React.Component {
  state = { isModal: false, questionMode: false, loading: true };

  componentDidMount() {
    this.setLoadder();
    this.scrollToTop();
    this.fetchDataProfile();
    if (
      this.props.location.state &&
      this.props.location.state.type === 'question'
    ) {
      this.setState({ questionMode: true }, this.handleClickOtherPerson);
    }

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchDataProfile = () => {
    const { authRedux } = this.props;
    // console.log('auth Redux', authRedux);
    this.setState({
      dataProfile: authRedux,
      initialValues: {
        email:
          authRedux?.loginWith !== 'line' && authRedux.email
            ? authRedux.email
            : '',
        phone_number: authRedux.phone_number && authRedux.phone_number,
      },
    });
  };

  handleOnClickSubmit = async () => {
    const {
      // authRedux,
      reduxForm: { values, syncErrors },
    } = this.props;
    const { dataProfile } = this.state;
    var day = moment(values.date).format('MM/DD/YYYY');
    var dateStart = new Date(day + '/' + values.time.startTime);
    var dateEnd = new Date(day + '/' + values.time.endTime);
    var startTime = dateStart.toUTCString();
    var endTime = dateEnd.toUTCString();

    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบ');
    } else {
      let begin_at = `${moment(values.date).format(
        'YYYY-MM-DD'
      )} ${startTime.substring(startTime.length - 12, startTime.length - 4)}`;
      let end_at = `${moment(values.date).format(
        'YYYY-MM-DD'
      )} ${endTime.substring(endTime.length - 12, endTime.length - 4)}`;

      let params = {
        phone_number: values.phone_number,
        email: values.email,
        begin_at,
        end_at,
      };

      let res = await userService.POST_GENETIC_APPOINTMENT(
        dataProfile.id,
        params
      );
      if (res && res.success) {
        // Tracking user event
        ReactGA.event({
          category: 'genetic_appointment',
          action: 'confirm_genetic_appointment',
        });
        this.props.history.push(ROUTE_PATH.THANK_YOU_INTEREST);
      }
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  handleClickOtherPerson = async () => {
    let res = await userService.GET_PROFILE_FAILIES();
    if (res && res.success) {
      this.setState({
        families: res.data,
      });
    }
    this.setState({
      isModal: true,
    });
  };

  handleSelectOtherPerson = (e) => {
    const { questionMode } = this.state;
    if (questionMode) {
      const { setReduxSubjectSelect } = this.props;
      setReduxSubjectSelect({
        porfile: 'other',
        profile_id: e.id,
        gender: e.gender,
      });
      this.props.history.push(ROUTE_PATH.PRE_CHECK);
    } else {
      this.setState({
        isModal: false,
        dataProfile: e,
      });
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
    });
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  render() {
    const {
      isModal,
      dataProfile,
      families,
      initialValues,
      questionMode,
    } = this.state;
    const { authRedux } = this.props;
    const { loading } = this.state;

    return (
      <ConsultGeneticContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div className="container">
              {!questionMode && (
                <>
                  <div
                    className="link_back"
                    onClick={() => this.handleClickBackFirstPage()}
                  >
                    <BoxLink theme_standard_box_link label="กลับสู่หน้าหลัก" />
                  </div>
                  <ConsultGeneticForm
                    handleOnClickSubmit={this.handleOnClickSubmit}
                    handleClickBackFirstPage={() =>
                      this.handleClickBackFirstPage()
                    }
                    handleClickOtherPerson={() => this.handleClickOtherPerson()}
                    initialValues={initialValues}
                    dataProfile={dataProfile}
                  />
                  <div className="btn_back">
                    <ButtonIcon
                      theme_gray
                      lable1Size="14px"
                      label1="กลับหน้าแรก"
                      handleOnClick={() => this.handleClickBackFirstPage()}
                    />
                  </div>
                </>
              )}
              <ModalCustom
                theme_ModalCustom_standard
                isShow={isModal}
                handleClickCloseModal={this.handleClickCloseModal}
                cusStyBody={{ maxHeight: '70vh', overflow: 'auto' }}
                notClose={questionMode}
              >
                {families ? (
                  <div className="show_member">
                    <div className="title_show">รายชื่อสมาชิก</div>
                    <div className="group_box">
                      <div className="obj_item">
                        <BoxMember
                          theme_standard
                          divKey={authRedux.id}
                          name={`${authRedux.firstname} ${authRedux.lastname} (ตัวเอง)`}
                          sex={authRedux.gender}
                          age={calAge(authRedux.birthday)}
                          smoking={authRedux.is_smoke === 0 ? 'ไม่สูบ' : 'สูบ'}
                          height={authRedux.height}
                          drink={authRedux.is_alcohol === 0 ? 'ไม่สูบ' : 'สูบ'}
                          weight={authRedux.weight}
                          handleClick={() =>
                            this.handleSelectOtherPerson(authRedux)
                          }
                        />
                      </div>
                      {families.map((e, i) => (
                        <div className="obj_item">
                          <BoxMember
                            theme_standard
                            divKey={e.id}
                            name={`${e.firstname} ${e.lastname}${
                              e.relationship && ` (${e.relationship.name})`
                            }`}
                            sex={e.gender}
                            age={calAge(e.birthday)}
                            smoking={e.is_smoke === 0 ? 'ไม่สูบ' : 'สูบ'}
                            height={e.height}
                            drink={e.is_alcohol === 0 ? 'ไม่สูบ' : 'สูบ'}
                            weight={e.weight}
                            handleClick={() => this.handleSelectOtherPerson(e)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="no_person">
                    <div className="no_person_title">รายชื่อสมาชิก</div>
                    ยังไม่มีข้อมูลบุคคลอื่น
                  </div>
                )}
                <div className="btn_add_family">
                  <ButtonIcon
                    theme_normal
                    lable1Size="18px"
                    label1="+ เพิ่มสมาชิก"
                    handleOnClick={() =>
                      this.props.history.push(
                        `${ROUTE_PATH.PROFILE_FAMILY}?redirectCallbackPath=${ROUTE_PATH.CONSULT_GENETIC}`
                      )
                    }
                  />
                </div>
              </ModalCustom>
            </div>
          </>
        )}
      </ConsultGeneticContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.ConsultGeneticForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxSubjectSelect: (data) => dispatch(setReduxSubjectSelect(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultGeneticContainer);

import React from "react";
import { SelectAreaContainerStyled } from "./styled";
import { iconImagesMale } from "assets";
import { iconImagesFemale } from "assets";
import { BoxLink } from "components";
import { ROUTE_PATH } from "helpers";
// import Loader from "react-loader-spinner";

class SelectAreaContainer extends React.Component {
  state = {
    left: true,
    right: false,
    loading: true
  };

  componentDidMount() {
    this.scrollToTop();
    this.setLoadder();
  }

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickLeft = () => {
    this.setState({
      left: true,
      right: false,
    });
  };

  handleClickRight = () => {
    this.setState({
      left: false,
      right: true,
    });
  };

  handleClickArea = (area) => {
    this.props.handleSelectArea(area);
  };

  handleClickBackPage = () => {
    const { historyLink } = this.props;

    historyLink.push(ROUTE_PATH.HOME_SCREENING);
  };

  render() {
    const { left, right, loading } = this.state;
    const { gender } = this.props;
    return (
      <SelectAreaContainerStyled>
        {loading ? (
          <div className="loader_layout">
            {/* <LogoLoadingFade /> */}
            {/* <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            /> */}
          </div>
        ) : (
            <>
              <div className="area_select">
                <div className="link_back" onClick={() => this.handleClickBackPage()}>
                  <BoxLink theme_standard_box_link label="กลับสู่หน้าหลัก" />
                </div>
                <div className="group_layout">
                  <div className="left_layout">
                    <div className="text_title">
                      คุณมีอาการส่วนไหนของร่างกายที่ ส่งสัญญาณเด่นชัด หรืออยากตรวจ?
              </div>
                  </div>
                  <div className="right_layout">
                    <div className="img_box">
                      {!right ? (
                        <>
                          <div
                            className="head"
                            onClick={() => this.handleClickArea("head")}
                          >
                            <div className="click" />
                            <div className="head_curcle" />
                            <div className="head_tab">
                              <div className="text">ศีรษะ</div>
                              <div className="text">จมูก</div>
                              <div className="text">ตา</div>
                            </div>
                          </div>

                          <div
                            className="neck"
                            onClick={() => this.handleClickArea("neck")}
                          >
                            <div className="neck_curcle" />
                            <div className="click" />
                            <div className="neck_tab">
                              <div className="text">ปาก</div>
                              <div className="text">คอ</div>
                            </div>
                          </div>

                          <div
                            className="chest"
                            onClick={() => this.handleClickArea("chest")}
                          >
                            <div className="chest_curcle" />
                            <div className="click" />
                            <div className="chest_tab">
                              <div className="text"> อก</div>
                              <div className="text"> เต้านม</div>
                              <div className="text"> หัวนม</div>
                            </div>
                          </div>

                          <div
                            className="skin"
                            onClick={() => this.handleClickArea("skin")}
                          >
                            <div className="skin_curcle" />
                            <div className="click" />
                            <div className="skin_tab">
                              <div className="text">ผิวหนัง</div>
                            </div>
                          </div>

                          <div
                            className="stomach"
                            onClick={() => this.handleClickArea("stomach")}
                          >
                            <div className="stomach_curcle" />
                            <div className="click" />
                            <div className="stomach_tab">
                              <div className="text">ท้อง</div>
                              <div className="text">เอว</div>
                            </div>
                          </div>

                          <div
                            className="urethra "
                            onClick={() => this.handleClickArea("urethra")}
                          >
                            <div className="urethra_curcle" />
                            <div className="click" />
                            <div className="urethra_tab">
                              <div className="text" style={{ fontSize: gender === "female" ? "" : 12 }}>ท่อปัสสาวะ</div>
                              {gender === "female" ? (
                                <div className="text">อวัยวะเพศหญิง</div>
                              ) : (
                                  <></>
                                )}
                            </div>
                          </div>
                        </>
                      ) : (
                          <>
                            <div
                              className="bottom "
                              onClick={() => this.handleClickArea("bottom")}
                            >
                              <div className="bottom_curcle" />
                              <div className="click" />
                              <div className="bottom_tab">
                                <div className="text">ทวารหนัก</div>
                                <div className="text">ก้น</div>
                              </div>
                            </div>

                            <div
                              className="back "
                              onClick={() => this.handleClickArea("back")}
                            >
                              <div className="back_curcle" />
                              <div className="click" />
                              <div className="back_tab">
                                <div className="text">หลัง</div>
                                <div className="text">เอว</div>
                              </div>
                            </div>
                          </>
                        )}

                      <img
                        className="img_logo"
                        src={
                          gender === "female"
                            ? iconImagesFemale[
                            left
                              ? "img_davinci_front.png"
                              : "img_davinci_back.png"
                            ]
                            : iconImagesMale[
                            left
                              ? "img_davinci_front.png"
                              : "img_davinci_back.png"
                            ]
                        }
                        alt="logo"
                      />
                    </div>

                    <div className="button">
                      <div
                        className="btn_item"
                        style={{ backgroundColor: left ? "#ffffff" : "#e8e8ea" }}
                        onClick={this.handleClickLeft}
                      >
                        <div className="text_btn">ด้านหน้า</div>
                      </div>
                      <div
                        className="btn_item"
                        style={{ backgroundColor: right ? "#ffffff" : "#e8e8ea" }}
                        onClick={this.handleClickRight}
                      >
                        <div className="text_btn">ด้านหลัง</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </SelectAreaContainerStyled>
    );
  }
}

export default SelectAreaContainer;

import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const BoxLabelStyled = styled.div`
/*===============================================
 Container 
===============================================*/
// width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        width: 100%;
        
        font-size: ${FONT.SIZE_14};
        color: ${COLOR.GRAY_13};
        display: flex;
        .left_layout {
            width: 35%;
            border-top-left-radius: 23px;
            border-bottom-left-radius: 23px;
            border: 1px solid ${COLOR.BLUE_1};
            background: ${COLOR.BLUE_4};
            color: ${COLOR.WHITE_1};
            font-size: ${FONT.SIZE_20};
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            padding 10px;
            .num_label {
                font-size: ${FONT.SIZE_32};
                margin-left: 5px;
            }
        }
        .right_layout {
            width: 65%;
            background: ${COLOR.WHITE_1};
            border-top-right-radius: 23px;
            border-bottom-right-radius: 23px;
            border: 1px solid ${COLOR.BLUE_1};
            padding: 10px 10px 10px 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: ${FONT.SIZE_14};
            .label1 {
                color: ${COLOR.BLACK_3};
            }
            .label2 {
                color: ${COLOR.BLUE_4};
                font-weight: bold;
                font-size: ${FONT.SIZE_20};
            }
            .label3 {
                
            }
        }
    }
`
import React, { useState, useEffect } from "react";
import { DateAntStyled } from "./styled";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
export const DateAnt = ({ placeholder, input, disabled, }) => {
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    setRefresh(false);
    setTimeout(() => {
      setRefresh(true);
    }, 100);
  }, [disabled]);

  return (
    <DateAntStyled>
      <div>
        {refresh && (
          <DatePicker
            placeholder={placeholder}
            format={"DD/MM/YYYY"}
            // disabledDate={disabledDate}
            onChange={input.onChange}
            disabled={disabled}
          />
        )}
      </div>
    </DateAntStyled>
  );
};

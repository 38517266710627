import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'
 
export const BoxMember2Styled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard_1 {
        
        font-size: ${FONT.SIZE_16};
        color: ${COLOR.BLUE_2};
        width: 100%;
        // background: ${COLOR.GRAY_10};
        border: 1px solid ${COLOR.GRAY_12};
        border-radius: 8px;
        padding: 20px;
        display: flex;
        .left_layout_member {
            width: 65%;
            line-height: 1.8;
            .group_label {
                display: flex;
                align-items: baseline;
                .label1 {
                    font-size: ${FONT.SIZE_14};
                    margin-right: 5px;
                    font-weight: 400;
                    min-width: 38px;
                }
                .label2 {
                    font-size: ${FONT.SIZE_16};
                    font-weight: bold;
                    word-break: break-word;
                }
            }
        }
        .right_layout_member {
            width: 35%;
            padding-top: 6px;
            padding-left: 10px;
            display: flex;
            align-items: flex-start;
            .btn {
                padding: 8px;
                font-size: ${FONT.SIZE_14};
                color: ${COLOR.BLUE_4};
                width: 100%;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 1px solid ${COLOR.BLUE_4};
            }
        }
    }
`
import styled from 'styled-components';
import { SCREEN, FONT, COLOR } from 'assets';

export const BuyProductContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background: ${COLOR.GRAY_9};
  min-height: calc(100vh - 87px);

  padding: 20px 0;
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_back {
    padding: 0px 60px 10px 60px;
  }
  .container {
    max-width: 70%;
    margin: auto;
    display: flex;
    .left_order {
      width: 50%;
    }
    .right_order {
      width: 50%;
      padding-top: 86px;
      padding-left: 30px;
    }
  }
  .show_member_container {
    width: 800px;
    &.payment {
      max-width: 440px;
    }
    .title_show {
      font-size: ${FONT.SIZE_18};
      font-weight: 600;
      margin-bottom: 24px;
      text-align: center;
    }
    .group_box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: -20px;
      .obj_item {
        width: 49%;
        margin-bottom: 20px;
      }
    }
  }
  .no_person {
    width: 800px;
    text-align: center;
    .no_person_title {
      font-size: ${FONT.SIZE_18};
      font-weight: 600;
      margin-bottom: 24px;
      text-align: center;
    }
  }
  .btn_add_family {
    padding: 20px 30% 0px 30%;
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .link_back {
    }
    .container {
    }
    .show_member_container {
      width: 600px;
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .link_back {
      padding: 0px 25px 10px 25px;
    }
    .container {
      max-width: 85%;
    }
    .show_member_container {
      width: 600px;
    }
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .link_back {
      padding: 0px 9px 10px 9px;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .link_back {
      padding: 0px 20px 10px 20px;
    }
    .container {
      display: block;
      padding: 0px 15px;
      max-width: 99%;
      .left_order {
        width: 100%;
      }
      .right_order {
        width: 100%;
        padding-top: 24px;
        padding-left: 0px;
      }
    }
    .show_member_container {
      width: 300px;
      .group_box {
        display: block;
        .obj_item {
          width: 100%;
        }
      }
      .title_show {
        &.payment {
          margin-bottom: 0px;
        }
      }
    }
    .no_person {
      width: 300px;
    }
    .btn_add_family {
      padding: 20px 7% 0px 7%;
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    .link_back {
      padding: 0px 20px 10px 20px;
    }
    .container {
      max-width: 99%;
    }
  }
`;

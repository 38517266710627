import React from 'react';
import { HospitalListStyled } from './styled';
import cx from 'classnames';
import { BoxHospital, ButtonIcon } from 'components';

export const HospitalList = ({
  theme_standard,
  handleClickBackFirstPage,
  dataCheckup,
  handleClickServiceOrder,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <HospitalListStyled>
      <div className={customClass}>
        <div className="group_package">
          <div className="list_package">
            {dataCheckup &&
              dataCheckup.map((e, i) => (
                <div
                  key={i}
                  className="body_package"
                // onClick={() => handleClickServiceOrder(e.id)}
                >
                  <BoxHospital
                    theme_standard_2
                    data={e}
                    handleClickServiceOrder={handleClickServiceOrder}
                  />
                </div>
              ))}
          </div>
          <div className="btn2">
            <ButtonIcon
              theme_gray
              lable1Size="16px"
              label1="กลับสู่หน้าแรก"
              handleOnClick={handleClickBackFirstPage}
            />
          </div>
        </div>
      </div>
    </HospitalListStyled>
  );
};

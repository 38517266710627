import React from 'react';
import { PaymentSuccessStyled } from './styled';
import { iconImages } from 'assets';

export const PaymentSuccess = ({ orderID, handleClickCloseModalCustom }) => {
  return (
    <PaymentSuccessStyled>
      <div className="payment_modal_container">
        <div className="icon_close_box" onClick={handleClickCloseModalCustom}>
          <img
            className="img_close"
            src={iconImages['close_gray.png']}
            alt="close icon"
          />
        </div>
        <div className="img_round_box">
          <img src={iconImages['testkit_blue3.png']} alt="pay icon" />
        </div>
        <div className="modal_title">การชำระเงินสำเร็จ</div>
        <div className="txt_content">ยินยันการชำระเงินของคำสั่งซื้อ</div>
        <div className="txt_content mb_13">{`เลขที่ ${orderID}`}</div>
        <div className="txt_recomment">
          ขอบคุณที่ใช้บริการ สินค้าจะถูกจัดส่งถึงท่านภายใน 7 วัน
        </div>
        <div className="btn_modal_next" onClick={handleClickCloseModalCustom}>
          รับทราบ
        </div>
      </div>
    </PaymentSuccessStyled>
  );
};

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'reducers';
import { GlobalStyle } from 'assets';
import theme from 'assets/styledComponent/theme.json';
import { ThemeProvider } from 'styled-components';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Routes />
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;

import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const ServiceContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  font-size: ${FONT.SIZE_18};
  color: ${COLOR.BLUE_2};
  width: 100%;
  position: relative;
  padding: 40px 0px;
  height: calc(100% - 87px);
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_back {
    padding: 0px 60px;
  }
  .content_container {
    max-width: 730px;
    margin: auto;
    z-index: 22;
    .top_layout {
      display: flex;
      margin-bottom: 45px;
      .top_left {
        width: 50%;
        .div1 {
          font-size: ${FONT.SIZE_16};
        }
        .div2 {
          font-weight: bold;
          display: flex;
          align-items: baseline;
          .show_logo {
            width: 35px;
            margin: 0px 5px;
          }
        }
      }
      .top_right {
        width: 50%;
        padding-left: 25px;
        display: flex;
        align-items: center;
        .button_layout {
          max-width: 344px;
        }
      }
    }
    .bottom_layout {
      .group_label {
        position: relative;
        padding-left: 12px;
        margin-bottom: 25px;
        .dot {
          background: ${COLOR.YELLOW_1};
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: absolute;
          top: 9px;
          left: 0;
        }
        .label1 {
          font-weight: 600px;
        }
        .label2 {
          font-size: ${FONT.SIZE_14};
        }
      }
    }
    .group_otp {
      display: flex;
      .left_otp {
        width: 50%;
        .top_left_otp {
          margin-bottom: 22px;
          .button_layout {
          }
        }
        .bottom_left_otp {
          .button_layout {
          }
        }
      }
      .right_otp {
        width: 50%;
        padding-left: 25px;
        .top_right_otp {
          margin-bottom: 22px;
          .button_layout {
          }
        }
        .bottom_right_otp {
          .button_layout {
          }
        }
      }
    }
    .btn_show {
      width: 100%;
      padding-top: 60px;
      display: flex;
      justify-content: center;
      .btn_back {
        width: 50%;
      }
    }
  }
  .bg_img {
    z-index: -1;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
  .dot_img {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .link_back {
      padding: 0px 20px 20px 20px;
    }
    .content_container {
      .top_layout {
        flex-direction: column;
        .top_left {
          margin-bottom: 45px;
        }
        .top_right {
          padding-left: 0;
          .button_layout {
            width: 100%;
            max-width: none;
          }
        }
      }
      .group_otp {
        flex-direction: column;
        .left_otp {
          margin-bottom: 22px;
        }
        .right_otp {
          padding-left: 0;
        }
      }
      .btn_show {
        width: 50%;
        padding-top: 60px;
        display: flex;
        .btn_back {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .link_back {
      padding: 0px 20px 20px 20px;
    }
    .container {
      ppadding: 0px 20px;
    }
  }
  @media (max-width: 700px) {
    .link_back {
      display: none;
    }
    .content_container {
      max-width: 400px;
      margin: auto;
      padding: 20px;
      .top_layout {
        flex-direction: column;
        .top_left {
          width: 100%;
          margin-bottom: 45px;
        }
        .top_right {
          width: 100%;
          padding-left: 0;
        }
      }
      .group_otp {
        flex-direction: column;
        .left_otp {
          width: 100%;
          margin-bottom: 22px;
        }
        .right_otp {
          width: 100%;
          padding-left: 0;
        }
      }
      .btn_show {
        width: 50%;
        padding-top: 60px;
        display: flex;
        .btn_back {
          width: 100%;
        }
      }
    }
    .bg_img {
      display: none;
    }
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .content_container {
      .btn_show {
        width: 100%;
        padding-top: 20px;
        display: flex;
        .btn_back {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .content_container {
      .btn_show {
        width: 100%;
        padding-top: 20px;
        display: flex;
        .btn_back {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    .content_container {
      .btn_show {
        width: 100%;
        padding-top: 20px;
        display: flex;
        .btn_back {
          width: 100%;
        }
      }
    }
  }
`;

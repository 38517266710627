import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { SingUpContainerStyled } from './styled';
import { userService } from 'apiService';
import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { ROUTE_PATH } from 'helpers';
import Loader from 'react-loader-spinner';
import { BannerShow } from 'components';
import {
  BrowserView,
  MobileView,
} from "react-device-detect";

class SingUpContainer extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  responseFacebook = async (e) => {
    let params = {
      provider: 'facebook',
      access_token: e.accessToken,
    };
    const res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      ReactGA.event({
        category: 'login',
        action: 'login_success_facebook',
      });

      localStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: true })
      );
      this.fetchProfileData();
      // this.handleClickNext();
    }
  };

  fetchProfileData = async () => {
    let res = await userService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      this.setState({
        initialValues: { ...res.data, ...res.data.profile },
      });
      let params = {
        ...res.data,
        ...res.data.profile,
      };
      delete params.profile;
      this.props.setReduxUserAuth({ ...params });
      this.handleClickNext();
    }
  };

  handleClickNext = () => {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
      this.props.history.push(`${ROUTE_PATH.REGISTER}`);
    }, Math.floor(Math.random() * 1000) + 200);
  };

  render() {
    const { loading } = this.state;
    const { configProjectRedux } = this.props;
    return (
      // <SingUpContainerStyled src={iconImages['bg_login.png']}>
      <SingUpContainerStyled>
        {/* <div className="layout_left" >
          <div className="top_left_layer">
            <img className="img_show" src={iconImages['fwd3.png']} alt="img" />
          </div>
          <div className="bottom_left_layer">
            <div className="title_show">
              FWD Easy E-CANCER
            </div>
            <div className="detail_show">
              ประกันมะเร็งออนไลน์ คุ้มครอง
            </div>
            <div className="detail_show">
              ทุกมะเร็ง ทุกระยะ ตรวจพบมะเร็ง
            </div>
            <div className="detail_show">
              รับสูงสุด 1 ล้านบาท
            </div>
            <div className="detail_show">
              เบี้ยประกัน คงที่ 5 ปี
            </div>
            <div className="detail_show">
              เบี้ยประกัน เริ่มต้นเพียง 2 บาทต่อวัน
            </div>
            <div className="detail_show no_margin">
              สำหรับเพศชาย อายุ 20 ปี
            </div>
          </div>
        </div> */}
        <div className="layout_right center_mode">
          {loading ? (
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            />
          ) : (
              <>
                <div className="banner_wrap">
                  <BannerShow />
                </div>
                <div className="regis_container">
                  <div className="title">ลงทะเบียน</div>
                  <BrowserView>
                    <FacebookLogin
                      autoLoad={false}
                      // isMobile={true}
                      // disableMobileRedirect={true}
                      appId={process.env.REACT_APP_FB_APP_ID}
                      callback={this.responseFacebook}
                      redirectUri={
                        // process.env.REACT_APP_SELF_ENDPOINT + '/page/login'
                        configProjectRedux?.pinkalert_uri +
                        configProjectRedux?.login_uri
                      }
                      render={(renderProps) => (
                        <button
                          className="btn_regis facebook"
                          onClick={renderProps.onClick}
                        >
                          <img
                            className="img_facebook"
                            src={iconImages['icon_face.png']}
                            alt="btn"
                          />
                      ลงทะเบียนด้วย Facebook
                        </button>
                      )}
                    />
                  </BrowserView>
                  <MobileView>
                    <FacebookLogin
                      autoLoad={false}
                      isMobile={true}
                      disableMobileRedirect={true}
                      appId={process.env.REACT_APP_FB_APP_ID}
                      callback={this.responseFacebook}
                      redirectUri={
                        // process.env.REACT_APP_SELF_ENDPOINT + '/page/login'
                        configProjectRedux?.pinkalert_uri +
                        configProjectRedux?.login_uri
                      }
                      render={(renderProps) => (
                        <button
                          className="btn_regis facebook"
                          onClick={renderProps.onClick}
                        >
                          <img
                            className="img_facebook"
                            src={iconImages['icon_face.png']}
                            alt="btn"
                          />
                      ลงทะเบียนด้วย Facebook
                        </button>
                      )}
                    />
                  </MobileView>
                  <div className="text_between">หรือ</div>
                  <button
                    className="btn_regis email"
                    onClick={this.handleClickNext}
                  >
                    ลงทะเบียนด้วยอีเมล์
                </button>
                </div>
              </>
            )}
        </div>
      </SingUpContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.SignUpForm,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingUpContainer);

import React from "react";
import ReactGA from "react-ga";
import { SuspectedCancerContainerStyled } from "./styled";
import { iconImages } from "assets";
import { iconSelectCancer } from "assets";
import { Button, BoxLink, LogoLoadingFade } from "components";
import { ROUTE_PATH } from "helpers";
import { connect } from "react-redux";

class SuspectedCancerContainer extends React.Component {
  state = {
    btn_select: null,
    loading: true,
    loading_question: false,
  };

  componentDidMount() {
    this.scrollToTop();
    this.setLoadder();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
        loading_question: true,
      });
    }, 1000);
    this.setState({
      loading_question: true,
    });
    setTimeout(() => {
      this.setState({
        loading_question: false,
      });
    }, 2500);
  };

  handleClickToHome = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  handleClickToSelectSymptom = () => {
    this.props.history.push(ROUTE_PATH.SELECT_SYMPTOM);
  };

  handleClickToggle = (label) => {
    this.setState(
      {
        btn_select: label,
      },
      this.handleClickNext
    );
  };

  handleClickNext = () => {
    const { btn_select } = this.state;
    this.props.history.push(ROUTE_PATH.NO_SYMPTOM_QUESTION, btn_select);
  };

  handleClickBackPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  render() {
    const { btn_select, loading, loading_question } = this.state;
    const { authRedux } = this.props;
    return (
      <SuspectedCancerContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div
              className="link_back"
              onClick={() => this.handleClickBackPage()}
            >
              <BoxLink theme_standard_box_link label="กลับสู่หน้าหลัก" />
            </div>
            <div className="group_layout">
              <div className="layout_detail">
                {loading_question ? (
                  <LogoLoadingFade />
                ) : (
                  <img
                    className="img_logo"
                    src={iconImages["logo_question.png"]}
                    alt="logo well"
                  />
                )}

                <div className="title">
                  ในการตรวจในครั้งนี้คุณสงสัยมะเร็งชนิดไหนเป็นพิเศษหรือไม่
                </div>
                <div className="title2">(เลือกได้ 1 ข้อ)</div>
                <div className="question_layout">
                  {data &&
                    data
                      .filter((item) =>
                        authRedux.gender === "male"
                          ? item.label !== "โรคมะเร็งปากมดลูก"
                          : item
                      )
                      .map((e, i) => (
                        <div key={i}>
                          <Button
                            width="190px"
                            // height="60px"
                            theme_cancer
                            style={{ marginTop: 20 }}
                            active={btn_select === e.label}
                            handleClickButton={() =>
                              this.handleClickToggle(e.label)
                            }
                          >
                            <div className="column">
                              <img src={e.img} alt="" />
                              <div className="text_button">{e.label}</div>
                            </div>
                          </Button>
                        </div>
                      ))}
                </div>
                {/* {btn_select && (
                        <Button
                          width="400px"
                          height="50px"
                          backgroundColor="#5495B4"
                          theme_standard
                          style={{ marginTop: 20 }}
                          handleClickButton={this.handleClickNext}
                        >
                          ต่อไป
                        </Button>
                      )} */}
              </div>
            </div>
          </>
        )}
      </SuspectedCancerContainerStyled>
    );
  }
}

const data = [
  {
    label: "โรคมะเร็งเต้านม",
    img: iconSelectCancer["breast.png"],
    value: 1,
  },
  {
    label: "โรคมะเร็งปากมดลูก",
    img: iconSelectCancer["cervix.png"],
    value: 2,
  },
  {
    label: "โรคมะเร็งลำไส้",
    img: iconSelectCancer["liver.png"],
    value: 3,
  },
];

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

export default connect(mapStateToProps)(SuspectedCancerContainer);

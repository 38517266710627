import styled from "styled-components"
import {
    COLOR,
    FONT,
    SCREEN
} from 'assets'

export const BoxOrderStyled = styled.div`
/*===============================================
 Container 
===============================================*/
width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard_1 {
        width: 100%;
        
        font-size: ${FONT.SIZE_16};
        color: ${COLOR.BLUE_2};
        background: ${COLOR.BLUE_6};
        padding: 4px 24px 16px 24px;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        .top_box {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgb(152 152 152 / 0.25);
            margin-bottom: 13px;
            padding: 0px 6px 2px 6px;
            align-items: center;
            height: 44px;
            .left_top {
                display: flex;
                align-items: center;
                .box_img {
                    margin-right: 15px;
                    border: 1px solid ${COLOR.BLUE_7};
                    border-radius: 50%;
                    background: ${COLOR.WHITE_1};
                    width: 26px;
                    height: 26px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .logo_show {
                    height: 13px;
                }
                .title_label {
                    font-weight: 600;   
                }
            }
            .right_top {
                display: flex;
                flex-direction: column;
                align-items: center;
                .label1 {
                    font-size: ${FONT.SIZE_8};
                }
                .amount_label {
                    font-size: ${FONT.SIZE_18};
                    font-weight: 600;   
                }
            }
        }
        .layer_1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left_layer_1 {
                font-size: ${FONT.SIZE_14};
                font-weight: 400;
                width: 70%;
            }
            .right_layer_1 {
                display: flex;
                width: 30%;
                .group_price {
                    width: 100%;
                    display: flex;
                    align-items: baseline;
                    justify-content: flex-end;
                    .show_price {
                        font-size: ${FONT.SIZE_18};
                        font-weight: 600;
                        margin-right: 5px;
                    }
                    .currency_show {
                        font-size: ${FONT.SIZE_7};
                        font-weight: 600;
                    }
                }
                .show_time {
                    font-size: ${FONT.SIZE_8};
                    width: 100%;
                    text-align: center;
                }
                .show_time2 {
                    width: 100%;
                    text-align: center;
                    font-size: ${FONT.SIZE_18};
                }
            }
        }
        .btn {
            margin-top: 18px;
            padding: 8px;
            font-size: ${FONT.SIZE_14};
            font-weight: 600;
            color: ${COLOR.WHITE_1};
            width: 100%;
            background: ${COLOR.BLUE_4};
            border-radius: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-shadow: 6px 7px 11px -8px rgba(88,88,88,0.32);
        }
    }

    @media(max-width: ${SCREEN.TABLET}){
        .theme_standard_1 {
            // background: pink;
        }
    }
    @media(max-width: ${SCREEN.MINI_TABLET}){
        .theme_standard_1 {
            // background: red;
        }
    }
    @media(max-width: ${SCREEN.PHONE}){
        .theme_standard_1 {

        }
    }
`
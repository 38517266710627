import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { CampaignContainerStyled } from './styled';
import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { ROUTE_PATH } from 'helpers';
import { HelmetMetaData } from 'components';
// import HelmetMetaData from '../../../components/HelmetMetaData';
import Loader from 'react-loader-spinner';
import { HerWillContainer } from './SubProjects';
class CampaignContainer extends React.Component {
  state = {
    loading: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { data: nextProps.data };
    } else return null;
  }

  componentDidMount() {
    // this.fetchData();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);

    ReactGA.event({
      category: this.props.configProjectRedux.scheme + '_landing',
      action: this.props.configProjectRedux.scheme + '_view',
    });
  }

  // fetchData = async () => {
  //   const { authRedux } = this.props;
  //   if (authRedux && authRedux.id && authRedux.firstname) {
  //     let res = await userService.POST_LOGIN_WITH_TOKEN();
  //     if (res && res.success) {
  //       this.setState({
  //         canToken: true,
  //       });
  //     } else {
  //       localStorage.clear('tokenList');
  //       this.props.setReduxUserAuth(false);
  //     }
  //   }
  // };

  // handleClickNextBackup = () => {
  //   const { canToken } = this.state;
  //   if (canToken) {
  //     // Tracking user event
  //     ReactGA.event({
  //       category: 'pinkalert_start',
  //       action: 'pinkalert_start_existing_sess',
  //     });
  //     this.setState({
  //       loading: true,
  //     });
  //     setTimeout(() => {
  //       this.setState({
  //         loading: false,
  //       });
  //       this.props.history.push(ROUTE_PATH.TERM_OF_USE);
  //     }, Math.floor(Math.random() * 500) + 100);
  //   } else {
  //     // Tracking user event
  //     ReactGA.event({
  //       category: 'pinkalert_start',
  //       action: 'pinkalert_start_new_sess',
  //     });
  //     this.setState({
  //       loading: true,
  //     });
  //     setTimeout(() => {
  //       this.setState({
  //         loading: false,
  //       });
  //       this.props.history.push(ROUTE_PATH.SING_UP);
  //     }, Math.floor(Math.random() * 500) + 100);
  //   }
  // };

  handleClickNext = () => {
    ReactGA.event({
      category: this.props.configProjectRedux.scheme + '_start',
      action: this.props.configProjectRedux.scheme + '_start_new_sess',
    });
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
      this.props.history.push(
        this.props.configProjectRedux.scheme === 'herwill'
          ? ROUTE_PATH.AUTO_SIGNUP
          : ROUTE_PATH.LOGIN_NEW
      );
    }, Math.floor(Math.random() * 500) + 100);
  };

  renderBody = (key) => {
    switch (key) {
      case 'herwill':
        return (
          <>
            <HelmetMetaData
              title={
                'Herwill | เพื่อเธอ เพื่อชีวิตที่ดีขึ้นของผู้ป่วยมะเร็ง'
              }
              description={
                "แด่ เธอ ผู้หญิงทุกคน วันนี้เราจะร่วมสู้มะเร็งเต้านมไปด้วยกัน คุณสามารถเข้าร่วมโครงการ Her Will ผ่านการตรวจความเสี่ยงตนเองฟรี ที่นี่ https://herwill.wellcancer.com และแชร์ข้อความนี้ต่อให้กับผู้หญิงรอบตัวท่านได้"
              }
              image={
                window.location.protocol + "//"
                + window.location.hostname + (window.location.port ? (':' + window.location.port) : '')
                + '/herwill/herwill_logo_th.png'
              }
              hashtag={"#Herwill"}
            />
            <HerWillContainer handleClickNext={this.handleClickNext} />
          </>
        );
      case 'pinkalert':
        return (
          <>
            <HelmetMetaData
              title={
                'PINK Alert : Check & Share Project : "รู้-เพราะ-รัก" รู้ทัน เพราะมะเร็งเต้านม รักษาได้'
              }
              description={
                'Pink Alert คือ โครงการเพื่อให้คนไทยตระหนักถึงโรคมะเร็งเต้านม โดยให้ความรู้ด้านวัตกรรมการรักษาความเข้าใจด้านโรค การให้กำลังใจ และรณรงค์ให้คุณและคนใกล้ตัวตรวจคัดกรองมะเร็งเต้านมด้วยตัวเอง'
              }
              image={
                process.env.REACT_APP_API_ENDPOINT + '/images/pinkalert.png'
              }
            />
            <div className="bg_layout">
              <img
                className="icon_logo"
                src={iconImages['logo_pink.png']}
                alt="logo well"
              />
            </div>
            <div style={{ padding: '0px 30px' }}>
              <div className="box_layout">
                <div className="txt_center">
                  <div className="txt_normal">
                    Pink Alert คือ โครงการเพื่อให้คนไทยตระหนักถึงโรคมะเร็งเต้านม
                    <span style={{ fontWeight: 'bolder' }}>
                      {
                        ' โดยให้ความรู้ด้านวัตกรรมการรักษาความเข้าใจด้านโรค การให้กำลังใจ '
                      }
                    </span>
                    และรณรงค์ให้คุณและคนใกล้ตัวตรวจคัดกรองมะเร็งเต้านมด้วยตัวเอง
                  </div>
                </div>

                <div className="txt_center">
                  <div className="txt_pink">
                    “ Pink Alert ขอชวนคุณเช็คและแชร์
                    กับการตรวจคัดกรองมะเร็งเต้านม ร่วมกับ Well ”
                  </div>
                </div>

                <div className="txt_center">
                  <div className="txt_normal">
                    <span style={{ fontWeight: 'bolder' }}>
                      {' Well (เวลล์) '}
                    </span>
                    คือเครื่องมือที่ทำให้คุณได้สำรวจสัญญาณเตือนจากร่างกาย
                    รวมถึงปัจจัยเสี่ยงที่อาจก่อให้เกิดโรคมะเร็ง โดยการใช้
                    <span style={{ fontWeight: 'bolder' }}>
                      {' ระบบ A.I.(ปัญญาประดิษฐ์) '}
                    </span>
                    Guideline ประสบการณ์จากผู้เชี่ยวชาญทางการแพทย์
                    และข้อมูลสนับสนุนโดย{' '}
                    <span style={{ fontWeight: 'bolder' }}>
                      {' รพ.มะเร็งอุดร '}
                    </span>
                  </div>
                </div>

                <button className="btn_end" onClick={this.handleClickNext}>
                  <div className=" icon_layout">
                    <img
                      className="icon_btn"
                      src={iconImages['well_logo.png']}
                      alt="logo well"
                    />
                  </div>
                  <div className="txt_btn">เริ่มการตรวจคัดกรองมะเร็ง</div>
                </button>
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };

  render() {
    const { loading } = this.state;
    const { configProjectRedux } = this.props;
    const { scheme } = configProjectRedux;
    return (
      <CampaignContainerStyled src={iconImages['bg_pink.png']}>
        {loading ? (
          <div className="loader_layout">
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            />
          </div>
        ) : (
          this.renderBody(scheme)
        )}
      </CampaignContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignContainer);

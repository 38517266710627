import styled, { keyframes } from "styled-components";
import { SCREEN } from "assets";
import { fadeInUp } from "react-animations";

const bounceAnimation = keyframes`${fadeInUp}`;
export const ColumnButtonAndTextFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  /* width: ${(props) => (props.type === "choice_2col" ? "420px" : "300px")}; */
  max-width: 420px;
  margin: auto;
  // display: flex;
  // flex-direction: column;
  // align-item: center;
  .img_logo {
    width: 50px;
    height: 50px;
  }
  .txt_detail {
    color: #003564;
    font-size: 18px;
    margin-top: 20px;
    max-width: 400px;
  }
  .txt_detail2 {
    color: grey;
    font-size: 16px;
    margin-top: 10px;
    max-width: 400px;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    color: #2f4f74;
    margin: 20px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .title2 {
    font-size: 14px;
    color: #2f4f74;
    margin: -3px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .button_layout {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 420px;
    animation: 2s ${bounceAnimation};
    margin-top: 20px;
  }
  .button_layout_row {
    display: flex;
    width: 420px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    align-self: center;
    animation: 2s ${bounceAnimation};
    margin-top: 20px;
  }
  .button_layout_start {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    animation: 2s ${bounceAnimation};
  }
  .btn_bottom {
    width: 300px;
    height: 100px;
    align-self: center;
    animation: 2s ${bounceAnimation};
  }
  .txt_error {
    color: red;
    font-size: 12px;
    margin-top: 10px;
  }
  .theme_border_question {
    .btn_layout {
      font-size: 13px;
      text-align:center;
      padding: 10px;
      width: ${(props) =>
        props.type === "choice_1col_wide" ? "300px" : "200px"};
      text-align: center;
    }
  }
  .theme_standard {
    .btn_layout {
      font-size: 13px;
      text-align:center;
      height:40px;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    width: 300px;
    padding-left: 10px;
    .button_layout_row {
      display: flex;
      width: 300px;
      font-size: 14px;
    }
    .button_layout {
      display: flex;
      width: 280px;
      font-size: 14px;
      margin-left: 10px;
    }
    .theme_border_question {
      .btn_layout {
        width: ${(props) =>
          props.type === "choice_2col" || props.type === "choice_text"
            ? "130px"
            : props.type === "choice_1col_wide"
            ? "300px"
            : "200px"};
        padding: 10px;
        text-align: center;
      }
    }
  }
`;

import React from 'react';
import { connect } from 'react-redux';
import { PinkAlertRedirectContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import {} from 'components';

class PinkAlertRedirectContainer extends React.Component {
  state = {};

  componentDidMount() {
    const { configProjectRedux } = this.props;
    window.location.replace(configProjectRedux?.pinkalert_uri);
  }

  render() {
    return (
      <PinkAlertRedirectContainerStyled></PinkAlertRedirectContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PinkAlertRedirectContainer);

import React from 'react'
import { BoxLabelStyled } from './styled'
import cx from "classnames";

export const BoxLabel = ({ theme_standard, label1, label2, label3, label4, label5 }) => {
    const customClass = cx({
        "theme_standard": theme_standard
    })
    return (
        <BoxLabelStyled>
            <div className={customClass}>
                <div className="left_layout">
                    {label1} <div className="num_label">{label2}</div>
                </div>
                <div className="right_layout">
                    <div className="label1">
                        {label3}
                    </div>
                    <div className="label2">
                        {label4}
                    </div>
                    <div className="label3">
                        {label5}
                    </div>
                </div>
            </div>
        </BoxLabelStyled>
    )
}
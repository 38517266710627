import styled from 'styled-components';

export const HealthItemType2Styled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  height: 100%;
  padding: 18px 30px 18px 30px;
  border: 1px solid #f395be;
  border-radius: 20px;
  background: #FFFFFF;
  .row_1 {
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .left_col {
      flex-shrink: 0;
      width: 80px;
      height: 80px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      .logo_h {
        object-fit: cover;
      }
    }
    .right_col {
      flex: 1;
      .group_label {

      }
      .btn_call {
        background: #cf1a71;
        cursor: pointer;
        display: flex;
        min-width: 88px;
        border-radius: 22px;
        padding: 4px 26px;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        .btn_txt {
          font-size: 17px;
          font-weight: bold;
          color: white;
        }
      }
    }
  }
`;

import styled from 'styled-components';
import {
  COLOR,
  // FONT
} from 'assets';

export const BoxSelectDateStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/

  .theme_standard {
    width: 100%;
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
`;

export const CustomInput = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 0px 7px 10px 7px;
  margin-bottom: 15px;
  border-bottom: 1px solid #7070703b;
  width: 100%;
  .top_left {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    .label_date {
      margin: 0px 15px;
    }
    .arrow {
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #FFA200;
      &.select {
        display: none;
      }
    }
  }
  .top_right {
    cursor: pointer;
    color: ${COLOR.BLUE_4};
    font-weight: 600;
    width: 100%;
    border: 1px solid ${COLOR.GRAY_11}10;
    padding: 10px;
    text-align: center;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .arrow {
      border: solid #3b96b7;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      cursor: pointer;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      position: absolute;
      top: 16px;
      right: 24px;
    }
  }
`;

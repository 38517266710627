import {
  CONFIG_PROJECT,
  USER_AUTHEN,
  SUBJECT_SELECT,
  SUMARY_QUESTION,
  ORDER_PRODUCT,
  ADDRESS_SHIPPING,
  SUMARY_QUESTION_NO_SYMPTOM,
  HOSPITAL_LIST,
  COOKIE_PERMISSION,
} from './actionTypes';

export function setReduxConfigProject(data) {
  return {
    type: CONFIG_PROJECT,
    data,
  };
}

export function setReduxUserAuth(data) {
  return {
    type: USER_AUTHEN,
    data,
  };
}

export function setReduxSubjectSelect(data) {
  return {
    type: SUBJECT_SELECT,
    data,
  };
}

export function setReduxHospitalList(data) {
  return {
    type: HOSPITAL_LIST,
    data,
  };
}

export function setReduxSummaryQuestionNoSymptom(data) {
  return {
    type: SUMARY_QUESTION_NO_SYMPTOM,
    data,
  };
}

export function setReduxSummaryQuestion(data) {
  return {
    type: SUMARY_QUESTION,
    data,
  };
}

export function setReduxOrderProduct(data) {
  return {
    type: ORDER_PRODUCT,
    data,
  };
}

export function setReduxAddressShipping(data) {
  return {
    type: ADDRESS_SHIPPING,
    data,
  };
}

export function setReduxCookiePermission(data) {
  return {
    type: COOKIE_PERMISSION,
    data,
  };
}

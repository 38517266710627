import styled from "styled-components"
import {
    COLOR,
    FONT,
    SCREEN
} from 'assets' 

export const PenddingListStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        width: 100%;
        
        font-size: ${FONT.SIZE_20};
        color: ${COLOR.BLUE_2};
        font-weight: 600;
        .title {
            margin-bottom: 20px;
        }
        .show_order {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: -4px;
            .box_show {
                width: 49%;
                margin: 5px 0px;
            }
        }
    }

    @media(max-width: ${SCREEN.TABLET}){
        .theme_standard {
            // background: pink;
        }
    }
    @media(max-width: ${SCREEN.MINI_TABLET}){
        .theme_standard {
            // background: red;
        }
    }
    @media(max-width: ${SCREEN.PHONE}){
        .theme_standard {
            padding: 0px 15px;
            .title {
                text-align: center;
            }
            .show_order {
                width: 100%;
                .box_show {
                    width: 100%;
                }
            }
        }
    }
`
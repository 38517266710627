import styled from 'styled-components';
import { SCREEN, iconImages, iconBackground, COLOR } from 'assets';

export const SurveyResultsContainerStyled = styled.div`
  /*===============================================
 Container
===============================================*/

  /*===============================================
Theme
===============================================*/
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  height: calc(100vh - 80px);
  background-image: url(${iconImages['dot_bg.png']});
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top 0;
  overflow-y: auto;
  .header_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  .txt_title {
    color: #003564;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 5px;
    text-align: center;
  }
  .txt_title_2 {
    color: #003564;
    font-size: 1.5rem;
    margin-top: 5px;
    text-align: center;
  }
  .date_show {
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    color: #7e7e7e;
  }
  .txt_title_3 {
    color: #003564;
    font-size: 1.3rem;
    margin-top: 15px;
    font-weight: 600;
    text-align: left;
  }
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .remark_show {
    margin-left: 30px;
    margin-bottom: 16px;
    margin-top: 16px;
    font-size: 0.9375rem;
    max-width: 400px;
    display: flex;
    color: #575959;
    font-weight: 500;
    .text_detail {
      margin-left: 10px;
    }
  }
  .result_detail {
    margin-left: 30px;
    margin-bottom: 16px;
    .text_list {
      font-weight: 400;
      font-size: 14px;
      color: #2f4f74;
    }
    .item_list {
      display: flex;
      margin: 5px 0 10px 0;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 10px;
        height: 10px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
  .box_detail {
    max-width: 400px;
    border-radius: 20px;
    margin-top: 30px;
    margin-left: 5%;
    margin-right: 5%;
    // border: 2px solid rgba(155, 155, 155, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
    background-image: url(${iconBackground['summary_box.png']});
    background-size: cover;
    .txt_title_box {
      color: #003564;
      font-size: 1rem;
      text-align: center;
      .hight_light {
        font-weight: bold;
      }
    }
    .circle_box {
      padding-top: 30px;
      .circle {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 8px solid #f59e06;
        box-shadow: 2px 1px 6px 0px rgba(0, 0, 0, 0.2),
          0 4px 20px 0 rgba(0, 0, 0, 0.19);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background: #f8f8f8;
        background-image: linear-gradient(
          to top,
          #f7f7f7,
          #f3f3f3,
          #eeeeee,
          #eaeaea,
          #e6e6e6
        );
        padding: 5px;
        &.green {
          border-color: #01b04f;
        }
        &.blue {
          border-color: #01b0ef;
        }
        &.blue_dark {
          border-color: #0070bf;
        }
        &.orange {
          border-color: #f59e06;
        }
        &.red {
          border-color: #d0021b;
        }
        .icon_btn {
          width: 45px;
          margin-bottom: 10px;
        }
        .txt_circle {
          color: black;
          font-size: 16px;
          font-weight: 700;
          /* margin-top: 10px; */
          text-align: center;
          line-height: 1.3;
          word-break: break-all;
          &.green {
            color: #01b04f;
          }
          &.blue {
            color: #01b0ef;
          }
          &.blue_dark {
            color: #0070bf;
          }
          &.orange {
            color: #f59e06;
          }
          &.red {
            color: #d0021b;
          }
        }
      }
    }
    .txt_detail {
      margin-top: 26px;
      text-align: center;
      font-size: 1rem;
      font-weight: 400;
      color: #575959;
      .hight_light {
        font-weight: bold;
      }
      &.mt {
        margin-top: 30px;
      }
    }
    .txt_detail2 {
      text-align: center;
      font-size: 1.375rem;
      font-weight: 400;
      color: #575959;
      .hight_light {
        font-weight: bold;
      }
      &.mt {
        margin-top: 30px;
      }
    }
    .txt_end {
      color: #575959;
      font-size: 1rem;
      margin-top: 4px;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
  .btn_next {
    width: 100%;
    height: 45px;
    margin-top: 16px;
    background-color: #3b96b7;
    &.herwill {
      background-color: ${COLOR.PINK_1};
    }
    font-size: 1rem;
    border: none;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }

  .md_container {
    background: #ffffff;
    min-width: 344px;
    width: 100%;
    text-align: center;
    padding: 30px 20px;
    border-radius: 8px;
    .close_wrap {
      cursor: pointer;
      position: absolute;
      top: 9px;
      right: 8px;
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .txt_title {
      color: #003564;
      font-size: 1.2rem;
      font-weight: 600;
      margin-top: 5px;
      text-align: center;
    }
    .txt_title_2 {
      color: #003564;
      font-size: 1.2rem;
      margin-top: 5px;
      text-align: center;
    }
    .txt_title_3 {
      color: #003564;
      font-size: 1rem;
      margin-top: 15px;
      font-weight: 600;
      text-align: left;
    }
    .box_detail {
      .circle_box {
        padding-top: 30px;
        .circle {
          width: 130px;
          height: 130px;
          padding: 0px;
          .txt_circle {
            /* margin-top: 20px; */
            font-size: 15px;
          }
        }
      }
      .txt_title_box {
        color: #003564;
        font-size: 1rem;
        font-weight: 400;
      }
      .txt_detail {
        /* font-size: 14px;
        width: 240px; */
      }
      .txt_end {
        font-size: 11px;
        margin-top: 10px;
      }
    }
    .btn_next {
      width: 100%;
      height: 45px;
      font-size: 1rem;
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .box_detail {
      padding: 20px;
      .circle_box {
        padding-top: 30px;
        .circle {
          width: 130px;
          height: 130px;
          padding: 0px;
          .txt_circle {
            /* margin-top: 20px; */
            font-size: 15px;
          }
        }
      }
      .txt_title_box {
        color: #003564;
        font-size: 1rem;
        font-weight: 400;
      }
      .txt_detail {
        /* font-size: 13px;
        width: 240px; */
      }
      .txt_end {
        font-size: 11px;
        margin-top: 10px;
      }
    }
    .btn_next {
      width: 100%;
      height: 45px;
      font-size: 1rem;
    }
  }
`;

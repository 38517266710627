import React from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { AddProfileFamilyContainerStyled, LoaderWrapStyled } from "./styled";
import { userService } from "apiService";
import { ROUTE_PATH } from "helpers";
import { setReduxUserAuth } from "actions";
import { ScreeningProfileForm } from "forms";
import moment from "moment";
import { LogoLoadingFade } from "components";
import queryString from "query-string";

class AddProfileFamilyContainer extends React.Component {
  state = {
    initialValues: {},
    edit_mode: true,
    option_relationship: [],
    relationship: "",
    section: "",
    familyID: "",
    relationship_key: "",
    loading: true,
  };

  componentDidMount() {
    this.checkSection();
    this.scrollToTop();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  checkSection = () => {
    const search = this.props.location.search; // could be '?foo=bar'
    const familyID = this.props.location.state && this.props.location.state.id;
    const params = new URLSearchParams(search);
    const section = params.get("section");

    this.setState(
      {
        section: section,
        family_profile: this.props.location.state,
        familyID: familyID,
      },
      this.fetchData
    );
  };

  fetchData = async () => {
    const { section, family_profile } = this.state;
    const res = await userService.RELATIONSHIP_KEY();
    if (res && res.success) {
      this.setState({
        option_relationship: res.data,
      });
    }
    if (section === "edit") {
      this.setState({
        loading: false,
        initialValues: {
          firstname: family_profile.firstname,
          lastname: family_profile.lastname,
          weight: family_profile.weight,
          height: family_profile.height,
          birthday: family_profile.birthday,
          disease: family_profile.disease,
          gender: family_profile.gender,
          is_alcohol: family_profile.is_alcohol,
          is_smoke: family_profile.is_smoke,
          relationship_key: family_profile.relationship_key,
        },
        relationship: family_profile.relationship,
        relationship_key: family_profile.relationship_key,
      });
    } else {
      this.setState({
        loading: false,
        initialValues: {
          firstname: "",
          lastname: "",
          weight: "",
          height: "",
          birthday: "",
          disease: "",
          gender: "",
          is_alcohol: "",
          is_smoke: "",
        },
      });
    }
  };

  handleSubmitForm = async () => {
    const { section, relationship_key, familyID } = this.state;
    const { redirectCallbackPath } = queryString.parse(
      this.props.location.search
    );
    const {
      reduxForm: { values },
    } = this.props;
    let params = {
      ...values,
      relationship_key: relationship_key,
    };
    let birthday = moment(values.birthday).format("YYYY-MM-DD");
    delete params.birthday;
    params = {
      ...params,
      birthday,
    };
    if (section === "edit") {
      const res = await userService.PUT_EDIT_FAMILY(familyID, params);
      if (res && res.success) {
        // Tracking user event
        ReactGA.event({
          category: "profile",
          action: "edit_family_profile",
        });

        this.props.history.push(ROUTE_PATH.PROFILE_SCREENING);
      }
    } else {
      const res = await userService.POST_PROFILE_FAILIES(params);
      if (res && res.success) {
        // Tracking user event
        ReactGA.event({
          category: "profile",
          action: "create_family_profile",
        });
        if (redirectCallbackPath) {
          this.props.history.push(redirectCallbackPath);
        } else {
          this.props.history.push(ROUTE_PATH.PROFILE_SCREENING);
        }
      }
    }
  };

  handleChangeRelation = (item) => {
    this.setState({
      relationship_key: item,
    });
  };

  render() {
    const {
      initialValues,
      loading,
      option_relationship,
      relationship,
      section,
    } = this.state;
    const { authRedux } = this.props;
    return loading ? (
      <LoaderWrapStyled>
        <LogoLoadingFade />
        {/* <Loader
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={500}
          className="lodding"
        /> */}
      </LoaderWrapStyled>
    ) : (
      <AddProfileFamilyContainerStyled>
        <div className="layout_form">
          <div className="user_name">
            {section === "edit"
              ? "แก้ไขโปรไฟล์สมาชิกของคุณ"
              : "เพิ่มสมาชิกของคุณ"}
          </div>
          <ScreeningProfileForm
            initialValues={initialValues}
            handleSubmitForm={this.handleSubmitForm}
            authRedux={authRedux}
            edit_mode={true}
            relationship={relationship}
            option_relationship={option_relationship}
            handleChangeRelation={this.handleChangeRelation}
            family
          />
        </div>
      </AddProfileFamilyContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.ScreeningProfileForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProfileFamilyContainer);

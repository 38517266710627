import styled from 'styled-components';
import { SCREEN, COLOR, FONT } from 'assets';

export const PaymentPopupStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  .btn_container {
    display: flex;
    justify-content: center;
  }
  .main_container {
    width: 100%;
    max-width: 388px;
    border-radius: 12px;
    background: white;
    padding: 17px 17px;
    position: relative;
    .img_round_box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 43px;
      height: 43px;
      border: 1px solid #0c96b7;
      border-radius: 43px;
      margin: auto;
      margin-bottom: 6px;
      img {
        width: 25px;
        height: 22.5px;
      }
    }
    .modal_title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 16px;
      text-align: center;
    }
    .icon_close_box {
      position: absolute;
      top: 14px;
      right: 34px;
      padding: 5px;
      cursor: pointer;
      .img_close {
        width: 12px;
        height: 12px;
      }
    }
    .container_select_payment {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -4px;
      .group_box {
        width: calc(50% - 8px);
        margin: 0 4px;
        margin-bottom: 9px;
        .payment_item_wrap {
          text-align: center;
          border: 1px solid rgb(152, 152, 152, 0.1);
          padding: 10px;
          cursor: pointer;
          border-radius: 8px;
          width: 100%;
          background: #f7f7f7;
          font-size: ${FONT.SIZE_14};
          color: ${COLOR.BLUE_7};
          // margin-bottom: 9px;
          .image_layout {
            text-align: center;
            .payment_img {
              height: 26px;
              width: auto;
            }
          }
          .image_layout_hide {
            height: 26px;
          }
        }
        img.icon_img {
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .main_container {
      .container_select_payment {
        width: 100%;
        overflow: auto;
        .h1_text {
          font-size: 17px;
        }
        .btn_layout {
          flex-direction: column;
          .group_box {
            width: 100%;
            margin-bottom: 10px;
            .payment_item_wrap1 {
              margin: auto;
              width: 100%;
            }
            .payment_item_wrap2 {
              margin: auto;
              margin-bottom: 10px;
              width: 100%;
            }
            .payment_item_wrap3 {
              margin: auto;
              width: 100%;
            }
          }
          .image_layout_hide {
            display: none;
          }
        }
      }
    }
  }
`;

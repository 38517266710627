import React from 'react';
import { CookiePermissionWidgetStyled } from './styled';
import { useDispatch } from 'react-redux';
import { setReduxCookiePermission } from 'actions';

const CookiePermissionWidget = () => {
  const dispatch = useDispatch();

  const _handleClickAcceptCookie = () => {
    dispatch(setReduxCookiePermission(true));
  };

  return (
    <CookiePermissionWidgetStyled>
      <div className="box_cookie">
        <div className="cookie_label">
          <div className="t_cookie">เว็บไซต์นี้ใช้คุ้กกี้</div>
          <div className="b_cookie">
            เราใช้คุ้กกี้เพื่อเพิ่มประสิทธิภาพ และประสบการณ์ที่ดี
            ในการใช้งานเว็บไซต์
          </div>
        </div>
        <div className="btn_cookie" onClick={_handleClickAcceptCookie}>
          ยอมรับทั้งหมด
        </div>
      </div>
    </CookiePermissionWidgetStyled>
  );
};

export default CookiePermissionWidget;

import React from 'react';
import { LogoLoadingFade2Styled } from './styled';
import { iconLogo } from 'assets';

export const LogoLoadingFade2 = () => {
  return (
    <LogoLoadingFade2Styled>
      <img
        className="logo_line_out"
        src={iconLogo['well_logo_circle_outer_black.png']}
        alt=""
      />
      <img
        className="logo_line_in"
        src={iconLogo['well_logo_circle_inner_black.png']}
        alt=""
      />
      <img className="log_char" src={iconLogo['well_square_transparent_black.svg']} alt="" />
    </LogoLoadingFade2Styled>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { ConsultGeneticFormStyled } from './styled';
import { iconImages } from 'assets';
import { VALIDATE, calAge } from 'helpers';
import {
  BoxIconLabel2,
  BoxMember2,
  InputText,
  BoxSelectDate,
  BoxSelectTime2,
  ButtonIcon,
} from 'components';
import moment from 'moment';
class ConsultGeneticForm extends React.Component {
  state = {};

  render() {
    const {
      handleOnClickSubmit,
      // handleClickBackFirstPage,
      handleClickOtherPerson,
      dataProfile,
      hasPhoneNumber,
      hasEmail,
      hasDate,
      hasTime,
    } = this.props;
    return (
      <ConsultGeneticFormStyled>
        <div className="title">
          ปรึกษาการตรวจรหัสพันธุกรรม
        </div>
        <div className="group_body">
          <div className="left_layout">
            <div className="top_left">
              <BoxIconLabel2
                theme_standard
                src={iconImages['toffy2.png']}
                label="ที่ปรึกษาทางด้าน Genetics จะติดต่อท่านกลับภายในสองวันเพื่อให้ความรู้และข้อมูลเพิ่มเติม"
              />
            </div>
            <div className="box_profile">
              <div className="title_profile">ข้อมูลของท่าน</div>
              {dataProfile && (
                <BoxMember2
                  theme_standard_1
                  name={`${dataProfile.firstname} ${dataProfile.lastname}`}
                  sex={dataProfile.gender}
                  age={calAge(dataProfile.birthday)}
                  handleClick={handleClickOtherPerson}
                  showButton
                />
              )}
            </div>
            <div className="box_input">
              <div className="group_input">
                <div className="label">เบอร์โทรศัพท์</div>
                <Field
                  theme_normal
                  name="phone_number"
                  component={InputText}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              <div className="group_input no_margin">
                <div className="label">อีเมลล์</div>
                <Field
                  theme_normal
                  name="email"
                  component={InputText}
                  validate={VALIDATE.EMAIL}
                />
              </div>
            </div>
          </div>
          <div className="right_layout">
            <div className="showLabel">ท่านสะดวกให้ติดต่อกลับเวลาใด</div>
            <div className="body_show">
              <div className='date_show'>
                <Field
                  theme_standard
                  name="date"
                  minDate={new Date(moment().add(1, 'days'))}
                  component={BoxSelectDate}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              <div className='time_show'>
                <Field
                  theme_standard
                  options={optionTime}
                  name="time"
                  component={BoxSelectTime2}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
            </div>
            <div className="btn">
              <ButtonIcon
                theme_normal
                lable1Size="16px"
                label1="ต่อไป"
                handleOnClick={handleOnClickSubmit}
                disabled={(hasPhoneNumber && hasEmail && hasDate && hasTime) ? false : true}
              />
            </div>
            {/* {hasPhoneNumber && hasEmail && hasDate && hasTime && (
              <div className="btn">
                <ButtonIcon
                  theme_normal
                  lable1Size="16px"
                  label1="ต่อไป"
                  handleOnClick={handleOnClickSubmit}
                />
              </div>
            )} */}
          </div>
        </div>
      </ConsultGeneticFormStyled>
    );
  }
}

const optionTime = [
  {
    label: '09:00 - 12.00',
    value: { startTime: '09:00:00', endTime: '12:00:00' },
  },
  {
    label: '12.00 - 13.00',
    value: { startTime: '12:00:00', endTime: '13:00:00' },
  },
  {
    label: '13.00 - 16.00',
    value: { startTime: '13:00:00', endTime: '16:00:00' },
  },
  {
    label: '16.00 - 19.00',
    value: { startTime: '16:00:00', endTime: '19:00:00' },
  },
];

const selector = formValueSelector('ConsultGeneticForm');
ConsultGeneticForm = connect((state) => {
  const hasPhoneNumber = selector(state, 'phone_number');
  const hasEmail = selector(state, 'email');
  const hasDate = selector(state, 'date');
  const hasTime = selector(state, 'time');
  return {
    hasPhoneNumber,
    hasEmail,
    hasDate,
    hasTime,
  };
})(ConsultGeneticForm);

export default reduxForm({
  form: 'ConsultGeneticForm',
  enableReinitialize: true,
})(ConsultGeneticForm);

import styled from 'styled-components';
import { SCREEN } from 'assets';
import { iconImages } from 'assets';

export const SummaryServiceHistoryContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background-color: #f8f8f8;
  min-height: calc(100% - 87px);

  background-image: url(${iconImages['dot_bg.png']});
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top 0;
  overflow-y: auto;
  padding: 3%;
  position: relative;
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_layout {
    position: absolute;
    top: 30px;
    right: 33px;
  }
  .container {
    width: 100%;
    max-width: 800px;
    margin: auto;
    .title_header {
      font-weight: 500;
      font-size: 18px;
      color: #2f4f74;
      width: 400px;
      margin: auto;
      text-align: center;
    }
    .title_header2 {
      font-weight: 300;
      font-size: 18px;
      color: #2f4f74;
      width: 400px;
      margin: auto;
      text-align: center;
    }
    .show_dorpdown {
      display: flex;
      justify-content: flex-end;
      padding: 10px 0px;
      align-items: center;
      .label_name {
        margin-right: 10px;
      }
      .drop_layout {
        width: 30%;
      }
    }
    .box_layout {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .box_text {
        width: 100%;
        min-height: 169px;
        height: auto;
        margin: 10px;
        .text_title {
          font-weight: 500;
          font-size: 24px;
          color: #2f4f74;
        }
        .text1 {
          font-weight: 300;
          font-size: 14px;
          color: #2f4f74;
          margin-top: 10px;
        }
        .text2 {
          color: #3b96b7;
          font-weight: 500;
          font-size: 14px;
        }
        .text3 {
          color: #3b96b7;
          font-weight: 300;
          font-size: 14px;
        }
        .row {
          display: flex;
          img {
            width: 10px;
            height: 10px;
            object-fit: contain;
            margin-right: 10px;
          }
          .text_list {
            font-weight: 300;
            font-size: 14px;
            color: #2f4f74;
          }
        }
      }

      .box {
        width: 380px;
        min-height: 169px;
        max-height: 200px;
        height: auto;
        border-radius: 10px;
        border: 2px solid #e7e7e7;
        background-color: #f8f8f8;
        display: flex;
        padding: 15px;
        padding-top: 20px;
        margin: 10px;
        .img_box {
          width: 160px;
          height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: -10px;
          margin-top: -15px;
          .text_logo {
            font-weight: 300;
            font-size: 18px;
            text-align: center;
            margin-top: -100px;
            .img_logo {
              width: 20px;
              object-fit: contain;
            }
            .text_score {
              font-weight: 500;
              font-size: 19px;
              margin-top: -5px;
            }
          }

          .img_level {
            width: 120px;
            object-fit: contain;
          }
        }
        .text_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          .text_grey {
            color: #858585;
            font-size: 12px;
            width: 220px;
          }
          .text_blue {
            color: #2f4f74;
            font-size: 24px;
            font-weight: 500;
          }
        }
      }
    }
    .txt_bottom {
      display: flex;
      margin: 20px 0 10px 0;
      justify-content: center;
      img {
        width: 10px;
        height: 10px;
        object-fit: contain;
        margin-right: 10px;
      }
      .text_list {
        font-weight: 400;
        font-size: 14px;
        color: #2f4f74;
      }
    }
    .btn_wrap {
      margin: auto;
      width: fit-content;
    }
    .btn_back {
      margin: auto;
      width: fit-content;
      display: none;
    }
  }

  @media (max-width: 800px) {
    .container {
      .box_layout {
        flex-direction: column;
        width: auto;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .container {
      .show_dorpdown {
        align-items: flex-start;
        .label_name {
          margin-bottom: 8px;
        }
        .drop_layout {
          width: 44%;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .link_layout {
      display: none;
    }
    .container {
      width: 100%;
      .title_header {
        font-size: 16px;
        width: 100%;
      }
      .title_header2 {
        font-size: 14px;
        width: 100%;
        margin-bottom: 20px;
      }
      .show_dorpdown {
        // justify-content: center;
        flex-direction: column;
        .drop_layout {
          width: 100%;
        }
      }
      .box_layout {
        width: 100%;
        .box_text {
          width: 100%;
          min-height: 169px;
          height: auto;
          margin: 10px 0;
          .text_title {
            font-size: 18px;
          }
          .text1 {
            font-size: 14px;
            margin-top: 10px;
          }
          .text2 {
            font-size: 14px;
          }
          .text3 {
            font-size: 14px;
          }
          .row {
            display: flex;
            img {
              width: 10px;
              height: 10px;
              object-fit: contain;
              margin-right: 10px;
            }
            .text_list {
              font-size: 14px;
            }
          }
        }

        .box {
          width: 100%;
          margin: 10px 0;
          flex-direction: column;
          align-items: center;
          .img_box {
            width: 100px;
            margin-left: -10px;
            margin-top: -15px;
            .text_logo {
              font-size: 12px;
              text-align: center;
              margin-top: -65px;
              .img_logo {
                width: 15px;
                height: 15px;
                object-fit: contain;
              }
              .text_score {
                font-size: 13px;
                margin-top: -5px;
              }
            }

            .img_level {
              width: 80px;
              object-fit: contain;
            }
          }
          .text_box {
            justify-content: center;
            margin-top: 15px;
            text-align: center;
            .text_grey {
              color: #858585;
              font-size: 10px;
              width: 260px;
            }
            .text_blue {
              font-size: 16px;
            }
          }
        }
      }
      .btn_wrap {
      }
      .btn_back {
        display: block;
      }
      .theme_gradient {
        .btn_layout {
          width: 300px;
          height: ${({ height }) => (height ? height : '50px')};
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      .theme_gray {
        .btn_layout {
          width: 300px;
          height: ${({ height }) => (height ? height : '50px')};
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
  }
`;

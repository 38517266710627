import styled from 'styled-components';
import { SCREEN } from 'assets';

export const RadioCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  .theme_circle2 {
    .container_wrap {
      margin-bottom: ${(props) =>
        props.margin_bottom2 ? props.margin_bottom2 : '5px'};
      .container {
        position: relative;
        padding-left: 28px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        justify-content: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &:focus {
          outline: none;
          border: none;
        }
        .txt_label {
          color: #3b96b7;

          font-size: 14px;
          font-weight: bold;
          padding-top: ${(props) =>
            props.padding_top ? props.padding_top : '3px'};
          margin-right: 79px;
          margin-left: 6px;
        }
      }
      .container input {
        // -webkit-appearance: none;
        // -moz-appearance: none;
        // appearance: none;

        border-radius: 50%;
        width: 16px;
        height: 16px;

        border: 2px solid #3da3c1;
        // margin-right: 5px;
        outline: none;
      }

      .container input:checked {
        background-color: #3da3c1;
      }

      .container input:checked :before {
        border: 2px solid #ef5350;
      }

      .container input:checked :after {
        border: 2px solid #ef5350;
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
  }
`;

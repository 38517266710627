import React from 'react';
import { connect } from 'react-redux';
import { RegisterContainerStyled } from './styled';
import { setReduxSubjectSelect, setReduxUserAuth } from 'actions';
import { userService } from 'apiService';
import { ROUTE_PATH } from 'helpers';
// import { iconImages } from 'assets';
import { WellRegisterForm } from 'forms/WellAppScreeningForm';
import { toast } from 'react-toastify';
import moment from 'moment';
// import { LogoLoadingFade } from 'components';

class RegisterContainer extends React.Component {
  state = { step: 1, loading: true, loading2: false, isOldUser: false };

  componentDidMount() {
    this.fetchDataProfile();
    this.scrollToTop();
    this.setLoadder();
  }

  setLoadder = () => {
    this.timeoutRef = setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1560);
  };

  // setLoadder2 = () => {
  //   setTimeout(() => {
  //     this.setState({
  //       loading: false,
  //     });
  //   }, 1560);
  // };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchDataProfile = async () => {
    const tokenCheck =
      sessionStorage.getItem('tokenList') || localStorage.getItem('tokenList');
    // console.log('abcdefsfsfsf', tokenCheck);
    if (tokenCheck) {
      let res = await userService.GET_PROFILE_DETAIL();
      if (res && res.success) {
        // console.log('df', res);
        this.setState({
          initialValues: {
            ...res.data,
            ...res.data.profile,
            company_id: res.data.profile.company?.id,
          },
          isOldUser: true,
        });
      }
    }
  };

  handleChangeStep = () => {
    const {
      reduxForm: { values, syncErrors },
    } = this.props;
    const { step } = this.state;

    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลด้วย');
    } else {
      clearTimeout(this.timeoutRef);
      this.setState(
        {
          registerName: values.firstname + ' ' + (values.lastname || ''),
          step: step + 1,
          loading: true,
        },
        () => this.setLoadder()
      );
    }
  };

  handleSetStep = (step) => {
    this.setState({
      step: step,
    });
  };

  handleBackStep = () => {
    const { step } = this.state;
    this.setState(
      {
        step: step - 1,
        loading: true,
      },
      () => this.setLoadder()
    );
  };

  // EMAIL PROCESS REGISTER
  // -====================================
  // -====================================
  handleSuccesRegister = async () => {
    const { isOldUser } = this.state;
    if (isOldUser || localStorage.getItem('tokenList')) {
      this.handleSubmitProfile();
    } else {
      const {
        reduxForm: { values },
      } = this.props;
      let params = {
        email: values.email,
        password: values.password,
        confirm_password: values.confirm_password,
        provider: 'local',
        birthday: moment(values.birthday).format('YYYY-MM-DD'),
      };
      let res = await userService.POST_SING_UP(params);
      if (res && res.success) {
        this.setState({
          isRegisEmailSuccess: true,
        });
        this.handleClickLoginEmail();
      } else {
        this.setState({
          loading: false,
        });
        toast.error('อีเมล์ถูกใช้แล้ว');
      }
    }
  };

  handleClickLoginEmail = async () => {
    const {
      reduxForm: { values },
    } = this.props;
    let params = {
      email: values.email,
      password: values.password,
    };
    let res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      localStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: true })
      );
      this.handleSubmitProfile();
    } else {
      this.setState({
        loading: false,
      });
      toast.error('ข้อมูลไม่ตรงในระบบ');
    }
  };

  handleSubmitProfile = async () => {
    const {
      reduxForm: { values },
    } = this.props;
    let params = {
      ...values,
      is_alcohol: values.is_alcohol.value,
      is_smoke: values.is_smoke.value,
      gender: values.gender.value,
      birthday: moment(values.birthday).format('YYYY-MM-DD'),
    };
    // console.log('parammm ', params)
    let res = await userService.PUT_DOCTOR_NOTE_ADD(params);
    if (res && res.success) {
      toast.success('อัพเดตสำเร็จ');
      console.log('dfdsf', res);
      this.props.setReduxUserAuth({ email: values.email, ...res.data });
      this.setState({
        loading: true,
      });
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        this.handleClickNext();
      }, Math.floor(Math.random() * 800) + 200);
    } else {
      this.setState({
        loading: false,
      });
      toast.error('อัพเดตไม่สำเร็จ');
    }
  };

  handleClickNext = () => {
    this.props.history.replace(ROUTE_PATH.HOME_SCREENING);
  };

  render() {
    const { step, registerName, loading, initialValues, isOldUser } =
      this.state;
    const { configProjectRedux } = this.props;

    return (
      <RegisterContainerStyled>
        <>
          {/* {loading2 && (
              <div className="loader_layout">
                <LogoLoadingFade />
              </div>
            )} */}
          {step > 1 && (
            <div className="back_layout" onClick={this.handleBackStep}>
              {`< ย้อนกลับ`}
            </div>
          )}
          <div className="layout_detail">
            {/* <div className="show_logo">
                <img
                  className="img_show"
                  src={iconImages['well_round.png']}
                  alt="icon show"
                />
              </div> */}
            <div className="body_show">
              <WellRegisterForm
                initialValues={initialValues}
                step={step}
                handleChangeStep={this.handleChangeStep}
                handleSuccesRegister={this.handleSuccesRegister}
                registerName={registerName}
                handleSetStep={this.handleSetStep}
                isOldUser={isOldUser}
                is_corporate={configProjectRedux.is_corporate}
                loading={loading}
              />
            </div>
          </div>
        </>
      </RegisterContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  subject: state.subject,
  reduxForm: state.form.WellRegisterForm,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxSubjectSelect: (data) => dispatch(setReduxSubjectSelect(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);

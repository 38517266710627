import styled from 'styled-components';

export const SplashScreenStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(2, 83, 163, 1) 0%,
    rgba(46, 183, 184, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

import styled from 'styled-components';

export const FBContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .fb_wrap {
    visibility: hidden;
  }
  .btn_fb {
    cursor: pointer;
    width: 320px;
    height: 45px;
    margin-top: 7px;
    background-color: #3061a1;
    font-size: 14px;
    font-weight: 500;
    border-radius: 9px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    margin-left: auto;
    margin-right: auto;
    .img_facebook {
      margin-right: 10px;
      width: 19px;
      height: 19px;
    }
  }
`;

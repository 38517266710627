export const calAge = (dob) => {
  var diff_ms = Date.now() - new Date(dob).getTime();
  var age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

export const getAge = (dateString) => {
  var now = new Date();
  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();
  var dob = new Date(dateString);
  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  var ageString = '';
  var yearString = '';
  var monthString = '';
  var dayString = '';
  var yearAge = yearNow - yearDob;
  let monthAge =
    monthNow >= monthDob ? monthNow - monthDob : 12 + monthNow - monthDob;
  let dateAge;
  if (dateNow >= dateDob) dateAge = dateNow - dateDob;
  else {
    monthAge--;
    dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }
  age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };
  if (age.years > 1) yearString = ' ปี';
  else yearString = ' ปี';
  if (age.months > 1) monthString = ' เดือน';
  else monthString = ' เดือน';
  if (age.days > 1) dayString = ' วัน';
  else dayString = ' วัน';
  if (age.years > 0 && age.months > 0 && age.days > 0)
    ageString =
      age.years +
      yearString +
      '\xa0\xa0\xa0\xa0' +
      age.months +
      monthString +
      '\xa0\xa0\xa0\xa0' +
      age.days +
      dayString +
      '';
  else if (age.years === 0 && age.months === 0 && age.days > 0)
    ageString = 'เพียง ' + age.days + dayString + '';
  else if (age.years > 0 && age.months === 0 && age.days === 0)
    ageString = age.years + yearString + ' Happy Birthday!!';
  else if (age.years > 0 && age.months > 0 && age.days === 0)
    ageString =
      age.years +
      yearString +
      '\xa0\xa0\xa0\xa0' +
      age.months +
      monthString +
      '';
  else if (age.years === 0 && age.months > 0 && age.days > 0)
    ageString =
      age.months + monthString + '\xa0\xa0\xa0\xa0' + age.days + dayString + '';
  else if (age.years > 0 && age.months === 0 && age.days > 0)
    ageString =
      age.years + yearString + '\xa0\xa0\xa0\xa0' + age.days + dayString + '';
  else if (age.years === 0 && age.months > 0 && age.days === 0)
    ageString = age.months + monthString + '';
  else ageString = 'Oops! Could not calculate age!';
  return ageString;
};

export const getAgeYearOnly = (dateString) => {
  var now = new Date();
  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();
  var dob = new Date(dateString);
  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  var yearString = '';
  var yearAge = yearNow - yearDob;
  if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
  else {
    yearAge--;
  }
  if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
  else {
    monthAge--;
    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }
  age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };
  if (age.years > 1) yearString = ' ปี';
  else yearString = ' ปี';
  return age.years + yearString;
};

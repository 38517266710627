import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { reducer as formReducer } from 'redux-form';
import {
  CONFIG_PROJECT,
  USER_AUTHEN,
  SUBJECT_SELECT,
  SUMARY_QUESTION,
  ORDER_PRODUCT,
  ADDRESS_SHIPPING,
  SUMARY_QUESTION_NO_SYMPTOM,
  HOSPITAL_LIST,
  COOKIE_PERMISSION,
} from 'actions/actionTypes';

const auth = (state = false, action) => {
  switch (action.type) {
    case USER_AUTHEN:
      return action.data;
    default:
      return state;
  }
};

const configProjectRedux = (state = false, action) => {
  switch (action.type) {
    case CONFIG_PROJECT:
      return action.data;
    default:
      return state;
  }
};

const subject = (state = {}, action) => {
  switch (action.type) {
    case SUBJECT_SELECT:
      return action.data;
    default:
      return state;
  }
};

const hospitalList = (state = {}, action) => {
  switch (action.type) {
    case HOSPITAL_LIST:
      return action.data;
    default:
      return state;
  }
};

const summary = (state = {}, action) => {
  switch (action.type) {
    case SUMARY_QUESTION:
      return action.data;
    default:
      return state;
  }
};

const summaryNoSymptom = (state = {}, action) => {
  switch (action.type) {
    case SUMARY_QUESTION_NO_SYMPTOM:
      return action.data;
    default:
      return state;
  }
};

const orderProduct = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PRODUCT:
      return action.data;
    default:
      return state;
  }
};

const addressShipping = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_SHIPPING:
      return action.data;
    default:
      return state;
  }
};

const cookiePermission = (state = false, action) => {
  switch (action.type) {
    case COOKIE_PERMISSION:
      return action.data;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  form: formReducer,
  configProjectRedux,
  auth,
  subject,
  summary,
  summaryNoSymptom,
  orderProduct,
  addressShipping,
  hospitalList,
  cookiePermission,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form', 'tabNueseDetailActive'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(persistedReducer);
let persistor = persistStore(store);

export { store, persistor };

import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const BoxIconLabelStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    cursor: pointer;
    display: flex;
    .icon_layout {
      width: 20%;
      padding: 23px;
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: ${COLOR.BLUE_1};
      display: flex;
      background-image: ${COLOR.LINEAR_BLUE2};
      .icon_btn {
        // width: 30px;
        height: 30px;
        // flex-shrink: 0;
        // display: flex;
      }
    }
    .label_layout {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      border-top-right-radius: 23px;
      border-bottom-right-radius: 23px;
      font-size: ${FONT.SIZE_16};
      color: ${COLOR.BLUE_2};
      background: ${COLOR.GRAY_1};
      border: 1px solid ${COLOR.BLUE_1};
      .txt_description {
        font-size: ${FONT.SIZE_12};
      }
    }
  }
  .theme_mini {
    cursor: pointer;
    display: flex;
    .icon_layout {
      width: 20%;
      padding: 15px;
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: ${COLOR.BLUE_1};
      display: flex;
      background-image: ${COLOR.LINEAR_BLUE2};
      .icon_btn {
        height: 20px;
        margin: 0px;
      }
    }
    .label_layout {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      border-top-right-radius: 23px;
      border-bottom-right-radius: 23px;
      font-size: ${FONT.SIZE_14};
      color: ${COLOR.BLUE_2};
      background-color: #ffffff;
      // background: ${COLOR.GRAY_1};
      border: 1px solid ${COLOR.BLUE_1};
      .txt_description {
        font-size: ${FONT.SIZE_12};
      }
    }
  }

  .theme_summary {
    cursor: pointer;
    display: flex;
    height: 64px;
    margin-top: 20px;
    margin-left: 30px;
    width: 320px;

    .icon_layout {
      width: 20%;
      padding: 23px;
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: ${COLOR.BLUE_1};
      display: flex;
      background-image: ${COLOR.LINEAR_BLUE2};
      .icon_btn {
        // width: 30px;
        height: 30px;
        // flex-shrink: 0;
        // display: flex;
      }
    }
    .label_layout {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
      border-top-right-radius: 23px;
      border-bottom-right-radius: 23px;
      font-size: ${FONT.SIZE_16};
      color: ${COLOR.BLUE_2};
      background: ${COLOR.GRAY_1};
      border: 1px solid ${COLOR.BLUE_1};
      .txt_description {
        font-size: ${FONT.SIZE_12};
      }
    }
  }
`;

import { ROUTE_PATH } from 'helpers';
// import CampaignContainer from 'containers/PinkApp/LandingSystem/CampaignContainer';
import LoginContainer from 'containers/PinkApp/MemberSystem/LoginContainer';
import RegisterContainer from 'containers/PinkApp/MemberSystem/RegisterContainer';
import RegisterContainerBackup from 'containers/PinkApp/MemberSystem/RegisterContainerBackup';
import SingUpContainer from 'containers/PinkApp/MemberSystem/SingUpContainer';
import TermOfUseContainer from 'containers/PinkApp/TermOfUseContainer';
import QualificationExaminationContainer from 'containers/PinkApp/ExaminationSystem/QualificationExaminationContainer';
import QuestionProcessContainer from 'containers/PinkApp/ExaminationSystem/QuestionProcessContainer';
import ShareContainer from 'containers/PinkApp/ShareContainer';
import LocationNearContainer from 'containers/PinkApp/LocationNearContainer';
import HealthInsurancerContainer from 'containers/PinkApp/HealthInsurancerContainer';
import ConsultDoctorContainer from 'containers/PinkApp/ConsultDoctorContainer';
import HospitalChooseContainer from 'containers/PinkApp/HospitalChooseContainer';
import InsurancePackageContainer from 'containers/PinkApp/InsurancePackageContainer';

const pinkAlertRoutes = [
  {
    keyIndex: 0,
    path: ROUTE_PATH.HOME_PATH,
    exact: true,
    name: 'HOME_PATH',
    component: QualificationExaminationContainer,
  },
  {
    keyIndex: 1,
    path: ROUTE_PATH.LOGIN_NEW,
    exact: true,
    name: 'LOGIN',
    component: LoginContainer,
  },
  {
    keyIndex: 2,
    path: ROUTE_PATH.SIGNUP,
    exact: true,
    name: 'SIGNUP',
    component: SingUpContainer,
  },
  {
    keyIndex: 3,
    path: ROUTE_PATH.REGISTER,
    exact: true,
    name: 'REGISTER',
    component: RegisterContainer,
  },
  {
    keyIndex: 4,
    path: ROUTE_PATH.TERM_OF_USE,
    exact: true,
    name: 'TermOfUseContainer',
    component: TermOfUseContainer,
  },
  {
    keyIndex: 5,
    path: ROUTE_PATH.QUANLIFICATION_EXAMINATION,
    exact: true,
    name: 'QUANLIFICATION_EXAMINATION',
    component: QualificationExaminationContainer,
  },
  {
    keyIndex: 6,
    path: ROUTE_PATH.QUESTIONNAIRE,
    exact: true,
    name: 'QUESTIONNAIRE',
    component: QuestionProcessContainer,
  },
  {
    keyIndex: 7,
    path: ROUTE_PATH.SHARE,
    exact: true,
    name: 'SHARE',
    component: ShareContainer,
  },
  {
    keyIndex: 8,
    path: ROUTE_PATH.LOCATION_NEAR,
    exact: true,
    name: 'SHARE',
    component: LocationNearContainer,
  },
  {
    keyIndex: 9,
    path: ROUTE_PATH.PINK_HOSPITAL_CHOOSE,
    exact: true,
    name: 'SHARE',
    component: HospitalChooseContainer,
  },
  {
    keyIndex: 10,
    path: ROUTE_PATH.PINK_INSURANCE_PACKAGE,
    exact: true,
    name: 'SHARE',
    component: InsurancePackageContainer,
  },
  {
    keyIndex: 11,
    path: ROUTE_PATH.PINK_PROFILE_SCREENING,
    exact: true,
    name: 'SHARE',
    component: RegisterContainerBackup,
  },
  {
    keyIndex: 12,
    path: ROUTE_PATH.CONSULT_DOCTOR,
    exact: true,
    name: 'SHARE',
    component: ConsultDoctorContainer,
  },
  {
    keyIndex: 13,
    path: ROUTE_PATH.HEALTH_INSURANCER,
    exact: true,
    name: 'SHARE',
    component: HealthInsurancerContainer,
  },
];

export default pinkAlertRoutes;

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { AssessmentFormStyled } from './styled';
import { iconImages } from 'assets';
import { VALIDATE } from 'helpers';
import { RadioCustom, ButtonIcon } from 'components';

class AssessmentForm extends React.Component {
  state = {};

  render() {
    const { handleClickTest, handleClickBackFirstPage } = this.props;
    return (
      <AssessmentFormStyled>
        <div className="title">
          <img
            className="show_logo"
            src={iconImages['well_logo.png']}
            alt="logo show"
          />
          <span className="show_text">
            ขออนุญาตถามคำถามเหล่านี้กับคุณ
            เพื่อประเมินว่าคุณเหมาะสำหรับการใช้ชุดตรวจ Test kit หรือไม่ ?
          </span>
        </div>
        <div className="body_question">
          <div className="group_question">
            <div className="label_name">
              1. คุณมีเลือดออกผิดปกติทางช่องคลอด ใช่หรือไม่?
            </div>
            <Field
              theme_circle2
              name="q1"
              padding_left="0px"
              options={[
                {
                  label: 'ใช่',
                  value: true,
                },
                {
                  label: 'ไม่ใช่',
                  value: false,
                },
              ]}
              component={RadioCustom}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="group_question">
            <div className="label_name">
              2. คุณมีเลือดออกขณะหรือหลังมีเพศสัมพันธ์ ใช่หรือไม่?
            </div>
            <Field
              theme_circle2
              name="q2"
              padding_left="0px"
              options={[
                {
                  label: 'ใช่',
                  value: true,
                },
                {
                  label: 'ไม่ใช่',
                  value: false,
                },
              ]}
              component={RadioCustom}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="group_question">
            <div className="label_name">
              3. คุณมีน้ำปนเลือดไหลออกจากช่องคลอด ใช่หรือไม่?
            </div>
            <Field
              theme_circle2
              name="q3"
              padding_left="0px"
              options={[
                {
                  label: 'ใช่',
                  value: true,
                },
                {
                  label: 'ไม่ใช่',
                  value: false,
                },
              ]}
              component={RadioCustom}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="group_question">
            <div className="label_name">
              4. คุณมีตกขาวผิดปกติ สีเปลี่ยน มีกลิ่นแรง ใช่หรือไม่?
            </div>
            <Field
              theme_circle2
              name="q4"
              padding_left="0px"
              options={[
                {
                  label: 'ใช่',
                  value: true,
                },
                {
                  label: 'ไม่ใช่',
                  value: false,
                },
              ]}
              component={RadioCustom}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="group_question no_margin">
            <div className="label_name">
              5. คุณมีขาบวมโดยไม่ทราบสาเหตุ ใช่หรือไม่?
            </div>
            <Field
              theme_circle2
              name="q5"
              padding_left="0px"
              options={[
                {
                  label: 'ใช่',
                  value: true,
                },
                {
                  label: 'ไม่ใช่',
                  value: false,
                },
              ]}
              component={RadioCustom}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="btn">
            <ButtonIcon
              theme_normal
              lable1Size="14px"
              label1="ประเมินผล"
              handleOnClick={handleClickTest}
            />
          </div>
          <div className="btn2">
            <ButtonIcon
              theme_gray
              lable1Size="16px"
              label1="กลับสู่หน้าแรก"
              handleOnClick={handleClickBackFirstPage}
            />
          </div>
        </div>
      </AssessmentFormStyled>
    );
  }
}

export default reduxForm({
  form: 'AssessmentForm', // a unique identifier for this form
  enableReinitialize: true,
})(AssessmentForm);

import styled from 'styled-components';
import { SCREEN, COLOR, FONT } from 'assets';

export const SingUpContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
  height: calc(100% - 87px);
  
  .layout_left_empty {
    width: 100%;
    position: relative;
    overflow: hidden;
    .img_bg {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 100%;
      width: auto;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  .layout_left {
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background: ${COLOR.ORANGE_1};
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .top_left_layer {
      border-radius: 10px;
      border: 1px solid ${COLOR.GRAY_5};
      height: 160px;
      max-width: 344px;
      width: 100%;
      background: ${COLOR.GRAY_10};
      .img_show {
        width: 100%;
        height: 100%;
      }
    }
    .bottom_left_layer {
      
      color: ${COLOR.BLACK_5};
      font-size: ${FONT.SIZE_18};
      border-radius: 10px;
      border: 1px solid ${COLOR.GRAY_5};
      max-width: 344px;
      width: 100%;
      margin-top: 56px;
      padding: 25px;
      background: ${COLOR.WHITE_1};
      .title_show {
        color: ${COLOR.ORANGE_1};
        font-size: ${FONT.SIZE_24};
        font-weight: 800;
        text-align: center;
        margin-bottom: 24px;
      }
      .detail_show {
        margin-bottom: 10px;
        text-align: center;
        &.no_margin {
          margin-bottom: 0px;
        }
      }
    }
  }
  .layout_right {
    width: 100%;
    padding: 2.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .banner_wrap {
      margin-bottom: 2.5rem;
    }
    .regis_container {
      .title {
        color: #003564;
        font-size: 24px;
        margin-bottom: 15px;
      }
      .btn_regis {
        display: flex;
        align-items: center;
        justify-content: center;
        
        width: 320px;
        height: 45px;
        border-radius: 10px;
        color: white;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        &.facebook {
          background-color: #3061a1;
          .img_facebook {
            margin-right: 8px;
          }
        }
        &.email {
          background-color: #3b96b7;
          .img_email {
            margin-right: 8px;
          }
        }
      }
      .text_between {
        margin: 10px 0;
        text-align: center;
        font-size: 12px;
        color: #003564;
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    flex-direction: column;
  }
`;

import styled from 'styled-components';
// import {
//     COLOR,
//     FONT
// } from 'assets'

export const LoadingStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { ROUTE_PATH } from 'helpers';
// import LoginContainer from "containers/WellScreenApp/MemberSystem/LoginContainer";
import LoginContainer from 'containers/MemberSystem/LoginContainer';
import SingUpContainer from 'containers/WellScreenApp/MemberSystem/SingUpContainer';
import RegisterContainer from 'containers/WellScreenApp/MemberSystem/RegisterContainer';
import HomeContainer from 'containers/WellScreenApp/HomeContainer';
import TermsOfUseContainer from 'containers/WellScreenApp/TermsOfUseContainer';
import TermsOfUseRegistryContainer from 'containers/WellScreenApp/TermsOfUseRegistryContainer';
import SelectSubjectContainer from 'containers/WellScreenApp/ScreeningSystem/SelectSubjectContainer';
import PreCheckContainer from 'containers/WellScreenApp/ScreeningSystem/PreCheckContainer';
import SelectSymptomAreaContainer from 'containers/WellScreenApp/ScreeningSystem/SelectSymptomAreaContainer';
import InterestedCancerContainer from 'containers/WellScreenApp/ScreeningSystem/InterestedCancerContainer';
import SummaryCancerContainer from 'containers/WellScreenApp/ScreeningSystem/SummaryContainer';
import ServiceContainer from 'containers/WellScreenApp/ServiceContainer';
import InsurancePackageContainer from 'containers/WellScreenApp/InsurancePackageContainer';
import InsurancePlanContainer from 'containers/WellScreenApp/InsurancePlanContainer';
import ConsultGeneticContainer from 'containers/WellScreenApp/ConsultGeneticContainer';
import ConsultCancerContainer from 'containers/WellScreenApp/ConsultCancerContainer';
import ExaminationTestKitContainer from 'containers/WellScreenApp/ExaminationTestKitContainer';
import CartContainer from 'containers/WellScreenApp/CartContainer';
import QuestionBeforeBuyContainer from 'containers/WellScreenApp/QuestionBeforeBuyContainer';
import ThankyouForInterestContainer from 'containers/WellScreenApp/ThankyouForInterestContainer';
import SuccessOrderContainer from 'containers/WellScreenApp/SuccessOrderContainer';
import AddProfileFamilyContainer from 'containers/WellScreenApp/ProfileScreeningSystem/AddProfileFamilyContainer';
import ProfileScreeningContainer from 'containers/WellScreenApp/ProfileScreeningSystem/ProfileScreeningContainer';
import BuyProductHistoryContainer from 'containers/WellScreenApp/BuyProductHistoryContainer';
import SummaryServiceHistoryContainer from 'containers/WellScreenApp/SummaryServiceHistoryContainer';
import AdviceDoctorContainer from 'containers/WellScreenApp/AdviceDoctorContainer';
import BuyProductContainer from 'containers/WellScreenApp/BuyProductContainer';
import SuspectedCancerContainer from 'containers/WellScreenApp/ScreeningSystem/SuspectedCancerContainer';
import NoSymptomQuestionContainer from 'containers/WellScreenApp/ScreeningSystem/NoSymptomQuestionContainer';
import GateWayQuestionContainer from 'containers/WellScreenApp/ScreeningSystem/GateWayQuestionContainer';
import SummaryNoSymtopmContainer from 'containers/WellScreenApp/ScreeningSystem/SummaryNoSymtopmContainer';
import GateWayQuestionNoSymptomContainer from 'containers/WellScreenApp/ScreeningSystem/GateWayQuestionNoSymptomContainer';

const wellScreenRoutes = [
  {
    keyIndex: -1,
    path: '/',
    exact: true,
    name: 'WellLogin',
    component: LoginContainer,
  },
  {
    keyIndex: 0,
    path: ROUTE_PATH.LOGIN_NEW,
    exact: true,
    name: 'WellLogin',
    component: LoginContainer,
  },
  {
    keyIndex: 1,
    path: ROUTE_PATH.WELL_SIGNUP,
    exact: true,
    name: 'WellSignup',
    component: SingUpContainer,
  },
  {
    keyIndex: 2,
    path: ROUTE_PATH.WELL_REGISTER,
    exact: true,
    name: 'WellRegister',
    component: RegisterContainer,
  },
  {
    keyIndex: 3,
    path: ROUTE_PATH.HOME_SCREENING,
    exact: true,
    name: 'HomeScreening',
    component: HomeContainer,
  },
  {
    keyIndex: 4,
    path: ROUTE_PATH.TERM,
    exact: true,
    name: 'TermOfUseContainer',
    component: TermsOfUseContainer,
  },
  {
    keyIndex: 5,
    path: ROUTE_PATH.SELECT_SUBJECT,
    exact: true,
    name: 'SelectSubjectContainer',
    component: SelectSubjectContainer,
  },
  {
    keyIndex: 6,
    path: ROUTE_PATH.PRE_CHECK,
    exact: true,
    name: 'PreCheckContainer',
    component: PreCheckContainer,
  },
  {
    keyIndex: 7,
    path: ROUTE_PATH.SELECT_SYMPTOM,
    exact: true,
    name: 'SelectSymptomAreaContainer',
    component: SelectSymptomAreaContainer,
  },
  {
    keyIndex: 8,
    path: ROUTE_PATH.INTERESTED_CENCER,
    exact: true,
    name: 'InterestedCancerContainer',
    component: InterestedCancerContainer,
  },
  {
    keyIndex: 9,
    path: ROUTE_PATH.SUMMARY,
    exact: true,
    name: 'SummaryCancerContainer',
    component: SummaryCancerContainer,
  },
  {
    keyIndex: 10,
    path: ROUTE_PATH.SERVICE,
    exact: true,
    name: 'ServiceContainer',
    component: ServiceContainer,
  },
  {
    keyIndex: 11,
    path: ROUTE_PATH.INSURANCE_PACKAGE,
    exact: true,
    name: 'InsurancePackageContainer',
    component: InsurancePackageContainer,
  },
  {
    keyIndex: 12,
    path: ROUTE_PATH.INSURANCE_PLAN,
    exact: true,
    name: 'InsurancePlanContainer',
    component: InsurancePlanContainer,
  },
  {
    keyIndex: 13,
    path: ROUTE_PATH.CONSULT_GENETIC,
    exact: true,
    name: 'ConsultGeneticContainer',
    component: ConsultGeneticContainer,
  },
  {
    keyIndex: 14,
    path: ROUTE_PATH.EXAMINE_TEST_KIT,
    exact: true,
    name: 'ExaminationTestKitContainer',
    component: ExaminationTestKitContainer,
  },
  {
    keyIndex: 15,
    path: ROUTE_PATH.CART,
    exact: true,
    name: 'CartContainer',
    component: CartContainer,
  },
  {
    keyIndex: 16,
    path: ROUTE_PATH.QUESTION_BEFORE_BUY,
    exact: true,
    name: 'QuestionBeforeBuyContainer',
    component: QuestionBeforeBuyContainer,
  },
  {
    keyIndex: 17,
    path: ROUTE_PATH.THANK_YOU_INTEREST,
    exact: true,
    name: 'ThankyouForInterestContainer',
    component: ThankyouForInterestContainer,
  },
  {
    keyIndex: 18,
    path: ROUTE_PATH.SUCCESS_ORDER_DETAIL,
    exact: true,
    name: 'OtherPersonContainer',
    component: SuccessOrderContainer,
  },
  {
    keyIndex: 19,
    path: ROUTE_PATH.PROFILE_FAMILY,
    exact: true,
    name: 'AddProfileFamilyContainer',
    component: AddProfileFamilyContainer,
  },
  {
    keyIndex: 20,
    path: ROUTE_PATH.PROFILE_SCREENING,
    exact: true,
    name: 'ProfileScreeningContainer',
    component: ProfileScreeningContainer,
  },
  {
    keyIndex: 21,
    path: ROUTE_PATH.BUY_PRODUCT_HISTORY,
    exact: true,
    name: 'BuyProductHistoryContainer',
    component: BuyProductHistoryContainer,
  },
  {
    keyIndex: 22,
    path: ROUTE_PATH.ADVICE_DOCTOR,
    exact: true,
    name: 'AdviceDoctorContainer',
    component: AdviceDoctorContainer,
  },
  {
    keyIndex: 23,
    path: ROUTE_PATH.BUY_PRODUCT,
    exact: true,
    name: 'BuyProductContainer',
    component: BuyProductContainer,
  },
  {
    keyIndex: 31,
    path: ROUTE_PATH.SUSPECTED_CANCER,
    exact: true,
    name: 'SuspectedCancerContainer',
    component: SuspectedCancerContainer,
  },
  {
    keyIndex: 32,
    path: ROUTE_PATH.NO_SYMPTOM_QUESTION,
    exact: true,
    name: 'NoSymptomQuestionContainer',
    component: NoSymptomQuestionContainer,
  },
  {
    keyIndex: 33,
    path: ROUTE_PATH.WELL_TERM_OF_USE_REGISTRY,
    exact: true,
    name: 'TermsOfUseRegistryContainer',
    component: TermsOfUseRegistryContainer,
  },
  {
    keyIndex: 34,
    path: ROUTE_PATH.SUMMARY_SERVICE_HISTORY,
    exact: true,
    name: 'SummaryServiceHistoryContainer',
    component: SummaryServiceHistoryContainer,
  },
  {
    keyIndex: 34,
    path: ROUTE_PATH.GATEWAY,
    exact: true,
    name: 'GateWayQuestionContainer',
    component: GateWayQuestionContainer,
  },
  {
    keyIndex: 35,
    path: ROUTE_PATH.SUMMARY_NO_SYMPTOM,
    exact: true,
    name: 'GateWayQuestionContainer',
    component: SummaryNoSymtopmContainer,
  },
  {
    keyIndex: 36,
    path: ROUTE_PATH.GATEWAY_NO_SYMPTOM,
    exact: true,
    name: 'GateWayQuestionContainer',
    component: GateWayQuestionNoSymptomContainer,
  },
  {
    keyIndex: 34,
    path: ROUTE_PATH.CONSULT_CANCER,
    exact: true,
    name: 'ConsultCancerContainer',
    component: ConsultCancerContainer,
  },
  {
    keyIndex: 37,
    path: ROUTE_PATH.SUMMARY_HISTORY,
    exact: true,
    name: 'SummaryCancerContainer',
    component: SummaryCancerContainer,
  },
  
];

export default wellScreenRoutes;

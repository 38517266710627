import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const BoxProductStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  height: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard_2 {
    min-height: 193px;
    width: 100%;

    font-size: ${FONT.SIZE_14};
    color: ${COLOR.BLUE_2};
    padding: 13px;
    border-radius: 30px;
    border: 1px solid ${COLOR.BLUE_8};
    display: flex;
    background: ${COLOR.GRAY_1};
    .left_layout {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      .box_img {
        width: 100%;
        border: 1px solid #f2f2f2;
        border-radius: 20px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // height: 74%;
        width: 80px;
        height: 80px;
      }
    }
    .right_layout {
      width: 70%;
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .show_head {
        font-weight: bold;
        color: ${COLOR.BLUE_4};
        margin-bottom: 10px;
        font-size: ${FONT.SIZE_14};
      }
      .show_discount {
        width: 55%;
        margin-bottom: 10px;
      }
      .group_price {
        display: flex;
        font-weight: bold;
        align-items: baseline;
        margin-bottom: 10px;
        .label_show {
          color: ${COLOR.BLACK_1};
          margin-right: 5px;
          font-weight: 500;
        }
        .price_1 {
          color: ${COLOR.GRAY_7};
          position: relative;
          margin: 0px 10px 0px 0px;
        }
        .price_1:after {
          content: '';
          position: absolute;
          left: 0;
          top: 64%;
          margin-top: -3px;
          width: 100%;
          height: 1px;
          background: ${COLOR.RED_3};
          -webkit-transform: rotateZ(-15deg);
          -moz-transform: rotateZ(-15deg);
        }
        .price_2 {
          color: ${COLOR.BLUE_2};
        }
      }
      .btn {
      }
    }
  }
  .theme_standard_pink {
    min-height: 193px;
    width: 100%;
    height: 100%;
    font-size: ${FONT.SIZE_14};
    color: ${COLOR.BLUE_2};
    padding: 13px;
    border-radius: 20px;
    border: 1px solid ${COLOR.PINK_2};
    display: flex;
    background: ${COLOR.GRAY_1};
    .left_layout {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      .box_img {
        width: 100%;
        border: 1px solid #f2f2f2;
        border-radius: 10px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // height: 74%;
        width: 88px;
        height: 88px;
      }
    }
    .right_layout {
      width: 70%;
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .show_head {
        font-weight: bold;
        color: ${COLOR.PINK_2};
        margin-bottom: 10px;
        font-size: ${FONT.SIZE_14};
      }
      .show_discount {
        width: 55%;
        margin-bottom: 10px;
      }
      .group_price {
        display: flex;
        font-weight: bold;
        align-items: baseline;
        margin-bottom: 10px;
        font-size: ${FONT.SIZE_14};
        .label_show {
          color: ${COLOR.BLACK_1};
          margin-right: 5px;
        }
        .price_1 {
          color: ${COLOR.GRAY_7};
          position: relative;
          margin: 0px 10px 0px 0px;
        }
        .price_1:after {
          content: '';
          position: absolute;
          left: 0;
          top: 64%;
          margin-top: -3px;
          width: 100%;
          height: 1px;
          background: ${COLOR.RED_3};
          -webkit-transform: rotateZ(-15deg);
          -moz-transform: rotateZ(-15deg);
        }
        .price_2 {
          color: ${COLOR.BLUE_2};
        }
      }
      .btn {
      }
    }
  }
`;

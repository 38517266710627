import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	html, 
	body {
			height: 100%;
			margin: 0;
			font-family: "Sukhumvit Set", Lato, Prompt, sans-serif;
			a {
				text-decoration: none;
			}
			// button:active {
			// 	outline: none;
			// 	border: none;
			// }
			// button:focus {
			// 	outline: none;
			// 	border: none;
			// }
	}
`;

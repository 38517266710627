import styled from "styled-components"
// import {
//     COLOR,
//     FONT
// } from 'assets'
 
export const DatePikerMobileStyled = styled.div`
/*===============================================
 Container 
===============================================*/
// width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard_date_piker_mobile {
 
    }
`
import styled from 'styled-components';
import { SCREEN } from 'assets';

export const LocationNearContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .loading {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.RED_1};
    margin: 0px;
  }

  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    align-items: center;
    justify-content: center;
    background: white;
    width: 100%;
    height: 100vh;
    z-index: 99;
    &.active {
      display: flex;
    }
  }

  .is_desktop {
    padding: 16px;
    padding-bottom: 0px;
    position: relative;
    .btn_back_wrap {
      max-width: 920px;
      width: 100%;
      margin: auto;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .location_container {
      max-width: 704px;
      width: 100%;
      margin: auto;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #284d72bf;
        margin-bottom: 25px;
      }
      .title_sec_2 {
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 14px;
      }
      .row_2_col {
        display: flex;
        flex-wrap: wrap;
        column-gap: 24px;
        row-gap: 36px;
        margin-bottom: 37px;
        .item_wrap {
          flex: 1;
        }
      }
    }
  }

  .is_mobile {
    display: none;
    position: relative;
    .bg_top {
      position: absolute;
      z-index: -1;
      height: 140px;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }
    .container {
      padding: 16px;
      padding-bottom: 0px;
      height: 100%;
      .m_btn_back_wrap {
        margin-bottom: 45px;
      }
      .m_title {
        font-size: 20px;
        font-weight: bold;
        color: #284d72bf;
        margin-bottom: 17px;
        text-align: center;
      }
      .m_title_sec_2 {
        padding-left: 10px;
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 14px;
      }
      .m_row {
        margin-bottom: 24px;
      }
    }
  }

  .btn_layout {
    padding: 15px 32%;
    .btn_end {
      cursor: pointer;
      height: 48px;
      width: 100%;
      border-radius: 22px;
      background-color: #e8e8e8;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2),
        0 4px 20px 0 rgba(0, 0, 0, 0.19);
      color: #000000;
      font-size: 16px;
      font-weight: bold;
      margin-top: 20px;
    }
  }

  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .is_desktop {
      display: none;
    }
    .is_mobile {
      display: block;
    }
    .btn_layout {
      padding: 15px 9%;
      .btn_end {
        cursor: pointer;
        height: 48px;
        width: 100%;
        border-radius: 22px;
        background-color: #e8e8e8;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2),
          0 4px 20px 0 rgba(0, 0, 0, 0.19);
        color: #000000;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .is_desktop {
      display: block;
    }
    .is_mobile {
      display: none;
    }
  }
`;

import styled, { keyframes } from "styled-components";
import { SCREEN } from "assets";
import { iconImages } from "assets";
import { fadeInUp } from "react-animations";
const bounceAnimation = keyframes`${fadeInUp}`;
export const InterestedCancerContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  background-color: #f8f8f8;
  height: calc(100vh - 80px);

  background-image: url(${iconImages["dot_bg.png"]});
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top 0;
  overflow-y: auto;
  padding: 20px 0px;
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_back {
    max-width: 80%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .group_layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .layout_detail {
      max-width: 400px;
      .title {
        font-weight: bold;
        font-size: 18px;
        color: #2f4f74;
        margin: 20px 0 0 10px;
        animation: 1s ${bounceAnimation};
      }
      .title2 {
        font-size: 14px;
        color: #2f4f74;
        margin: 0px 0 0 10px;
        animation: 1s ${bounceAnimation};
      }
      .img_logo {
        width: 50px;
        height: 50px;
      }
      .txt_detail {
        color: #003564;
        font-size: 18px;
        margin-top: 20px;
        max-width: 400px;
      }
      .txt_detail2 {
        color: grey;
        font-size: 16px;
        margin-top: 10px;
        max-width: 400px;
      }
      .header_layout {
        width: 90vw;
      }
      .btn_bottom {
        animation: 2s ${bounceAnimation};
      }
      .question_layout {
        display: flex;
        flex-direction: row;
        width: 400px;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 50px;

        .column {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 30px;
            object-fit: contain;
          }
          .text_button {
            font-weight: 500;
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .link_back {
      width: 100%;
    }
    .group_layout {
      // padding-top: 350px;
      .layout_detail {
        width: 90vw;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
      .theme_standard {
        width: 90vw;
        height: 45px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        .btn_layout {
          width: 100%;
        }
      }
      .layout_detail {
        .question_layout {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          .theme_cancer {
            width: 85vw;
            display: flex;
            justify-content: center;
            .btn_layout {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

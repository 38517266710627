import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const BoxServiceStyled = styled.div`
/*===============================================
 Container 
===============================================*/
width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        
        font-size: ${FONT.SIZE_18};
        color: ${COLOR.BLUE_2};
        width: 100%;
        .top_layout {
            display: flex;
            margin-bottom: 45px;
            .top_left {
                width: 50%;
                .div1 {
                    font-size: ${FONT.SIZE_16};
                }
                .div2 {
                    font-weight: bold;
                    display: flex;
                    align-items: baseline;
                    .show_logo {
                        width: 35px;
                        margin: 0px 5px;
                    }
                }
            }
            .top_right {
                width: 50%;
                padding-left: 25px;
                display: flex;
                align-items: center;
                .button_layout {
                    width: 344px;
                }
            }
        }
        .bottom_layout {
            .group_label {
                position: relative;
                padding-left: 12px;
                margin-bottom: 25px;
                .dot {
                    background: ${COLOR.YELLOW_1};
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    position: absolute;
                    top: 9px;
                    left: 0;
                }
                .label1 {
                    font-weight: 600px;
                }
                .label2 {
                    font-size: ${FONT.SIZE_14};
                }
            }
        }
        .group_otp {
            display: flex;
            .left_otp {
                width: 50%;
                .top_left_otp {
                    margin-bottom: 22px;
                    .button_layout {
                        width: 344px;
                    }
                }
                .bottom_left_otp {
                    .button_layout {
                        width: 344px;
                    }
                }
            }
            .right_otp {
                width: 50%;
                padding-left: 25px; 
                .button_layout {
                    width: 344px;
                }
            }
        }
    }
`
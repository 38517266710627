import React from "react";
import { BoxProduct2Styled } from "./styled";
import cx from "classnames";

export const BoxProduct2 = ({
  theme_standard,
  src,
  title1,
  title2,
  label,
  label2,
  label2_1,
  label3,
  not_cursor,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <BoxProduct2Styled not_cursor={not_cursor}>
      <div className={customClass}>
        <div className="left_layout">
          <div className="box_img">
            <img className="show_img" src={src} alt="product icon" />
          </div>
        </div>
        <div className="right_layout">
          <div className="right_title">
            {title1 && <span className="label_show">{title1}</span>}
            {title2}
          </div>
          {label && <div dangerouslySetInnerHTML={{ __html: label }} />}
          {label2 && <div className="show_detail2 mb_2">{label2}</div>}
          {label2_1 && <div className="show_detail2 semibold">{label2_1}</div>}
          {label3 && <div className="show_detail3">{label3}</div>}
        </div>
      </div>
    </BoxProduct2Styled>
  );
};

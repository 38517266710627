import React from 'react';
import { BoxPackageStyled } from './styled';
import cx from 'classnames';

export const BoxPackage = ({ theme_standard_1, dataCheckup }) => {
  const customClass = cx({
    theme_standard_1: theme_standard_1,
  });
  return (
    <BoxPackageStyled>
      <div className={customClass}>
        <div className="txt_head">แนะนำ</div>
        <div className="group_top">
          <div className="left_layout position_center">
            <img
              className="logo_show"
              src={dataCheckup?.image_uri}
              alt="logo show"
            />
          </div>
          <div className="right_layout">
            <div dangerouslySetInnerHTML={{ __html: dataCheckup?.name }} />
            <div
              dangerouslySetInnerHTML={{ __html: dataCheckup?.description }}
            />
          </div>
        </div>
        <div className="bottom_label">
          เงื่อนไขการใช้บริการ :{' '}
          <span className="red_font">
            ***กรุณานัดกับโรงพยาบาลก่อนเข้ารับบริการ***
          </span>
        </div>
      </div>
    </BoxPackageStyled>
  );
};

import styled from 'styled-components';
import { SCREEN, COLOR, FONT } from 'assets';
// import { iconImages } from "assets";

export const RegisterContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(
    to bottom,
    #0253a3,
    #006fb4,
    #0089bb,
    #00a1bb,
    #2eb7b8
  );
  min-height: calc(100% - 87px);
  color: ${COLOR.WHITE_1};
  font-size: ${FONT.SIZE_24};

  overflow-y: auto;
  position: relative;
  padding: 20px 10px;
  &::-webkit-scrollbar {
    width: 0px;
  }
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .back_layout {
    font-size: ${FONT.SIZE_14};
    cursor: pointer;
    position: absolute;
    top: 70px;
    left: 340px;
  }
  .layout_detail {
    width: 40%;
    .show_logo {
      width: 50px;
      height: 50px;
      .img_show {
        width: 100%;
      }
    }
    .body_show {
      margin-top: 20px;
      width: 100%;
    }
  }

  .btn_layer {
    display: flex;
    margin-top: 103px;
    justify-content: flex-end;
    .show_button {
      width: 70%;
      &.full_width {
        width: 100%;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .back_layout {
      left: 140px;
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    min-height: calc(100vh - 89px);
    .back_layout {
      left: 110px;
    }
    .layout_detail {
      width: 45%;
    }
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .layout_detail {
      width: 60%;
    }
    .back_layout {
      left: 30px;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    min-height: calc(100vh - 89px);
    .back_layout {
      left: 26px;
      top: 20px;
    }
    .layout_detail {
      width: 90%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    height: calc(100vh - 70px);
    .back_layout {
      left: 10px;
      top: 26px;
    }
    .layout_detail {
      width: 100%;
    }
  }
`;

import styled from 'styled-components';
import { SCREEN } from 'assets';
import { iconImages } from 'assets';

export const QuestionContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  width: 100vw;
  min-height: calc(100vh - 87px);
  background-image: url(${iconImages['dot_bg.png']});
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top 0;
  background-color: #f8f8f8;

  padding: 20px 0px;
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_back {
    max-width: 80%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .theme_standard {
    .btn_layout {
      text-align:center;
    }
  }
  .group_layout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .box_question {
      width: 500px;
      height: auto;
      min-height: 500px;
      border-radius: 15px;
      border: 2px solid #bcd5df;
      padding: 2%;
      .header {
        display: flex;
        align-items: center;
        .img_logo {
          width: 60px;
          border-radius: 50%;
          border: 2px solid #bcd5df;
        }
        .title {
          font-weight: bold;
          font-size: 18px;
          color: #2f4f74;
          width: 60%;
          margin-left: 20px;
        }
      }
      .body {
        width: 500px;
        height: auto;
        display: flex;
        padding-top: 30px;
        .question {
          width: 50%;
          .header_question {
            display: flex;
            img {
              width: 25px;
              height: 25px;
              object-fit: contain;
              margin-right: 20px;
            }
            .test_header_question {
              font-size: 14px;
              color: #2f4f74;
              width: 180px;
            }
          }
          .btn_question {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .btn_next {
        margin-bottom: 50px;
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
  }
  @media (max-width: ${SCREEN.PHONE}) {
    padding: 20px;
    .link_back {
      max-width: 100%;
    }
    .group_layout {
      .box_question {
        width: 100%;
        .body {
          width: 100%;
          display: flex;
          flex-direction: column;
          .question {
            width: 100%;
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            .header_question {
              .test_header_question {
                width: auto;
              }
            }
          }
        }
      }
    }
    // overflow-y:auto;
    // padding-top:80px;
    // .box_question {
    //   width: 100vw;
    //   height: 400px;
    //   border-radius: 15px;
    //   border: none;
    //   .header {
    //     margin-top:-80px;
    //     padding-left:20px;
    //     .title {
    //
    //       font-weight: 500;
    //       font-size: 16px;
    //       color: #2f4f74;
    //       width: 60%;
    //     }
    //   }
    //   .body {
    //     flex-direction: column;
    //     overflow-y: auto;
    //     height: auto;
    //     width: 100%;
    //     .question {
    //       width: 100%;
    //       .header_question {
    //         align-items: center;
    //         justify-content: center;
    //       }
    //       .btn_question {
    //         align-items: center;
    //       }
    //     }
    //   }
    // }
    // .btn_next {
    //   margin-top: -30px;
    // }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
  }
`;

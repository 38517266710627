import styled from "styled-components"
import {
    COLOR,
    FONT,
    SCREEN
} from 'assets'

export const ShowDetailOrderStyled = styled.div`
/*===============================================
 Container 
===============================================*/
width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        width: 100%;
        
        font-size: ${FONT.SIZE_16};
        color: ${COLOR.BLUE_2};
        display: flex;
        .left_layout {
            width: 50%;
            display: flex;
            align-items: center;
            .img_scr {
                width: 100%;
            }
        }
        .right_layout {
            width: 50%;
            padding: 0px 15px;
            .title {
                font-size: ${FONT.SIZE_25};
                font-weight: bold;
                margin-bottom: 8px;
            }
            .group_label {
                margin-bottom: 8px;
                .top_label {
                    color: ${COLOR.GRAY_6};
                }
                .bottom_label {
                    font-weight: bold;
                }
                &.no_margin {
                    margin-bottom: 0px;
                }
            }
        }
    }

    @media(max-width: ${SCREEN.TABLET}){
        .theme_standard {
            
        }
    }
    @media(max-width: ${SCREEN.MINI_TABLET}){
        .theme_standard {
            
        }
    }
    @media(max-width: ${SCREEN.PHONE}){
        .theme_standard {
            padding: 15px;
            border: 1px solid rgb(152,152,152,0.1);
            border-radius: 8px;
            .left_layout {
                
            }
            .right_layout {
                width: 97%;
                padding: 0px 10px;
            }
        }
    }
`
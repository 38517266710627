import React from "react";
import { StandardNoSymptomFormStyled } from "./styled";
import { iconImages } from "assets";
import { Button } from "../../../components/Button";
import { LogoLoadingFade } from "components";

class StandardNoSymptomForm extends React.Component {
  state = { loading: true };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  };

  handleClickButton = (value) => {
    const { handleClickAns } = this.props;
    handleClickAns(String(value));
  };

  render() {
    const { question } = this.props;
    const { loading } = this.state;

    return (
      <StandardNoSymptomFormStyled>
        {question && question.answers && (
          <>
            {loading ? (
              <LogoLoadingFade />
            ) : (
              <img
                className="img_logo"
                src={iconImages["logo_question.png"]}
                alt="logo well"
              />
            )}
          </>
        )}
        <div className="title">{question && question.label}</div>
        {question && question.description && (
          <div className="title2">{question.description}</div>
        )}
        <div className="button_layout">
          {question &&
            question.answers &&
            question.answers.map((e, i) => (
              <div key={i}>
                <Button
                  width="200px"
                  // height="45px"
                  font="14px"
                  height="auto"
                  padding="10px"
                  theme_standard
                  style={{ marginTop: 20 }}
                  handleClickButton={() => this.handleClickButton(e.id)}
                  backgroundColor={e.is_unknown ? "#ffffff" : ""}
                  color={e.is_unknown ? "#000000" : ""}
                  weight={e.is_unknown ? "" : "500"}
                >
                  {e.label}
                </Button>
              </div>
            ))}
        </div>
      </StandardNoSymptomFormStyled>
    );
  }
}

export default StandardNoSymptomForm;

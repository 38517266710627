import styled from 'styled-components';
import { SCREEN, COLOR, FONT } from 'assets';

export const HealthInsurancerContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .loading {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.RED_1};
    margin: 0px;
  }

  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    align-items: center;
    justify-content: center;
    background: white;
    width: 100%;
    height: 100vh;
    z-index: 99;
    &.active {
      display: flex;
    }
  }

  .is_desktop {
    padding: 16px;
    padding-bottom: 0px;
    position: relative;
    .mb_bg {
      display: none;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: auto;
      z-index: -1;
      width: 100%;
    }
    .btn_back_wrap {
      max-width: 920px;
      width: 100%;
      margin: auto;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .btn_mb_back_wrap {
      display: none;
      margin-top: 44px;
    }
    .location_container {
      max-width: 704px;
      width: 100%;
      margin: auto;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #284d72bf;
        margin-bottom: 25px;
      }
      .box_show {
        margin-bottom: 24px;
        .bg_show {
          height: 130px;
          width: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 8px;
          padding: 13px 16px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .box_label_show {
            font-size: ${FONT.SIZE_16};
            color: ${COLOR.PINK_3};
            width: 50%;
            .bText {
              font-size: ${FONT.SIZE_18};
              font-weight: bold;
              color: ${COLOR.PINK_3};
            }
          }
        }
      }

      .box_mobile_show {
        display: none;
        margin-bottom: 16px;
        border: 1px solid #8e8e8e1a;
        border-radius: 8px;
        padding: 12px 16px;
        align-items: center;
        column-gap: 18px;
        background: white;
        .bms_left_col {
          flex-shrink: 0;
          width: 62px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .bms_right_col {
          .bms_title {
            color: #cf1a71;
            font-size: ${FONT.SIZE_14};
            font-weight: bold;
          }
          .bms_sub_title {
            color: #74083c;
            font-size: ${FONT.SIZE_14};
          }
        }
      }

      .title_sec_2 {
        font-size: 19px;
        color: #003564;
        font-weight: bold;
        margin-bottom: 14px;
      }
      // .row_2_col {
      //   display: flex;
      //   flex-wrap: wrap;
      //   column-gap: 24px;
      //   row-gap: 36px;
      //   margin-bottom: 37px;
      //   .item_wrap {
      //     flex: 1;
      //   }
      // }
      .insurance_row {
        display: flex;
        flex-wrap: wrap;
        column-gap: 55px;
        row-gap: 40px;
        .insurance_col {
          width: calc(50% - 28px);
        }
      }
    }
  }

  // .is_mobile {
  //   display: none;
  //   position: relative;
  //   .bg_top {
  //     position: absolute;
  //     z-index: -1;
  //     height: 140px;
  //     width: 100%;
  //     background-size: cover;
  //     background-repeat: no-repeat;
  //     background-position: top;
  //   }
  //   .container {
  //     padding: 16px;
  //     padding-bottom: 0px;
  //     height: 100%;
  //     .m_btn_back_wrap {
  //       margin-bottom: 45px;
  //     }
  //     .m_title {
  //       font-size: 20px;
  //       font-weight: bold;
  //       color: #003564;
  //       margin-bottom: 17px;
  //       text-align: center;
  //     }
  //     .m_box_show {
  //       border-radius: 8px;
  //       margin-bottom: 16px;
  //       background: ${COLOR.WHITE_1};
  //       border: 1px solid #8e8e8e1a;
  //       padding: 13px 16px;
  //       display: flex;
  //       .box_image {
  //         margin-right: 18px;
  //         width: 62px;
  //         height: 61px;
  //       }
  //       .group_label {
  //         font-size: ${FONT.SIZE_14};
  //         color: ${COLOR.PINK_3};
  //         .bText {
  //           color: #cf1a71;
  //           font-size: ${FONT.SIZE_16};
  //           font-weight: bold;
  //           color: ${COLOR.PINK_3};
  //         }
  //       }
  //     }
  //     .m_title_sec_2 {
  //       padding-left: 10px;
  //       font-size: 19px;
  //       font-weight: bold;
  //       margin-bottom: 14px;
  //       color: #003564;
  //     }
  //     .m_row {
  //       margin-bottom: 24px;
  //     }
  //   }
  // }

  .btn_layout {
    padding: 15px 32%;
    .btn_end {
      cursor: pointer;
      height: 48px;
      width: 100%;
      border-radius: 22px;
      background-color: #e8e8e8;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2),
        0 4px 20px 0 rgba(0, 0, 0, 0.19);
      color: #000000;
      font-size: 16px;
      font-weight: bold;
      margin-top: 20px;
    }
  }

  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    .is_desktop {
      display: block;
      .mb_bg {
        display: block;
      }
      .btn_mb_back_wrap {
        display: block;
      }
      .btn_back_wrap {
        display: none;
      }
      .location_container {
        .title {
          margin-top: 48px;
          margin-bottom: 18px;
          text-align: center;
          color: #003564;
        }
        .box_show {
          display: none;
        }
        .box_mobile_show {
          display: flex;
        }
        .title_sec_2 {
          font-size: 14px;
        }
        .insurance_row {
          display: flex;
          flex-wrap: wrap;
          column-gap: 55px;
          row-gap: 40px;
          .insurance_col {
            width: 100%;
          }
        }
      }
    }
    .btn_layout {
      padding: 15px;
    }
  }
  //   .is_mobile {
  //     display: block;
  //   }
  //   .btn_layout {
  //     padding: 15px 9%;
  //     .btn_end {
  //       cursor: pointer;
  //       height: 48px;
  //       width: 100%;
  //       border-radius: 22px;
  //       background-color: #e8e8e8;
  //       border: none;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       outline: none;
  //       box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2),
  //         0 4px 20px 0 rgba(0, 0, 0, 0.19);
  //       color: #000000;
  //       font-size: 16px;
  //       font-weight: bold;
  //     }
  //   }
  // }
`;

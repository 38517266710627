import React from 'react';
import { ModalStyled } from './styled';
import cx from 'classnames';

export const Modal = ({
  theme_modal_standard,
  theme_modal_showpic,
  theme_modal_light,
  children,
  isShow,
  handleClickCloseModal,
  bgColor
}) => {
  const customClass = cx({
    theme_modal_standard: theme_modal_standard,
    theme_modal_showpic: theme_modal_showpic,
    theme_modal_light: theme_modal_light,
  });

  return (
    <ModalStyled
     _isShow={isShow} 
     bgColor={bgColor}
    >
      <div className={customClass}>
        <div className="modal_body">{children}</div>
        <div className="backdrop" onClick={handleClickCloseModal} />
      </div>
    </ModalStyled>
  );
};

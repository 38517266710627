import React from 'react';
import { connect } from 'react-redux';
import { userService } from 'apiService';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Notification } from 'components';
import { PinkAlertMainLayout, WellScreeningMainLayout } from 'mainLayouts';
import { setReduxConfigProject } from 'actions';
import CampaignContainer from 'containers/PinkApp/LandingSystem/CampaignContainer';
import PinkAlertRedirectContainer from 'containers/WellScreenApp/PinkAlertRedirectContainer';
import LoginContainer from 'containers/MemberSystem/LoginContainer';
import FBContainer from 'containers/MemberSystem/FBContainer';
import LineContainer from 'containers/MemberSystem/LineContainer';
import AutoSignupContainer from 'containers/MemberSystem/AutoSignupContainer';
import { Loading } from 'components';
import { TokenManager } from 'contexts';
import { ROUTE_PATH } from 'helpers';

const indexPinkAlertRoutes = [
  { path: '/', exact: true, name: 'home', component: CampaignContainer },
  { path: '/login', exact: true, name: 'home', component: LoginContainer },
  {
    path: '/login/facebook',
    exact: false,
    name: 'home',
    component: FBContainer,
  },
  {
    path: '/login/line',
    exact: false,
    name: 'home',
    component: LineContainer,
  },
  {
    path: ROUTE_PATH.AUTO_SIGNUP,
    exact: false,
    name: 'home',
    component: AutoSignupContainer,
  },
  {
    path: '/pinkalert',
    exact: false,
    name: 'pinkalert',
    component: PinkAlertMainLayout,
  },
];

const indexWellScreeningRoutes = [
  {
    path: '/',
    exact: true,
    name: 'home',
    component: WellScreeningMainLayout,
    redirect: '/login',
  },
  { path: '/login', exact: true, name: 'home', component: LoginContainer },
  {
    path: '/login/facebook',
    exact: true,
    name: 'facebook',
    component: FBContainer,
  },
  {
    path: '/login/line',
    exact: true,
    name: 'line',
    component: LineContainer,
  },
  {
    path: '/pinkalert',
    exact: false,
    name: 'pinkalert',
    component: PinkAlertRedirectContainer,
  },
  {
    path: '/wellscreening',
    exact: false,
    name: 'wellscreening',
    component: WellScreeningMainLayout,
  },
];

class Routes extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchDataConfigDomain();
  }

  fetchDataConfigDomain = async () => {
    const hostname = domainList[7] || window.location.hostname.toLowerCase();
    let res = await userService.GET_SETTING_CONFIG_BY_DOMAIN(hostname);
    if (res && res.success) {
      // console.log('config activated');
      this.props.setReduxConfigProject(res.data);
      this.setState({
        config: res.data,
        indexRoutes: res.data.is_pinkalert
          ? indexPinkAlertRoutes
          : indexWellScreeningRoutes,
      });
    }
  };

  render() {
    const { indexRoutes } = this.state;
    return (
      <>
        <Notification />
        {!indexRoutes ? (
          <Loading />
        ) : (
          <TokenManager>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return (
                  <Route
                    exact={prop.exact}
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  >
                    {prop.redirect && <Redirect to={prop.redirect} />}
                  </Route>
                );
              })}
              <Redirect to="/login" />
              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </TokenManager>
        )}
      </>
    );
  }
}

const domainList = [
  'well-pinkalert-beta.invitrace.app',
  'well-herwill-beta.invitrace.app',
  'well-phyathai-beta.invitrace.app',
  'well-fwd-beta.invitrace.app',
  'well-beta.invitrace.app',
  'well-herwill-corporate-beta.invitrace.app',
  'well-corporate-beta.invitrace.app',
  false,
];

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxConfigProject: (data) => dispatch(setReduxConfigProject(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);

import styled from "styled-components";
import { SCREEN } from "assets";

export const LoginFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  input.input {
    
  }
  .form_layout {
    margin: 30px 0px;
    margin-bottom: 15px;
    .layout_top {
      display: flex;
      flex-direction: column;
      margin-top: 45px;
      justify-content: center;
      .txt_login {
        color: #003564;
        font-size: 24px;
      }
      .btn_fb {
        width: 320px;
        height: 45px;
        margin-top: 15px;
        background-color: #3061a1;
        font-size: 14px;
        font-weight: 500;
        border-radius: 9px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        margin-left: auto;
        margin-right: auto;
        .img_facebook {
          margin-right: 10px;
        }
      }
    }
    .txt_middle {
      display: flex;
      justify-content: center;
      color: #3061a1;
      font-size: 12px;
      margin: 10px;
    }
    .group_input {
      text-align: left;
      margin-bottom: 10px;
      .label_name {
        color: #3061a1;
        
        margin: 10px 0;
        font-weight: 600;
        font-size: 13px;
        display: inline-block;
        line-height: 1.5;
      }
    }
    .check_input {
      margin-top: 20px;
      font-size: 13px;
    }
  }
  .btn_next {
    width: 320px;
    height: 45px;
    font-weight: 700;
    margin-top: 10px;
    background-color: #3b96b7;
    
    font-size: 13px;
    border: none;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .form_layout {
      .layout_top {
        .txt_login {
          color: #003564;
          font-size: 24px;
          margin-left: 55px;
          margin-bottom: 10px;
        }
        .btn_fb {
          width: 270px;
          height: 40px;
          margin-left: auto;
          margin-right: auto;
          font-weight: 700;
        }
      }
      .group_input {
        width: 260px;
        margin-left: auto;
        margin-right: auto;
      }
      .check_input {
        margin-left: 55px;
      }
    }
    .btn_next {
      width: 270px;
      height: 40px;
      font-weight: 700;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

import React from 'react';
import { connect } from 'react-redux';
import { WellScreeningMainLayoutStyled } from './styled';
import { Route, Switch } from 'react-router-dom';
import wellScreenRoutes from 'routes/wellScreenRoutes';
import { WellScreenAppWidgets } from 'widgets';
class WellScreeningMainLayout extends React.Component {
  render() {
    return (
      <WellScreeningMainLayoutStyled>
        <WellScreenAppWidgets.SplashScreen>
          <WellScreenAppWidgets.Navbar />
          <Switch>
            {wellScreenRoutes &&
              wellScreenRoutes.map((e, i) => {
                return !e.sub ? (
                  <Route
                    key={i}
                    exact={e.exact}
                    path={e.path}
                    component={e.component}
                  />
                ) : (
                  e.subComponent.map((e2, i2) => (
                    <Route
                      key={i2}
                      exact={e2.exact}
                      path={e2.path}
                      component={e2.component}
                    />
                  ))
                );
              })}
          </Switch>
        </WellScreenAppWidgets.SplashScreen>
      </WellScreeningMainLayoutStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WellScreeningMainLayout);

import styled from 'styled-components';
// import {
//     COLOR,
//     FONT
// } from 'assets'

export const AddressNowStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  // width: 100%;
  /*===============================================
Theme 
===============================================*/
  .address_container {
    padding: 16px;
    -webkit-box-shadow: 9px 11px 8px -3px rgba(0, 0, 0, 0.15);
    box-shadow: 9px 11px 8px -3px rgba(0, 0, 0, 0.15);
    background: #f7f7f7;
    border-radius: 8px;
    .top {
      display: flex;
      column-gap: 8px;
      align-items: center;
      margin-bottom: 10px;
      .txt {
        font-size: 20px;
        font-weight: semibold;
        color: #003564;
      }
    }
    .bottom {
      display: flex;
      column-gap: 5px;
      .content_group {
        display: flex;
        column-gap: 5px;
        .label {
          font-size: 16px;
          font-weight: bold;
          color: #003564;
        }
        .value {
          font-size: 16px;
          font-weight: medium;
          color: #003564;
        }
      }
      .icon_edit_wrap {
        cursor: pointer;
      }
    }
  }

  .theme_standard {
  }
`;

import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { ConfirmOrderFormStyled } from './styled'
import { connect } from 'react-redux';
import { VALIDATE } from "helpers"
import {
    InputText,
    ButtonIcon
} from 'components'

class ConfirmOrderForm extends React.Component {
    state = {

    }

    render() {
        const {
            handleClickConfirmPayment,
            hasPhoneNumber,
            hasEmail,
            hasAddress,
        } = this.props
        return (
            <ConfirmOrderFormStyled>
                <div className="group_input">
                    <div className="label">
                        ที่อยู่สำหรับจัดส่ง
                    </div>
                    <Field
                        theme_normal
                        name="address"
                        component={InputText}
                        validate={VALIDATE.REQUIRE}
                    />
                </div>
                <div className="group_input">
                    <div className="label">
                        เบอร์โทรศัพท์
                    </div>
                    <Field
                        theme_normal
                        name="phone_number"
                        component={InputText}
                        validate={VALIDATE.REQUIRE}
                    />
                </div>
                <div className="group_input no_margin">
                    <div className="label">
                        อีเมล์
                    </div>
                    <Field
                        theme_normal
                        name="email"
                        component={InputText}
                        validate={VALIDATE.EMAIL}
                    />
                </div>
                {
                    hasPhoneNumber && hasEmail && hasAddress && (
                        <div className="btn">
                            <ButtonIcon
                                theme_normal
                                lable1Size="14px"
                                label1="ยืนยันคำสั่งซื้อ"
                                handleOnClick={handleClickConfirmPayment}
                            />
                        </div>
                    )
                }
            </ConfirmOrderFormStyled>
        )
    }
}

const selector = formValueSelector('ConfirmOrderForm');
ConfirmOrderForm = connect((state) => {
    const hasPhoneNumber = selector(state, 'phone_number');
    const hasEmail = selector(state, 'email');
    const hasAddress = selector(state, 'address');
    return {
        hasPhoneNumber,
        hasEmail,
        hasAddress,
    };
})(ConfirmOrderForm);

export default reduxForm({
    form: 'ConfirmOrderForm',  // a unique identifier for this form
    enableReinitialize: true
})(ConfirmOrderForm)

import React from 'react';
import { HistoryServiceStyled } from './styled';
import cx from 'classnames';
import { BoxOrder } from 'components';
import { iconImages } from 'assets';
import { RenderCommaMoney } from 'helpers';
import moment from 'moment';

export const HistoryService = ({
  theme_standard_history,
  data,
  handleClickOrderItemNext,
}) => {
  const customClass = cx({
    theme_standard_history: theme_standard_history,
  });
  return (
    <HistoryServiceStyled>
      <div className={customClass}>
        <div className="title">ประวัติการสั่งซื้อสินค้าและบริการ</div>
        <div className="show_order">
          {data &&
            data.map((e, i) => (
              <div
                key={i + 1}
                className={`box_show ${!e.typeShow && 'disabled'}`}
                onClick={() => {
                  if (e.typeShow) {
                    handleClickOrderItemNext(e.id);
                  }
                }}
              >
                <BoxOrder
                  theme_standard_1
                  src={
                    e.typeShow
                      ? iconImages['testkit_blue3.png']
                      : iconImages['toffy_blue2.png']
                  }
                  title={
                    e.typeShow
                      ? 'Test kit มะเร็งปากมดลูก'
                      : 'บริการตรวจรหัสพันธุกรรม'
                  }
                  amount={e.typeShow && e.amount}
                  orderDate={moment(e.created_at).format('DD/MM/YYYY')}
                  price={
                    e.typeShow
                      ? RenderCommaMoney(e.total_price)
                      : '9:00 - 12:00'
                  }
                  status={
                    e.typeShow
                      ? 'สินค้าชำระแล้ว'
                      : moment(e.end_at).format('DD/MM/YYYY')
                  }
                  label1={e.typeShow ? 'วันที่สั่งซื้อ' : 'วันที่ทำรายการ'}
                  label2={e.typeShow ? 'สถานะ' : 'วันที่นัดรับการติดต่อกลับ'}
                  typeShow={e.typeShow}
                />
              </div>
            ))}
        </div>
      </div>
    </HistoryServiceStyled>
  );
};
// const data2 = [
//     {
//         src: iconImages['testkit_blue3.png'],
//         title: 'Test kit มะเร็งปากมดลูก',
//         amount: '2',
//         orderDate: '21/08/2020',
//         price: '3,180',
//         status: 'สินค้ารอการชำระ',
//         label1: "วันที่สั่งซื้อ",
//         label2: "สถานะ ",
//         typeShow: true
//     },
//     {
//         src: iconImages['toffy_blue2.png'],
//         title: 'บริการตรวจรหัสพันธุกรรม',
//         // amount: '2',
//         orderDate: '21/08/2020',
//         price: '9:00 - 12:00',
//         status: '22/08/2020',
//         label1: "วันที่ทำรายการ",
//         label2: "วันที่นัดรับการติดต่อกลับ",
//         typeShow: false
//     },
//     {
//         src: iconImages['testkit_blue3.png'],
//         title: 'Test kit มะเร็งปากมดลูก',
//         amount: '1',
//         orderDate: '21/08/2020',
//         price: '1,290',
//         status: 'สินค้ารอการชำระ',
//         label1: "วันที่สั่งซื้อ",
//         label2: "สถานะ ",
//         typeShow: true
//     },
// ]

import styled from "styled-components"


export const LabelCustomStyled = styled.div`
/*===============================================
 Container 
===============================================*/
// width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard_label {
   
    }
`
import styled from "styled-components"
import {
    COLOR,
    FONT,
    SCREEN
} from 'assets'

export const BoxQAStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        
        font-size: ${FONT.SIZE_14};
        color: ${COLOR.BLACK_3};
        .title_show {
            text-align: center;
            font-size: ${FONT.SIZE_20};
            font-weight: 600;
            color: ${COLOR.BLUE_2};
            margin: 30px 0px;
        }
        .body_show {
            display: flex;
            justify-content: center;
            padding: 14px;
            border-radius: 8px;
            border: 1px solid ${COLOR.GRAY_12};
            .section_1 {
                margin-bottom: 30px;
                .title_section_1 {
                    font-size: ${FONT.SIZE_20};
                    color: ${COLOR.BLUE_4};
                    margin-bottom: 15px;
                }
                .img_layer {
                    text-align: center;
                    .img_show {
                        width: 50%;
                    }                   
                }
                &.no_margin {
                    margin-bottom: 0px;
                }
            }
            .detail_show {
                line-height: 1.8;
                display: flex;
                flex-direction: column;
                .span_show {

                }
                .red_show {
                    margin-top: 20px;
                    font-size: ${FONT.SIZE_12};
                    color: ${COLOR.RED_4};
                    text-align: center;
                }
            }
        }
    }

    @media(max-width: ${SCREEN.TABLET}){
        .theme_standard {
            // background: pink;
        }
    }
    @media(max-width: ${SCREEN.MINI_TABLET}){
        .theme_standard {
            // background: red;
        }
    }
    @media(max-width: ${SCREEN.PHONE}){
        .theme_standard {
            .body_show {
                .section_1 {
                    .red_show {
                        text-align: left;
                    }
                }
            }
        }
    }
`
import React from "react";
import { MultipleButtonFormStyled } from "./styled";
import { iconImages, COLOR } from "assets";
import { Button } from "../../../components/Button";
import { LogoLoadingFade } from "components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 700, min: 300 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class MultipleButtonForm extends React.Component {
  state = {
    value: null,
    select: [],
    no_symptom: null,
    loading: true,
    page_carousel: [],
  };


  componentDidMount = () => {
    const { question } = this.props;
    let page = [];
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);

    for (
      let index = 0;
      index < Math.ceil(question?.symptoms.length / 10);
      index++
    ) {
      page = [...page, index + 1];
    }
    this.setState({
      page_carousel: page,
    });
  };

  handleClickButton = (id, index, has_symptom) => {
    const { select } = this.state;
    const { max_select, question } = this.props;
    let arr_select = [...select];
    if (has_symptom) {
      if (
        arr_select.find((item) =>
          question.symptoms.find(
            (item2) => item2.has_symptom === 0 && item.id === item2.id
          )
        )
      ) {
        arr_select = [];
      }
      if (arr_select.find((item) => item.id === id)) {
        for (let i = 0; i < arr_select.length; i++) {
          if (arr_select[i].id === id) {
            arr_select.splice(i, 1);
          }
        }
      } else {
        if (arr_select.length < max_select) {
          arr_select = [...arr_select, { id: id }];
        } else {
          arr_select.splice(0, 1);
          arr_select = [...arr_select, { id: id }];
        }
      }
    } else {
      this.setState({
        no_symptom: id,
      });
      arr_select = [{ id: id }];
    }

    this.setState({
      select: arr_select,
    });
  };

  handleSubmit = () => {
    const { select } = this.state;
    const { handleSubmitAns } = this.props;
    handleSubmitAns(select);
  };

  render() {
    const { question, max_select, scheme } = this.props;
    const { select, loading, page_carousel } = this.state;
    return (
      <MultipleButtonFormStyled
        window_width={Math.ceil(question.symptoms.length / 5) * 200}
      >
        {question && (
          <>
            {loading ? (
              <LogoLoadingFade />
            ) : (
              <img
                className="img_logo"
                src={iconImages["logo_question.png"]}
                alt="logo well"
              />
            )}
          </>
        )}
        <div className="title">{question && question.label}</div>
        <div className="title2">
          (เลือกอาการที่เด่นชัดที่สุด {max_select} ข้อ)
        </div>
        <div className="button_layout">
          <div className="width_btn">
            {question &&
              question.symptoms &&
              question.symptoms.map((e, i) => (
                <div key={i} style={{ marginTop: 10, marginRight: 10 }}>
                  <Button
                    theme_border_question
                    font={e.name_th.length < 20 ? "13px" : "11px"}
                    borderColor={scheme && COLOR.PINK_1}
                    active={
                      select.find((item) => item.id === e.id) ? true : false
                    }
                    handleClickButton={() =>
                      this.handleClickButton(e.id, i + 1, e.has_symptom)
                    }
                  >
                    {e.name_th}
                  </Button>
                </div>
              ))}
          </div>
        </div>
        <div className="button_layout_carousel">
          <div className="width_btn_carousel">
            <div className="carousel_wrap">
              <Carousel
                responsive={responsive}
                arrows={false}
                showDots={question.symptoms.length > 10}
              >
                {page_carousel.map((e, i) => (
                  <div className="layout_carousel" key={i}>
                    {question &&
                      question.symptoms &&
                      question.symptoms
                        .slice((e - 1) * 10, e * 10)
                        .map((e, i) => (
                          <div
                            key={i}
                            style={{ marginTop: 10, marginRight: 10 }}
                          >
                            <Button
                              theme_border_question
                              font={e.name_th.length < 15 ? "13px" : "12px"}
                              borderColor={scheme && COLOR.PINK_1}
                              active={
                                select.find((item) => item.id === e.id)
                                  ? true
                                  : false
                              }
                              handleClickButton={() =>
                                this.handleClickButton(
                                  e.id,
                                  i + 1,
                                  e.has_symptom
                                )
                              }
                            >
                              {e.name_th}
                            </Button>
                          </div>
                        ))}
                  </div>
                ))}
              </Carousel>
              {question.symptoms.length > 10 && (
                <div className="txt_grey">เลื่อนดูตัวเลือกเพิ่มเติมทางขวา</div>
              )}
            </div>
          </div>
        </div>

        <div className="btn_bottom">
          {select.length > 0 && (
            <Button
              width="350px"
              height="40px"
              theme_standard
              handleClickButton={() => this.handleSubmit()}
              backgroundColor={scheme && COLOR.PINK_1}
            >
              ต่อไป
            </Button>
          )}
        </div>
      </MultipleButtonFormStyled>
    );
  }
}

export default MultipleButtonForm;

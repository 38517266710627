import React from 'react'
import { UpLoadProfilePictureStyled } from './styled'
import cx from "classnames";

export const UpLoadProfilePicture = ({ theme_standard, src }) => {
    const customClass = cx({
        "theme_standard": theme_standard
    })
    return (
        <UpLoadProfilePictureStyled>
            <div className={customClass}>
                <div className="show_img">
                    <div className="box_img" style={{ backgroundImage: `url(${src})` }}>
                    </div>
                </div>
                <div className="button_layout">
                    <div className="btn1">
                        อัพโหลดรูปภาพ
                    </div>
                    <div className="btn2">
                        แก้ไขโปรไฟล์
                    </div>
                </div>
            </div>
        </UpLoadProfilePictureStyled>
    )
}
import React from 'react'
import { IconCartStyled } from './styled'
import cx from "classnames";
import { iconImages } from 'assets'

export const IconCart = ({ theme_standard, showDot }) => {
    const customClass = cx({
        "theme_standard": theme_standard
    })
    return (
        <IconCartStyled>
            <div className={customClass}>
                <img
                    className="icon_btn"
                    src={iconImages['cart_blue.png']}
                    alt="cart show"
                />
                {
                    showDot &&
                    <div className="dot_show" />
                }
            </div>
        </IconCartStyled>
    )
}
import React from 'react';

 const CloseHerWill = ({ color = '#4E5B7E' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.001"
      height="13.001"
      viewBox="0 0 13.001 13.001"
    >
      <g id="cancel" transform="translate(0 -0.001)">
        <g
          id="Group_14696"
          data-name="Group 14696"
          transform="translate(0 0.001)"
        >
          <path
            id="Path_22260"
            data-name="Path 22260"
            d="M7.219,6.5,12.852.868A.508.508,0,0,0,12.134.15L6.5,5.783.867.15A.508.508,0,0,0,.149.868L5.782,6.5.149,12.135a.508.508,0,0,0,.718.718L6.5,7.22l5.634,5.634a.508.508,0,0,0,.718-.718Z"
            transform="translate(0 -0.001)"
            fill="#8e8e8e"
          />
        </g>
      </g>
    </svg>
  );
};

export default CloseHerWill

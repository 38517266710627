import React from 'react';

export default function Camera({ color = '#fff', width = "24", height = "14.528" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 14.528">
      <g id="video-camera" transform="translate(-30 -84.586)">
        <g id="Group_60262" data-name="Group 60262" transform="translate(30 84.586)">
          <g id="Group_60261" data-name="Group 60261" transform="translate(0 0)">
            <path id="Path_87260" data-name="Path 87260" d="M13.987,96.512H2.705A2.713,2.713,0,0,0,0,99.217v9.118a2.713,2.713,0,0,0,2.705,2.706H13.987a2.713,2.713,0,0,0,2.705-2.706V99.217A2.7,2.7,0,0,0,13.987,96.512Z" transform="translate(0 -96.512)" fill={color} />
          </g>
        </g>
        <g id="Group_60264" data-name="Group 60264" transform="translate(47.318 86.048)">
          <g id="Group_60263" data-name="Group 60263" transform="translate(0 0)">
            <path id="Path_87261" data-name="Path 87261" d="M346.184,123.891a1.378,1.378,0,0,0-.46.189l-4.22,2.435v6.277l4.247,2.435a1.608,1.608,0,0,0,2.218-.6,1.653,1.653,0,0,0,.216-.812v-8.36A1.628,1.628,0,0,0,346.184,123.891Z" transform="translate(-341.504 -123.847)" fill={color} />
          </g>
        </g>
      </g>
    </svg>
  );
}

import React from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { SelectSubjectContainerStyled } from "./styled";
import { setReduxSubjectSelect } from "actions";
import { ROUTE_PATH } from "helpers";
import { iconImages } from "assets";
import { Button, BoxLink, LogoLoadingFade } from "components";

class SelectSubjectContainer extends React.Component {
  state = {
    loading_question: true,
  };

  componentDidMount() {
    this.scrollToTop();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    setTimeout(() => {
      this.setState({
        loading_question: false,
      });
    }, 1500);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickButton = () => {
    ReactGA.event({
      category: "wellscreening_has_symptom",
      action: "has_symptom_select_me",
    });

    const { authRedux, setReduxSubjectSelect } = this.props;
    setReduxSubjectSelect({
      porfile: "me",
      profile_id: authRedux.id,
      gender: authRedux.gender,
    });
    this.props.history.push(ROUTE_PATH.PRE_CHECK);
  };

  handleClickOther = () => {
    ReactGA.event({
      category: "wellscreening_has_symptom",
      action: "has_symptom_select_other",
    });

    this.props.history.push(ROUTE_PATH.CONSULT_GENETIC, { type: "question" });
  };

  handleClickBackPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  render() {
    const {loading_question} = this.state;
    return (
      <SelectSubjectContainerStyled>
        <div className="link_back" onClick={() => this.handleClickBackPage()}>
          <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
        </div>
        <div className="container">
          <div className="layout_detail">
            {loading_question ? (
              <LogoLoadingFade />
            ) : (
              <img
                className="img_logo"
                src={iconImages["logo_question.png"]}
                alt="logo well"
              />
            )}
            <div className="title">
              คุณกำลังสอบถามอาการ และปัจจัยเสี่ยงของโรคมะเร็งสำหรับใคร
            </div>
            <Button
              width="400px"
              // height="45px"
              font="14px"
              height="auto"
              padding="10px"
              theme_standard
              handleClickButton={this.handleClickButton}
              style={{ marginTop: 20 }}
              className="btn_bottom"
            >
              ตนเอง
            </Button>
            <Button
              width="400px"
              font="14px"
              height="auto"
              padding="10px"
              theme_standard
              backgroundColor="#efefef"
              color="#000000"
              style={{ marginTop: 20 }}
              handleClickButton={this.handleClickOther}
              className="btn_bottom"

            >
              บุคคลอื่น
            </Button>
            {/* <div className="question_wrap">{renderQuestion}</div>
              <div className="choice_wrap">{renderChoice}</div> */}
          </div>
        </div>
      </SelectSubjectContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  subject: state.subject,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxSubjectSelect: (data) => dispatch(setReduxSubjectSelect(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectSubjectContainer);

import styled from 'styled-components';

export const HopitalItemType4Styled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  padding: 15px 10px;
  border-bottom: 1px solid #00356433;
  .row_1 {
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    width: 100%;
    .left_col {
      flex-shrink: 0;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 144px;
      height: 144px;
      .logo_h {
        object-fit: cover;
      }
    }
    .right_col {
      flex: 1;
      .txt {
        font-size: 16px;
        font-weight: 400;
        color: #376ebf;
        margin-bottom: 4px;
        // &.name {
        //   min-height: 90px;
        // }
        &.gray {
          color: #767676;
        }
        &.mb_20 {
          margin-bottom: 20px;
        }
      }
      .btn_status {
        padding: 5px;
        border-radius: 8px;
        border: 1px solid #56bfb0;
        background: #ffff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 88px;
        margin-bottom: 16px;
        &.offline {
          border-color: #8e8e8e;
          .round {
            background: #8e8e8e;
          }
        }
        .round {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: #2ad900;
        }
        .txt_status {
          margin-left: 5px;
          color: #767676;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
  .row_2 {
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .left_col {
      width: 144px;
      .rang_row {
        display: flex;
        column-gap: 10px;
        align-items: center;
        .txt {
          font-size: 18px;
          font-weight: medium;
          color: #848484;
        }
      }
    }
    .right_col {
      flex: 1;
      .btn_call {
        background: #cf1a71;
        cursor: pointer;
        display: flex;
        column-gap: 5px;
        justify-content: center;
        align-items: center;
        border-radius: 22px;
        max-width: 360px;
        width: 100%;
        padding: 5px;
        .btn_txt {
          font-size: 19px;
          font-weight: bold;
          color: white;
        }
        &.offline {
          background: #8e8e8e;
          .btn_txt {
            font-size: 18px;
            font-weight: 400;
            color: white;
          }
        }
      }
    }
  }
`;

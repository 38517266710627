import React from 'react';
import { InputStyled } from './styled';
import cx from 'classnames';

export const Input = ({
  theme_standard,
  theme_standard_2,
  label,
  input,
  type,
  disabled,
  width,
  showOnly,
  placeholder,
  meta: { touched, error },
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
  });

  return (
    <InputStyled isInvalid={touched && error} width={width}>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        {showOnly ? (
          input.value
        ) : (
          <>
            <input
              type={type || 'text'}
              {...input}
              disabled={disabled}
              placeholder={placeholder}
            />
            {touched && error && <div className="error">{error}</div>}
          </>
        )}
      </div>
    </InputStyled>
  );
};

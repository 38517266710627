import styled from "styled-components"
import {
    COLOR,
    FONT,
    SCREEN
} from 'assets'

export const BoxMember3Styled = styled.div`
/*===============================================
 Container 
===============================================*/
    width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        
        font-size: ${FONT.SIZE_14};
        color: ${COLOR.BLUE_2};
        width: 100%;
        // background: ${COLOR.WHITE_1};
        // border: 1px solid ${COLOR.BLUE_4};
        // border-radius: 8px;
        // padding: 20px;
        // box-shadow: 6px 7px 11px -8px rgba(88,88,88,0.32);
        // display: flex;
        // flex-direction: column;
        // align-items: center;


        .detail_show {
            width: 100%;
            padding: 18px;
            background: ${COLOR.GRAY_10};
            border: 1px solid #e6e6e6;
            border-radius: 8px;
            line-height: 1.8;
            .show_name {
                font-weight: bold;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .btn2 {
                    padding: 8px;
                    font-size: ${FONT.SIZE_12};
                    color: ${COLOR.BLUE_4};
                    // width: 100%;
                    min-width: 90px;
                    border-radius: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    border: 1px solid ${COLOR.BLUE_4};
                }
            }
            .group_detail {
                display: flex;
                width: 100%;
                .group_detail_left {
                    width: 50%;
                    .group_label {
                        .label {
                            font-size: ${FONT.SIZE_14};
                            font-weight: bold;
                        }
                    }
                }
                .group_detail_right {
                    width: 50%;
                    .group_label {
                        .label {
                            font-size: ${FONT.SIZE_14};
                            font-weight: bold;
                        }
                    }
                }
            }
            .btn {
                padding: 8px;
                font-size: ${FONT.SIZE_14};
                color: ${COLOR.BLUE_4};
                width: 100%;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 1px solid ${COLOR.BLUE_4};
                margin-top: 10px;
            }
        }
    }

    @media(max-width: ${SCREEN.TABLET}){
        .theme_standard {
            
        }
    }
    @media(max-width: ${SCREEN.MINI_TABLET}){
        .theme_standard {
            
        }
    }
    @media(max-width: ${SCREEN.PHONE}){
        .theme_standard {
            
        }
    }
`
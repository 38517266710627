import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const ShowLabelStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_red {
        
        width: 100%;
        padding: ${props => props.noPadding ? '0px' : '4px;'};
        background: #E93838;
        color: ${COLOR.WHITE_1};
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${props => props.fontSize ? props.fontSize : FONT.SIZE_14};
        font-weight: bold;
        border-radius: 14px;
        border: 1px solid ${COLOR.GRAY_4};
    }
    .theme_blue {
        
        box-shadow: 6px 7px 11px -8px rgba(88,88,88,0.32);
        width: 100%;
        padding: 12px;
        background: ${COLOR.LINEAR_BLUE1};
        color: ${COLOR.WHITE_1};
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${FONT.SIZE_16};
        font-weight: 600;
        border-radius: 22.5px;
        border: 1px solid ${COLOR.GRAY_4};
        cursor: pointer;
    }
    .theme_gray {
        
        box-shadow: 6px 7px 11px -8px rgba(88,88,88,0.32);
        width: 100%;
        padding: 12px;
        background: ${COLOR.GRAY_9};
        color: ${COLOR.BLUE_4};
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${FONT.SIZE_16};
        font-weight: 600;
        border-radius: 22.5px;
        border: 1px solid ${COLOR.BLUE_3};
        cursor: pointer;
    }
    .theme_white {
        
        box-shadow: 6px 7px 11px -8px rgba(88,88,88,0.32);
        width: 100%;
        padding: 12px;
        background: ${COLOR.GRAY_1};
        color: ${COLOR.BLUE_4};
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${FONT.SIZE_14};
        font-weight: bold;
        border-radius: 22.5px;
        border: 1px solid ${COLOR.BLUE_5};
        cursor: pointer;
    }
    .theme_gray2 {
        
        box-shadow: 6px 7px 11px -8px rgba(88,88,88,0.32);
        width: 100%;
        padding: 10px;
        background: ${COLOR.GRAY_9};
        color: ${COLOR.BLUE_2};
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${FONT.SIZE_14};
        font-weight: bold;
        border-radius: 22.5px;
        border: 1px solid ${COLOR.GRAY_12};
        cursor: pointer;
        font-weight: 600;
        &:focus {
            border: 3px solid ${COLOR.BLUE_4};
            outline: none;
            color: ${COLOR.BLUE_4};
            font-weight: 800;
        }
    }
`
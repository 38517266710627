import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const ConfirmOrderFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    width: 100%;
    
    font-size: ${FONT.SIZE_16};
    color: ${COLOR.BLUE_2};
   .group_input {
       margin-bottom: 20px;
       .label {
            margin-bottom: 10px;
            font-weight: 600;
       }
       &.no_margin {
           margin-bottom: 0px;
       }
   }
   .btn {
       margin-top: 60px;
   }
`
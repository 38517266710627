import React from 'react';
import { PenddingListStyled } from './styled';
import cx from 'classnames';
import { BoxOrder } from 'components';
import { iconImages } from 'assets';
import { convertDateShow2, RenderCommaMoney } from 'helpers';

export const PenddingList = ({
  theme_standard,
  data,
  handleClickNext,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <PenddingListStyled>
      <div className={customClass}>
        <div className="title">รายการสินค้ารอการชำระ</div>
        <div className="show_order">
          {data &&
            data.map((e, i) => (
              <div key={i} className="box_show">
                <BoxOrder
                  theme_standard_1
                  id={e.id}
                  src={iconImages['testkit_blue3.png']}
                  label1="วันที่สั่งซื้อ"
                  title={e.product.name}
                  amount={e.amount}
                  orderDate={convertDateShow2(e.order_at)}
                  price={RenderCommaMoney(e.total_price)}
                  label2="สถานะ"
                  status="สินค้ารอการชำระ"
                  showButton
                  handleClickNext={handleClickNext}
                />
              </div>
            ))}
        </div>
      </div>
    </PenddingListStyled>
  );
};

import apiIteleService from '../apiIteleService';

const apiPath = '/service-api';

export const iteleService = {
  ///SERVICE HOSPITAL
  GET_ITELE_ACCOUNT_BY_SERVICE_CODE: (service_code, queryString) => {
    return apiIteleService.get(
      `${apiPath}/services/${service_code}/accounts${
        queryString ? queryString : ''
      }`
    );
  },

  GET_ITELE_ACCOUNT_BY_SERVICE_CODE_ACOUNTID: (service_code, account_id) => {
    return apiIteleService.get(
      `${apiPath}/services/${service_code}/accounts/${account_id}/patient_uri`
    );
  },
};

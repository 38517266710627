import React from 'react';

export default function Location({ color = '#1278ab' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.75"
      height="29"
      viewBox="0 0 21.75 29"
    >
      <path
        id="location-pin"
        d="M13.875,0A10.927,10.927,0,0,0,3,10.954C3,19.536,12.853,28.4,13.272,28.77a.908.908,0,0,0,1.206,0C14.9,28.4,24.75,19.536,24.75,10.954A10.927,10.927,0,0,0,13.875,0Zm0,16.917a6.042,6.042,0,1,1,6.042-6.042A6.049,6.049,0,0,1,13.875,16.917Z"
        transform="translate(-3)"
        fill={color}
      />
    </svg>
  );
}

import React from "react";
import { InsuranceStyled } from "./styled";
import { BoxProduct2, BoxLabel, ButtonIcon } from "components";
import { iconImages } from "assets";
import { RenderCommaMoney } from "helpers";

export const Insurance = ({
  handleClickBackFirstPage,
  data,
  handleSelectPackage,
}) => {
  return (
    <InsuranceStyled>
      <div className="title_obj">ประกันมะเร็งออนไลน์</div>
      <div className="group_obj">
        <div className="left_obj">
          <BoxProduct2
            not_cursor
            theme_standard
            src={data?.image_uri}
            title2="FWD Easy E-CANCER"
            label={data?.description}
          />
        </div>
        <div className="right_obj">
          <BoxProduct2
            theme_standard
            not_cursor
            src={iconImages["product4.png"]}
            title1="ทำไมต้อง"
            title2="FWD Easy E-CANCER"
            label2="ประกันมะเร็งหนึ่งเดียวที่ได้รับรางวัล"
            label2_1="สุดยอดสินค้าและบริการนวัตกรรมยอดเยี่ยมแห่งปี 2563"
            label3="PRODUCT INNOVATION AWARDS 2020"
          />
          {/* <div className="campaign_container">
            <div className="campagin_row between mb_16">
              <div className="campaign_icon_wrap">
                <img
                  className="campaign_icon"
                  src={iconImages['campaign-icon.png']}
                  alt="cancer icon"
                />
                <div className="txt_title">World Cancer Day 2021</div>
              </div>
              <div className="txt_date">4 - 6 ก.พ. 64</div>
            </div>
            <div className="campagin_row">
              <div className="campaign_left_col">
                <img
                  className="campaign_img"
                  src={iconImages['campaign-img.png']}
                  alt="fwd gift voucher"
                />
              </div>
              <div className="campaign_right_col">
                <div className="campagin_txt">ซื้อประกันวันนี้</div>
                <div className="campagin_txt">รับบัตรกำนัลเซ็นทรัล</div>
                <div className="campagin_txt mb_11">มูลค่าสูงสุด 800 บาท</div>
                <div className="btn_wrap">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data && data.redirect_uri}
                  >
                    <ButtonIcon
                      theme_blue
                      lable1Size="16px"
                      label1="ซื้อประกันออนไลน์"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="title_sec_2">โปรแกรมคุ้มครองทั้งหมด</div>
      <div className="group_obj no_margin">
        <div className="left_obj">
          {data &&
            data.packages.map((e, i) => (
              <div
                className="show_obj"
                key={i + 1}
                onClick={() => handleSelectPackage(e.id)}
              >
                <BoxLabel
                  theme_standard
                  label1={e.name}
                  label3="ตรวจพบมะเร็งรับ"
                  label4={`${RenderCommaMoney(Number(e.full_price))} บาท`}
                  label5={`เบี้ยประกัน เริ่มต้น ${RenderCommaMoney(
                    Number(e.final_price)
                  )} บาท/ปี`}
                />
              </div>
            ))}
        </div>
        <div className="right_obj">
          {/* <div className="right_col">
            <BoxProduct2
              theme_standard
              src={iconImages['product4.png']}
              title1="ทำไมต้อง"
              title2="FWD Easy E-CANCER"
              label2="ประกันมะเร็งหนึ่งเดียวที่ได้รับรางวัล"
              label2_1="สุดยอดสินค้าและบริการนวัตกรรมยอดเยี่ยมแห่งปี 2563"
              label3="PRODUCT INNOVATION AWARDS 2020"
            />
          </div> */}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={data && data.redirect_uri}
          >
            <div className="btn">
              <ButtonIcon
                theme_normal
                lable1Size="16px"
                label1="ซื้อประกันออนไลน์"
              />
            </div>
          </a>
          <div className="btn_home_wrap">
            <ButtonIcon
              theme_gray
              lable1Size="16px"
              label1="กลับสู่หน้าแรก"
              handleOnClick={handleClickBackFirstPage}
            />
          </div>
        </div>
      </div>
    </InsuranceStyled>
  );
};

import React from 'react';
import { ShareBtnPinkAlertStyled } from './styled';
import { iconImages } from 'assets';
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from 'react-share';

const ShareBtnPinkAlert = () => {
  const copyFunction = () => {
    var copyText = document.createElement('textarea');
    copyText.value = process.env.REACT_APP_SELF_ENDPOINT;
    document.body.append(copyText);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    copyText.remove();
    // alert('Copied the URL: ' + copyText.value);
  };

  return (
    <ShareBtnPinkAlertStyled>
      <div className="layout_share">
        <FacebookShareButton
          url={'https://pinkalert.wellcancer.com'}
          quote={
            'PINK Alert : Check & Share Project : "รู้-เพราะ-รัก" รู้ทัน เพราะมะเร็งเต้านม รักษาได้'
          }
          hashtag="#PinkAlert2020"
        >
          <img
            className="icon_share icon_share_logo"
            src={iconImages['share_facebook.png']}
            alt="logo well"
          />
        </FacebookShareButton>

        <LineShareButton
          url={'https://pinkalert.wellcancer.com'}
          title={
            'PINK Alert : Check & Share Project : "รู้-เพราะ-รัก" รู้ทัน เพราะมะเร็งเต้านม รักษาได้'
          }
        >
          <img
            className="icon_share icon_share_logo"
            src={iconImages['line_share.png']}
            alt="logo well"
          />
        </LineShareButton>

        <TwitterShareButton
          url={'https://pinkalert.wellcancer.com'}
          title={
            'PINK Alert : Check & Share Project : "รู้-เพราะ-รัก" รู้ทัน เพราะมะเร็งเต้านม รักษาได้'
          }
          hashtags={['PinkAlert2020', 'รู้เพราะรัก']}
        >
          <img
            className="icon_share icon_share_logo"
            src={iconImages['twit_share.png']}
            alt="logo well"
          />
        </TwitterShareButton>

        <div className="icon_share">
          <input
            type="text"
            value={'https://herwill.wellcancer.com'}
            id="myInput"
            hidden
          />
          <img
            onClick={copyFunction}
            className=" icon_share_logo"
            src={iconImages['link_share.png']}
            alt="logo well"
          />
        </div>
      </div>
    </ShareBtnPinkAlertStyled>
  );
};

export default ShareBtnPinkAlert;

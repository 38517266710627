import styled from 'styled-components';
import { SCREEN, iconImages } from 'assets';

export const TermOfUseContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background-color: #f8f8f8;
  min-height: calc(100% - 80px);

  background-image: url(${iconImages['dot_bg.png']});
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top;
  overflow-y: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  /* justify-content:center;
  align-items:center; */
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_back {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  .box_layout {
    width: 450px;
    color: #131313;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .title {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .sub_title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .content_container {
      padding: 1rem;
      border: 2px solid #e9e9e9;
      border-radius: 8px;
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 20px;
      .desciption {
        margin-bottom: 30px;
      }
      .icon_term {
        /* max-width: 100%; */
        width: 65%;
      }
      .title_question {
        font-weight: 700;
        text-decoration: underline;
        margin-bottom: 20px;
      }
      .question_container {
        .question_item {
          margin-bottom: 10px;
          .text_highlight {
            color: #3b96b7;
            font-weight: 700;
          }
        }
      }
      .accept_container {
        margin-top: 80px;
        .input_group {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          input {
            width: 15px;
            height: 15px;
          }
          label {
            margin-left: 5px;
            font-weight: 700;
            color: #000;
          }
        }
        .accept_detail {
          font-size: 13px;
          color: gray;
          .text_highlight {
            text-decoration: underline;
          }
        }
      }
    }
    .show_btn_term {
      width: 100%;
      display: flex;
      justify-content: center;
      .btn_term {
        width: 80%;
      }
    }
    .btn_accept {
      cursor: pointer;
      width: 100%;
      height: 45px;
      margin-top: 10px;
      background-color: #3b96b7;

      font-size: 13px;
      border: none;
      border-radius: 30px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      font-weight: 700;
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .box_layout {
      width: 90%;
      color: #131313;
      .title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .content_container {
        .show_btn_term {
          .btn_term {
            width: 100%;
          }
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    .box_layout {
      width: 90%;
      color: #131313;
      .title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .content_container {
        .show_btn_term {
          .btn_term {
            width: 100%;
          }
        }
      }
    }
  }
`;

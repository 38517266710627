import styled from "styled-components";
import { SCREEN } from "assets";

export const ScreeningProfileFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  input.input {
  }
  .form_layout {
    margin: 10px 0px;
    .header_layout {
      width: 650px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 20px;
      }
      .btn_header {
        display: flex;
        flex-direction: row;
      }
    }
    .user_name {
      font-size: 20px;
      font-weight: 500;
      color: #2f4f74;
      margin: 10px 0;
      width: 650px;
    }
    .flex_layout {
      display: flex;
      width: 650px;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .group_input {
      text-align: left;
      margin-bottom: 10px;
      .theme_standard {
        .react-datepicker-wrapper {
          width: 300px;
          input {
            width: 300px;
          }
        }
      }
      .label_name {
        color: #3061a1;

        margin: 10px 0;
        font-weight: 500;
        font-size: 14px;
        display: inline-block;
        line-height: 1;
      }
    }
    .group_input_weight {
      text-align: left;
      margin-bottom: 10px;
      margin-top: -87px;
      .theme_standard {
        .react-datepicker-wrapper {
          width: 300px;
          input {
            width: 300px;
          }
        }
      }
      .label_name {
        color: #3061a1;

        margin: 10px 0;
        font-weight: 500;
        font-size: 14px;
        display: inline-block;
        line-height: 1;
      }
    }
    .row_layout {
      display: flex;
      .radio_layout {
        width: 50%;
        .layout_YN {
          display: flex;
          margin-bottom: 10px;
          .YN_left {
            width: 50%;
            .label_name {
              color: #3061a1;

              margin-bottom: 10px;
              font-weight: 600;
              font-size: 14px;
              display: inline-block;
              line-height: 1;
            }
          }
          .YN_right {
            width: 50%;
            .label_name {
              color: #3061a1;

              margin-bottom: 10px;
              font-weight: 600;
              font-size: 14px;
              display: inline-block;
              line-height: 1;
            }
          }
        }
        .gender_layout {
          display: flex;
          flex-direction: row;
          // width: 300px;
          // margin-top: 20px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          .label_name {
            color: #3061a1;

            margin: 1px 0;
            font-weight: 600;
            font-size: 14px;
            display: inline-block;
            line-height: 1;
          }
          .gen_opt {
            // margin-bottom: -5px;
          }
        }
      }
      .input_layout {
        width: 50%;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-left: 50px;
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    width: 100%;
    padding: 0px 30px;
    .form_layout {
      padding: 0px;
      .layout_top {
        .txt_login {
          color: #003564;
          font-size: 18px;
          margin-left: 55px;
        }
        .btn_fb {
          width: 250px;
          height: 40px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .group_input {
        width: 100%;
        .theme_standard_dropdown {
          select {
            width: 250px;
          }
        }
        .theme_standard {
          .react-datepicker-wrapper {
            width: 250px;
            input {
              width: 300px;
            }
          }
        }
        .label_name {
          font-size: 13px;
          margin: 10px 0;
        }
      }
      .group_input_weight {
        margin-top: 0px;
        width: 100%;
        .theme_standard_dropdown {
          select {
            width: 250px;
          }
        }
        .theme_standard {
          .react-datepicker-wrapper {
            width: 250px;
            input {
              width: 250px;
            }
          }
        }
        .label_name {
          font-size: 13px;
          margin: 10px 0;
        }
      }
      .row_layout {
        display: flex;
        flex-direction: column;
        .radio_layout {
          width: 100%;
          .layout_YN {
            display: flex;
            margin-bottom: 10px;
            .YN_left {
              width: 50%;
              .label_name {
                color: #3061a1;

                margin-bottom: 10px;
                font-weight: 600;
                font-size: 14px;
                display: inline-block;
                line-height: 1;
              }
            }
            .YN_right {
              width: 50%;
              .label_name {
                color: #3061a1;

                margin-bottom: 10px;
                font-weight: 600;
                font-size: 14px;
                display: inline-block;
                line-height: 1;
              }
            }
          }
          .gender_layout {
            display: flex;
            flex-direction: row;
            // width: 300px;
            // margin-top: 20px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            .label_name {
              color: #3061a1;

              margin: 1px 0;
              font-weight: 600;
              font-size: 14px;
              display: inline-block;
              line-height: 1;
            }
            .gen_opt {
              // margin-bottom: -5px;
            }
          }
        }
        .input_layout {
          width: 100%;
          align-items: center;
          display: flex;
          flex-direction: column;
          margin-left: 0px;
        }
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .form_layout {
      .header_layout {
        .btn_header {
          display: flex;
          flex-direction: column;
        }
      }
      .user_name {
        width: 100%;
      }
      .group_input {
        width: 100%;
        .theme_standard_dropdown {
          select {
            width: 250px;
          }
        }
        .theme_standard {
          .react-datepicker-wrapper {
            width: 250px;
            input {
              width: 250px;
            }
          }
        }
        .label_name {
          font-size: 13px;
          margin: 10px 0;
        }
      }
    }
  }
`;

import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const ProfileFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/

/*===============================================
Theme 
===============================================*/
    width: 100%;
    
    font-size: ${FONT.SIZE_13};
    font-weight: 600;
    color: ${COLOR.BLUE_2};
    .show_img_profile {
        width: 50%;
        margin-bottom: 10px;
    }
    .show_name {
        font-size: ${FONT.SIZE_24};
        margin-bottom: 20px;
    }
    .input_layer {
        display: flex;
        width: 100%;
        .left_input {
            width: 50%;
            padding-right: 30px;
            .group_input {
                margin-bottom: 25px;
                .label {
                     margin-bottom: 15px;
                     font-weight: 600;
                }
                &.no_margin {
                    margin-bottom: 0px;
                }
            }
            .gender_layout {
                display: flex;
                align-items: center;
                margin-bottom: 28px;
            }
            .layout_YN {
                display: flex;
                // padding-left: 30px;
                margin-bottom: 10px;
                .YN_left {
                  width: 50%;
                  .label_name {
                    color: #3061a1;
                    
                    margin-bottom: 10px;
                    font-weight: 600;
                    font-size: 14px;
                    display: inline-block;
                    line-height: 1;
                  }
                }
                .YN_right {
                  width: 50%;
                  .label_name {
                    color: #3061a1;
                    
                    margin-bottom: 10px;
                    font-weight: 600;
                    font-size: 14px;
                    display: inline-block;
                    line-height: 1;
                  }
                }
            }
            .label1 {
                font-size: ${FONT.SIZE_18};
                margin-bottom: 10px;
            }
            .label2 {
                font-size: ${FONT.SIZE_20};
                font-weight: 600;
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                .link_show {
                    font-size: ${FONT.SIZE_14};
                    margin-left: 23px;
                    color: #A7A7A7;
                    text-decoration: underline;
                }
            }
            .add_member {
                display: flex;
                align-items: center;
                .plus_img {
                    width: 26px;
                    height: 26px;
                    .img_show {
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }
                }
                .label3 {
                    font-size: ${FONT.SIZE_20};
                    margin-left: 15px;
                }
            }
        }
        .right_input {
            width: 50%;
            padding-left: 30px;
            .group_input {
                margin-bottom: 25px;
                .label {
                     margin-bottom: 15px;
                     font-weight: 600;
                }
                &.no_margin {
                    margin-bottom: 0px;
                }
            }
            .btn1 {
                padding: 8px;
                font-size: ${FONT.SIZE_12};
                color: ${COLOR.BLUE_4};
                width: 100%;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 1px solid ${COLOR.BLUE_4};
                margin-top: 56px;
            }
            .btn2 {
                padding: 8px;
                font-size: ${FONT.SIZE_12};
                color: ${COLOR.BLACK_1};
                width: 100%;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-top: 32px;
                background: ${COLOR.GRAY_9};
                border: 1px solid ${COLOR.GRAY_12};
                box-shadow: 6px 7px 11px -8px rgba(88,88,88,0.32);
            }
        }
    }
`
import styled, { keyframes } from "styled-components";
import { SCREEN, COLOR } from "assets";
import { fadeInUp } from "react-animations";

const bounceAnimation = keyframes`${fadeInUp}`;
export const SliderFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  width: 420px;
  margin-left: 10px;

  .img_logo {
    width: 50px;
    height: 50px;
  }
  .MuiSlider-root {
    color: ${({ scheme }) => (scheme ? COLOR.PINK_1 : '#5495b4')};
    box-shadow: 2px -1px 34px -8px rgba(0, 0, 0, 0.75);
    padding: 5px 0;
  }
  .MuiSlider-rail {
    height: 8px;
    border-radius: 10px;
    color: #e3e3e4;
    box-shadow: 4px -1px 34px -8px rgba(0, 0, 0, 0.75);
  }
  .MuiSlider-track {
    height: 8px;
    border-radius: 10px;
    box-shadow: 4px -1px 34px -8px rgba(0, 0, 0, 0.75);
  }
  .MuiSlider-thumb {
    width: 25px;
    height: 25px;
    display: flex;
    border: 3px solid #ffffff;
    margin-top: -10px;
    margin-left: -6px;
    box-shadow: 4px -1px 34px -8px rgba(0, 0, 0, 0.75);
    color: ${({ scheme }) => (scheme ? COLOR.PINK_1 : '#5495b4')};
  }
  .txt_detail {
    color: #003564;
    font-size: 18px;
    margin-top: 20px;
    max-width: 400px;
  }
  .txt_detail2 {
    color: grey;
    font-size: 16px;
    margin-top: 10px;
    max-width: 400px;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    color: #2f4f74;
    margin: 20px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .title2 {
    font-size: 14px;
    color: #777777;
    margin: -3px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .title_slide {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
    animation: 2s ${bounceAnimation};
  }
  .label_layout {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    animation: 2s ${bounceAnimation};
  }
  .theme_standard {
    width: 300px;
    display: flex;
    justify-content: center;
    .btn_layout {
      width: 300px;
    }
  }
  .btn_bottom {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media (max-width: ${SCREEN.PHONE}) {
    width: 300px;
  }
`;

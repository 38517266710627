import React from "react";
import { RadioStyled } from "./styled";
import cx from "classnames";

export const Radio = ({
  theme_standard,
  theme_circle_column,
  theme_circle,
  theme_circle2,
  options,
  input,
  disabled,
  padding_left,
  margin_bottom,
  padding_top,
  margin_bottom2,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_circle_column: theme_circle_column,
    theme_circle: theme_circle,
    theme_circle2: theme_circle2,
  });
  return (
    <RadioStyled
      padding_left={padding_left}
      padding_top={padding_top}
      margin_bottom2={margin_bottom2}
    >
      <div className={customClass}>
        <div className="container_wrap">
          <div className="container">
            {options.map((e, i) => (
              <label
                key={i}
                style={{
                  display: "flex",
                  marginBottom: margin_bottom ? margin_bottom : "0px",
                  alignItems: "center",
                  cursor:'pointer',
                }}
              >
                <input
                  type="radio"
                  id={e.id}
                  {...input}
                  value={e.value}
                  checked={
                    e.value.toString() === { ...input }.value.toString()
                      ? true
                      : false
                  }
                  disabled={disabled}
                />
                <div for ={e.id} className="txt_label">
                  {e.label}
                </div>
              </label>
            ))}
          </div>
        </div>
      </div>
    </RadioStyled>
  );
};

import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { HerWillContainerStyled } from './styled';
import { userService } from 'apiService';
import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { ShareGroup } from 'widgets';
import { ROUTE_PATH, RenderCommaNumber } from 'helpers';
import AnimatedNumber from 'animated-number-react';
import { Icons } from 'components';

class HerWillContainer extends React.Component {
  state = {
    screenCount: 0,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_HERWILL_SCREEN_COUNT();
    if (res && res.success) {
      this.setState({
        screenCount: res.data,
      });
    }
  };

  handleClickMemmogram = () => {
    const { configProjectRedux } = this.props;
    ReactGA.event({
      category: configProjectRedux.scheme + '_exit',
      action: 'click_goMammogram',
    });
    this.props.propsHitory.push(ROUTE_PATH.LOCATION_NEAR);
  };

  handleClickHealth = () => {
    const { configProjectRedux } = this.props;

    ReactGA.event({
      category: configProjectRedux.scheme + '_exit',
      action: 'click_healthInsurancer',
    });
    this.props.propsHitory.push(ROUTE_PATH.HEALTH_INSURANCER);
  };

  handleClickToBookAppointment = () => {
    const { configProjectRedux } = this.props;
    ReactGA.event({
      category: configProjectRedux.scheme + '_exit',
      action: 'click_goCancerHospital',
    });
    this.props.propsHitory.push(ROUTE_PATH.PINK_HOSPITAL_CHOOSE);
  };

  handleClickArticle = () => {
    const { configProjectRedux } = this.props;
    ReactGA.event({
      category: configProjectRedux.scheme + '_exit',
      action: 'click_goArticle',
    });
    window.open(
      'https://www.thaibreast.org/TH/breastcancerknowledge.html',
      '_blank'
    );
  };

  handleClickConsult = () => {
    const { configProjectRedux } = this.props;
    ReactGA.event({
      category: configProjectRedux.scheme + '_exit',
      action: 'click_goIteleList',
    });
    this.props.propsHitory.push(ROUTE_PATH.CONSULT_DOCTOR);
  };

  render() {
    const { loading, screenCount } = this.state;
    const { authRedux, handleClickHome } = this.props;
    return (
      <HerWillContainerStyled src={loading ? '' : iconImages['share_bg.svg']}>
        <div className="logo_wrap">
          <img
            className="icon_logo"
            src={iconImages['logo_HerWill.svg']}
            alt="logo well"
          />
        </div>
        <div className="share_detail">
          <div className="box_layout">
            <div className="txt_center">
              <div
                className="txt_normal"
                style={{ fontWeight: 'bold', fontSize: '22px' }}
              >
                ขอบคุณ{' '}
              </div>
              <div className="txt_pink">
                คุณ{authRedux && authRedux.firstname}
              </div>
            </div>
            <div className="txt_center row">
              <div className="txt_normal">คุณได้เข้าร่วมเป็น 1 ใน</div>
              <div className="txt_pink fs_36">
                <AnimatedNumber
                  value={screenCount}
                  formatValue={(count) => RenderCommaNumber(parseInt(count))}
                  duration={1000}
                />
              </div>
            </div>
            <div className="txt_center">
              <div className="txt_normal fs_14 bold">
                เลือกวางแผนและดูแลสุขภาพของคุณ
              </div>
            </div>
            <div className="group_card">
              <div className="box_card">
                <div className="card_wrap">
                  <img
                    className="img_brand"
                    src={iconImages['brand.png']}
                    alt="img_icon"
                  />
                  <div className="group_text">
                    <div className="text1">มะเร็งเต้านม</div>
                    <div className="text2">รู้ไว เริ่มเร็ว หายทัน</div>
                  </div>
                </div>
                <div className="label_show">
                  พบคุณหมอเพื่อตรวจมะเร็งเต้านมที่โรงพยาบาล
                </div>
                <button
                  className="btn_pink_rent"
                  onClick={this.handleClickToBookAppointment}
                >
                  <div className="txt_btn">จองคิวตรวจมะเร็ง</div>
                </button>
              </div>
              <div className="box_card">
                <div className="card_wrap">
                  <img
                    className="img_brand"
                    src={iconImages['brand_2.png']}
                    alt="img_icon"
                  />
                  <div className="group_text_2">
                    <img
                      className="img_itele"
                      src={iconImages['itele_2.svg']}
                      alt="icon show"
                    />
                    <div className="text1">ปรึกษาแพทย์ได้ทุกที่ทุกเวลา</div>
                  </div>
                </div>
                <div className="label_show">
                  ปรึกษาคุณหมอหากท่านมีความกังวลเรื่องอาการทางร่างกาย
                </div>
                <button
                  className="btn_pink_rent"
                  onClick={this.handleClickConsult}
                >
                  <div className="icon_btn">
                    <Icons.Camera />
                  </div>
                  <div className="txt_btn">ปรึกษาแพทย์ผู้เชี่ยวชาญ</div>
                </button>
              </div>
            </div>
            <div className="line" />
            <div className="bot_layout">
              <div className="left_bot">
                <div className="txt_center mb_12">
                  <div className="txt_normal fs_14 bold">บริการอื่นๆ</div>
                </div>
                <div className="row_2_col">
                  <div className="btn_container">
                    <img
                      className="img_icon"
                      src={iconImages['share1.svg']}
                      alt="service etc"
                      onClick={() => this.handleClickMemmogram()}
                    />
                    <button
                      className="btn_pink_sub"
                      onClick={() => this.handleClickMemmogram()}
                    >
                      ข้อมูลศูนย์ตรวจ
                    </button>
                    <div className="btn_txt_detail">
                      ศูนย์ตรวจเมมโมแกรม
                      <br />
                      ใกล้คุณ
                    </div>
                  </div>
                  <div className="btn_container">
                    <img
                      className="img_icon"
                      src={iconImages['share2.svg']}
                      alt="service etc"
                      onClick={() => this.handleClickArticle()}
                    />
                    <button
                      className="btn_pink_sub"
                      onClick={() => this.handleClickArticle()}
                    >
                      อ่านบทความ
                    </button>
                    <div className="btn_txt_detail">
                      เข้าใจและเพิ่มความรู้
                      <br />
                      โรคมะเร็ง
                    </div>
                  </div>
                </div>
                <div className="row_2_col mt">
                  <div className="btn_container">
                    <img
                      className="img_icon"
                      src={iconImages['asure2.svg']}
                      alt="service etc"
                      onClick={() => this.handleClickHealth()}
                    />
                    <button
                      className="btn_pink_sub"
                      onClick={() => this.handleClickHealth()}
                    >
                      ประกันสุขภาพ
                    </button>
                    <div className="btn_txt_detail">
                      วางแผนการเงิน
                      <br />
                      เพื่อสุขภาพของคุณ
                    </div>
                  </div>
                </div>
              </div>
              <div className="right_bot">
                <div className="txt_center">
                  <div className="txt_navy">
                    แชร์ลิงค์ ชวนเพื่อนมาเช็คมะเร็งเต้านม
                  </div>
                </div>
                <ShareGroup.ShareBtnHerWill source={'exit'} />
              </div>
            </div>
            <button className="btn_end" onClick={handleClickHome}>
              <div className="txt_btn">กลับสู่หน้าหลัก</div>
            </button>
          </div>
        </div>
      </HerWillContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HerWillContainer);

import React from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { QuestionnaireContainerStyled } from "./styled";
import { userService } from "apiService";
import { setReduxUserAuth } from "actions";
// import { toast } from "react-toastify";
import { LogoLoadingFade, BoxLink } from "components";
import { iconImages } from "assets";
import Loader from "react-loader-spinner";
class QuestionnaireContainer extends React.Component {
  state = {
    questions: [],
    currentQuestion: 0,
  };

  componentDidMount() {
    this.fetchQuestion();
  }

  fetchQuestion = async () => {
    const { configProjectRedux } = this.props;
    let res =
      configProjectRedux.scheme === "herwill"
        ? await userService.GET_HERWILL_QUESTION_LIST()
        : await userService.GET_PINKALERT_QUESTION_LIST();
    if (res && res.success) {
      // Tracking user event
      ReactGA.event({
        category: "screening",
        action: `screening_${configProjectRedux.scheme}_start`,
      });

      this.setState({
        questionList: res.data.questions,
        loadingFetch: true,
      });
      setTimeout(() => {
        this.setState({
          loadingFetch: false,
        });
      }, 2000);
    }
  };

  handleClickAnswer = (id, value) => {
    const { questionList, currentQuestion } = this.state;

    this.setState(
      (state) => ({
        loadingFetch: true,
        currentQuestion: state.currentQuestion + 1,
        questions: (!id || !value)
          ? [...state.questions]
          : [...state.questions, { id: id, value: value }],
      }),
      () => {
        if (currentQuestion === questionList.length - 1) {
          this.setState({
            loading: true,
          });
          setTimeout(() => {
            this.setState({
              loading: false,
            });
            this.handleSubmitResult();
          }, Math.floor(Math.random() * 500) + 100);
        }
      }
    );
    setTimeout(() => {
      this.setState({
        loadingFetch: false,
      });
    }, 2000);
  };

  handleSubmitResult = async () => {
    const { questions } = this.state;
    const { authRedux, configProjectRedux } = this.props;
    let params = {
      risk_factors: { ...authRedux },
      questions,
    };

    if (configProjectRedux.scheme === 'herwill') {
      delete params.risk_factors.birthday
    }

    let res =
      configProjectRedux.scheme === "herwill"
        ? await userService.POST_HERWILL_SEND_QUESTION(params)
        : await userService.POST_PINKALERT_SEND_QUESTION(params);
    if (res && res.success) {
      // Tracking user event
      ReactGA.event({
        category: "screening",
        action: `screening_${configProjectRedux.scheme}_finish`,
      });

      // toast.success("ส่งข้อมูลสำเร็จ");
      this.props.handleChangeProcess(2, res.data);
    }
  };

  handleClickBackPage = () => {
    this.setState((state) => ({
      questions: [...state.questions].slice(0, -1),
      currentQuestion: state.currentQuestion - 2,
    }),
      this.handleClickAnswer
    );
  };

  render() {
    const { questionList, currentQuestion, loading, loadingFetch } = this.state;
    return (
      <QuestionnaireContainerStyled>
        <div
          className="link_back"
          onClick={() => (loadingFetch ? null : this.handleClickBackPage())}
        >
          {currentQuestion > 0 && (
            <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
          )}
        </div>
        {loading ? (
          <div className="loader_layout">
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            />
          </div>
        ) : (
          <>
            {questionList && questionList[currentQuestion] && (
              <div className={loadingFetch ? "question_layout" : ""}>
                {loadingFetch ? (
                  <LogoLoadingFade />
                ) : (
                  <img
                    className="img_logo"
                    src={iconImages["logo_question.png"]}
                    alt="logo well"
                  />
                )}
                <div className="txt_detail">
                  {questionList[currentQuestion].label}
                </div>
                {questionList[currentQuestion].description && (
                  <div className="txt_detail2">
                    {/* questionList[currentQuestion].description */}
                  </div>
                )}
                {questionList[currentQuestion].answers &&
                  questionList[currentQuestion].answers.map((e, i) => (
                    <button
                      key={i}
                      className="btn_next_white"
                      onClick={() =>
                        loadingFetch
                          ? null
                          : this.handleClickAnswer(
                            questionList[currentQuestion].id,
                            e.value
                          )
                      }
                    >
                      {e.label}
                    </button>
                  ))}
              </div>
            )}
          </>
        )}
      </QuestionnaireContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireContainer);

import React from 'react'
import { ShowDetailOrderStyled } from './styled'
import cx from "classnames";
import { iconImages } from 'assets'

export const ShowDetailOrder = ({ theme_standard, amount, price }) => {
    const customClass = cx({
        "theme_standard": theme_standard
    })
    return (
        <ShowDetailOrderStyled>
            <div className={customClass}>
                <div className="left_layout">
                    <img
                        className="img_scr"
                        src={iconImages['product2.png']}
                        alt="product show"
                    />
                </div>
                <div className="right_layout">
                    <div className="title">
                        iCheck Test
                    </div>
                    <div className="group_label">
                        <div className="top_label">
                            จำนวน
                        </div>
                        <div className="bottom_label">
                            {amount} ชุด
                        </div>
                    </div>
                    <div className="group_label no_margin">
                        <div className="top_label">
                            จำนวนเงิน
                        </div>
                        <div className="bottom_label">
                            {price} บาท
                        </div>
                    </div>
                </div>
            </div>
        </ShowDetailOrderStyled>
    )
}
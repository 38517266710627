import React from 'react';
import { CheckBoxStyled } from './styled';
import cx from 'classnames';

export const CheckBox = ({
  theme_standard,
  theme_standard_2,
  input,
  theme_circle,
  theme_circle_column,
  label,
  label_link,
  link,
  fontWeight,
  width_container,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2 : theme_standard_2,
    theme_circle: theme_circle,
    theme_circle_column: theme_circle_column,
  });

  return (
    <CheckBoxStyled width_container={width_container}>
      <div className={customClass}>
        <div className="container_wrap">
          <label className="container">
            <input type="checkbox" {...input} checked={input.value} />
            <span className="checkmark"></span>
            <div style={{ display: 'flex' }}>
              <div className="txt_label"> {label} </div>
              <a
                href={link}
                className="txt_label"
                style={{
                  marginLeft: '5px',
                  fontWeight: fontWeight ? fontWeight : 'normal',
                }}
              >
                {label_link}{' '}
              </a>
            </div>
          </label>
        </div>
      </div>
    </CheckBoxStyled>
  );
};

import styled from 'styled-components';

export const MyAddressLocationStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .my_address_location_container {
    .address_current_wrap {
      margin-bottom: 25px;
    }
    .address_filter_container {
      position: relative;
      .icon_close_filter {
        position: absolute;
        top: -18px;
        right: -3px;
        cursor: pointer;
      }
      .input_dropdown_wrap {
        margin-bottom: 20px;
      }
    }
  }

  .theme_standard {
  }
`;

import React from "react";
import { TextAndButtonFormStyled } from "./styled";
import { iconImages, COLOR } from "assets";
import { Button } from "../../../components/Button";
import { InputText } from "../../../components/InputText";
import { Field, reduxForm } from "redux-form";
import { LogoLoadingFade, DateAnt } from "components";
import moment from "moment";
import "antd/dist/antd.css";
class TextAndButtonForm extends React.Component {
  state = {
    id: {},
    loading: true,
    check: {},
    show_btn: false,
    error_size: false,
    reset: false,
  };

  componentDidMount = () => {
    const { question } = this.props;
    let type_id = {};
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
    if (question && question.answers) {
      for (let index = 0; index < question.answers.length; index++) {
        switch (question.answers[index].value) {
          case "date":
            type_id.date = question.answers[index].id;
            break;
          case "size":
            type_id.size = question.answers[index].id;
            break;
          case "text":
            type_id.text = question.answers[index].id;
            break;

          default:
            break;
        }
      }
    }

    this.setState({
      id: type_id,
    });
  };

  handleChangeTxt = (text, type) => {
    const { check } = this.state;

    if (type === "date") {
      check[type] = moment(text).format("DD-MM-YYYY");
    } else {
      check[type] = text;
    }
    if (type === "size") {
      if (!/^[0-9]+$/.test(text)) {
        this.setState({
          error_size: true,
        });
      } else {
        this.setState({
          error_size: false,
        });
      }
    }
    this.setState(
      {
        check: check,
      },
      this.handleCheck
    );
  };

  handleCheck = () => {
    const { check } = this.state;
    const { question } = this.props;
    var check_state = true;
    var check_item;
    Object.size = function (obj) {
      var size = 0,
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      }
      return size;
    };

    if (Object.size(check) === question.answers.length - 1) {
      for (var key in check) {
        if (check[key].toString().length > 0) {
          check_item = true;
        } else {
          check_item = false;
        }
        check_state = check_state && check_item;
        this.setState({
          show_btn: check_state,
        });
      }
    } else if (
      question.answers.find((item) => item.is_unknown) &&
      question.description === null
    ) {
      this.setState({
        show_btn: true,
      });
    } else {
      this.setState({
        show_btn: false,
      });
    }
  };

  render() {
    const { handleSubmit, handleSubmitAns, question, scheme } = this.props;
    const { loading, check, show_btn, error_size } = this.state;
    return (
      <TextAndButtonFormStyled>
        <form
          onSubmit={handleSubmit(() => handleSubmitAns(this.state.id, check))}
        >
          {question && question.answers && (
            <>
              {loading ? (
                <LogoLoadingFade />
              ) : (
                <img
                  className="img_logo"
                  src={iconImages["logo_question.png"]}
                  alt="logo well"
                />
              )}
            </>
          )}
          <div className="question_layout">
            {question &&
              question.answers &&
              question.answers
                .filter(
                  (item) =>
                    item.value === "date" ||
                    item.value === "size" ||
                    item.value === "text"
                )
                .map((item, i) => (
                  <div key={i}>
                    <div className="title">{item.label}</div>
                    <div style={{ marginTop: 30 }}>
                      {item.value === "date" ? (
                        <Field
                          component={DateAnt}
                          placeholder={item.description}
                          name="date"
                          onChange={(e) => this.handleChangeTxt(e, item.value)}
                          disabled={
                            question.answers.find((item) => item.is_unknown) &&
                              check["button"] &&
                              question.description === null
                              ? true
                              : false
                          }
                        />
                      ) : (
                        <Field
                          theme_normal
                          component={InputText}
                          input_width="300px"
                          placeholder={item.description}
                          disabled={
                            question.answers.find((item) => item.is_unknown) &&
                              check["button"] &&
                              question.description === null
                              ? true
                              : false
                          }
                          value_reset={
                            check["button"] && question.description === null
                              ? ""
                              : check[item.value]
                          }
                          name={item.value}
                          type={item.value === "date" ? "date" : ""}
                          onChange={(e) =>
                            this.handleChangeTxt(e.target.value, item.value)
                          }
                        />
                      )}
                      {item.value === "size" && error_size && (
                        <div className="error">กรุณากรอกเฉพาะตัวเลข</div>
                      )}
                    </div>
                  </div>
                ))}
          </div>
          <div className="title">{question.description}</div>
          <div className="button_layout">
            {question &&
              question.answers &&
              question.answers
                .filter(
                  (item) =>
                    item.value !== "date" &&
                    item.value !== "size" &&
                    item.value !== "text"
                )
                .map((e, i) => (
                  <div key={i} style={{ marginTop: 20 }}>
                    <div style={{ marginRight: 20 }}>
                      <Button
                        theme_border_question
                        active={this.state.id.button === e.id ? true : false}
                        borderColor={scheme && COLOR.PINK_1}
                        handleClickButton={() => {
                          let btn_id = this.state.id;

                          if (this.state.id.button === e.id) {
                            btn_id.button = null;
                            check["button"] = "";
                            this.setState(
                              { id: btn_id, check: check },
                              this.handleCheck
                            );
                          } else {
                            btn_id.button = e.id;
                            check["button"] = e.id;
                            this.setState(
                              { id: btn_id, check: check },
                              this.handleCheck
                            );
                            if (e.is_unknown && question.description === null) {
                              check["text"] = "";
                              check["date"] = "";
                              check["size"] = "";
                              this.setState({ reset: true, check: check });
                              setTimeout(() => {
                                this.setState({ reset: false });
                              }, 100);
                            }
                          }
                        }}
                      >
                        {e.label}
                      </Button>
                    </div>
                  </div>
                ))}
          </div>
          <div className="txt_input">
            {this.state.value === "other" && (
              <div style={{ marginTop: 30 }}>
                <Field
                  theme_normal
                  component={InputText}
                  input_width="300px"
                  placeholder="โปรดระบุ"
                />
              </div>
            )}
          </div>
          <div className="btn_bottom">
            {show_btn && !error_size && (
              <Button
                width="300px"
                height="40px"
                theme_standard
                style={{ marginTop: 30 }}
                backgroundColor={scheme && COLOR.PINK_1}
                btn
              >
                ต่อไป
              </Button>
            )}
          </div>
        </form>
      </TextAndButtonFormStyled>
    );
  }
}

export default reduxForm({
  form: "TextAndButtonForm", // a unique identifier for this form
  enableReinitialize: true,
})(TextAndButtonForm);

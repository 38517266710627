import styled from 'styled-components';
import { SCREEN, COLOR, FONT } from 'assets';

export const ExaminationTestKitContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  padding: 20px 0;
  position: relative;
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dot_img {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }
  .mb_show {
    display: none;
  }
  .link_back {
    padding: 0px 60px;
  }
  .container {
    max-width: 70%;
    margin: auto;
    .group_top {
      display: flex;
      margin-bottom: 30px;
      .left_box_top {
        width: 50%;
        font-size: ${FONT.SIZE_20};
        font-weight: 600;
        color: ${COLOR.BLUE_2};
        display: flex;
        align-items: center;
      }
      .right_box_top {
        width: 50%;
        display: flex;
        justify-content: flex-end;
      }
    }
    .bottom_detail {
      display: none;
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    // background: pink;
    .link_back {
      padding: 0px 50px;
    }
    .container {
      max-width: 90%;
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    // background: blue;
    .link_back {
      padding: 0px 33px;
    }
    .container {
      max-width: 90%;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    // background: gold;
    .mb_show {
      display: none;
      .bg_img {
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }
    .container {
      max-width: 97%;
      padding: 20px;
      .group_top {
        width: 100%;
        margin-bottom: 10px;
        .left_box_top {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .right_box_top {
          width: 0%;
          // display: none;
        }
      }
      .group_obj {
        padding: 15px;
        border-radius: 10px;
        border: 1px solid ${COLOR.GRAY_12};
      }
      .bottom_detail {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 16px;
        font-size: ${FONT.SIZE_12};
        .detail_2 {
          width: 94%;
          margin-bottom: 20px;
          color: #868686;
          .img_show {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          .img_show2 {
            width: 24px;
            height: 24px;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
        .detail_3 {
          font-size: ${FONT.SIZE_14};
          margin-bottom: 10px;
        }
        .detail_4 {
          color: ${COLOR.BLUE_4};
        }
        .btn2 {
          margin-top: 20px;
          width: 100%;
        }
      }
    }
  }
  // @media(max-width: 700px){
  //     background: red;
  //     .link_back {
  //         display: none;
  //     }
  // }
`;

import React from 'react';
import { BoxMemberStyled } from './styled';
import cx from 'classnames';
// import { iconImages } from 'assets'

export const BoxMember = ({
  theme_standard,
  name,
  sex,
  age,
  smoking,
  height,
  drink,
  weight,
  divKey,
  handleClick,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <BoxMemberStyled>
      <div className={customClass} key={divKey}>
        <div className="detail_show">
          <div className="show_name">{name}</div>
          <div className="group_detail">
            <div className="group_detail_left">
              <div className="group_label">
                เพศ : <span className="label">{sex}</span>
              </div>
            </div>
          </div>
          <div className="group_detail">
            <div className="group_detail_left">
              <div className="group_label">
                อายุ : <span className="label">{age} ปี</span>
              </div>
            </div>
            <div className="group_detail_right">
              <div className="group_label">
                สูบบุหรี่ : <span className="label">{smoking}</span>
              </div>
            </div>
          </div>
          <div className="group_detail">
            <div className="group_detail_left">
              <div className="group_label">
                ส่วนสูง : <span className="label">{height} ซ.ม.</span>
              </div>
            </div>
            <div className="group_detail_right">
              <div className="group_label">
                ดื่มเหล้า : <span className="label">{drink}</span>
              </div>
            </div>
          </div>
          <div className="group_detail">
            <div className="group_detail_left">
              <div className="group_label">
                น้ำหนัก : <span className="label">{weight} ก.ก.</span>
              </div>
            </div>
          </div>
          <div className="btn" onClick={() => handleClick()}>
            เลือก
          </div>
        </div>
      </div>
    </BoxMemberStyled>
  );
};

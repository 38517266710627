import CloseHerWill from './CloseHerWill';
import BtnHerWillShare from './BtnHerWillShare';
import WellQuestion from './WellQuestion';
import Back from './Back';
import Location from './Location';
import Call from './Call';
import SocialFB from './SocialFB';
import SocialLine from './SocialLine';
import Camera from './Camera';
import ArrowDown from './ArrowDown';
import ArrowUp from './ArrowUp';


const Export = {
  CloseHerWill,
  BtnHerWillShare,
  WellQuestion,
  Back,
  Location,
  Call,
  SocialFB,
  SocialLine,
  Camera,
  ArrowDown,
  ArrowUp,
};

export default Export;

import styled, { keyframes } from 'styled-components';
import { COLOR, FONT } from 'assets';
import { fadeInUp } from 'react-animations';
const bounceAnimation = keyframes`${fadeInUp}`;

export const ModalCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .modal_container {
    overflow: auto;
    position: fixed;
    top: ${({ useTop }) => (useTop ? useTop : '0px')};
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
    justify-content: center;
    display: ${({ _isShow }) => (_isShow ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    .group_modal {
      background: ${COLOR.WHITE_1};
      border: 1px solid ${COLOR.BLUE_4};
      border-radius: 8px;
      padding: 20px;
      box-shadow: 6px 7px 11px -8px rgba(88, 88, 88, 0.32);
      position: relative;
      animation: 1s ${bounceAnimation};
      .close_layer {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 22px;
        right: 20px;
        cursor: pointer;
      }
      .img_show {
        background: ${COLOR.WHITE_1};
        border-radius: 50%;
        border: 1px solid ${COLOR.BLUE_7};
        height: 56px;
        width: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-bottom: 20px;
        .img_title {
          height: 29px;
          width: 29px;
        }
      }
      .body_modal {
        z-index: 91;
        font-size: ${FONT.SIZE_14};
        color: ${COLOR.BLUE_2};
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .modal_body {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .theme_ModalCustom_standard {
  }

  .theme_modal_light {
    .modal_container {
      background: red;
    }
  }
`;

import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { CartFormStyled } from './styled'
import { ShowLabel, ButtonIcon, QtyBox } from 'components'
import { iconImages } from 'assets'
import { RenderCommaMoney } from 'helpers';
// import { iconImages } from 'assets'
// import { VALIDATE } from "helpers"


class CartForm extends React.Component {
    state = {

    }

    render() {
        const {
            handleClickBuy,
            data,
        } = this.props
        return (
            <CartFormStyled>
                <div className="group_bottom">
                    <div className="bottom_left_box">
                        <div className="img_layer">
                            <img
                                className="product_show"
                                src={data && data.image_uri}
                                alt="product show"
                            />
                        </div>
                        <div className="detail_layer">
                            <div className="title_show">
                                {data && data.name}
                            </div>
                            <div className="label_show">
                                ราคา
                            </div>
                            <div className="group_price">
                                <div className="price_1">
                                    {data && `฿ ${RenderCommaMoney(Number(data.full_price))}`}
                                </div>
                                <div className="price_2">
                                    {data && `฿ ${RenderCommaMoney(Number(data.final_price))}`}
                                </div>
                            </div>
                            <ShowLabel
                                theme_red
                                noPadding
                                label={data && data.discount_message}
                            />
                        </div>
                    </div>
                    <div className="bottom_right_box">
                        <div className="group_amount">
                            <div className="label_show">
                                จำนวน
                            </div>
                            <div className="box_qty">
                                <Field
                                    theme_standard
                                    name="amount"
                                    component={QtyBox}
                                // validate={VALIDATE.REQUIRE}
                                />
                                {/* <QtyBox
                                    theme_standard
                                /> */}
                            </div>
                        </div>
                        <div className="btn_icon">
                            <ButtonIcon
                                theme_standard
                                handleOnClick={handleClickBuy}
                                src={iconImages['testkit-blue2.png']}
                                label1="สั่งซื้อชุดตรวจมะเร็งปากมดลูก"
                            />
                        </div>
                    </div>
                </div>
                <div className="show_border" />
            </CartFormStyled >
        )
    }
}

export default reduxForm({
    form: 'CartForm',  // a unique identifier for this form
    enableReinitialize: true
})(CartForm)

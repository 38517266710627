import React from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { QuestionScreeningContainerStyled } from "./styled";
import { ROUTE_PATH } from "helpers";
import { userService } from "apiService";
import { withRouter } from "react-router-dom";
import { setReduxSummaryQuestion } from "actions";
import { StandardForm } from "../../../../forms/QuestionForm";
import { BoxLink, LogoLoadingFade } from "components";

class QuestionScreeningContainer extends React.Component {
  state = {
    answer: {},
    all_answer: [],
    question: {},
    data: {},
    change: true,
  };

  componentDidMount = () => {
    const { first_answer } = this.props;
    this.scrollToTop();
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
    this.setState(
      {
        answer: first_answer,
        all_answer: [first_answer],
      },
      this.fetchData
    );

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    const { answer } = this.state;

    let res = await userService.PUT_SCREENING_QUESTION_PART1(answer);
    if (res && res.success) {
      this.setState({
        question: res.data && res.data.question,
        data: res.data,
        change: false,
      });
      setTimeout(() => {
        this.setState({
          change: true,
        });
      }, 100);
      if (res.data && res.data.question === null) {
        this.postQuestionPart1();
      }
    }
  };

  handleClickAns = (value, key, area, primary) => {
    const { answer, data } = this.state;
    this.setState(
      {
        answer: {
          risk_factors: answer.risk_factors,
          symptoms: data.symptoms,
          answer: {
            key: key,
            area: area,
            value: value,
            is_primary: primary,
          },
        },
        all_answer: [
          ...this.state.all_answer,
          {
            risk_factors: answer.risk_factors,
            symptoms: data.symptoms,
            answer: {
              key: key,
              area: area,
              value: value,
              is_primary: primary,
            },
          },
        ],
      },
      this.fetchData
    );
  };

  postQuestionPart1 = async () => {
    const { subject, setReduxSummaryQuestion } = this.props;
    const { answer } = this.state;
    let res = await userService.POST_SCREENING_QUESTION_PART1(
      subject.profile_id,
      answer
    );
    if (res && res.success) {
      setReduxSummaryQuestion({ summary: res, symptom: answer.symptoms });
      if (
        res?.data?.cancers[0]?.level === "HIGH" ||
        res?.data?.cancers[0]?.level === "MEDIUM"
      ) {
        this.props.history.push(ROUTE_PATH.GATEWAY);
      } else {
        this.props.history.push(ROUTE_PATH.SUMMARY);
      }
    }
  };

  handleClickHome = () => {
    const { historyLink } = this.props;
    historyLink.push(ROUTE_PATH.HOME_SCREENING);
  };

  handleClickBack = () => {
    const { all_answer } = this.state;
    let all_arr = [...all_answer];
    if (all_arr.length > 1) {
      all_arr.splice(all_arr?.length - 1, 1);
      this.setState(
        {
          answer: all_arr[all_arr?.length - 1],
          all_answer: all_arr,
        },
        this.fetchData
      );
    } else {
      this.fetchData();
    }
  };

  render() {
    const { question, loading, change } = this.state;
    // console.log('question',question);
    return (
      <QuestionScreeningContainerStyled>
        <div className="link_back">
          <div onClick={() => this.handleClickBack()}>
            <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
          </div>
          <div onClick={() => this.handleClickHome()}>
            <BoxLink theme_standard_box_link label="กลับสู่หน้าหลัก" />
          </div>
        </div>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <div className="group_layout">
            <div className="layout_detail">
              {change && (
                <StandardForm
                  question={question}
                  handleClickAns={this.handleClickAns}
                />
              )}
            </div>
          </div>
        )}
      </QuestionScreeningContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  subject: state.subject,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxSummaryQuestion: (data) => dispatch(setReduxSummaryQuestion(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuestionScreeningContainer));

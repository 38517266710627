import styled from "styled-components"
import {
    COLOR,
    FONT,
    SCREEN
} from 'assets'

export const ConfirmOrderStyled = styled.div`
/*===============================================
 Container 
===============================================*/
width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        width: 100%;
        
        font-size: ${FONT.SIZE_18};
        font-weight: 600;
        color: ${COLOR.BLUE_2};
        .title {
            margin-bottom: 30px;
        }
        .body_layout {
            display: flex;
            width: 100%;
            .left_layout {
                // width: 50%;
                .left_top {
                    margin-bottom: 60px;
                }
            }
            // .display_form {
            //     width: 50%;
            //     padding: 0px 35px;
            //     display: flex;
            //     align-items: center;
            // }
        }
    }

    @media(max-width: ${SCREEN.TABLET}){
        .theme_standard {
            
        }
    }
    @media(max-width: ${SCREEN.MINI_TABLET}){
        .theme_standard {
            
        }
    }
    @media(max-width: ${SCREEN.PHONE}){
        .theme_standard {
            .title {
                text-align: center;
                margin-bottom: 16px;
            }
            .body_layout {
                flex-direction: column;
                .left_layout {
                    width: 100%;
                    .left_top {
                        margin-bottom: 24px;
                    }
                }
                .display_form {
                    width: 100%;
                    padding: 0px;
                    margin-top: 24px;
                }
            }
        }
    }
`
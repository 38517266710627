import styled from 'styled-components';
import { FONT, COLOR, SCREEN } from 'assets';

export const ThankyouForInterestContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  font-size: ${FONT.SIZE_16};
  color: ${COLOR.BLUE_2};
  font-weight: bold;
  background: ${COLOR.GRAY_9};
  min-height: calc(100vh - 87px);
  position: relative;
  padding: 20px 0;
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_layout {
    position: absolute;
    right: 157px;
    top: 29px;
  }
  .bg_layout {
    height: calc(100vh - 167px);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logo_well {
      text-align: center;
      margin-bottom: 30px;
      .logo_show {
        width: 96px;
      }
    }
    .label1 {
      font-size: ${FONT.SIZE_18};
      margin-bottom: 20px;
    }
  }
  .show_button {
    display: none;
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .bg_layout {
      .logo_well {
        display: none;
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .link_layout {
      right: 50px;
    }
    .bg_layout {
      min-height: calc(100vh - 390px);
      .logo_well {
        display: none;
      }
    }

    // @media (orientation:landscape) {
    //   padding: 40px 0px;
    // }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .link_layout {
      display: none;
    }
    .bg_layout {
      background-size: cover;
      height: calc(100vh - 252px);
      .logo_well {
        display: none;
      }
    }
    .show_button {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .btn {
        width: 96%;
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    .link_layout {
      display: none;
    }
    .bg_layout {
      background-size: cover;
      min-height: calc(100vh - 252px);
      .logo_well {
        display: none;
      }
    }
    .show_button {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .btn {
        width: 96%;
      }
    }
  }
`;

// @media (orientation:portrait) {
//   body{
//    color:green;
//  }
// }
// @media (orientation:landscape) {
//   body{
//    color:orange;
//  }
// }

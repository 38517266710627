import React from 'react';
import { LogoLoadingFade3Styled } from './styled';
import { iconLogo } from 'assets';

export const LogoLoadingFade3 = () => {
  return (
    <LogoLoadingFade3Styled>
      <img
        className="logo_line_out"
        src={iconLogo['well_logo_circle_white_outer.png']}
        alt=""
      />
      <img
        className="logo_line_in"
        src={iconLogo['well_logo_circle_white_inner.png']}
        alt=""
      />
      <img className="log_char" src={iconLogo['well_square_white_transparent.png']} alt="" />
    </LogoLoadingFade3Styled>
  );
};

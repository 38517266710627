import styled, { keyframes } from "styled-components";
import { SCREEN } from "assets";
import { fadeInUp } from "react-animations";

const bounceAnimation = keyframes`${fadeInUp}`;

export const GridFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  width: 420px;
  display: flex;
  flex-direction: column;
  .img_logo {
    width: 50px;
    height: 50px;
  }
  .img_question {
    width: 250px;
    align-self: center;
    margin-top: 20px;
    animation: 2s ${bounceAnimation};

  }
  .txt_detail {
    color: #003564;
    font-size: 18px;
    margin-top: 20px;
    max-width: 400px;
  }
  .txt_detail2 {
    color: grey;
    font-size: 16px;
    margin-top: 10px;
    max-width: 400px;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    color: #2f4f74;
    margin: 20px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .title2 {
    font-size: 14px;
    color: #2f4f74;
    margin: -3px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .button_layout {
    display: flex;
    flex-direction: ${(props) => (props.quardrant ? "row" : "column")};
    flex-wrap: ${(props) => (props.quardrant ? "wrap" : "")};
    align-items: center;
    justify-content: center;
    width: 420px;
    animation: 2s ${bounceAnimation};
    margin-top: 20px;
  }
  .btn_bottom {
    width: 300px;
    height: 100px;
    align-self: center;
    animation: 2s ${bounceAnimation};
    margin-left: -20px;
  }
  .theme_border_question {
    .btn_layout {
      padding: 10px;
      width: ${(props) => (props.quardrant ? "130px" : "300px")};
      height: ${(props) => (props.quardrant ? "40px" : "50px")};
      text-align: center;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    width: 300px;
    margin-left: 20px;
    margin-top: 0px;

    .button_layout {
      display: flex;
      flex-direction: ${(props) => (props.quardrant ? "row" : "column")};
      flex-wrap: ${(props) => (props.quardrant ? "wrap" : "")};
      width: 300px;
      font-size: 14px;
      margin-left: 5px;
    }
    .btn_bottom {
      margin-left: -20px;
    }
    .theme_border_question {
      .btn_layout {
        width: ${(props) => (props.quardrant ? "130px" : "300px")};
        height: ${(props) => (props.quardrant ? "40px" : "50px")};
        padding: 10px;
        text-align: center;
      }
    }
    .theme_standard {
      width: 300px;
      display: flex;
      justify-content: center;
      .btn_layout {
        width: 300px;
      }
    }
  }
`;

import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { AppointmentFormStyled } from './styled'
import { iconImages } from 'assets'
import { VALIDATE } from "helpers"
import { BoxIconLabel2, BoxMember2, InputText, BoxSelectTime } from 'components'

class AppointmentForm extends React.Component {
    state = {

    }

    render() {
        const {
            handleClickBackFirstPage
        } = this.props
        return (
            <AppointmentFormStyled>
                <div className="title">
                    ปรึกษาการตรวจรหัสพันธุกรรม
                </div>
                <div className="group_body">
                    <div className="left_layout">
                        <div className="top_left">
                            <BoxIconLabel2
                                theme_standard
                                src={iconImages['toffy.png']}
                                label="ที่ปรึกษาทางด้าน Genetics จะติดต่อท่านกลับภายในสองวันเพื่อให้ความรู้และข้อมูลเพิ่มเติม"
                            />
                        </div>
                        <div className="box_profile">
                            <div className="title_profile">
                                ข้อมูลของท่าน
                            </div>
                            <BoxMember2
                                theme_standard_1
                                name="สมหญิง  มิสาย"
                                sex="หญิง"
                                age="29"
                            />
                        </div>
                        <div className="box_input">
                            <div className="group_input">
                                <div className="label">
                                    เบอร์โทรศัพท์
                                </div>
                                <Field
                                    theme_normal
                                    name="telephone"
                                    component={InputText}
                                    validate={VALIDATE.REQUIRE}
                                />
                            </div>
                            <div className="group_input no_margin">
                                <div className="label">
                                    อีเมล์
                                </div>
                                <Field
                                    theme_normal
                                    name="email"
                                    component={InputText}
                                    validate={VALIDATE.EMAIL}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="right_layout">
                        <BoxSelectTime
                            theme_standard
                            handleClickBackFirstPage={handleClickBackFirstPage}
                        />
                    </div>
                </div>
            </AppointmentFormStyled>
        )
    }
}

export default reduxForm({
    form: 'AppointmentForm',  // a unique identifier for this form
    enableReinitialize: true
})(AppointmentForm)

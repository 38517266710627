import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { ProfileFormStyled } from './styled'
import { iconImages } from 'assets'
import { VALIDATE } from "helpers"
import { UpLoadProfilePicture, InputText, DateTime, Radio } from 'components'

class ProfileForm extends React.Component {
    state = {

    }

    render() {
        // const {

        // } = this.props
        return (
            <ProfileFormStyled>
                <div className="show_img_profile">
                    <UpLoadProfilePicture
                        theme_standard
                        src={iconImages['man1.jpg']}
                    />
                </div>
                <div className="show_name">
                    เจตนิพัทธ์ เลขวรรณวิจิตร
                </div>
                <div className="input_layer">
                    <div className="left_input">
                        <div className="group_input">
                            <div className="label">
                                ชื่อ - นามสกุล
                            </div>
                            <Field
                                theme_standard
                                name="name"
                                component={InputText}
                                validate={VALIDATE.REQUIRE}
                                width="100%"
                            />
                        </div>
                        <div className="group_input">
                            <div className="label">
                                วัน/เดือน/ปี
                            </div>
                            <Field
                                theme_standard
                                name="birthday"
                                component={DateTime}
                                validate={VALIDATE.REQUIRE}
                                width="100%"
                            />
                        </div>
                        <div className="gender_layout">
                            <div className="label_name">เพศ</div>
                            <div className="gen_opt">
                                <Field
                                    theme_circle
                                    label="เพศ"
                                    name="gender"
                                    padding_top="0px"
                                    margin_bottom2="0px"
                                    options={[
                                        {
                                            label: 'เพศชาย',
                                            value: 'male',
                                        },
                                        {
                                            label: 'เพศหญิง',
                                            value: 'female',
                                        },
                                    ]}
                                    component={Radio}
                                // validate={VALIDATE.REQUIRE}
                                />
                            </div>
                        </div>
                        <div className="layout_YN">
                            <div className="YN_left">
                                <div className="label_name">ท่านเคยสูบบุหรี่หรือไม่?</div>
                                <Field
                                    theme_circle_column
                                    name="is_alcohol"
                                    padding_left="0px"
                                    margin_bottom="10px"
                                    options={[
                                        {
                                            label: 'ใช่',
                                            value: true,
                                        },
                                        {
                                            label: 'ไม่ใช่',
                                            value: false,
                                        },
                                    ]}
                                    component={Radio}
                                // validate={VALIDATE.REQUIRE}
                                />
                            </div>
                            <div className="YN_right">
                                <div className="label_name">ท่านเคยดื่มสุราหรือไม่?</div>
                                <Field
                                    theme_circle_column
                                    name="is_smoke"
                                    padding_left
                                    margin_bottom="10px"
                                    options={[
                                        {
                                            label: 'ใช่',
                                            value: true,
                                        },
                                        {
                                            label: 'ไม่ใช่',
                                            value: false,
                                        },
                                    ]}
                                    component={Radio}
                                // validate={VALIDATE.REQUIRE}
                                />
                            </div>
                        </div>
                        <div className="label1">
                            สมาชิก
                        </div>
                        <div className="label2">
                            ธันยารัตน์ เลขวรรณวิจิตร <div className="link_show">แก้ไข</div>
                        </div>
                        <div className="add_member">
                            <div className="plus_img">
                                <img
                                    className="img_show"
                                    src={iconImages['blue_plus.png']}
                                    alt="img show"
                                />
                            </div>
                            <div className="label3">
                                เพิ่มสมาชิก
                            </div>
                        </div>
                    </div>
                    <div className="right_input">
                        <div className="group_input">
                            <div className="label">
                                อีเมล์
                            </div>
                            <Field
                                theme_standard
                                name="email"
                                placeHolder="you@example.com"
                                component={InputText}
                                validate={VALIDATE.EMAIL}
                                width="100%"
                            />
                        </div>
                        <div className="group_input">
                            <div className="label">
                                เบอร์โทร
                            </div>
                            <Field
                                theme_standard
                                name="telephone"
                                component={InputText}
                                validate={VALIDATE.REQUIRE}
                                width="100%"
                            />
                        </div>
                        <div className="group_input">
                            <div className="label">
                                โรคประจำตัว
                            </div>
                            <Field
                                theme_standard
                                name="disease"
                                component={InputText}
                                validate={VALIDATE.REQUIRE}
                                width="100%"
                            />
                        </div>
                        <div className="btn1">
                            ดูประวัติการประเมินความเสี่ยง
                        </div>
                        <div className="btn2">
                            ประวัติคำสั่งซื้อสินค้าและบริการ
                        </div>
                    </div>
                </div>
            </ProfileFormStyled>
        )
    }
}

export default reduxForm({
    form: 'ProfileForm',  // a unique identifier for this form
    enableReinitialize: true
})(ProfileForm)

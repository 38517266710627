import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { LoginContainerStyled } from './styled';
import { userService } from 'apiService';
import { iconImages, iconBackground } from 'assets';
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth, setReduxConfigProject } from 'actions';
import { LoginFormNew } from 'forms';
import { toast } from 'react-toastify';
// import Loader from 'react-loader-spinner';
import { BannerShow, LogoLoadingFade } from 'components';
// import { SplashContext } from 'widgets/WellScreenAppWidgets/SplashScreen';
import url from 'url';
import { BannerPartner } from 'widgets';

class LoginContainer extends React.Component {
  state = {
    loading: false,
  };

  // static contextType = SplashContext;

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    // this.context.splashAction.showSplashScreen();
    this.scrollToTop();
    // this.checkLogIn();
    this.handleRenderPage();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  checkLogIn = async () => {
    const { authRedux } = this.props;
    if (authRedux && authRedux.id && authRedux.firstname) {
      let res = await userService.POST_LOGIN_WITH_TOKEN();
      if (res && res.success) {
        this.props.history.push(ROUTE_PATH.HOME_SCREENING);
      } else {
        localStorage.clear('tokenList');
        this.props.setReduxUserAuth(false);
      }
      this.context.splashAction.notShowSplashScreen();
    } else {
      setTimeout(() => {
        this.context.splashAction.notShowSplashScreen();
      }, 1000);
    }
  };

  handleRenderPage = () => {
    const { configProjectRedux } = this.props;
    switch (configProjectRedux.home_scheme) {
      case 'fwd':
        this.setState({
          renderLeftLayout: (
            <div className="layout_left">
              <div className="top_left_layer">
                <img
                  className="img_show"
                  src={iconImages['fwd3.png']}
                  alt="img"
                />
              </div>
              <div className="bottom_left_layer">
                <div className="title_show">FWD Easy E-CANCER</div>
                <div className="detail_show">ประกันมะเร็งออนไลน์ คุ้มครอง</div>
                <div className="detail_show">
                  ทุกมะเร็ง ทุกระยะ ตรวจพบมะเร็ง
                </div>
                <div className="detail_show">รับสูงสุด 1 ล้านบาท</div>
                <div className="detail_show">เบี้ยประกัน คงที่ 5 ปี</div>
                <div className="detail_show">
                  เบี้ยประกัน เริ่มต้นเพียง 2 บาทต่อวัน
                </div>
                <div className="detail_show no_margin">
                  สำหรับเพศชาย อายุ 20 ปี
                </div>
              </div>
            </div>
          ),
        });
        break;
      default:
        this.setState({
          renderLeftLayout: (
            <div className="layout_left_empty">
              <img
                className="img_bg"
                src={iconBackground['bg-login-well.jpg']}
                alt="img"
              />
            </div>
          ),
        });
        break;
    }
  };

  responseFacebook = async (e) => {
    // work around / skip on LINE callback
    var urlParts = url.parse(window.location.href, true);
    var query = urlParts.query;
    var hasProviderProperty = Object.prototype.hasOwnProperty.call(
      query,
      'provider'
    );
    var hasErrorProperty = Object.prototype.hasOwnProperty.call(query, 'error');
    if (hasProviderProperty && query.provider === 'line' && !hasErrorProperty) {
      return false;
    }

    let params = {
      provider: 'facebook',
      access_token: e.accessToken,
    };
    this.setState({
      loading: true,
    });
    const res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      ReactGA.event({
        category: 'login',
        action: 'login_success_facebook',
      });
      localStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: true })
      );
      toast.success('ล็อคอินสำเร็จ');
      this.fetchProfileData('facebook');
    }
  };

  responseLine = async (e) => {
    if (!e || !e.access_token) {
      return false;
    }
    let params = {
      provider: 'line',
      access_token: e.access_token,
    };
    this.setState({
      loading: true,
    });
    const res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      ReactGA.event({
        category: 'login',
        action: 'login_success_line',
      });
      localStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: true })
      );
      toast.success('ล็อคอินสำเร็จ');
      this.fetchProfileData('line');
    }
  };

  handleClickLoginEmail = async () => {
    const {
      reduxForm: { values },
    } = this.props;
    let params = {
      ...values,
    };
    let res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      // Tracking user event
      ReactGA.event({
        category: 'login',
        action: 'login_success_email',
      });
      if (params.isRemember) {
        localStorage.setItem(
          'tokenList',
          JSON.stringify({ token: res.token, isRemember: params.isRemember })
        );
      } else {
        sessionStorage.setItem(
          'tokenList',
          JSON.stringify({ token: res.token, isRemember: false })
        );
      }
      toast.success('ล็อคอินสำเร็จ');
      this.fetchProfileData('email');
    } else {
      toast.error('ข้อมูลไม่ตรงในระบบ');
    }
  };

  fetchProfileData = async (loginWith) => {
    let res = await userService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      this.setState({
        initialValues: { ...res.data, ...res.data.profile },
      });
      let params = {
        ...res.data,
        ...res.data.profile,
        loginWith: loginWith,
      };
      delete params.profile;
      this.props.setReduxUserAuth({ ...params });
      this.handleCheckProfile();
    }
  };

  handleCheckProfile = () => {
    const { authRedux } = this.props;
    if (authRedux.id && authRedux.firstname) {
      // Set userId for existing users
      ReactGA.set({
        userId: authRedux.id,
      });
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        if (this.props.configProjectRedux.is_corporate && !authRedux.company) {
          if (this.props.configProjectRedux.is_pinkalert) {
            this.props.history.push(`${ROUTE_PATH.TERM_OF_USE}`);
          } else {
            this.props.history.push(`${ROUTE_PATH.WELL_TERM_OF_USE_REGISTRY}`);
          }
        } else {
          this.props.history.push(this.props.configProjectRedux.home_uri);
        }
      }, Math.floor(Math.random() * 800) + 200);
    } else {
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        if (this.props.configProjectRedux.is_pinkalert) {
          this.props.history.push(`${ROUTE_PATH.TERM_OF_USE}`);
        } else {
          this.props.history.push(`${ROUTE_PATH.WELL_TERM_OF_USE_REGISTRY}`);
        }
      }, Math.floor(Math.random() * 800) + 200);
    }
  };

  handleClickRigsiter = () => {
    if (this.props.configProjectRedux.is_pinkalert) {
      this.props.history.push(`${ROUTE_PATH.REGISTER}`);
    } else {
      this.props.history.push(`${ROUTE_PATH.WELL_TERM_OF_USE_REGISTRY}`);
    }
  };

  render() {
    const { loading, renderLeftLayout } = this.state;
    const { configProjectRedux } = this.props;
    const { is_pinkalert } = configProjectRedux;
    return (
      <LoginContainerStyled>
        {!is_pinkalert && renderLeftLayout}
        <div className="layout_right">
          {!is_pinkalert && (
            <div className="banner_layout">
              <BannerPartner />
            </div>
          )}
          <div className="banner_show">
            <BannerShow />
          </div>
          <LoginFormNew
            configProjectRedux={configProjectRedux}
            responseFacebook={this.responseFacebook}
            responseLine={this.responseLine}
            handleClickLoginEmail={this.handleClickLoginEmail}
            handleClickRigsiter={this.handleClickRigsiter}
          />
        </div>
        {loading && (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        )}
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.LoginFormNew,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxConfigProject: (data) => dispatch(setReduxConfigProject(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { WellRegisterFormStyled } from './styled';
import {
  InputText,
  Button,
  DateTime,
  OptionBox,
  DropDownCustom,
  LogoLoadingFade,
  LogoLoadingFade2,
  Icons,
} from 'components';
import { iconImages } from 'assets';
import { VALIDATE } from 'helpers';
import { userService } from 'apiService';

class WellRegisterForm extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchCompanyList();
    this.fetchChangwarList();
  }

  fetchCompanyList = async () => {
    let res = await userService.GET_COMPANY_LIST();
    if (res && res.success) {
      this.setState({
        companyOption: res.data.map((e) => ({
          label: e.name,
          value: e.id,
        })),
      });
    }
  };

  fetchChangwarList = async () => {
    let res = await userService.GET_CHANGWAT_LIST();
    if (res && res.success) {
      this.setState({
        provinceOptions: res.data.map((e) => ({
          label: e.name_th,
          value: e.id,
        })),
      });
    }
  };

  fetchAmphoeList = async (provinceID) => {
    let res = await userService.GET_AMPHOES_LIST(provinceID);
    if (res && res.success) {
      this.setState({
        amphoeOptions: res.data.map((e) => ({
          label: e.name_th,
          value: e.id,
        })),
      });
    }
  };

  handleSelectedProvince = (e) => {
    this.fetchAmphoeList(e);
    this.props.setAmphoe(false);
  };

  renderTheme = () => {
    const { schemeB } = this.props;
    switch (schemeB) {
      case 'herwill':
        return 'theme_herwell';
      default:
        return 'theme_well';
    }
  };

  renderBtnTheme = () => {
    const { schemeB } = this.props;
    switch (schemeB) {
      case 'herwill':
        return 'theme_herwill_button';
      default:
        return 'theme_well_button';
    }
  };

  renderDropdown = () => {
    const { schemeB } = this.props;
    switch (schemeB) {
      case 'herwill':
        return 'theme_line_herwill';
      default:
        return 'theme_line';
    }
  };

  renderBtnSelectTheme = () => {
    const { schemeB } = this.props;
    switch (schemeB) {
      case 'herwill':
        return 'theme_herwill';
      default:
        return 'theme_standard_opt';
    }
  };

  render() {
    const {
      step,
      handleChangeStep,
      registerName,
      handleSetStep,
      handleSuccesRegister,
      configProjectRedux,
      loading,
      // scheme, 
      schemeB,
      disable,
      is_corporate,
    } = this.props;
    const { provinceOptions, amphoeOptions, companyOption } = this.state;
    return (
      <WellRegisterFormStyled>
        <div className={schemeB}>
          {step === 1 && (
            <div className="move_show">
              <div className="load_move_form">
                {loading ? (
                  schemeB === 'herwill' ? (
                    <LogoLoadingFade2 />
                  ) : (
                    <LogoLoadingFade />
                  )
                ) : schemeB === 'herwill' ? (
                  <Icons.WellQuestion />
                ) : (
                  <img
                    className="img_show"
                    src={iconImages['well_round.png']}
                    alt="icon show"
                  />
                )}
              </div>
              <div className="label_question" style={{ fontSize: '24px' }}>
                สวัสดีค่ะ
              </div>
              <div
                className="label_question"
                style={{ marginBottom: '20px', fontSize: '24px' }}
              >
                ชื่อย่อของคุณคืออะไร ?
              </div>
              <div>
                <div className="group_input">
                  <div className="label_name">กรุณากรอกชื่อ</div>
                  <Field
                    {...{ [this.renderTheme()]: true }}
                    name="firstname"
                    component={InputText}
                    width="100%"
                    validate={VALIDATE.REQUIRE}
                  />
                </div>
                {configProjectRedux.scheme !== 'herwill' && (
                  <div className="group_input">
                    <div className="label_name">กรุณากรอกนามสกุล</div>
                    <Field
                      // theme_herwell
                      {...{ [this.renderTheme()]: true }}
                      name="lastname"
                      component={InputText}
                      width="100%"
                      validate={VALIDATE.REQUIRE}
                    />
                  </div>
                )}
                <div className="btn_layer">
                  <div className="show_button">
                    <Button
                      // theme_herwill_button theme_well_button
                      // theme_well_button
                      {...{ [this.renderBtnTheme()]: true }}
                      handleClickButton={handleChangeStep}
                    >
                      {configProjectRedux.scheme !== 'herwill'
                        ? 'นี่คือชื่อและนามสกุลของฉัน'
                        : 'นี่คือชื่อของฉัน'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="move_show">
              <div className="load_move_form">
                {loading ? (
                  schemeB === 'herwill' ? (
                    <LogoLoadingFade2 />
                  ) : (
                    <LogoLoadingFade />
                  )
                ) : schemeB === 'herwill' ? (
                  <Icons.WellQuestion />
                ) : (
                  <img
                    className="img_show"
                    src={iconImages['well_round.png']}
                    alt="icon show"
                  />
                )}
              </div>
              <div className="label_question" style={{ fontSize: '24px' }}>
                ยินดีต้อนรับ
                <span style={{ fontWeight: 'bold', marginLeft: '7px' }}>
                  {`คุณ ${registerName}`}
                </span>
              </div>
              <div
                className="label_question"
                style={{ marginTop: '30px', fontSize: '24px' }}
              >
                <div>Well จะเก่งและแม่นยํามากขึ้น</div>
                <div>หากเรารู้จักคุณมากขึ้น...</div>
              </div>
              <div className="btn_layer2">
                <div className="show_button">
                  <Button
                    {...{ [this.renderBtnTheme()]: true }}
                    // handleClickButton={handleChangeStep}
                    handleClickButton={() => {
                      configProjectRedux.scheme === 'herwill'
                        ? handleSetStep(4)
                        : handleChangeStep();
                    }}
                  >
                    ตกลง เรามาทำความรู้จักกันเถอะ
                  </Button>
                </div>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="move_show">
              <div className="load_move_form">
                {loading ? (
                  schemeB === 'herwill' ? (
                    <LogoLoadingFade2 />
                  ) : (
                    <LogoLoadingFade />
                  )
                ) : schemeB === 'herwill' ? (
                  <Icons.WellQuestion />
                ) : (
                  <img
                    className="img_show"
                    src={iconImages['well_round.png']}
                    alt="icon show"
                  />
                )}
              </div>
              <div
                className="label_question"
                style={{ marginBottom: '20px', fontSize: '24px' }}
              >
                คุณเกิดวันที่...
              </div>
              <div>
                <div className="group_input">
                  <Field
                    {...{ [this.renderDropdown()]: true }}
                    component={DateTime}
                    name="birthday"
                    validate={VALIDATE.REQUIRE}
                  />
                </div>
                <div className="group_input text_center">
                  ( การตรวจปัจจัยเสี่ยงไม่เหมาะสมกับบุคคลที่อายุ ต่ำกว่า 16 ปี )
                </div>
                <div className="btn_layer">
                  <div className="show_button">
                    <Button
                      {...{ [this.renderBtnTheme()]: true }}
                      handleClickButton={() => {
                        configProjectRedux.scheme === 'herwill'
                          ? handleChangeStep()
                          : handleSetStep(5);
                      }}
                    >
                      นี่คือวันเกิดของฉัน
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="move_show">
              <div className="load_move_form">
                {loading ? (
                  schemeB === 'herwill' ? (
                    <LogoLoadingFade2 />
                  ) : (
                    <LogoLoadingFade />
                  )
                ) : schemeB === 'herwill' ? (
                  <Icons.WellQuestion />
                ) : (
                  <img
                    className="img_show"
                    src={iconImages['well_round.png']}
                    alt="icon show"
                  />
                )}
              </div>
              <div
                className="label_question"
                style={{ marginBottom: '20px', fontSize: '24px' }}
              >
                จังหวัดและเขตพื้นที่ที่อาศัย
              </div>
              <div>
                <div className="group_input">
                  <div className="label_show">จังหวัด</div>
                  <Field
                    theme_herwill
                    label=""
                    width="100%"
                    name="province_id"
                    options={provinceOptions}
                    alignItems="center"
                    fontSize="23px"
                    onChangeCustom={(e) => {
                      this.handleSelectedProvince(e);
                    }}
                    component={DropDownCustom}
                    validate={VALIDATE.REQUIRE}
                  />
                </div>
                <div className="group_input tm">
                  <div className="label_show">เขตพื้นที่</div>
                  <Field
                    theme_herwill
                    label=""
                    width="100%"
                    name="amphoe_id"
                    options={amphoeOptions}
                    alignItems="center"
                    fontSize="23px"
                    component={DropDownCustom}
                    validate={VALIDATE.REQUIRE}
                  />
                </div>
                <div className="btn_layer">
                  <div className="show_button">
                    <Button
                      {...{ [this.renderBtnTheme()]: true }}
                      handleClickButton={handleChangeStep}
                    >
                      นี่คือจังหวัดที่ฉันเกิด
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 5 && (
            <div className="move_show">
              <div className="load_move_form">
                {loading ? (
                  schemeB === 'herwill' ? (
                    <LogoLoadingFade2 />
                  ) : (
                    <LogoLoadingFade />
                  )
                ) : schemeB === 'herwill' ? (
                  <Icons.WellQuestion />
                ) : (
                  <img
                    className="img_show"
                    src={iconImages['well_round.png']}
                    alt="icon show"
                  />
                )}
              </div>
              <div
                className="label_question"
                style={{ marginBottom: '160px', fontSize: '24px' }}
              >
                เพศของคุณ...
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <div style={{ width: '70%' }}>
                  <Field
                    // theme_herwill
                    {...{ [this.renderBtnSelectTheme()]: true }}
                    name="gender"
                    data={[
                      {
                        label: 'หญิง',
                        value: 'female',
                      },
                      // {
                      //   label: 'ชาย',
                      //   value: 'male',
                      // },
                    ]}
                    onChangeCustom={handleChangeStep}
                    component={OptionBox}
                  // validate={VALIDATE.REQUIRE}
                  />
                </div>
              </div>
            </div>
          )}
          {step === 6 && (
            <div className="move_show">
              <div className="load_move_form">
                {loading ? (
                  schemeB === 'herwill' ? (
                    <LogoLoadingFade2 />
                  ) : (
                    <LogoLoadingFade />
                  )
                ) : schemeB === 'herwill' ? (
                  <Icons.WellQuestion />
                ) : (
                  <img
                    className="img_show"
                    src={iconImages['well_round.png']}
                    alt="icon show"
                  />
                )}
              </div>
              <div
                className="label_question"
                style={{ marginBottom: '110px', fontSize: '24px' }}
              >
                น้ำหนักของคุณ...
              </div>
              <div>
                <div className="group_input">
                  <div className="label_name">กรุณากรอกน้ำหนักของคุณ</div>
                  <div className="input_box">
                    <Field
                      // theme_herwell
                      {...{ [this.renderTheme()]: true }}
                      name="weight"
                      type="number"
                      component={InputText}
                      width="100%"
                      validate={VALIDATE.NUMBER}
                    />
                    <span className="back_label">กิโลกรัม</span>
                  </div>
                </div>
                <div className="btn_layer4">
                  <div className="show_button">
                    <Button
                      {...{ [this.renderBtnTheme()]: true }}
                      handleClickButton={handleChangeStep}
                    >
                      ถัดไป
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 7 && (
            <div className="move_show">
              <div className="load_move_form">
                {loading ? (
                  schemeB === 'herwill' ? (
                    <LogoLoadingFade2 />
                  ) : (
                    <LogoLoadingFade />
                  )
                ) : schemeB === 'herwill' ? (
                  <Icons.WellQuestion />
                ) : (
                  <img
                    className="img_show"
                    src={iconImages['well_round.png']}
                    alt="icon show"
                  />
                )}
              </div>
              <div
                className="label_question"
                style={{ marginBottom: '110px', fontSize: '24px' }}
              >
                ส่วนสูงของคุณ......
              </div>
              <div>
                <div className="group_input">
                  <div className="label_name">กรุณากรอกส่วนสูงของคุณ</div>
                  <div className="input_box">
                    <Field
                      {...{ [this.renderTheme()]: true }}
                      name="height"
                      type="number"
                      component={InputText}
                      width="100%"
                      validate={VALIDATE.NUMBER}
                    />
                    <span className="back_label">เซนติเมตร</span>
                  </div>
                </div>
                <div className="btn_layer">
                  <div className="show_button">
                    <Button
                      {...{ [this.renderBtnTheme()]: true }}
                      handleClickButton={handleChangeStep}
                    >
                      ถัดไป
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 8 && (
            <div className="move_show">
              <div className="load_move_form">
                {loading ? (
                  schemeB === 'herwill' ? (
                    <LogoLoadingFade2 />
                  ) : (
                    <LogoLoadingFade />
                  )
                ) : schemeB === 'herwill' ? (
                  <Icons.WellQuestion />
                ) : (
                  <img
                    className="img_show"
                    src={iconImages['well_round.png']}
                    alt="icon show"
                  />
                )}
              </div>
              <div
                className="label_question"
                style={{ marginBottom: '160px', fontSize: '24px' }}
              >
                คุณสูบบุหรี่หรือไม่...
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <div style={{ width: '70%' }}>
                  <Field
                    {...{ [this.renderBtnSelectTheme()]: true }}
                    name="is_smoke"
                    data={[
                      {
                        label: 'สูบ',
                        value: true,
                      },
                      {
                        label: 'ไม่สูบ',
                        value: false,
                      },
                    ]}
                    component={OptionBox}
                    onChangeCustom={handleChangeStep}
                  />
                </div>
              </div>
            </div>
          )}
          {step === 9 && (
            <div className="move_show">
              <div className="load_move_form">
                {loading ? (
                  schemeB === 'herwill' ? (
                    <LogoLoadingFade2 />
                  ) : (
                    <LogoLoadingFade />
                  )
                ) : schemeB === 'herwill' ? (
                  <Icons.WellQuestion />
                ) : (
                  <img
                    className="img_show"
                    src={iconImages['well_round.png']}
                    alt="icon show"
                  />
                )}
              </div>
              <div
                className="label_question"
                style={{ marginBottom: '160px', fontSize: '24px' }}
              >
                คุณดื่มเครื่องดื่มมีแอลกอฮอล์หรือไม่...
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <div style={{ width: '70%' }}>
                  <Field
                    {...{ [this.renderBtnSelectTheme()]: true }}
                    name="is_alcohol"
                    data={[
                      {
                        label: 'ดื่ม',
                        value: true,
                      },
                      {
                        label: 'ไม่ดื่ม',
                        value: false,
                      },
                    ]}
                    component={OptionBox}
                    onChangeCustom={handleChangeStep}
                  // validate={VALIDATE.REQUIRE}
                  />
                </div>
              </div>
            </div>
          )}
          {is_corporate ? (
            <>
              {step === 10 && (
                <div className="move_show">
                  <div className="load_move_form">
                    {loading ? (
                      schemeB === 'herwill' ? (
                        <LogoLoadingFade2 />
                      ) : (
                        <LogoLoadingFade />
                      )
                    ) : schemeB === 'herwill' ? (
                      <Icons.WellQuestion />
                    ) : (
                      <img
                        className="img_show"
                        src={iconImages['well_round.png']}
                        alt="icon show"
                      />
                    )}
                  </div>
                  <div
                    className="label_question"
                    style={{
                      marginBottom: '10px',
                      marginTop: '20px',
                      fontSize: '24px',
                    }}
                  >
                    คุณอยู่บริษัทอะไร
                  </div>
                  <div>
                    <div className="group_input">
                      <div className="label_show">กรุณาเลือกบริษัท</div>
                      <Field
                        theme_herwill
                        {...{ [this.renderDropdown()]: true }}
                        label=""
                        width="100%"
                        name="company_id"
                        options={companyOption}
                        alignItems="center"
                        fontSize="23px"
                        // onChangeCustom={(e) => {
                        //   this.handleSelectedProvince(e);
                        // }}
                        component={DropDownCustom}
                        validate={VALIDATE.REQUIRE}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginBottom: '10px',
                      marginTop: '20px',
                      fontSize: '24px',
                    }}
                  >
                    ตำแหน่ง
                  </div>
                  <div>
                    <div className="group_input">
                      <div className="label_show">
                        กรุณากรอกตำแหน่งที่คุณอยู่ปัจจุบัน
                      </div>
                      <Field
                        // theme_herwell
                        {...{ [this.renderTheme()]: true }}
                        name="position"
                        component={InputText}
                        width="100%"
                        validate={VALIDATE.REQUIRE}
                      />
                    </div>
                  </div>
                  <div className="btn_layer">
                    <div className="show_button">
                      <Button
                        {...{ [this.renderBtnTheme()]: true }}
                        handleClickButton={handleChangeStep}
                      >
                        ถัดไป
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {step === 11 && (
                <div className="move_show">
                  <div style={{ fontSize: '24px' }}>
                    <div>
                      ขอบคุณมากค่ะ
                      <span style={{ fontWeight: 'bold', marginLeft: '7px' }}>
                        {`คุณ${registerName}`}
                      </span>
                    </div>
                    <div>ตอนนี้ Well รู้จักคุณมากขึ้นแล้ว</div>
                    <div style={{ marginTop: '30px' }}>
                      <div>งั้นเรามาเริ่มต้นใช้งาน</div>
                      <div>Well กันเถอะ...</div>
                    </div>
                    <div className="btn_layer">
                      <div className="show_button full_width">
                        <Button
                          {...{ [this.renderBtnTheme()]: true }}
                          handleClickButton={
                            disable === false && handleSuccesRegister
                          }
                          disable={disable}
                        >
                          ถัดไป
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            step === 10 && (
              <div className="move_show">
                <div style={{ fontSize: '24px' }}>
                  <div>
                    ขอบคุณมากค่ะ
                    <span style={{ fontWeight: 'bold', marginLeft: '7px' }}>
                      {`คุณ${registerName}`}
                    </span>
                  </div>
                  <div>ตอนนี้ Well รู้จักคุณมากขึ้นแล้ว</div>
                  <div style={{ marginTop: '30px' }}>
                    <div>งั้นเรามาเริ่มต้นใช้งาน</div>
                    <div>Well กันเถอะ...</div>
                  </div>
                  <div className="btn_layer">
                    <div className="show_button full_width">
                      <Button
                        {...{ [this.renderBtnTheme()]: true }}
                        handleClickButton={
                          disable === false && handleSuccesRegister
                        }
                        disable={disable}
                      >
                        ถัดไป
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </WellRegisterFormStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setAmphoe: (data) => dispatch(change(`WellRegisterForm`, `amphoe_id`, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'WellRegisterForm', // a unique identifier for this form
    enableReinitialize: true,
  })(WellRegisterForm)
);

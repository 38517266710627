import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'
 
export const PreviewFormStyled = styled.div`
/*===============================================
 Container 
===============================================*/
// width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        // width: 100%;
        // 
        // font-size: ${FONT.SIZE_16};
        // color: ${COLOR.GRAY_13};
    }
`
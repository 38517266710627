import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const BoxSelectTime2Styled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    width: 100%;
    
    font-size: ${FONT.SIZE_14};
    color: ${COLOR.BLUE_2};
    .top_layer {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      .label_date {
        margin: 0px 15px;
      }
      .arrow {
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #FFA200;
        &.select {
          display: none;
        }
      }
    }
    .bottom_layer {
      .show_button {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .block {
      
      box-shadow: 6px 7px 11px -8px rgba(88,88,88,0.32);
      width: 100%;
      padding: 10px;
      background: ${COLOR.GRAY_9};
      color: ${COLOR.BLUE_2};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${FONT.SIZE_14};
      font-weight: bold;
      border-radius: 22.5px;
      border: 1px solid ${COLOR.GRAY_12};
      cursor: pointer;
      font-weight: 600;
      &.active {
        border: 3px solid ${COLOR.BLUE_4};
        outline: none;
        color: ${COLOR.BLUE_4};
        font-weight: 800;
    }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
  }
  @media (max-width: 700px) {
    .theme_standard {
      .showLabel {
        margin-top: 30px;
        padding-top: 20px;
        border-top: 1px solid ${COLOR.GRAY_12};
      }
      .btn2 {
        display: block;
        margin-top: 16px;
      }
    }
  }
`;

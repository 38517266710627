import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { LoginContainerStyled } from './styled';
import { userService } from 'apiService';
// import { iconImages } from 'assets';
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth } from 'actions';
import { LoginForm } from 'forms';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { BannerShow } from 'components';

class LoginContainer extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  responseFacebook = async (e) => {
    let params = {
      provider: 'facebook',
      access_token: e.accessToken,
    };
    const res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      ReactGA.event({
        category: 'login',
        action: 'login_success_facebook',
      });
      localStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: true })
      );
      toast.success('ล็อคอินสำเร็จ');
      this.fetchProfileData();
    }
  };

  handleClickLoginEmail = async () => {
    const {
      reduxForm: { values },
    } = this.props;
    let params = {
      ...values,
    };
    let res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      // Tracking user event
      ReactGA.event({
        category: 'login',
        action: 'login_success_email',
      });
      if (params.isRemember) {
        localStorage.setItem(
          'tokenList',
          JSON.stringify({ token: res.token, isRemember: params.isRemember })
        );
      } else {
        sessionStorage.setItem(
          'tokenList',
          JSON.stringify({ token: res.token, isRemember: false })
        );
      }
      toast.success('ล็อคอินสำเร็จ');
      this.fetchProfileData();
    } else {
      toast.error('ข้อมูลไม่ตรงในระบบ');
    }
  };

  fetchProfileData = async () => {
    let res = await userService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      this.setState({
        initialValues: { ...res.data, ...res.data.profile },
      });
      let params = {
        ...res.data,
        ...res.data.profile,
      };
      delete params.profile;
      this.props.setReduxUserAuth({ ...params });
      this.handleCheckProfile();
    }
  };

  handleCheckProfile = () => {
    const { authRedux } = this.props;
    if (authRedux.id && authRedux.firstname) {
      // Set userId for existing users
      ReactGA.set({
        userId: authRedux.id,
      });
      this.setState({
        loading: true,
      });
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        this.props.history.push(ROUTE_PATH.TERM_OF_USE);
      }, Math.floor(Math.random() * 800) + 200);
    } else {
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        this.props.history.push(ROUTE_PATH.REGISTER);
      }, Math.floor(Math.random() * 800) + 200);
    }
  };

  render() {
    const { loading } = this.state;
    const { configProjectRedux } = this.props;
    return (
      <LoginContainerStyled loading={loading}>
        {/* <div className="layout_left" >
          <div className="top_left_layer">
            <img className="img_show" src={iconImages['fwd3.png']} alt="img" />
          </div>
          <div className="bottom_left_layer">
            <div className="title_show">
              FWD Easy E-CANCER
            </div>
            <div className="detail_show">
              ประกันมะเร็งออนไลน์ คุ้มครอง
            </div>
            <div className="detail_show">
              ทุกมะเร็ง ทุกระยะ ตรวจพบมะเร็ง
            </div>
            <div className="detail_show">
              รับสูงสุด 1 ล้านบาท
            </div>
            <div className="detail_show">
              เบี้ยประกัน คงที่ 5 ปี
            </div>
            <div className="detail_show">
              เบี้ยประกัน เริ่มต้นเพียง 2 บาทต่อวัน
            </div>
            <div className="detail_show no_margin">
              สำหรับเพศชาย อายุ 20 ปี
            </div>
          </div>
        </div> */}
        <div className="layout_right center_mode">
          <BannerShow />
          {loading ? (
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            />
          ) : (
            <LoginForm
              configProjectRedux={configProjectRedux}
              responseFacebook={this.responseFacebook}
              handleClickLoginEmail={this.handleClickLoginEmail}
            />
          )}
        </div>
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.LoginForm,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

import React from 'react';
import { ModalCustomStyled } from './styled';
import cx from 'classnames';
import { iconImages } from 'assets';

export const ModalCustom = ({
  theme_ModalCustom_standard,
  theme_modal_light,
  children,
  isShow,
  useTop,
  notClose,
  handleClickCloseModal,
  cusStyBody,
}) => {
  const customClass = cx({
    theme_ModalCustom_standard: theme_ModalCustom_standard,
    theme_modal_light: theme_modal_light,
  });

  return (
    <ModalCustomStyled _isShow={isShow} useTop={useTop}>
      <div className={customClass}>
        <div className="modal_container">
          <div className="group_modal">
            {!notClose && (
              <img
                className="close_layer"
                src={iconImages['close_gray.png']}
                alt="close show"
                onClick={handleClickCloseModal}
              />
            )}
            <div className="img_show">
              <img
                className="img_title"
                src={iconImages['testkit-blue2.png']}
                alt="testkit-blue show"
              />
            </div>
            <div className="  " style={cusStyBody && { ...cusStyBody }}>
              <div className="modal_body">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </ModalCustomStyled>
  );
};

import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ConsultDoctorContainerStyled } from './styled';
import { iconBackground, iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { Icons, BackBtn, LogoLoadingFade, Modal } from 'components';
import { HopitalItemType4, HopitalItemType3, MyAddressLocation } from 'widgets';
import { iteleService } from 'apiService';
import { BASE_API_ITELE } from 'apiService/apiConfig';
import { SCREEN } from 'assets';

class ConsultDoctorContainer extends React.Component {
  state = {
    hideBox: true,
    isDesktop: window.matchMedia(`(max-width: ${SCREEN.MINI_TABLET})`).matches
      ? false
      : true,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    this.setState({
      loading: true,
    });
    // window.addEventListener('resize', this.handleDetectScreen, true);
  }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleDetectScreen, true);
  // }

  // handleDetectScreen = () => {
  //   const setDevice = (e) => {
  //     this.setState({
  //       isDesktop: e,
  //     });
  //   };
  //   if (window.matchMedia(`(max-width: ${SCREEN.MINI_TABLET})`).matches) {
  //     setDevice(false);
  //   } else {
  //     setDevice(true);
  //   }
  // };

  onGetCurrentPosition = async (resPosition) => {
    if (resPosition && resPosition.status) {
      switch (resPosition.type) {
        case 'amphoeID':
          let resAmphoeID = await this.fetchMammogramCenter(
            `?per_page=99&sort_by=is_online&amphoe_id=${resPosition.amphoeID}`
          );
          if (resAmphoeID) {
            this.setState({
              loading: false,
              queryString: `sort_by=is_online&amphoe_id=${resPosition.amphoeID}`,
            });
          }
          break;
        case 'latln':
          let resLatLng = await this.fetchMammogramCenter(
            `?per_page=99&latitude=${resPosition.lat}&longitude=${resPosition.lng}`
          );
          if (resLatLng) {
            this.setState({
              loading: false,
              queryString: `sort_by=is_online&latitude=${resPosition.lat}&longitude=${resPosition.lng}`,
            });
          }
          break;
        default:
          break;
      }
    } else {
      let res = await this.fetchMammogramCenter(
        '?per_page=99&sort_by=is_online'
      );
      if (res) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  fetchMammogramCenter = async (queryString) => {
    let res = await iteleService.GET_ITELE_ACCOUNT_BY_SERVICE_CODE(
      'wellscreening',
      queryString
    );
    console.log('ressssssssssss ', res)
    if (res && res.success) {
      let splitIsOnlineGroup = res.data.reduce(
        (result, data) => {
          if (data.is_online) {
            result.online.push(data);
          } else {
            result.offline.push(data);
          }
          return result;
        },
        { online: [], offline: [] }
      );
      this.setState({
        iteleServiceData: splitIsOnlineGroup.online,
        iteleServiceOfflineData: splitIsOnlineGroup.offline,
        hideBox: true,
      });
      return res.data;
    }
  };

  handleClickBack = () => {
    this.props.history.goBack();
  };

  handleClickLandingPage = () => {
    this.props.history.goBack();
  };

  handleSelectedAmphoe = async (e) => {
    let res = await this.fetchMammogramCenter(
      `?per_page=99&sort_by=is_online&amphoe_id=${e}`
    );
    if (res) {
      this.setState({
        loading: false,
        queryString: `amphoe_id=${e}`,
      });
    }
  };

  handleClickConsult = (e) => {
    this.setState({
      isModal: true,
      renderModal: (
        <div className="md_container">
          <img
            className="logo_show"
            src={iconImages['new_logo_her_will.png']}
            alt="icon show"
          />
          <div className="text_1">คุณกำลังเข้าสู่ระบบปรึกษาแพทย์ออนไลน์ของ</div>
          <div
            className="hopital_logo_show"
            style={{
              backgroundImage: `url(${e.logo_uri})`,
            }}
          />
          <div dangerouslySetInnerHTML={{ __html: e.description }} />
          <div
            className={`btn_action  ${e.is_online ? `` : `offline`}`}
            onClick={() => this.handleClickGoItele(e)}
          >
            {e.is_online ? 'เริ่มบริการ' : 'นอกเวลาทำงาน'}
          </div>
          <div className="back" onClick={this.handleClickCloseModal}>
            ย้อนกลับ
          </div>
          <div className="note" dangerouslySetInnerHTML={{ __html: e.note }} />
        </div>
      ),
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  handleClickGoItele = async (e) => {
    const { configProjectRedux } = this.props;

    if (e.is_online) {
      ReactGA.event({
        category: configProjectRedux.scheme + '_iTele',
        action: 'click_goItele',
      });
      window.open(
        `${BASE_API_ITELE}/service-api/services/wellscreening/accounts/${e.id}/patient_uri`,
        '_blank'
      );
      this.handleClickCloseModal();
    }
  };

  handleShowBox = () => {
    const { hideBox } = this.state;
    this.setState({
      hideBox: !hideBox,
    });
  };

  render() {
    const {
      iteleServiceData,
      iteleServiceOfflineData,
      loading,
      isModal,
      renderModal,
      hideBox,
      // isDesktop,
    } = this.state;
    return (
      <ConsultDoctorContainerStyled>
        <div className={`loader_layout ${loading ? 'active' : ''}`}>
          <LogoLoadingFade />
        </div>
        <div className="is_desktop">
          <div className="btn_back_wrap" onClick={this.handleClickBack}>
            <BackBtn
              theme_gray
              txt="ย้อนกลับ"
              icon={<Icons.Back color="#6F7070" />}
            />
          </div>
          <div className="location_container">
            <div className="title">โรงพยาบาลที่เข้าร่วม</div>
            <div
              className="address_wrap"
              style={{
                backgroundImage: `url(${iconBackground['con_doctor.png']})`,
              }}
            >
              <img
                className="img_itele"
                src={iconImages['itele_2.svg']}
                alt="icon show"
              />
              <div className="obj_text">
                <div className="ob_label">Telemedicine</div>
                <div className="group_text">
                  <div className="text1">ปรึกษาแพทย์ได้ทุกที่ทุกเวลา</div>
                  <div className="text2">ด้วยระบบวิดีโอคอล</div>
                </div>
              </div>
            </div>
            <MyAddressLocation
              onGetCurrentPosition={this.onGetCurrentPosition}
              onChangeAmphoe={this.handleSelectedAmphoe}
            />
            <div className="title_sec_2">รายชื่อโรงพยาบาล</div>
            <div className="row_2_col">
              {iteleServiceData &&
                iteleServiceData.map((e, i) => (
                  <>
                    <div className="item_wrap" key={i}>
                      <HopitalItemType3
                        name={e.name}
                        image_uri={e.logo_uri}
                        province={e.amphoe?.changwat?.name_th}
                        amphoe={e.amphoe?.name_th}
                        distance={e.distance}
                        phone_no={e.phone_no}
                        handleOnClick={() => this.handleClickConsult(e)}
                      />
                    </div>
                    {iteleServiceData &&
                      iteleServiceData.length % 2 !== 0 &&
                      i + 1 === iteleServiceData.length && (
                        <div className="item_wrap" key={i}></div>
                      )}
                  </>
                ))}
            </div>
            <div className="no_service">
              <div className="title_service">
                <div className="round" />
                <div>ไม่พร้อมให้บริการ</div>
              </div>
              <div className="btn_show" onClick={this.handleShowBox}>
                {hideBox ? (
                  <>
                    <div>แสดงทั้งหมด</div>
                    <Icons.ArrowDown />
                  </>
                ) : (
                  <>
                    <div>แสดงน้อยลง</div>
                    <Icons.ArrowUp />
                  </>
                )}
              </div>
              <div className={`hide_box ${!hideBox ? 'hide' : ''}`}>
                <div className="hide_box_title">โรงพยาบาลอื่นๆ</div>
                <div className="row_2_col_no_service">
                  {iteleServiceOfflineData &&
                    iteleServiceOfflineData.map((e, i) => (
                      <div key={i} className="item_wrap">
                        <HopitalItemType3
                          name={e.name}
                          image_uri={e.logo_uri}
                          province={e.amphoe?.changwat?.name_th}
                          amphoe={e.amphoe?.name_th}
                          distance={e.distance}
                          phone_no={e.phone_no}
                          handleOnClick={() => this.handleClickConsult(e)}
                        />
                      </div>
                    ))}
                  {iteleServiceOfflineData &&
                    iteleServiceOfflineData.length % 2 !== 0 && (
                      <div className="item_wrap"></div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="is_mobile">
          <div
            className="bg_top"
            style={{
              backgroundImage: `url(${iconBackground['group_doctor.png']})`,
            }}
          />
          <div className="container">
            <div
              className="m_btn_back_wrap"
              onClick={this.handleClickBack}
            ></div>
            <div className="m_title">โรงพยาบาลที่ให้บริการ</div>
            <div className="m_address_wrap">
              <img
                className="img_itele"
                src={iconImages['itele_2.svg']}
                alt="icon show"
              />
              <img
                className="img_doctor_mb"
                src={iconImages['doctor_mb.png']}
                alt="icon show"
              />
              <div className="group_text">
                <div className="text_style fs_18">Telemedicine</div>
                <div className="text_style">ปรึกษาแพทย์ได้ทุกที่ทุกเวลา</div>
                <div className="text_style small">
                  ท่านจะได้รับการปรึกษากับแพทย์ที่โรงพยาบาล
                </div>
                <div className="text_style small">
                  จัดสรรให้ โดยอาจมีแพทย์หลากลายสาขาที่ให้บริการ
                </div>
              </div>
            </div>
            <MyAddressLocation
              onGetCurrentPosition={this.onGetCurrentPosition}
              onChangeAmphoe={this.handleSelectedAmphoe}
            />
            <div className="m_title_sec_2">รายชื่อโรงพยาบาล</div>
            <div className="m_row">
              {iteleServiceData &&
                iteleServiceData.map((e, i) => (
                  <div key={i} className="m_item_wrap">
                    <HopitalItemType4
                      name={e.name}
                      image_uri={e.logo_uri}
                      province={e.amphoe?.changwat?.name_th}
                      amphoe={e.amphoe?.name_th}
                      distance={e.distance}
                      phone_no={e.phone_no}
                      isActive={e.is_online}
                      handleOnClick={() => this.handleClickConsult(e)}
                    />
                  </div>
                ))}
            </div>
            <div className="m_no_service">
              <div className="title_service">
                <div className="round" />
                <div>ไม่พร้อมให้บริการ</div>
              </div>
              <div className="btn_show" onClick={this.handleShowBox}>
                {hideBox ? (
                  <>
                    <div>แสดงทั้งหมด</div>
                    <Icons.ArrowDown />
                  </>
                ) : (
                  <>
                    <div>แสดงน้อยลง</div>
                    <Icons.ArrowUp />
                  </>
                )}
              </div>
              <div className={`hide_box ${!hideBox ? 'hide' : ''}`}>
                <div className="hide_box_title">โรงพยาบาลอื่นๆ</div>
                <div className="row_2_col_no_service">
                  {iteleServiceOfflineData &&
                    iteleServiceOfflineData.map((e, i) => (
                      <div key={i} className="item_wrap">
                        <HopitalItemType4
                          name={e.name}
                          image_uri={e.logo_uri}
                          province={e.amphoe?.changwat?.name_th}
                          amphoe={e.amphoe?.name_th}
                          distance={e.distance}
                          phone_no={e.phone_no}
                          isActive={e.is_online}
                          handleOnClick={() => this.handleClickConsult(e)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_layout">
          <button className="btn_end" onClick={this.handleClickLandingPage}>
            ย้อนกลับ
          </button>
        </div>
        <Modal
          theme_modal_standard
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          {renderModal}
        </Modal>
      </ConsultDoctorContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultDoctorContainer);

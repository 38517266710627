import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { RegisterContainerStyled, LoaderWrapStyled } from './styled';
import { userService } from 'apiService';
import { iconImages } from 'assets';
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth } from 'actions';
import { RegisterFormBackUp } from 'forms';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import moment from 'moment';

class RegisterContainer extends React.Component {
  state = {
    loading: false,
    scheme: this.props.configProjectRedux.scheme === 'herwill' && 'herwill',
  };

  componentDidMount() {
    this.fetchData();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  fetchData = async () => {
    const { authRedux } = this.props;
    console.log('auth', authRedux);
    if (authRedux && authRedux.id) {
      this.setState({
        initialValues: {
          ...authRedux,
          is_confirm1: true,
          is_confirm2: true,
          is_alcohol: authRedux.is_alcohol === 1 ? true : false,
          is_smoke: authRedux.is_smoke === 1 ? true : false,
        },
        notEmailForm: true,
      });
    } else {
      this.setState({
        initialValues: { is_confirm1: true, is_confirm2: true },
        notEmailForm: false,
      });
    }
  };

  handleSubmitForm = async (values) => {
    const { notEmailForm, isRegisEmailSuccess } = this.state;
    this.setState(
      {
        loading: true,
        dataFormValues: { ...values },
      },
      () => {
        if (!notEmailForm) {
          if (isRegisEmailSuccess) {
            this.handleClickLoginEmail();
          } else {
            this.handleRegisEmail();
          }
        } else {
          this.handleSubmitProfile();
        }
      }
    );
  };

  // EMAIL PROCESS REGISTER
  // -====================================
  // -====================================
  handleRegisEmail = async () => {
    const { dataFormValues } = this.state;
    let params = {
      email: dataFormValues.email,
      password: dataFormValues.password,
      confirm_password: dataFormValues.confirm_password,
      provider: 'local',
      birthday: moment(dataFormValues.birthday).format('YYYY-MM-DD'),
    };
    let res = await userService.POST_SING_UP(params);
    if (res && res.success) {
      ReactGA.event({
        category: 'register',
        action: 'signup_success',
      });
      this.setState({
        isRegisEmailSuccess: true,
      });
      this.handleClickLoginEmail();
    } else {
      this.setState({
        loading: false,
      });
      toast.error('อีเมล์ถูกใช้แล้ว');
    }
  };
  handleClickLoginEmail = async () => {
    // const { values } = this.props.reduxForm;
    const { dataFormValues } = this.state;
    // console.log('ddd', this.props);
    let params = {
      email: dataFormValues.email,
      password: dataFormValues.password,
    };
    let res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      // Tracking user event
      ReactGA.event({
        category: 'login',
        action: 'login_success_email',
      });
      localStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: true })
      );
      this.handleSubmitProfile();
    } else {
      this.setState({
        loading: false,
      });
      toast.error('ข้อมูลไม่ตรงในระบบ');
    }
  };

  handleSubmitProfile = async () => {
    const { dataFormValues } = this.state;
    let params = {
      ...dataFormValues,
      is_alcohol: dataFormValues.is_alcohol === 'true' ? true : false,
      is_smoke: dataFormValues.is_smoke === 'true' ? true : false,
      birthday: moment(dataFormValues.birthday).format('YYYY-MM-DD'),
    };
    let res = await userService.PUT_DOCTOR_NOTE_ADD(params);
    if (res && res.success) {
      ReactGA.event({
        category: 'register',
        action: 'profile_success',
      });

      // Set userId for new users
      ReactGA.set({
        userId: res.data.id,
      });
      toast.success('อัพเดตสำเร็จ');
      this.props.setReduxUserAuth(res.data);
      this.setState({
        loading: true,
      });
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        this.handleClickNext();
      }, Math.floor(Math.random() * 800) + 200);
    } else {
      this.setState({
        loading: false,
      });
      toast.error('อัพเดตไม่สำเร็จ');
    }
  };

  handleClickNext = () => {
    // this.props.history.push(ROUTE_PATH.TERM_OF_USE);
    this.props.history.push(ROUTE_PATH.QUANLIFICATION_EXAMINATION);
  };

  handleClickProfileEdit = () => {
    this.props.history.push(ROUTE_PATH.REGISTER);
  };

  render() {
    const { initialValues, notEmailForm, loading, scheme } = this.state;
    return loading ? (
      <LoaderWrapStyled>
        <Loader
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={500}
          className="lodding"
        />
      </LoaderWrapStyled>
    ) : (
      <RegisterContainerStyled src={iconImages['bg_login.png']}>
        <div className="layout_left" />
        <div className="layout_right">
          <RegisterFormBackUp
            initialValues={initialValues}
            notEmailForm={notEmailForm}
            handleClickProfileEdit={this.handleClickProfileEdit}
            handleSubmitForm={this.handleSubmitForm}
            scheme={scheme}
          />
        </div>
      </RegisterContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.RegisterFormBackUp,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);

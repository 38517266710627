import styled from 'styled-components';
import { SCREEN } from 'assets';

export const AddProfileFamilyContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;

  min-height: calc(100vh - 105px);
  height: auto;

  .layout_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 20px;
    margin-bottom: 50px;
    .user_name {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      margin: 10px 0;
      width: 650px;
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    justify-content: center;
    // height: 100vh;
    .layout_form {
      width: 100%;
      overflow-x: hidden;
      .user_name {
        width: auto;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    justify-content: center;
    .layout_form {
      width: 100%;
      overflow-x: hidden;
    }
  }
`;

export const LoaderWrapStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const ConsultCancerFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  width: 100%;
  
  font-size: ${FONT.SIZE_16};
  color: ${COLOR.BLUE_2};
  font-weight: 600;
  .title {
    font-size: ${FONT.SIZE_18};
    font-weight: bold;
    margin-bottom: 27px;
  }
  .group_body {
    display: flex;
    .left_layout {
      width: 50%;
      padding-right: 10px;
      .top_left {
        margin-bottom: 26px;
      }
      .box_profile {
        margin-bottom: 14px;
        .title_profile {
          margin-bottom: 10px;
        }
      }
      .box_input {
        .group_input {
          margin-bottom: 20px;
          .label {
            margin-bottom: 5px;
            font-weight: 600;
          }
          &.no_margin {
            margin-bottom: 0px;
          }
        }
      }
    }
    .right_layout {
      width: 50%;
      padding-left: 10px;
      .showLabel {
        font-size: ${FONT.SIZE_16};
        font-weight: 600;
        margin-bottom: 13px;
        text-align: center;
      }
      .body_show {
        background: ${COLOR.WHITE_1};
        border-radius: 20px;
        border: 1px solid ${COLOR.GRAY_12};
        padding: 15px;
        margin-bottom: 30px;
      }
      .btn {
        margin-top: 27px;
      }
      .btn2 {
        display: none;
      }
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    
  }
  @media (max-width: 700px) {
    padding: 20px;
    .title {
      text-align: center;
    }
    .group_body {
      display: block;
      .left_layout {
        width: 100%;
        padding-right: 0px;
      }
      .right_layout {
        width: 100%;
        padding-left: 0px;
        .showLabel {
          margin-top: 13px;
        }
      }
    }
  }
  @media(max-width: ${SCREEN.SURFACE_DUO}){
    .group_body {
      .right_layout {
        .body_show {
          margin-bottom: 0px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .group_body {
      .right_layout {
        .body_show {
          margin-bottom: 0px;
        }
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    .group_body {
      .right_layout {
        .body_show {
          margin-bottom: 0px;
        }
      }
    }
  }
`;

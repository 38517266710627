import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ThankyouForInterestContainerStyled } from './styled';
import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { ButtonIcon, BoxLink, LogoLoadingFade } from 'components';
import { ROUTE_PATH } from 'helpers';

class ThankyouForInterestContainer extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.setLoadder();
    this.scrollToTop();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  componentDidUpdate() {
    window.onpopstate = (e) => {
      this.props.history.push('/');
    };
  }

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 400);
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  render() {
    const { loading } = this.state;
    return (
      <ThankyouForInterestContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div
              className="link_layout"
              onClick={this.handleClickBackFirstPage}
            >
              <BoxLink theme_standard_box_link label="กลับสู่หน้าหลัก" />
            </div>
            <div
              className="bg_layout"
              style={{ backgroundImage: `url(${iconImages['bg1.png']})` }}
            >
              <div className="logo_well">
                <img
                  className="logo_show"
                  src={iconImages['well_logo_2.png']}
                  alt="logo show"
                />
              </div>
              <div className="label1">ขอบคุณสำหรับความสนใจของคุณ</div>
              <div>กรุณารอการติดต่อกลับ</div>
              <div>ตามวันและเวลาที่คุณได้เลือกไว้</div>
            </div>
            <div className="show_button">
              <div className="btn">
                <ButtonIcon
                  theme_gray
                  lable1Size="16px"
                  label1="กลับสู่หน้าแรก"
                  handleOnClick={() => this.handleClickBackFirstPage()}
                />
              </div>
            </div>
          </>
        )}
      </ThankyouForInterestContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankyouForInterestContainer);

import styled from 'styled-components';
import { SCREEN, COLOR } from 'assets';

export const AdviceDoctorContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background: ${COLOR.GRAY_9};
  min-height: calc(100% - 87px);

  padding: 40px 0;
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .link_back {
    display: flex;
    padding: 0px 60px;
  }
  .container {
    // max-width: 725px;
    max-width: 100%;
    height: 100%;
    margin: auto;

    .title {
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .container {
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .container {
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .container {
    }
  }
  @media (max-width: 700px) {
    .link_back {
      display: none;
    }
  }
`;

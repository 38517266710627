import React from 'react';
import { BoxIconLabelStyled } from './styled';
import cx from 'classnames';

export const BoxIconLabel = ({
  handleClick,
  theme_standard,
  theme_mini,
  theme_summary,
  src,
  label,
  label2,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_mini: theme_mini,
    theme_summary:theme_summary,
  });
  return (
    <BoxIconLabelStyled>
      <div className={customClass} onClick={handleClick}>
        <div className="icon_layout">
          <img className="icon_btn" src={src} alt="logo icon" />
        </div>
        <div className="label_layout">
          <div>
            <div>{label}</div>
            {label2 && <div className="txt_description">{label2}</div>}
          </div>
        </div>
      </div>
    </BoxIconLabelStyled>
  );
};

import React, { useState } from 'react';
import { BoxSelectTimeStyled } from './styled';
import cx from 'classnames';

export const BoxSelectTime = ({ theme_standard, input, options }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [active, setActive] = useState();

  const handleClickActive = (e) => {
    setActive(e.label);
    input.onChange(e.value);
  };

  return (
    <BoxSelectTimeStyled>
      <div className={customClass}>
        <div className="bottom_layer">
          {options.map((e, i) => (
            <div className="show_button" key={i + 1}>
              <div
                className={`block ${e.label === active ? 'active' : ''}`}
                onClick={() => handleClickActive(e)}
              >
                {e.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </BoxSelectTimeStyled>
  );
};


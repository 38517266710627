import styled from "styled-components";
import { SCREEN, iconImages, COLOR } from "assets";

export const IntroductionContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  
  background-color: #f8f8f8;
  height: calc(100vh - 80px);
  background-image: url(${iconImages['dot_bg.png']}) ;
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top 0;
  .txt_title {
    color: #003564;
    font-size: 22px;
    max-width: 400px;
    font-weight: 600;
  }
  .txt_detail {
    color: #003564;
    font-size: 16px;
    margin-top: 10px;
    max-width: 400px;
  }
  .txt_detail2 {
    color: grey;
    font-size: 14px;
    margin-top: 10px;
    max-width: 400px;
  }
  .btn_next {
    width: 400px;
    height: 40px;
    margin-top: 30px;
    background-color: #3b96b7;
    &.herwill {
      background-color: ${COLOR.PINK_1};
    }
    
    font-size: 18px;
    border: none;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }

  @media (max-width: ${SCREEN.TABLET}) {
    padding-left:10%;
    .txt_title {
      font-size: 18px;
    }
    .txt_detail {
      font-size: 14px;
      width: 90%;
    }
    .txt_detail2 {
      font-size: 12px;
    }
    .btn_next {
      width: 90%;
      height: 40px;
      font-size: 14px;
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    padding-left:10%;
    .txt_title {
      font-size: 18px;
    }
    .txt_detail {
      font-size: 14px;
      width: 90%;
    }
    .txt_detail2 {
      font-size: 12px;
    }
    .btn_next {
      width: 90%;
      height: 40px;
      font-size: 14px;
    }
  }
`;

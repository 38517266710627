import styled from 'styled-components';
import { SCREEN, COLOR, FONT } from 'assets';

export const SuccessOrderContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background: ${COLOR.GRAY_9};
  height: 100%;
  position: relative;
  font-size: ${FONT.SIZE_16};
  color: ${COLOR.BLUE_2};
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
  .container_body {
    margin: auto;
    .title {
    }
    .link_back {
      position: absolute;
      left: 157px;
      top: 10px;
    }
    .link_layout {
        position: absolute;
        right: 157px;
        top: 10px;
      }
      .bg_layout {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: calc(100vh - 0px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title {
          font-size: ${FONT.SIZE_18};
          font-weight: 600;
          margin-bottom: 35px;
        }
        .group_box {
          line-height: 2;
          margin-bottom: 33px;
          width: 350px;
          .top_layer {
            color: ${COLOR.BLUE_4};
          }
          .group_label {
            font-size: ${FONT.SIZE_14};
            display: flex;
            align-items: baseline;
            .bold_font {
              font-size: ${FONT.SIZE_16};
              font-weight: 800;
              margin-left: 5px;
            }
            &.font_bold {
              font-size: ${FONT.SIZE_16};
              font-weight: 800;
            }
          }
        }
        .show_address {
          width: 50%;
          display: flex;
          align-items: center;
          flex-direction: column;
          color: ${COLOR.GRAY_13};
          .detail_2 {
            font-size: ${FONT.SIZE_12};
            display: flex;
            align-items: center;
            .group_line {
              font-size: ${FONT.SIZE_12};
              display: flex;
              margin-left: 5px;
              .icon_show {
                display: flex;
                align-items: center;
                margin-right: 7px;
                .img_show {
                  margin-right: 5px;
                  width: 21px;
                }
                &.no_margin {
                  margin-right: 0px;
                }
              }
            }
          }
          .detail_3 {
            font-size: ${FONT.SIZE_14};
            font-weight: 400;
            margin-top: 18px;
          }
          .btn2 {
            display: none;
          }
        }
      }
    }
  }
  .btn_payment_wrap {
      width: 250px;
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .container_body {
        .link_back {
          left: 20px;
          top: 30px;
        }
        .link_layout {
            right: 70px;
            top: 30px;
          }
          .bg_layout {
            height: calc(100vh + 200px);
            background-size: cover;
          }
        }
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .container_body {
        .theme_standard {
            .bg_layout {
              height: calc(100vh + 300px);
              background-size: cover;
            }
          }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .container_body {
      padding: 75px 15px 15px 15px;
      .link_back {
        left: 20px;
        top: 30px;
      }
      .link_layout {
        display: none;
      }
      .bg_layout {
        height: 100%;
        background-image: none !important;
        .title {
          margin-bottom: 18px;
          width: 100%;
        }
        .group_box {
          width: 100%;
          margin-bottom: 24px;
          padding-left: 15px;
        }
        .show_address {
          width: 100%;
          .btn2 {
            margin-top: 33px;
            display: block;
            width: 100%;
          }
        }
      }
    }
    .btn_payment_wrap {
      width: 100%;
    }
  }
`;

import React, { useEffect } from 'react';
import { NavbarHerWillStyled } from './styled';
import { iconImages } from 'assets';
// import { useHistory } from 'react-router-dom';
// import { setReduxUserAuth } from 'actions';
import { useSelector } from 'react-redux';
// import { userService } from 'apiService';
// import { ROUTE_PATH } from 'helpers/constant';

export const NavbarHerWill = () => {
  // let history = useHistory();
  // const dispatch = useDispatch();
  const authRedux = useSelector((state) => state.auth);
  // const [isMbMenuOpen, setIsMbMenuOpen] = useState();
  // const [hasToken, setHasToken] = useState();

  useEffect(() => {
    // if (authRedux && authRedux.id) {
    //   setHasToken(true);
    // } else {
    //   setHasToken(false);
    // }
  }, [authRedux]);

  return (
    <NavbarHerWillStyled >
      <div className="web_menu_layout">
        <div className="item_left">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wellcancer.com"
          >
            <div className="logo_navbar">
              <img
                className="icon_logo"
                src={iconImages['well_logo.png']}
                alt="logo well"
              />

              <div className="text_logo">
                Revolutionize your healthcare journey
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="mobile_menu_layout">
        <div className="logo_wrap">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wellcancer.com"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <img
              className="img_header"
              src={iconImages['well_logo.png']}
              alt=""
            />
            <div className="text_logo">
              Revolutionize your healthcare journey
            </div>
          </a>
        </div>
      </div>
    </NavbarHerWillStyled>
  );
};

import React from "react";
import { connect } from "react-redux";
import { GateWayQuestionContainerStyled } from "./styled";
import { userService } from "apiService";
import { withRouter } from "react-router-dom";
import { BoxLink, LogoLoadingFade } from "components";
import {
  BodyQuardrantForm,
  ImgAndButtonForm,
  ImgChoiceForm,
  ColumnButtonAndTextForm,
  MultipleButtonForm,
  TextAndButtonForm,
  SliderForm,
  GridForm,
  TextInputForm,
} from "../../../../../../forms/QuestionForm";
import moment from "moment";

class GateWayQuestionContainer extends React.Component {
  state = {
    answer: {},
    answersAllState: [],
    question: {},
    data: {},
    renderQuestion: null,
    scheme: this.props.configProjectRedux.scheme === 'herwill' && 'herwill'
  };

  componentDidMount = () => {
    this.fetchData();
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async () => {
    const { surveyResults } = this.props;
    this.setState({
      loading: true,
    });
    let res = await userService.GET_GATEWAY("BREAST_CANCER");
    if (res && res.success) {
      this.setState(
        {
          question: res.data,
        },
        this.handleTypeQuestion
      );
      this.setState({
        loading: false,
        answer: {
          type: "BREAST_CANCER",
          ref_screening_type: "herwill",
          ref_screening_id: surveyResults?.id,
          symptoms: [{}],
          answers: [],
        },
      });
    }
  };

  handleTypeQuestion = () => {
    const { question, scheme } = this.state;
    const { authRedux } = this.props;
    let formQuestion;
    // console.log('question', question);
    switch (question.question && question.question.answer_form) {
      case "choice_2col":
        formQuestion = (
          <ColumnButtonAndTextForm
            question={question.question}
            type="choice_2col"
            handleSubmitAns={this.handleSubmitAns}
            scheme={scheme}
          />
        );
        break;

      case "image_2col":
        formQuestion = (
          <ImgChoiceForm
            question={question.question}
            handleSubmitAns={this.handleSubmitAns}
            scheme={scheme}
          />
        );
        break;
      case "choice_1col":
        formQuestion = (
          <ColumnButtonAndTextForm
            type="choice_1col"
            question={question.question}
            handleSubmitAns={this.handleSubmitAns}
            scheme={scheme}
          />
        );
        break;
      case "size_date_choice":
        formQuestion = (
          <TextAndButtonForm
            question={question.question}
            handleSubmitAns={this.handleClickSizeDate}
            scheme={scheme}
          />
        );
        break;
      case "text_choice":
        formQuestion = (
          <TextAndButtonForm
            question={question.question}
            handleSubmitAns={this.handleClickSizeDate}
            scheme={scheme}
          />
        );
        break;
      case "slider":
        formQuestion = (
          <SliderForm
            question={question.question}
            handleSubmitAns={this.handleSubmitAns}
            scheme={scheme}
          />
        );
        break;
      case "image_1col":
        formQuestion = (
          <ImgAndButtonForm
            question={question.question}
            handleSubmitAns={this.handleSubmitAns}
            scheme={scheme}
          />
        );
        break;
      case "date":
        formQuestion = (
          <TextInputForm
            question={question.question}
            type="date"
            gateWay
            handleSubmitForm={this.handleSubmitTextForm}
            scheme={scheme}
          />
        );
        break;
      case "date_choice":
        formQuestion = (
          <TextAndButtonForm
            question={question.question}
            handleSubmitAns={this.handleClickSizeDate}
            scheme={scheme}
          />
        );
        break;
      case "choice_text":
        formQuestion = (
          <ColumnButtonAndTextForm
            type="choice_text"
            question={question.question}
            handleSubmitAns={this.handleSubmitAns}
            scheme={scheme}
          />
        );
        break;
      case "number":
        formQuestion = (
          <TextInputForm
            type="number"
            question={question.question}
            gateWay
            handleSubmitForm={this.handleSubmitTextForm}
            scheme={scheme}
          />
        );
        break;
      case "choice_1col_wide":
        formQuestion = (
          <ColumnButtonAndTextForm
            type="choice_1col_wide"
            question={question.question}
            handleSubmitAns={this.handleSubmitAns}
            scheme={scheme}
          />
        );
        break;
      case "choice_4quardrant":
        formQuestion = (
          <BodyQuardrantForm
            question={question.question}
            handleSubmitAns={this.handleSubmitAns}
            gender={authRedux.gender}
            scheme={scheme}
          />
        );
        break;
      case "choice_9quardrant":
        formQuestion = (
          <GridForm
            question={question.question}
            handleSubmitAns={this.handleSubmitAns}
            scheme={scheme}
          />
        );
        break;
      default:
        formQuestion = (
          <MultipleButtonForm
            question={question}
            max_select={question && question.max_symptom_count}
            handleSubmitAns={this.handleSubmitFirstQuestion}
            scheme={scheme}
          />
        );
    }
    this.setState({
      renderQuestion: formQuestion,
    });
  };

  handleSubmitTextForm = (id) => {
    const {
      reduxForm: { values },
    } = this.props;
    const ans = [{ id: id, ...values }];
    this.handleSubmitAns(ans);
  };

  handleSubmitAns = (ans) => {
    let data = this.state.answer;
    let dataAll = this.state.answersAllState;
    if (
      !this.state.answer?.answers?.find((item) =>
        ans.find((item2) => item.id === item2.id)
      )
    ) {
      data.answers = [...data.answers, ...ans];
      dataAll = [...dataAll, [...data.answers]];
      this.setState(
        {
          answer: data,
          answersAllState: dataAll,
        },
        this.handleFetchQuestion
      );
    }
  };

  handleSubmitFirstQuestion = (ans) => {
    let data = this.state.answer;
    data.symptoms = ans;
    this.setState({
      answer: data,
    });
    this.handleFetchQuestion();
  };

  handleFetchQuestion = async () => {
    const { answer } = this.state;
    const { authRedux, surveyResults } = this.props;
    // console.log("answer", answer);
    let res = await userService.POST_GETWAY(authRedux.id, answer);
    // console.log("question", res);
    if (res && res.success) {
      if (res.data.question) {
        this.setState({
          renderQuestion: null,
        });
        setTimeout(() => {
          this.setState(
            {
              question: res.data,
            },
            this.handleTypeQuestion
          );
        }, 100);
      } else {
        this.props.handleChangeProcess(3, surveyResults);
      }
    }
  };

  handleClickSizeDate = async (type_id, check) => {
    const {
      TextAndButtonForm: { values },
    } = this.props;
    let ans = [];
    if (values?.date) {
      if (check["date"].length > 0) {
        ans = [
          ...ans,
          { id: type_id.date, value: moment(values.date).format("YYYY-MM-DD") },
        ];
      } else {
        ans = [...ans];
      }
    }
    if (values?.size) {
      if (check["size"].length > 0) {
        ans = [...ans, { id: type_id.size, value: values.size }];
      } else {
        ans = [...ans];
      }
    }
    if (values?.text) {
      if (check["text"].length > 0) {
        ans = [{ id: type_id.text, value: values.text }];
      } else {
        ans = [...ans];
      }
    }
    if (type_id.button) {
      ans = [...ans, { id: type_id.button }];
    }

    this.handleSubmitAns(ans);
  };

  handleClickBackPage = () => {
    const { answer, answersAllState } = this.state;
    let data = answer;
    let answerAll;
    let answerPrev;
    if (answersAllState.length > 0) {
      if (answersAllState.length === 1) {
        answerAll = [];
        answerPrev = [];
      } else {
        answerAll = answersAllState.slice(0, answersAllState.length - 1);
        answerPrev = answerAll[answerAll.length - 1];
      }

      data.answers = answerPrev;
      this.setState({
        answer: data,
        answersAllState: answerAll,
      });
      this.handleFetchQuestion();
    } else {
      this.fetchData();
    }
  };

  render() {
    const { loading, renderQuestion, answer } = this.state;

    return (
      <GateWayQuestionContainerStyled>
        <div className="link_back" onClick={() => this.handleClickBackPage()}>
          {answer?.symptoms && answer?.symptoms[0]?.id && (
            <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
          )}
        </div>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <div className="group_layout">
            <div className="layout_detail">{renderQuestion}</div>
          </div>
        )}
      </GateWayQuestionContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  summary: state.summary,
  authRedux: state.auth,
  TextAndButtonForm: state.form.TextAndButtonForm,
  reduxForm: state.form.TextInputForm,
  configProjectRedux: state.configProjectRedux,
});

export default connect(mapStateToProps)(withRouter(GateWayQuestionContainer));

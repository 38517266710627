import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { PaymentPopupStyled } from './styled';
import { Modal } from 'components';
import {
  CreditCard,
  InternetBanking,
  PromtPay,
} from 'widgets/PaymentsSystem/OmisePaymentSystem';
import { iconImages } from 'assets';

export const PaymentPopup = forwardRef((props, ref) => {
  const { invoiceID, amount, children, onSuccess } = props;
  const [isModal, setIsModal] = useState(false);
  const [renderModal, setRenderModal] = useState(false);

  useImperativeHandle(ref, () => ({
    clickPayment() {
      handleClickPayment();
    },
  }));

  const handleClickPayment = () => {
    setIsModal(true);
    setRenderModal(
      <div className="main_container">
        <div className="icon_close_box" onClick={handleClickCloseModalCustom}>
          <img
            className="img_close"
            src={iconImages['close_gray.png']}
            alt="close icon"
          />
        </div>
        <div className="img_round_box">
          <img src={iconImages['testkit_blue3.png']} alt="pay icon" />
        </div>
        <div className="modal_title">กรุณาชำเลือกวิธีชำระเงิน</div>
        <div className="container_select_payment">
          <div className="group_box">
            <div className="payment_item_wrap">
              <CreditCard
                invoiceID={invoiceID}
                amount={amount}
                handleClickCloseModalCustom={handleClickCloseModalCustom}
              >
                <div>
                  <img
                    className="icon_img"
                    src={iconImages['pay_creditcard.png']}
                    alt=" "
                    width="auto"
                    height="44px"
                  />
                </div>
                <div>ชําระผ่าน</div>บัตรเครดิต
                <div className="image_layout">
                  <img
                    className="payment_img"
                    src={iconImages['mastercard.png']}
                    alt=" "
                  />
                </div>
              </CreditCard>
            </div>
          </div>
          <div className="group_box">
            <div className="payment_item_wrap">
              <InternetBanking
                invoiceID={invoiceID}
                amount={amount}
                handleClickCloseModalCustom={handleClickCloseModalCustom}
              >
                <div>
                  <img
                    className="icon_img"
                    src={iconImages['pay_banking.png']}
                    alt=" "
                    width="auto"
                    height="44px"
                  />
                </div>
                <div>ชำระผ่านธนาคาร</div> Online-banking
                <div className="image_layout">
                  <img
                    className="payment_img"
                    src={iconImages['bank.png']}
                    alt=" "
                  />
                </div>
              </InternetBanking>
            </div>
          </div>
          <div className="group_box">
            <div className="payment_item_wrap">
              <PromtPay
                invoiceID={invoiceID}
                amount={amount}
                handleRenderCustom={handleRenderCustom}
                handleClickCloseModalCustom={handleClickCloseModalCustom}
                onSuccess={onSuccess}
              >
                <div>
                  <img
                    className="icon_img"
                    src={iconImages['pay_promptpay.png']}
                    alt=" "
                    width="auto"
                    height="44px"
                  />
                </div>
                <div>ชําระผ่าน</div> Prompt pay
                <div className="image_layout">
                  <img
                    className="payment_img"
                    src={iconImages['promtpay.png']}
                    alt=" "
                  />
                </div>
              </PromtPay>
            </div>
          </div>
          <div className="group_box">
            <div className="payment_item_wrap">
              <div onClick={handleClickCloseModalCustom}>
                <div>
                  <img
                    className="icon_img"
                    src={iconImages['payment_later.png']}
                    alt=" "
                    width="auto"
                    height="44px"
                  />
                </div>
                <div>ชําระเงิน</div> ภายหลัง
                <div className="image_layout_hide"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleClickCloseModalCustom = () => {
    setIsModal(false);
  };

  const handleRenderCustom = (node) => {
    setRenderModal(node);
  };

  return (
    <PaymentPopupStyled>
      <div className="btn_container" onClick={handleClickPayment}>
        {children}
      </div>
      <Modal
        theme_modal_standard
        isShow={isModal}
        handleClickCloseModal={handleClickCloseModalCustom}
      >
        {renderModal}
      </Modal>
    </PaymentPopupStyled>
  );
});

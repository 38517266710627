import React from 'react'
import { BoxMember2Styled } from './styled'
import cx from "classnames";

export const BoxMember2 = ({ theme_standard_1, name, sex, birthday, age, showButton, handleClick }) => {
    const customClass = cx({
        "theme_standard_1": theme_standard_1
    })
    return (
        <BoxMember2Styled>
            <div className={customClass}>
                <div className="left_layout_member">
                    {
                        name &&
                        <div className="group_label">
                            <span className="label1">
                                {`ชื่อ : `}
                            </span>
                            <span className="label2">
                                {name}
                            </span>
                        </div>
                    }
                    {
                        sex &&
                        <div className="group_label">
                            <span className="label1">
                                {`เพศ : `}
                            </span>
                            <span className="label2">
                                {sex}
                            </span>
                        </div>
                    }
                    {
                        birthday &&
                        <div className="group_label">
                            <span className="label1">
                                {`วันเดือนปีเกิด : `}
                            </span>
                            <span className="label2">
                                {birthday}
                            </span>
                        </div>
                    }
                    {
                        (age || age >= 0) &&
                        <div className="group_label">
                            <span className="label1">
                                {`อายุ : `}
                            </span>
                            <span className="label2">
                                {`${age} ปี`}
                            </span>
                        </div>
                    }
                </div>
                {
                    showButton &&
                    <div className="right_layout_member">
                        <div className="btn" onClick={handleClick}>
                            บุคคลอื่น
                        </div>
                    </div>
                }

            </div>
        </BoxMember2Styled>
    )
}
import React from 'react';
import { ButtonIconStyled } from './styled';
import cx from 'classnames';
// import { iconImages } from 'assets';

export const ButtonIcon = ({
  theme_standard,
  theme_normal,
  theme_gray,
  theme_blue,
  theme_pink,
  theme_pink_2,
  handleOnClick,
  label1,
  label2,
  src,
  lable1Size,
  lable2Size,
  fontColor,
  iconBackground,
  disabled,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_normal: theme_normal,
    theme_gray: theme_gray,
    theme_blue: theme_blue,
    theme_pink: theme_pink,
    theme_pink_2: theme_pink_2,
  });
  return (
    <ButtonIconStyled
      lable1Size={lable1Size}
      lable2Size={lable2Size}
      fontColor={fontColor}
      iconBackground={iconBackground}
    >
      <div className={customClass} onClick={handleOnClick}>
        <button className={`btn_end ${disabled === true ? 'disabled' : ''}`} disabled={disabled}>
          {src && (
            <div className="icon_layout">
              <img className="icon_btn" src={src} alt="logo icon" />
            </div>
          )}
          <div className="txt_btn">
            <div className="label_1">{label1}</div>
            {label2 && <div className="label_2">{label2}</div>}
          </div>
        </button>
      </div>
    </ButtonIconStyled>
  );
};

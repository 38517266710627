import React from 'react'
import { BoxIconLabel2Styled } from './styled'
import cx from "classnames";

export const BoxIconLabel2 = ({ theme_standard, src, label }) => {
    const customClass = cx({
        "theme_standard": theme_standard
    })
    return (
        <BoxIconLabel2Styled>
            <div className={customClass}>
                <div className="icon_layout">
                    <img
                        className="icon_btn"
                        src={src}
                        alt="logo icon"
                    />
                </div>
                <div className="label_layout">
                    {label}
                </div>
            </div>
        </BoxIconLabel2Styled>
    )
}
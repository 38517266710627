import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { BuyProductHistoryContainerStyled } from './styled';
import { userService } from 'apiService';
// import { iconImages } from 'assets'
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth } from 'actions';
// import { ConsultGeneticForm } from 'forms';
// import { toast } from 'react-toastify';
// import moment from 'moment';
import { BoxLink, HistoryService, LogoLoadingFade } from 'components';

class BuyProductHistoryContainer extends React.Component {
  state = { loading: true };

  async componentDidMount() {
    this.scrollToTop();
    const res = await Promise.all([
      this.fetchOrderProductData(),
      this.fetchOrderServiceData(),
    ]);
    if (res) {
      this.setState({
        loading: false,
        data: [
          ...res[0].data.map((e) => ({ ...e, typeShow: true })),
          ...res[1].data.map((e) => ({ ...e, typeShow: false })),
        ].sort((a, b) => b.created_at - a.created_at),
      });

      // Tracking user pageview
      ReactGA.set({ page: this.props.location.pathname });
      ReactGA.pageview(this.props.location.pathname);
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchOrderProductData = async () => {
    const { authRedux } = this.props;

    let res = await userService.GET_PRODUCT_ORDER_LIST_BY_STATUS(
      authRedux.id,
      'completed'
    );
    if (res && res.success) {
      return res;
    }
  };

  fetchOrderServiceData = async () => {
    const { authRedux } = this.props;

    let res = await userService.GET_GENETIC_APPOINTMENT_LIST(authRedux.id);
    if (res && res.success) {
      return res;
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.PROFILE_SCREENING);
  };

  handleClickOrderItemNext = (id) => {
    ReactGA.event({
      category: 'view_product_order',
      action: 'history_view_product_order',
    });
    this.props.history.push(
      `${ROUTE_PATH.SUCCESS_ORDER}/${id}?payment_id=latest&from=transactionhistory`
    );
  };

  render() {
    const { data, loading } = this.state;

    return (
      <BuyProductHistoryContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
            {/* <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            /> */}
          </div>
        ) : (
            <>
              <div
                className="link_back"
                onClick={() => this.handleClickBackFirstPage()}
              >
                <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
              </div>
              <div className="container">
                <div className="body_show">
                  <HistoryService
                    theme_standard_history
                    data={data}
                    handleClickOrderItemNext={this.handleClickOrderItemNext}
                  />
                </div>
              </div>
            </>
          )
        }
      </BuyProductHistoryContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  // reduxForm: state.form.ConsultGeneticForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyProductHistoryContainer);

import styled from 'styled-components';
import { FONT, COLOR, SCREEN } from 'assets';

export const ConsultCancerContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background: ${COLOR.GRAY_9};
  min-height: calc(100% - 87px);

  padding: 20px 0px;
 .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container {
    max-width: 800px;
    margin: auto;
    // margin-top: 30px;
    .title {
    }
    .link_back {
      display: flex;
      justify-content: flex-end;
    }
    .btn_back {
      padding: 0px 20px 20px 20px;
      display: none;
    }
    .show_member {
      width: 800px;
      .title_show {
        font-size: ${FONT.SIZE_18};
        font-weight: 600;
        margin-bottom: 24px;
        text-align: center;
      }
      .group_box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -5px;
        .obj_item {
          width: 49%;
          margin-bottom: 20px;
        }
      }
    }
    .no_person {
      width: 800px;
      text-align: center;
      .no_person_title {
        font-size: ${FONT.SIZE_18};
        font-weight: 600;
        margin-bottom: 24px;
        text-align: center;
      }
    }
    .btn_add_family {
      padding: 20px 30% 0px 30%;
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
    min-height: calc(100% - 87px);
    .container {
      max-width: 670px;
      .btn_add_family {
        padding: 20px 30% 0px 30%;
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    min-height: calc(100% - 87px);
    .container {
      max-width: 670px;
      .show_member {
        width: 600px;
      }
    }
  }
  @media (max-width: ${SCREEN.SURFACE_DUO}) {
    padding: 20px 0px 0px 0px;
    .container {
      .link_back {
        padding-right: 20px;
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    padding: 0px;
    .container {
      .link_back {
        display: none;
      }
      .btn_back {
        display: block;
      }
      .show_member {
        width: 300px;
        .group_box {
          display: block;
          .obj_item {
            width: 100%;
          }
        }
      }
      .no_person {
        width: 300px;
      }
      .btn_add_family {
        padding: 20px 7% 0px 7%;
      }
    }
  }
  @media (max-width: ${SCREEN.MINI_PHONE}) {
    background: blue;
    .container {
      .link_back {
        display: none;
      }
      .btn_back {
        display: block;
      }
      .show_member {
        width: 300px;
        .group_box {
          display: block;
          .obj_item {
            width: 100%;
          }
        }
      }
      .no_person {
        width: 300px;
      }
    }
  }
`;

import styled from "styled-components"
import {
    COLOR,
    FONT
} from 'assets'

export const UpLoadProfilePictureStyled = styled.div`
/*===============================================
 Container 
===============================================*/
// width: 100%;
/*===============================================
Theme 
===============================================*/
    .theme_standard {
        width: 100%;
        
        font-size: ${FONT.SIZE_12};
        color: ${COLOR.GRAY_13};
        display: flex;
        .show_img {
            height: 75px;
            width: 75px;
            .box_img {
                width: 75px;
                height: 75px;
                border: 1px solid #f2f2f2;
                border-radius: 50%;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
        .button_layout {
            padding-left: 24px;
            display: flex;
            align-items: center;
            width: 100%;
            .btn1 {
                padding: 8px;
                font-size: ${FONT.SIZE_12};
                color: ${COLOR.BLUE_4};
                width: 100%;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 1px solid ${COLOR.BLUE_4};
            }
            .btn2 {
                margin-left: 16px;
                padding: 8px;
                font-size: ${FONT.SIZE_12};
                color: ${COLOR.WHITE_1};
                width: 100%;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background: ${COLOR.BLUE_4};
                border: 1px solid ${COLOR.BLUE_4};
            }
        }
    }
`
import styled from 'styled-components';
// import {
//     COLOR,
//     FONT
// } from 'assets'

export const PaymentSuccessStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  width: 100%;
  max-width: 388px;
  .payment_modal_container {
    width: 100%;
    border-radius: 12px;
    background: white;
    padding: 15px 12px;
    position: relative;
    .img_round_box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 43px;
      height: 43px;
      border: 1px solid #0c96b7;
      border-radius: 43px;
      margin: auto;
      margin-bottom: 6px;
      img {
        width: 25px;
        height: 22.5px;
      }
    }
    .modal_title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 8px;
      text-align: center;
    }
    .txt_content {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: #0c96b7;
      &.mb_13 {
        margin-bottom: 13px;
      }
    }
    .txt_recomment {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 21px;
      text-align: center;
      color: #575757;
    }
    .btn_modal_next {
      width: 100%;
      background: #3b96b7;
      padding: 12px 5px;
      color: white;
      font-size: 14px;
      font-weight: semi-bold;
      text-align: center;
      border-radius: 22.5px;
      cursor: pointer;
    }
    .icon_close_box {
      position: absolute;
      top: 14px;
      right: 34px;
      padding: 5px;
      cursor: pointer;
      .img_close {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

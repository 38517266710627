import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { HerWillContainerStyled } from './styled';
import { userService } from 'apiService';
import { iconImages, iconBackground } from 'assets';
import { setReduxUserAuth } from 'actions';
import { ROUTE_PATH } from 'helpers';
import { withRouter } from 'react-router-dom';
import { ButtonIcon, Modal, Icons } from 'components';
import { RenderCommaNumber } from 'helpers';
import { ShareGroup, CookiePermissionWidget } from 'widgets';
import AnimatedNumber from 'animated-number-react';
import { InView } from 'react-intersection-observer';

class HerWillContainer extends React.Component {
  state = {
    landingCount: 0,
  };

  componentDidMount() {
    this.clearToken();
    this.fetchData();
    // this.popUp();
  }

  fetchData = async () => {
    let res = await userService.GET_HERWILL_LANDING_COUNT();
    if (res && res.success) {
      this.setState({
        landingCount: res.data,
      });
    }
  };

  popUp = () => {
    let isShowPopUp = sessionStorage.getItem('isShowPopup');
    if (isShowPopUp !== 'true') {
      sessionStorage.setItem('isShowPopup', 'true');
      this.setState({
        isModal: true,
        renderModal: (
          <div className="pop_div">
            <div className="box_img_show">
              <img
                className="close_x"
                src={iconImages['close_x.svg']}
                alt="close"
                onClick={this.handleClickCloseModal}
              />
              <img
                className="pop_img"
                src={iconBackground['HerwillSeminar-02_popup.jpg']}
                alt="img"
              />
            </div>
            <div className="group_btn">
              <div className="btn_live" onClick={this.handleGoLive}>
                รับชม Live
              </div>
              <div className="btn_web" onClick={this.handleClickCloseModal}>
                เข้าสู่เว็บไซต์
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  handleGoLive = () => {
    ReactGA.event({
      category: 'herwill_seminar',
      action: 'goto_live_popup',
    });
    window.open('http://www.atxmediastreaming.net/HerWill_live/', '_blank');
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  handleGoLiveMain = () => {
    ReactGA.event({
      category: 'herwill_seminar',
      action: 'goto_live_landing',
    });
    window.open('http://www.atxmediastreaming.net/HerWill_live/ ', '_blank');
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  handleClickMission = (key) => {
    switch (key) {
      case '1':
        ReactGA.event({
          category: 'herwill_start',
          action: 'mission_herwill_start_new_sess',
        });
        this.props.history.push(ROUTE_PATH.AUTO_SIGNUP);
        break;
      case '2':
        ReactGA.event({
          category: 'click_mission',
          action: 'click_mission_article',
        });
        window.open(
          'https://www.thaibreast.org/TH/breastcancerknowledge.html',
          '_blank'
        );
        break;
      case '3':
        ReactGA.event({
          category: 'click_mission',
          action: 'click_mission_share',
        });
        this.setState({
          isModal: true,
          renderModal: (
            <div className="md_container">
              <div className="close_wrap" onClick={this.handleClickCloseModal}>
                <Icons.CloseHerWill />
              </div>
              <div className="md_title">
                แชร์ลิงค์ ชวนเพื่อนมาเช็คมะเร็งเต้านม
              </div>
              <div className="logo_share">
                <Icons.BtnHerWillShare />
              </div>
              <ShareGroup.ShareBtnHerWill source={'landing'} />
            </div>
          ),
        });
        break;
      default:
        break;
    }
  };

  handleClickPartner = (key) => {
    switch (key) {
      case 'fb_tbcc':
        ReactGA.event({
          category: 'click_partner',
          action: 'click_join_fb_tbcc',
        });
        window.open('https://www.facebook.com/tbcccommunity', '_blank');
        break;
      case 'line_tbcc':
        ReactGA.event({
          category: 'click_partner',
          action: 'click_join_line_tbcc',
        });
        window.open('https://lin.ee/1HzRzAq', '_blank');
        break;
      default:
        break;
    }
  };

  clearToken = () => {
    // sessionStorage.clear();
    localStorage.clear();
    this.props.setReduxUserAuth(false);
  };

  handleClickToBookAppointment = () => {
    const { configProjectRedux } = this.props;

    ReactGA.event({
      category: configProjectRedux.scheme + '_fromLanding',
      action: 'click_goCancerHospital',
    });
    this.props.history.push(ROUTE_PATH.PINK_HOSPITAL_CHOOSE);
  };

  handleClickMemmogram = () => {
    const { configProjectRedux } = this.props;

    ReactGA.event({
      category: configProjectRedux.scheme + '_fromLanding',
      action: 'click_goMammogram',
    });
    this.props.history.push(ROUTE_PATH.LOCATION_NEAR);
  };

  handleClickHealth = () => {
    const { configProjectRedux } = this.props;

    ReactGA.event({
      category: configProjectRedux.scheme + '_fromLanding',
      action: 'click_healthInsurancer',
    });
    this.props.history.push(ROUTE_PATH.HEALTH_INSURANCER);
  };

  handleClickConsultDocotr = () => {
    const { configProjectRedux } = this.props;

    ReactGA.event({
      category: configProjectRedux.scheme + '_fromLanding',
      action: 'click_goIteleList',
    });
    this.props.history.push(ROUTE_PATH.CONSULT_DOCTOR);
  };
  render() {
    const { landingCount, isModal, renderModal } = this.state;
    const { handleClickNext, cookiePermission } = this.props;
    return (
      <HerWillContainerStyled>
        {!cookiePermission && <CookiePermissionWidget />}
        <div
          className="top_page"
          style={{
            backgroundImage: `url(${iconImages['bg_pink.svg']})`,
          }}
        >
          <div className="left_layout">
            <img
              className="logo_show"
              src={iconImages['logo_HerWill.svg']}
              alt="icon show"
            />
            <div className="group_text">
              <div className="body_detail">
                <div className="group_1">
                  <span className="h1">HER Will</span>
                  <span>
                    เป็นแคมเปญที่เกิดจากความร่วมมือของ สมาคมโรคเต้านม
                    กลุ่มผู้ป่วย องค์กรต่างๆที่ต้องการแก้ ปัญหาโรคมะเร็งเต้านม
                    ตั้งแต่การตรวจ คัดกรองจนถึงการรักษา
                  </span>
                </div>
                <div className="group_2">
                  <span className="h1">เราจึงอยากชวนผู้หญิงทุกคน</span>
                  <span>
                    เข้าร่วมแคมเปญนี้ เพื่อตรวจปัจจัยเสี่ยง
                    สร้างพลังของผู้ป่วยมะเร็งเต้านม และส่งต่อกำลังใจให้แก่กัน
                    "สู้เพื่อเธอไปพร้อมกัน"
                  </span>
                </div>
              </div>
              <div className="btn_show">
                <div className="body_btn">
                  <ButtonIcon
                    theme_pink
                    src={iconImages['well_logo_small.svg']}
                    label1="เริ่มตรวจปัจจัยเสี่ยงมะเร็ง"
                    lable1Size="19px"
                    handleOnClick={handleClickNext}
                  />
                </div>
              </div>
              <div className="label_bottom_detail">
                #มะเร็งเต้านม รู้ไว เริ่มเร็ว หายทัน
              </div>
            </div>
          </div>
          <div className="right_layout">
            <img
              className="logo_right"
              src={iconImages['girl2.png']}
              alt="icon show"
            />
          </div>
        </div>
        <div className="bottom_page">
          <div className="layer_one">
            <div className="left_box">
              <div className="bottom_label">
                ภายใต้เจตจำนงของเรา และของเธอ ที่พร้อมจะลุกขึ้น
              </div>
              <div className="bottom_label">
                ในการตรวจมะเร็งเต้านมและต่อสู้กับโรค ภายใต้แคมเปญ "สู้เพื่อเธอ”
              </div>
              <div className="bottom_label heightlight">3 ภารกิจ เพื่อเธอ</div>
              <div className="bottom_label">
                ที่ผู้หญิงทุกคนสามารถตรวจมะเร็งเต้านม
              </div>
              <div className="bottom_label">
                และยังสามารถส่งต่อไปยังคนที่คุณรักได้
              </div>
            </div>
            <div className="right_box">
              <div className="box_mission">
                <div className="top_title">ภารกิจที่ 1</div>
                <img
                  className="box_image"
                  src={iconImages['mission1.svg']}
                  alt="icon show"
                  onClick={() => this.handleClickMission('1')}
                />
                <div className="box_label">
                  <div
                    className="button_show"
                    onClick={() => this.handleClickMission('1')}
                  >
                    เช็คความเสี่ยง
                  </div>
                  <div className="text_show">ประเมินปัจจัยเสี่ยง</div>
                  <div className="text_show">มะเร็งเต้านม</div>
                </div>
              </div>
              <div className="box_mission">
                <div className="top_title">ภารกิจที่ 2</div>
                <img
                  className="box_image"
                  src={iconImages['mission2.svg']}
                  alt="icon show"
                  onClick={() => this.handleClickMission('2')}
                />
                <div className="box_label">
                  <div
                    className="button_show"
                    onClick={() => this.handleClickMission('2')}
                  >
                    อ่านบทความ
                  </div>
                  <div className="text_show">เข้าใจและเพิ่มความรู้</div>
                  <div className="text_show">โรคมะเร็ง</div>
                </div>
              </div>
              <div className="box_mission">
                <div className="top_title">ภารกิจที่ 3</div>
                <img
                  className="box_image"
                  src={iconImages['mission3.svg']}
                  alt="icon show"
                  onClick={() => this.handleClickMission('3')}
                />
                <div className="box_label">
                  <div
                    className="button_show"
                    onClick={() => this.handleClickMission('3')}
                  >
                    แชร์แคมเปญ
                  </div>
                  <div className="text_show">ชวนเพื่อเข้าร่วม</div>
                  <div className="text_show">กิจกรรม</div>
                </div>
              </div>
            </div>
          </div>
          <div className="layer_two">
            <div className="box_top_new">
              <div className="itele_layout">
                <div
                  className="img_show"
                  style={{
                    backgroundImage: `url(${iconBackground['doctor_man.png']})`,
                  }}
                >
                  <img
                    className="img_itele"
                    src={iconImages['itele_2.svg']}
                    alt="icon show"
                  />
                  <div className="label_top_show">
                    <div className="group_text">
                      <div className="text1">Telemedicine</div>
                      <div className="text1 fs_18">
                        ปรึกษาแพทย์ได้ทุกที่ทุกเวลา
                      </div>
                      <div className="text2">ด้วยระบบวิดีโอคอล</div>
                    </div>
                  </div>
                  <div className="box_btn">
                    <div
                      className="btn_itele"
                      onClick={() => this.handleClickConsultDocotr()}
                    >
                      <div className="icon_btn">
                        <Icons.Camera />
                      </div>
                      ปรึกษาแพทย์ฟรี
                    </div>
                  </div>
                </div>
                <div className="service_box">
                  <div className="service_title">บริการอื่นๆ</div>
                  <div className="service_icon">
                    <div
                      className="box_service_detail"
                      onClick={() => this.handleClickMemmogram()}
                    >
                      <img
                        className="box_image"
                        src={iconImages['case.svg']}
                        alt="icon show"
                      />
                      <div className="box_label">
                        <div
                          className="button_show"
                        // onClick={() => this.handleClickMission('3')}
                        >
                          ข้อมูลศูนย์ตรวจ
                        </div>
                        <div className="text_show">เช็คศูนย์ตรวจ</div>
                        <div className="text_show">เมมโมแกรมใกล้คุณ</div>
                      </div>
                    </div>
                    <div
                      className="box_service_detail"
                      onClick={() => this.handleClickToBookAppointment()}
                    >
                      <img
                        className="box_image"
                        src={iconImages['nurse.svg']}
                        alt="icon show"
                      />
                      <div className="box_label">
                        <div className="button_show">จองคิวตรวจมะเร็ง</div>
                        <div className="text_show">พบคุณหมอเพื่อตรวจ</div>
                        <div className="text_show">
                          มะเร็งเต้านมที่โรงพยาบาล
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="service_icon mt">
                    <div
                      className="box_service_detail"
                      onClick={() => this.handleClickHealth()}
                    >
                      <img
                        className="box_image"
                        src={iconImages['asure.svg']}
                        alt="icon show"
                      />
                      <div className="box_label">
                        <div
                          className="button_show"
                        // onClick={() => this.handleClickMission('3')}
                        >
                          ประกันสุขภาพ
                        </div>
                        <div className="text_show">วางแผนการเงิน</div>
                        <div className="text_show">เพื่อสุขภาพของคุณ</div>
                      </div>
                    </div>
                  </div>
                  <div className="line_service_box" />
                </div>
              </div>
              <div className="right_detail">
                <div className="social_block">
                  <div className="sb_title">
                    รู้จักกลุ่มผู้ป่วยมะเร็งเต้านม TBCC
                  </div>
                  <div className="sb_row_2_col">
                    <div className="sb_item">
                      <div className="icon_wrap">
                        <Icons.SocialFB />
                      </div>

                      <div
                        className="btn_social"
                        onClick={() => this.handleClickPartner('fb_tbcc')}
                      >
                        เข้าเฟสบุ๊คเพจ
                      </div>
                      <div className="lb_social">
                        ชมรมผู้ป่วยมะเร็งเต้านม แห่งประเทศไทย
                      </div>
                    </div>
                    <div className="sb_item">
                      <div className="icon_wrap">
                        <Icons.SocialLine />
                      </div>
                      <div
                        className="btn_social"
                        onClick={() => this.handleClickPartner('line_tbcc')}
                      >
                        แอด Line@
                      </div>
                      <div className="lb_social">
                        ชมรมผู้ป่วยมะเร็งเต้านม แห่งประเทศไทย
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line" />
                {/* <div className="mobile_hightlight">
                  <div className="text_hightlight">Highlight น่าสนใจ</div>
                  <div className="detail_hightlight">
                    <img
                      className="img_hightlight"
                      src={iconBackground['HerwillSeminar-02_popup.jpg']}
                      alt="img"
                    />
                    <div className="btn_group_highlight">
                      <div className="time_show">
                        <div className="group_line_show">
                          <img
                            className="img_hightlight"
                            src={iconImages['calendar2.svg']}
                            alt="img"
                          />
                          <div>วันอาทิตย์ที่ 9 เมษายน 2565</div>
                        </div>
                        <div className="group_line_show">
                          <img
                            className="img_hightlight"
                            src={iconImages['time2.svg']}
                            alt="img"
                          />
                          <div>เวลา 14:00-15:00 น.</div>
                        </div>
                      </div>
                      <div className="btn_live_box">
                        <div
                          className="btn_live_display"
                          onClick={this.handleGoLiveMain}
                        >
                          รับชม Live
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='gray_line' />
                </div> */}
                <div className="left_box_social">
                  <div className="group_label">
                    <div className="label_show h1">จุดบันทึกแห่งการเดินทาง</div>
                    <div className="label_show">มีคนร่วมแคมเปญแล้ว</div>
                    <div className="label_show h2">
                      <InView triggerOnce={true}>
                        {({ inView, ref, entry }) => (
                          <div ref={ref}>
                            {inView && (
                              <AnimatedNumber
                                value={landingCount}
                                formatValue={(count) =>
                                  RenderCommaNumber(parseInt(count))
                                }
                                duration={1000}
                              />
                            )}
                          </div>
                        )}
                      </InView>
                    </div>
                  </div>
                  <div className="sub_detail">
                    <div className="group_sub">
                      <span className="h1">เพื่อโลก...</span>
                      <span>ที่มีผู้ป่วยมะเร็งเต้านมลดลง</span>
                    </div>
                    <div className="group_sub">
                      <span className="h1">เพื่อโลก...</span>
                      <span>ที่ผู้ป่วยมะเร็งเต้านมสามารถหายขาดได้</span>
                    </div>
                    <div className="group_sub">
                      <span className="h1">เพื่อโลก...</span>
                      <span>ที่ผู้ป่วยมะเร็งเต้านมยังสวย และมั่นใจได้</span>
                    </div>
                    <div className="group_sub">
                      <span>เพราะไม่จำเป็นต้องตัดเต้านมออก</span>
                    </div>
                    <div className="group_sub">
                      <span className="h1">และเพื่อโลก...</span>
                      <span>ที่มะเร็งเต้านมไม่ใช่เรื่องน่ากลัวอีกต่อไป</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hightlight_show">
              <div className='gray_line' />
              {/* <div className="text_hightlight">Highlight น่าสนใจ</div>
              <div className="detail_hightlight">
                <img
                  className="img_hightlight"
                  src={iconBackground['HerwillSeminar-02_popup.jpg']}
                  alt="img"
                />
                <div className="btn_group_highlight">
                  <div className="time_show">
                    <div className="group_line_show">
                      <img
                        className="img_hightlight"
                        src={iconImages['calendar2.svg']}
                        alt="img"
                      />
                      <div>วันอาทิตย์ที่ 9 เมษายน 2565</div>
                    </div>
                    <div className="group_line_show">
                      <img
                        className="img_hightlight"
                        src={iconImages['time2.svg']}
                        alt="img"
                      />
                      <div>เวลา 14:00-15:00 น.</div>
                    </div>
                  </div>
                  <div className="btn_live_box">
                    <div
                      className="btn_live_display"
                      onClick={this.handleGoLiveMain}
                    >
                      รับชม Live
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className='video_show'>
              <div className='line_video' />
              <div className="video_text">Highlight น่าสนใจ</div>
              <div className='box_video'>
                <iframe
                  title="herwill"
                  src="https://www.atxlivestreaming.net/Herwill/player.php"
                  style={{ width: '100%', height: '100%', overflow: "hidden", border: 'none' }}
                  scrolling="no"
                  allowfullscreen="true"
                />
              </div>
            </div>
            <div className="line_row" />
            <div className="cp_2_row">
              <div className="left_box">
                <div className="box_logo">
                  <img
                    className="logo_show"
                    src={iconImages['logo_HerWill_big.svg']}
                    alt="icon show"
                  />
                </div>
              </div>
              <div className="right_box">
                <div className="grop_text">
                  <div className="title_text">
                    HER Will แคมเปญมีเป้าหมายและความตั้งใจหลัก{' '}
                  </div>
                </div>
                <div className="box_img_label">
                  <div className="box_img">
                    <img
                      className="opt_img"
                      src={iconImages['her1.svg']}
                      alt="icon show"
                    />
                  </div>
                  <div className="opt_label">
                    <div>
                      รณรงค์ให้ผู้หญิงตรวจมะเร็งเต้านมและรับรู้อาการมะเร็งเต้านมในระยะเริ่มต้น
                    </div>
                    {/* <div>ในระยะเริ่มต้น</div> */}
                  </div>
                </div>
                <div className="box_img_label">
                  <div className="box_img">
                    <img
                      className="opt_img"
                      src={iconImages['her2.svg']}
                      alt="icon show"
                    />
                  </div>
                  <div className="opt_label">
                    <div>สร้างความเข้าใจและให้มุมมอง ว่า “มะเร็งเต้านม</div>
                    <div>ยิ่งตรวจ พบเร็ว ยิ่งมีโอกาสรักษาหายขาดมากขึ้น ”</div>
                    {/* <a
                    onClick={() => this.handleClickPartner('fb_tbcc')}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/tbcccommunity"
                  >
                    เข้าร่วมกลุ่มผู้ป่วย คลิก
                  </a> */}
                  </div>
                </div>
                <div className="box_img_label">
                  <div className="box_img">
                    <img
                      className="opt_img"
                      src={iconImages['her4.png']}
                      alt="icon show"
                    />
                  </div>
                  <div className="opt_label">
                    <div>ให้ความรู้ การรักษามะเร็งเต้านม โดยเฉพาะชนิดที่</div>
                    <div>มียีน เฮอร์ทู (HER2)+ เป็นบวก รวมถึงข้อมูลการ</div>
                    <div>รับมือ ดูแล รักษาแก่ผู้ป่วยและญาติ</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="layer_three">
            <div className="box_img">
              <img
                className="opt_img"
                src={iconImages['logo_HerWill.svg']}
                alt="icon show"
              />
            </div>
            <div className="group_three_label">
              <div className="group_sub">
                <span className="h1">เพื่อโลก...</span>
                <span>ที่มีผู้ป่วยมะเร็งเต้านมลดลง</span>
              </div>
              <div className="group_sub">
                <span className="h1">เพื่อโลก...</span>
                <span>ที่ผู้ป่วยมะเร็งเต้านมสามารถหายขาดได้</span>
              </div>
              <div className="group_sub">
                <span className="h1">เพื่อโลก...</span>
                <span>ที่ผู้ป่วยมะเร็งเต้านมยังสวย และมั่นใจได้</span>
              </div>
              <div className="group_sub">
                <span>เพราะไม่จำเป็นต้องตัดเต้านมออก</span>
              </div>
              <div className="group_sub">
                <span className="h1">และเพื่อโลก...</span>
                <span>ที่มะเร็งเต้านมไม่ใช่เรื่องน่ากลัวอีกต่อไป</span>
              </div>
            </div>
          </div>
        </div>
        <div className="band_layout">
          <div className="top_img">
            <img
              className="band_imb mr"
              src={iconImages['tbs1.png']}
              alt="icon show"
            />
            <img
              className="band_imb sm"
              src={iconImages['tbcc1.png']}
              alt="icon show"
            />
            <img
              className="band_imb sm"
              src={iconImages['logo_roche.png']}
              alt="icon show"
            />
            <img
              className="band_imb mr"
              src={iconImages['well11.png']}
              alt="icon show"
            />
            {/* <img
              className="band_imb mr"
              src={iconImages['planb.jpg']}
              alt="icon show"
            /> */}
            {/* <img
              className="band_imb sm2 mr"
              src={iconImages['tiktok.png']}
              alt="icon show"
            /> */}
            <img
              className="band_imb mr"
              src={iconImages['paolo_logo_header.png']}
              alt="icon show"
            />
            <img
              className="band_imb w_140"
              src={iconImages['pyt-logo.png']}
              alt="icon show"
            />
          </div>
          <div className="band_label">พันธมิตรที่เข้าร่วมโครงการ</div>
          <div className="code">M-TH-00001130</div>
        </div>
        <Modal
          theme_modal_standard
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
          bgColor='rgba(0, 0, 0, 0.8)'
        >
          {renderModal}
        </Modal>
      </HerWillContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  cookiePermission: state.cookiePermission,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HerWillContainer));

import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const BoxPackageStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard_1 {
    width: 100%;
    font-size: ${FONT.SIZE_14};
    color: ${COLOR.GRAY_13};
    padding: 40px 16px 16px 16px;
    background: ${COLOR.GRAY_10};
    border: 1px solid ${COLOR.GRAY_12};
    border-radius: 8px;
    // box-shadow: 2px 5px 15px -1px #dadada;
    position: relative;
    .txt_head {
      position: absolute;
      top: 6px;
      left: 16px;
      font-size: ${FONT.SIZE_16};
      color: ${COLOR.BLUE_2};
    }
    .group_top {
      display: flex;
      width: 100%;
      font-size: ${FONT.SIZE_16};
      align-items: flex-start;
      &.position_center {
        display: flex;
        align-items: center;
      }
      .left_layout {
        width: 120px;
        color: ${COLOR.BLUE_2};
        display: flex;
        align-items: center;
        .logo_show {
          width: 100%;
        }
      }
      .right_layout {
        flex: 1;
        padding-left: 20px;
        .label1 {
          font-weight: 600;
          color: ${COLOR.GREEN_1};
        }
        .label2 {
          font-size: ${FONT.SIZE_14};
          font-weight: 400;
        }
      }
      &.new_margin {
        margin-bottom: 20px;
      }
    }
    .bottom_label {
      display: flex;
      justify-content: center;
      color: rgb(19, 19, 19, 0.9);
      font-size: ${FONT.SIZE_10};
      .red_font {
        margin-left: 5px;
        color: rgb(227, 39, 39, 0.9);
      }
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .theme_standard_1 {
      // background: pink;
    }
  }
  @media (max-width: ${SCREEN.MINI_TABLET}) {
    .theme_standard_1 {
      // background: red;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .theme_standard_1 {
      .bottom_label {
        font-size: ${FONT.SIZE_10};
      }
    }
  }
`;

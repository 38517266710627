import React from 'react';
import { HopitalItemType4Styled } from './styled';
import { Icons } from 'components';

const HopitalItemType4 = ({
  name,
  province,
  amphoe,
  distance,
  image_uri,
  phone_no,
  isActive,
  handleOnClick,
}) => {
  return (
    <HopitalItemType4Styled>
      <div className="row_1">
        <div
          className="left_col"
          style={{
            backgroundImage: `url(${image_uri})`,
          }}
        ></div>
        <div className="right_col">
          <div className="txt name">{name}</div>
          <div className="txt gray mb_20">
            <div>{province}</div>
            <div>{amphoe}</div>
          </div>
          <div className={`btn_status  ${isActive ? `` : `offline`}`}>
            <div className="round" />
            <div className={`txt_status`}>
              {isActive ? 'ออนไลน์' : 'ออฟไลน์'}
            </div>
          </div>
        </div>
      </div>

      <div className="row_2">
        <div className="left_col">
          {distance && (
            <div className="rang_row">
              <Icons.Location color={isActive ? `#F395BE` : `#069EBF`} />
              <div className="txt">{distance} กม.</div>
            </div>
          )}
        </div>
        <div className="right_col">
          <div
            // className={`btn_call  ${isActive ? `` : `offline`}`}
            className={`btn_call`}
            onClick={() => handleOnClick()}
          >
            <>
                <Icons.Camera />
                <div className="btn_txt">ปรึกษาหมอ</div>
              </>
            {/* {isActive ? (
              <>
                <Icons.Camera />
                <div className="btn_txt">ปรึกษาหมอ</div>
              </>
            ) : (
              <div className="btn_txt">นอกเวลาทำการ</div>
            )} */}
          </div>
        </div>
      </div>
    </HopitalItemType4Styled>
  );
};

export default HopitalItemType4;

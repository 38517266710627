import styled from 'styled-components';
import { COLOR, FONT, SCREEN } from 'assets';

export const HerWillContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background-color: #f8f8f8;
  padding-bottom: 8px;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // .bg_layout {
  //   background-image: ${({ src }) => `url(${src})`};
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   width: 100%;
  //   height: 400px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   .icon_logo {
  //     margin-top: 70px;
  //   }
  // }
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo_wrap {
    display: flex;
    justify-content: center;
  }
  .share_detail {
    padding: 0px 20px;
    .box_layout {
      margin-left: auto;
      margin-right: auto;
      max-width: 1048px;
      width: 100%;
      /* height: 350px; */
      background-color: #F8F8F8;
      border: 2px solid rgba(155, 155, 155, 0.2);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 20px;
      .layout_share {
        display: flex;
        flex-direction: row;
        width: 220px;
        justify-content: space-between;
      }
      .txt_center {
        display: flex;
        align-items: center;
        &.row {
          flex-direction: column;
        }
        &.mb_37 {
          margin-bottom: 37px;
        }
        &.mb_12 {
          margin-bottom: 12px;
        }
        .txt_normal {
          color: black;
          font-size: 16px;
          &.bold {
            font-weight: bold;
          }
          &.fs_14 {
            font-size: 14px;
          }
        }
        .txt_pink {
          margin-left: 4px;
          color: #e96ea5;
          font-size: 22px;
          font-weight: bold;
          &.fs_36 {
            font-size: 36px;
          }
        }
        .txt_navy {
          color: #3e6689;
          font-size: 20px;
          margin-top: 40px;
          font-weight: 700;
        }
      }
      .line {
        width: 100%;
        margin-top: 56px;
        max-width: 830px;
        height: 1px;
        background: #fdb8c49c;
        margin-bottom: 16px;
      }
      .group_card {
        margin-top: 77px;
        display: flex;
        column-gap: 120px;
        .box_card {
          display: flex;
          flex-direction: column;
          align-items: center;
          .card_wrap {
            margin-top: 15px;
            margin-bottom: 15px;
            position: relative;
            min-width: 312px;
            min-height: 140px;
            .img_brand {
              width: 312px;
            }
            .group_text {
              font-size: 16px;
              font-weight: bold;
              color: #BF1A69;
              position: absolute;
              left: 26px;
              bottom: 70px;
              .text1 {
                text-align: left;
              }
              .text2 {
                text-align: left;
                color: #74083C;
              }
            }
            .group_text_2 {
              font-size: 18px;
              font-weight: bold;
              color: #007D69;
              position: absolute;
              left: 87px;
              bottom: 70px;
              display: flex;
              flex-direction: column;
              align-items: center;
              .img_itele {
                width: 110px;
              }
              .text1 {
                margin-top: 8px;
              }
            }
          }
        }
      }
      .bot_layout {
        display: flex;
        column-gap: 170px;
        margin-top: 56px;
        margin-bottom: 98px;
        .left_bot {
          display: flex;
          flex-direction: column;
          align-items: center;
          .row_2_col {
            display: flex;
            column-gap: 16px;
            &.mt {
              margin-top: 25px;
              justify-content: flex-start;
              width: 100%;
            }
            .btn_container {
              text-align: center;
              max-width: 144px;
              width: 100%;
              .img_icon {
                cursor: pointer;
                width: 104px;
                height: 104px;
                margin-bottom: 21px;
              }
              .btn_pink_sub {
                margin: auto;
                margin-bottom: 14px;
                height: 32px;
                width: 144px;
                border-radius: 22px;
                background-color: #cf1a71;
                outline: none;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #ffffff;
                font-size: 16px;
              }
              .btn_txt_detail {
                font-size: 14px;
                text-align: center;
              }
              a {
                color: #ffffff;
              }
            }
          }
        }
        .right_bot {
          display: flex;
          flex-direction: column;
          align-items: center;
          .txt_navy {
            color: #3e6689;
            font-size: 20px;
            margin-top: 40px;
            font-weight: 700;
          }
        }
      }
      .icon_share {
        margin-top: 10px;
        margin-bottom: 30px;
        cursor: pointer;
      }
      .icon_share_logo {
        width: 45px;
        height: 45px;
        cursor: pointer;
      }
      .btn_end {
        cursor: pointer;
        height: 48px;
        width: 298px;
        border-radius: 22px;
        background-color: #e8e8e8;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2),
          0 4px 20px 0 rgba(0, 0, 0, 0.19);
        .txt_btn {
          color: #000000;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .label_show {
        font-size: ${FONT.SIZE_16};
        color: ${COLOR.BLACK_3};
        margin-bottom: 12px;
        font-weight: 400;
        text-align: center;
      }
      .btn_pink_rent {
        .icon_btn {
          margin-right: 6px;
          padding-top: 7px;
        }
        border-radius: 22px;
        padding: 6px 9px;
        background-color: #cf1a71;
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .txt_btn {
          color: #ffffff;
          font-size: 20px;
        }
      }
    }
  }
  .group_box_share {
    
  }

  @media (max-width: ${SCREEN.TABLET}) {
    // height: 108vh;
    // .bg_layout {
    //   margin-top: -40px;
    //   .icon_logo {
    //     margin-top: 70px;
    //     width: 150px;
    //   }
    // }
    .share_detail {
      .box_layout {
        // width: 70%;
        // margin-top: -200px;
        text-align: center;
        .group_card {
          flex-direction: column;
          margin-top: 12px;
          row-gap: 12px;
        }
        .bot_layout {
          flex-direction: column;
        }
        .line {
          margin-top: 24px;
          margin-bottom: 17px;
        }
        .bot_layout {
          margin-top: 0px;
          margin-bottom: 0px;
        }
        .txt_center {
          text-align: center;
          .txt_normal {
            font-size: 12px;
          }
          .txt_pink {
            font-size: 22px;
            margin-left: 5px;
          }
          .txt_navy {
            color: #3e6689;
            font-size: 15px;
            margin-top: 40px;
            font-weight: 700;
          }
        }
        .btn_end {
          width: 350px;
          height: 45px;
          .icon_layout {
            width: 35px;
            height: 35px;
            background-color: white;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            .icon_btn {
              width: 20px;
              resize: cover;
            }
          }
          .txt_btn {
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    // height: 108vh;
    // .bg_layout {
    //   .icon_logo {
    //     margin-top: 70px;
    //     width: 150px;
    //   }
    // }
    .share_detail {
      .box_layout {
        // width: 250px;
        // margin-top: -200px;
        .txt_center {
          .txt_normal {
            font-size: 16px;
          }
          .txt_pink {
            font-size: 22px;
          }
          .txt_navy {
            color: #3e6689;
            font-size: 15px;
            margin-top: 40px;
            font-weight: 700;
            font-size: 20px;
          }
        }
        .btn_end {
          width: 210px;
          height: 40px;
          .icon_layout {
            width: 35px;
            height: 35px;
            background-color: white;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            .icon_btn {
              width: 20px;
              resize: cover;
            }
          }
          .txt_btn {
            text-align: center;
            font-size: 14px;
          }
        }
        .btn_pink {
          width: 210px;
          height: 40px;
          .txt_btn {
            color: #e96ea5;
            font-size: 14px;
          }
        }
      }
    }
  }
`;

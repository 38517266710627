import styled from 'styled-components';
import { 
  // COLOR, FONT 
} from 'assets';

export const InternetBankingStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
`;

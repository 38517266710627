import React from 'react';
import { BoxHospitalStyled } from './styled';
import cx from 'classnames';
import { ButtonIcon } from 'components';

export const BoxHospital = ({
  theme_standard_2,
  handleClickServiceOrder,
  data,
}) => {
  const customClass = cx({
    theme_standard_2: theme_standard_2,
  });
  return (
    <BoxHospitalStyled>
      <div className={customClass}>
        <div className="is_desktop">
          <div className="container">
            <div className="left_layout">
              <div
                className="box_img"
                style={
                  data && { backgroundImage: `url(${data.square_image_uri})` }
                }
              ></div>
            </div>
            <div className="right_layout">
              <div className="detail">
                <div
                  className="name_title"
                  dangerouslySetInnerHTML={data && { __html: data.name }}
                />
                <div
                  className="detail_sub"
                  dangerouslySetInnerHTML={
                    data && { __html: data.short_description }
                  }
                />
              </div>
              <div
                className="btn"
                onClick={() => handleClickServiceOrder(data)}
              >
                <ButtonIcon theme_pink_2 lable1Size="17px" label1="เลือก" />
              </div>
            </div>
          </div>
        </div>

        <div className="is_mobile">
          <div className="container">
            <div className="left_layout">
              <div
                className="box_img"
                style={
                  data && { backgroundImage: `url(${data.square_image_uri})` }
                }
              ></div>
            </div>
            <div className="right_layout">
              <div className="detail">
                <div
                  className="name_title"
                  dangerouslySetInnerHTML={data && { __html: data.name }}
                />
                <div
                  className="detail_sub"
                  dangerouslySetInnerHTML={
                    data && { __html: data.short_description }
                  }
                />
              </div>
              <div
                className="btn"
                onClick={() => handleClickServiceOrder(data)}
              >
                <ButtonIcon theme_pink_2 lable1Size="17px" label1="เลือก" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BoxHospitalStyled>
  );
};

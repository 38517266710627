import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const ButtonStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  .theme_standard {
    .btn_layout {
      width: ${({ width }) => (width ? width : '200px')};
      height: ${({ height }) => (height ? height : '50px')};
      /* min-height: 40px;
       */
      background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#3B96B7'};
      border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '25px'};
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${({ color }) => (color ? color : 'white')};
      font-weight: ${({ weight }) => (weight ? weight : 500)};
      font-size: ${({ font }) => (font ? font : '18px')};
      box-shadow: 18px 10px 18px -18px rgba(0, 0, 0, 0.75);
      border: none;
      cursor: pointer;
      padding: ${({ padding }) => (padding && padding)};
    }
  }
  .theme_well_login {
    width: 100%;
    .btn_layout {
      
      border-radius: 22px;
      padding: 12px;
      border: 1px solid rgb(12, 150, 183, 0.04);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${FONT.SIZE_14};
      background: ${COLOR.BLUE_4};
      color: ${COLOR.WHITE_1};
      font-weight: 600;
      cursor: pointer;
    }
  }
  .theme_well_login_border {
    width: 100%;
    .btn_layout {
      
      border-radius: 22px;
      padding: 12px;
      border: 1px solid rgb(12, 150, 183, 0.04);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${FONT.SIZE_14};
      border: 1px solid ${COLOR.BLUE_4};
      color: ${COLOR.BLUE_4};
      font-weight: 600;
      cursor: pointer;
    }
  }
  .theme_herwill_button {
    width: 100%;
    .btn_layout {
      border-radius: 22px;
      padding: 12px;
      // border: 1px solid;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${FONT.SIZE_16};
      font-weight: 500;
      cursor: pointer;
      background: ${COLOR.WHITE_1};
      color: ${COLOR.PINK_1};
    }
  }
  .theme_well_button {
    width: 100%;
    .btn_layout {
      color: white;
      border-radius: 22px;
      padding: 12px;
      border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${FONT.SIZE_14};
      font-weight: 500;
      cursor: pointer;
    }
  }
  .theme_well_button_2 {
    width: 100%;
    .btn_layout {
      
      border-radius: 22px;
      padding: 12px;
      border: 1px solid;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${FONT.SIZE_14};
      background: ${COLOR.WHITE_1};
      color: ${COLOR.BLUE_7};
      font-weight: 500;
      cursor: pointer;
    }
  }
  .theme_standard_2 {
    .btn_layout {
      width: ${({ width }) => (width ? width : '200px')};
      height: ${({ height }) => (height ? height : '50px')};
      /* min-height: 40px;
       */
      background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#3B96B7'};
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${({ color }) => (color ? color : 'white')};
      font-weight: ${({ weight }) => (weight ? weight : 500)};
      font-size: ${({ font }) => (font ? font : '18px')};
      box-shadow: 18px 10px 18px -18px rgba(0, 0, 0, 0.75);
      border: 1px solid #7070703b;
      cursor: pointer;
    }
  }
  .theme_cancer {
    .btn_layout {
      width: ${({ width }) => (width ? width : '200px')};
      height: ${({ height }) => (height ? height : 'auto')};
      min-height: ${({ height }) => (height ? height : '80px')};
      background-color: #ffffff;
      border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '25px'};
      display: flex;
      justify-content: center;
      border: ${({ active }) => (active ? '3px solid #5495B4' : 'none')};
      align-items: center;
      color: ${({ active }) => (active ? '#6AA3BE' : '#000000')};
      font-weight: ${({ weight }) => (weight ? weight : 500)};
      font-size: ${({ font }) => (font ? font : '18px')};
      box-shadow: 18px 10px 25px -22px rgba(0, 0, 0, 0.75);
      cursor: pointer;
    }
  }
  .theme_border_question {
    .btn_layout {
      width: ${({ width }) => (width ? width : '200px')};
      height: ${({ height }) => (height ? height : '50px')};
      background-color: #ffffff;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      border: ${({ active }) => (active ? '3px solid #5495B4' : 'none')};
      border-color: ${({ borderColor }) => (borderColor ? borderColor : '#5495B4')};
      align-items: center;
      color: ${({ active, borderColor }) => (active ? (borderColor ? borderColor : '#6AA3BE') : '#000000')};
      font-weight: ${({ weight }) => (weight ? weight : 500)};
      font-size: ${({ font }) => (font ? font : '18px')};
      box-shadow: 18px 10px 25px -22px rgba(0, 0, 0, 0.75);
      cursor: pointer;
    }
  }
  .theme_gradient {
    .btn_layout {
      width: ${({ width }) => (width ? width : '200px')};
      height: ${({ height }) => (height ? height : '50px')};
      /* background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'white'}; */
      border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '25px'};
      background-image: linear-gradient(to right, #22569e, #5ab3b6);
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${({ color }) => (color ? color : 'white')};
      font-weight: 500;
      font-size:  ${({ fontSize }) => (fontSize ? fontSize : '18px')};
      cursor: pointer;
      border: none;
    }
  }
  .theme_gray {
    .btn_layout {
      width: ${({ width }) => (width ? width : '200px')};
      height: ${({ height }) => (height ? height : '50px')};
      border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '25px'};
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 6px 7px 11px -8px rgba(88, 88, 88, 0.32);
      background: ${COLOR.GRAY_9};
      border: 1px solid #e5e5e5;
      color: ${({ color }) => (color ? color : 'black')};
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .theme_disable {
    .btn_layout {
      width: ${({ width }) => (width ? width : '200px')};
      height: ${({ height }) => (height ? height : '50px')};
      padding: ${({ padding }) => (padding && padding)};
      background-color: #ededed;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a5a5a5;
      font-weight: 500; 
      font-size: ${({ font }) => (font ? font : '18px')};
      border: none;
      cursor: pointer;
    }
  }
  .theme_border {
    .btn_layout {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${({ width }) => (width ? width : '200px')};
      height: ${({ height }) => (height ? height : '50px')};
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${({ color }) => (color ? color : '#a5a5a5')};
      font-weight: 500;
      font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
      border: 2px solid ${({ border }) => (border ? border : '200px')};
      cursor: pointer;
    }
  }
`;

import React, { useState } from 'react'
import { QtyBoxStyled } from './styled'
import cx from "classnames";

export const QtyBox = ({ theme_standard, input }) => {
    const [numValue, setNumValue] = useState(0);

    const handleDecrease = () => {
        numValue <= 0 ? setNumValue(0) : setNumValue(numValue - 1);
        input.onChange(numValue - 1);
    }

    const handleIncrease = () => {
        if (numValue < 99) {
            setNumValue(numValue + 1);
            input.onChange(numValue + 1);
        }
    }

    const customClass = cx({
        "theme_standard": theme_standard
    })
    return (
        <QtyBoxStyled>
            <div className={customClass}>
                <div class="decrease" onClick={() => handleDecrease()} >
                    -
                </div>
                <div className="input_layer">
                    <input class="input_number" min="0" max="99" value={numValue} disabled={true} />
                </div>
                <div class="increase" onClick={() => handleIncrease()}>
                    +
                </div>
            </div>
        </QtyBoxStyled>
    )
}
import React from "react";
import { BoxAdviceStyled } from "./styled";
import cx from "classnames";
import { iconImages } from "assets";
import { ButtonIcon } from "components";

export const BoxAdvice = ({
  theme_standard,
  handleClickBackFirstPage,
  handleClickService,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <BoxAdviceStyled>
      <div className={customClass}>
        <div
          className="bg_layout"
          style={{
            backgroundImage: `url(${iconImages["bg1.png"]})`,
            
          }}
        >
          <div className="logo_well">
            <img
              className="logo_show"
              src={iconImages["well_logo_2.png"]}
              alt="logo show"
            />
          </div>
          <div className="label1">ขออภัย เราแนะนำให้คุณพบแพทย์ผู้เชียวชาญ</div>
          <div className="label1">โดยตรงเพื่อตรวจคัดกรองมะเร็งปากมดลูก</div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.tgcsthai.com/expertise.php?code=CG"
          >
            <div className="label2" style={{ cursor: "pointer" }}>
              ดูรายชื่อแพทย์มะเร็งนรีเวชใกล้คุณได้ที่นี่
            </div>
          </a>
          <div className="btn_layout">
            <ButtonIcon
              theme_standard
              src={iconImages["calendar_blue2.png"]}
              label1="จองคิวตรวจคัดกรองมะเร็ง"
              label2="กับแพทย์ในเครือโรงพยาบาลพันธมิตรของเรา"
              lable1Size="18px"
              handleOnClick={handleClickService}
            />
          </div>
          <div className="btn2">
            <ButtonIcon
              theme_gray
              lable1Size="16px"
              label1="กลับสู่หน้าแรก"
              handleOnClick={handleClickBackFirstPage}
            />
          </div>
        </div>
      </div>
    </BoxAdviceStyled>
  );
};

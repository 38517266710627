import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { InsurancePackageContainerStyled } from './styled';
import { userService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import { PackageList, BoxLink, LogoLoadingFade } from 'components';
import { ROUTE_PATH } from 'helpers';

class InsurancePackageContainer extends React.Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.fetchDataCheckUp();
    this.scrollToTop();
    this.disableLoader();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchDataCheckUp = async () => {
    let res = await userService.GET_CANCER_CHECKUP_LIST();
    if (res && res.success) {
      this.setState({
        dataCheckup: res.data[0],
      });
    }
  };

  disableLoader = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  handleClickServiceOrder = async (service_package_id) => {
    const { authRedux } = this.props; 
    let params = {
      service_package_id: service_package_id,
    };
    let profile_id = authRedux ? authRedux.id : 0

    let res = await userService.POST_SERVICE_ORDER(profile_id, params);
    if (res && res.success) {
      // Tracking user event
      ReactGA.event({
        category: 'pyt_package',
        action: 'confirm_pyt_package',
        label: service_package_id,
        value: 1,
      });
      window.open(res.data.redirect_uri, '_blank') || window.location.assign(res.data.redirect_uri);
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  handleClickBackPage = () => {
    this.props.history.goBack();
  };

  render() {
    const { dataCheckup, loading } = this.state;
    return (
      <InsurancePackageContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
            <>
              <div
                className="link_back"
                onClick={() => this.handleClickBackPage()}
              >
                <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
              </div>
              <div className="container">
                <PackageList
                  theme_standard
                  handleClickBackFirstPage={() => this.handleClickBackFirstPage()}
                  dataCheckup={dataCheckup}
                  handleClickServiceOrder={(e) => this.handleClickServiceOrder(e)}
                />
              </div>
            </>
          )}
      </InsurancePackageContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsurancePackageContainer);

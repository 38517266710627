import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { SummaryNoSymtopmContainerStyled } from './styled';
import { iconSummary, iconImages } from 'assets';
import { Button } from '../../../../components/Button';
import { ROUTE_PATH } from 'helpers';
import { withRouter } from 'react-router-dom';
import { BoxIconLabel, LogoLoadingFade, Modal } from 'components';
import { userService } from 'apiService';
import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
// import { userService } from 'apiService';

class SummaryNoSymtopmContainer extends React.Component {
  state = { loading: true, isModal: false, hostname: '' };

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  componentDidMount() {
    this.scrollToTop();
    this.setLoadder();
    this.fetchDataConfigDomain();
    this.fetchData();
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  fetchData = async () => {
    const { authRedux, summaryNoSymptom } = this.props;

    const res = await userService.GET_NO_SYMPTOM_SCREENING_BY_ID(
      authRedux.id,
      summaryNoSymptom.summary.data.id
    );
    // // console.log('res', res)
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  fetchDataConfigDomain = async () => {
    const { configProjectRedux } = this.props;
    // this.setState({
    //   hostname: domainList[4] || window.location.hostname.toLowerCase()
    // })
    // console.log("hostnameee ", hostname)

    if (
      configProjectRedux.is_pinkalert === false &&
      configProjectRedux.is_corporate === false
    ) {
      this.setState({
        hostname: 'https://app.wellcancer.com',
      });
    } else if (
      configProjectRedux.is_pinkalert === false &&
      configProjectRedux.is_corporate === true
    ) {
      this.setState({
        hostname: 'https://corporate.wellcancer.com',
      });
    } else {
      this.setState({
        hostname: domainList[4] || window.location.hostname.toLowerCase(),
      });
    }
  };

  componentDidUpdate() {
    window.onpopstate = (e) => {
      this.props.history.push('/');
    };
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickCloseModalCustom = () => {
    this.setState({
      isModal: false,
    });
  };

  handleClickOpenModalCustom = () => {
    this.setState({
      isModal: true,
    });
  };

  levelItem = (level) => {
    switch (level) {
      case 'HIGH':
        return 'มีความเกี่ยวข้อง';
      case 'CRITICAL':
        return 'มีความเกี่ยวข้องสูง';
      case 'LOW':
        return 'ไม่มีความเสี่ยง';
      case 'MEDIUM':
        return 'คุณอาจมีความเสี่ยง';
      default:
        break;
    }
  };

  imgItem = (level) => {
    switch (level) {
      case 'HIGH':
        return 'height.png';
      case 'CRITICAL':
        return 'height.png';
      case 'MEDIUM':
        return 'medium.png';
      case 'LOW':
        return 'low.png';
      default:
        break;
    }
  };

  colorItem = (level) => {
    switch (level) {
      case 'HIGH':
        return '#F59E06';
      case 'CRITICAL':
        return '#F59E06';
      case 'LOW':
        return '#7AAA4A';
      case 'MEDIUM':
        return '#3B96B7';
      default:
        break;
    }
  };

  descriptionItem = (level) => {
    const { data } = this.state;

    if (data.type === 'colorectal') {
      switch (level) {
        case 'HIGH':
          return 'จากผลการตอบแบบสอบถามคัดกรองเบื้องต้น คุณมีความเสี่ยงเป็นมะเร็งลำไส้ เราแนะนำให้คุณพบแพทย์เพื่อตรวจคัดกรองมะเร็งที่โรงพยาบาลโดยด่วน';
        case 'LOW':
          return 'จากผลการตอบแบบสอบถามคัดกรองเบื้องต้น เราแนะนำให้หมั่นเอาใจใส่ดูแลสุขภาพ และตรวจสุขภาพเป็นประจำทุกปี';
        case 'MEDIUM':
          return 'จากผลการตอบแบบสอบถามคัดกรองเบื้องต้น เราแนะนำให้ตรวจคัดกรองมะเร็งที่โรงพยาบาล เมื่อเกณฑ์อายุถึง 50 ปี ขึ้นไป';
        case 'CRITICAL':
          return 'จากผลการตอบแบบสอบถามคัดกรองเบื้องต้น เราแนะนำให้ปรึกษาแพทย์ผู้เชี่ยวชาญต่อไป';
        default:
          break;
      }
    } else {
      switch (level) {
        case 'HIGH':
          return 'จากผลการตอบแบบสอบถามคัดกรองเบื้องต้น เราแนะนำให้คุณตรวจคัดกรองมะเร็ง หรือปรึกษาหมอเพิ่มเติม';
        case 'LOW':
          return 'จากผลการตอบแบบสอบถามคัดกรองเบื้องต้น เราแนะนำ ให้คุณตรวจสุขภาพเป็นประจำทุกปี';
        case 'MEDIUM':
          return 'จากผลการตอบแบบสอบถามคัดกรองเบื้องต้น เราแนะนำให้คุณควรหมั่นตรวจมะเร็งเต้านมด้วยตัวเองหรือตรวจคัดกรองที่โรงพยาบาล';
        case 'CRITICAL':
          return 'จากผลการตอบแบบสอบถามคัดกรองเบื้องต้น เราแนะนำให้คุณควรพบแพทย์เพื่อตรวจคัดกรองมะเร็งที่โรงพยาบาล';
        default:
          break;
      }
    }
  };

  renderType = (type) => {
    switch (type) {
      case 'breast':
        return 'มะเร็งเต้านม';
      case 'hpv':
        return 'มะเร็งปากมดลูก';
      case 'colorectal':
        return 'โรคมะเร็งลำไส้';
      default:
        break;
    }
  };

  render() {
    // const { summaryNoSymptom } = this.props;
    const { loading, isModal, hostname, data } = this.state;
    return (
      <SummaryNoSymtopmContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div className="title_header">ข้อแนะนำสำหรับคุณ</div>
            <div className="box_layout">
              <div className="box">
                <div
                  className="img_box"
                  style={
                    data && {
                      color: this.colorItem(data.level),
                    }
                  }
                >
                  <div className="text_header_box">
                    {data && data.level === 'LOW'
                      ? 'สัญญาณอาการที่อาจบ่งชี้ และเกี่ยวข้องกับอาการของมะเร็ง'
                      : 'คุณอาจมีแนวโน้มเกี่ยวข้องกับ' +
                      this.renderType(data.type)}
                  </div>
                  <img
                    className="img_level"
                    src={data && iconSummary[this.imgItem(data.level)]}
                    alt=""
                  />
                  <div className="text_logo">
                    <div className="text_score">
                      {data && this.levelItem(data.level)}
                    </div>
                  </div>
                </div>
                <div className="text_box">
                  <div className="text_grey">
                    {data && this.descriptionItem(data.level)}
                  </div>
                </div>
              </div>
            </div>
            <div className="txt_grey">
              * การแนะนำครั้งนี้ไม่ใช่การวินิจฉัยหรือการตรวจ
            </div>
            {data && data.level !== 'LOW' && (
              <div className="txt_bottom">
                <img
                  className="img_list"
                  src={iconImages['img_dot_circle.png']}
                  alt=""
                />
                <div className="text_list">แนะนำให้ปรึกษาแพทย์ด้านมะเร็ง </div>
              </div>
            )}

            <div className="result_detail">
              <div className="txt_title_3">{data && data.level === 'LOW' && (<span>คุณไม่มี</span>)}ปัจจัยเสี่ยงที่ตรวจพบ</div>
              {data.answers?.map((e, i) => (
                <div key={i} className="item_list">
                  <img
                    className="img_logo"
                    src={iconImages['img_dot_circle.png']}
                    alt=""
                  />
                  <div className="text_list">
                    {e?.question_description + ' ' + e?.answer_label}{' '}
                  </div>
                </div>
              ))}
              <div className="text_list">
                คำตอบดังกล่าวนี้ ถูกคำนวนโดย A.I. ร่วมกับประวัติ พฤติกรรม
              </div>
              <div className="text_list">
                ปัจจัยเสี่ยงและอาการร่วมอื่นๆที่เกี่ยวข้อง
              </div>
              {data?.gateway?.answers?.length > 0 && (
                <div>
                  <div className="txt_title_3">อาการนำของคุณเบื้องต้น</div>
                  {data.gateway.answers.map((e, i) => (
                    <div key={i} className="item_list">
                      <img
                        className="img_logo"
                        src={iconImages['img_dot_circle.png']}
                        alt=""
                      />
                      <div className="text_list">
                        {e?.answer_prefix + ' ' + e?.answer_label}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {data && data.score <= 3 ? (
              <div>
                <BoxIconLabel
                  theme_summary
                  handleClick={() =>
                    this.props.history.push(ROUTE_PATH.INSURANCE_PLAN)
                  }
                  src={iconImages['fwd4.png']}
                  label="วางแผนประกันด้านมะเร็ง"
                />
              </div>
            ) : (
              <Button
                width="310px"
                height="60px"
                theme_gradient
                borderRadius="20px"
                handleClickButton={() =>
                  this.props.history.push(
                    data?.type === 'โรคมะเร็งปากมดลูก'
                      ? ROUTE_PATH.EXAMINE_TEST_KIT
                      : ROUTE_PATH.INSURANCE_PACKAGE
                  )
                }
              >
                <div className="btn_service">
                  <div className="icon_btn">
                    <img
                      src={
                        iconImages[
                        data?.type === 'โรคมะเร็งปากมดลูก'
                          ? 'testkit.png'
                          : 'calendar_blue2.png'
                        ]
                      }
                      alt=""
                    />
                  </div>
                  <div className="txt_service">
                    <div>
                      {data?.type === 'โรคมะเร็งปากมดลูก'
                        ? 'ชุดตรวจ Testkit'
                        : 'จองคิวตรวจคัดกรองมะเร็ง'}
                    </div>
                    <div className="txt_xs">
                      {data?.type === 'โรคมะเร็งปากมดลูก'
                        ? 'ง่ายๆ ด้วยตนเอง'
                        : 'กับแพทย์ในเครือโรงพยาบาลพันธมิตรของเรา'}
                    </div>
                  </div>
                </div>
              </Button>
            )}
            {data?.type === 'โรคมะเร็งปากมดลูก' && (
              <div className="txt_grey2">
                การตรวจหาเชื้อไวรัสเอชพีวี (HPV DNA VIRUS)
                ที่เป็นสาเหตุของการเกิดมะเร็งปากมดลูก โดยอุปกรณ์พิเศษที่ชื่อว่า
                แปรงอีวาลีน ภายใน 10-15 วัน
              </div>
            )}

            {/* <div className="box_layout">
              <div className="box">
                <div
                  className="img_box"
                  style={{
                    color: this.colorItem(
                      summaryNoSymptom?.summary?.data?.level
                    ),
                  }}
                >
                  <div className="text_header_box">
                    {summaryNoSymptom?.summary?.data?.level === "LOW"
                      ? "สัญญาณอาการที่อาจบ่งชี้ และเกี่ยวข้องกับอาการของมะเร็ง"
                      : "คุณอาจมีแนวโน้มเกี่ยวข้องกับ" +
                      this.renderType(summaryNoSymptom?.summary?.data?.type)}
                  </div>
                  <img
                    className="img_level"
                    src={
                      iconSummary[
                      this.imgItem(summaryNoSymptom?.summary?.data?.level)
                      ]
                    }
                    alt=""
                  />
                  <div className="text_logo">
                    <div className="text_score">
                      {this.levelItem(summaryNoSymptom?.summary?.data?.level)}
                    </div>
                  </div>
                </div>
                <div className="text_box">
                  <div className="text_grey">
                    {this.descriptionItem(
                      summaryNoSymptom?.summary?.data?.level
                    )}
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="txt_grey">
              * การแนะนำครั้งนี้ไม่ใช่การวินิจฉัยหรือการตรวจ
            </div>
            {summaryNoSymptom?.summary?.data?.level !== "LOW" && (
              <div className="txt_bottom">
                <img
                  className="img_list"
                  src={iconImages["img_dot_circle.png"]}
                  alt=""
                />
                <div className="text_list">แนะนำให้ปรึกษาแพทย์ด้านมะเร็ง </div>
              </div>
            )}
            {summaryNoSymptom?.summary?.data?.score <= 3 ? (
              <div>
                <BoxIconLabel
                  theme_summary
                  handleClick={() =>
                    this.props.history.push(ROUTE_PATH.INSURANCE_PLAN)
                  }
                  src={iconImages["fwd4.png"]}
                  label="วางแผนประกันด้านมะเร็ง"
                />
              </div>
            ) : (
              <Button
                width="310px"
                height="60px"
                theme_gradient
                borderRadius="20px"
                handleClickButton={() =>
                  this.props.history.push(
                    summaryNoSymptom?.summary?.data?.type ===
                      "โรคมะเร็งปากมดลูก"
                      ? ROUTE_PATH.EXAMINE_TEST_KIT
                      : ROUTE_PATH.INSURANCE_PACKAGE
                  )
                }
              >
                <div className="btn_service">
                  <div className="icon_btn">
                    <img
                      src={
                        iconImages[
                        summaryNoSymptom?.summary?.data?.type ===
                          "โรคมะเร็งปากมดลูก"
                          ? "testkit.png"
                          : "calendar_blue2.png"
                        ]
                      }
                      alt=""
                    />
                  </div>
                  <div className="txt_service">
                    <div>
                      {summaryNoSymptom?.summary?.data?.type ===
                        "โรคมะเร็งปากมดลูก"
                        ? "ชุดตรวจ Testkit"
                        : "จองคิวตรวจคัดกรองมะเร็ง"}
                    </div>
                    <div className="txt_xs">
                      {summaryNoSymptom?.summary?.data?.type ===
                        "โรคมะเร็งปากมดลูก"
                        ? "ง่ายๆ ด้วยตนเอง"
                        : "กับแพทย์ในเครือโรงพยาบาลพันธมิตรของเรา"}
                    </div>
                  </div>
                </div>
              </Button>
            )}
            {summaryNoSymptom?.summary?.data?.type === "โรคมะเร็งปากมดลูก" && (
              <div className="txt_grey2">
                การตรวจหาเชื้อไวรัสเอชพีวี (HPV DNA VIRUS)
                ที่เป็นสาเหตุของการเกิดมะเร็งปากมดลูก โดยอุปกรณ์พิเศษที่ชื่อว่า
                แปรงอีวาลีน ภายใน 10-15 วัน
              </div>
            )} */}
            <div className="btn_healthy">
              <div className="box_btn">
                <Button
                  width="100%"
                  height="60px"
                  theme_standard
                  borderRadius="20px"
                  handleClickButton={() =>
                    this.props.history.push(ROUTE_PATH.SERVICE)
                  }
                >
                  บริการทางด้านสุขภาพ
                </Button>
              </div>
              <Button
                width="90px"
                height="60px"
                theme_cancer
                borderRadius="20px"
                handleClickButton={() =>
                  this.props.history.push(ROUTE_PATH.HOME_SCREENING)
                }
              >
                <div className="btn_right">
                  <img src={iconImages['logo.png']} alt="" />
                  <div className="txt">กลับหน้าแรก</div>
                </div>
              </Button>
            </div>
            <div className="share">
              <div>ร่วมกันหยุดมะเร็งก่อนการลุกลาม</div>
              <div>ด้วยการชวนคนรอบตัวและคนที่คุณรักมาเช็คความเสี่ยง</div>
              <div
                className="btn_share_show"
                onClick={this.handleClickOpenModalCustom}
              >
                <img
                  className="share_pic"
                  src={iconImages['heart_blue.png']}
                  alt="share"
                />
                <div>กดแชร์ Link ให้กับคนที่คุณรัก</div>
              </div>
              <div></div>
            </div>
            <Modal
              theme_modal_standard
              isShow={isModal}
              handleClickCloseModal={this.handleClickCloseModalCustom}
            >
              <div className="share_panel">
                <div className="body_icon">
                  <FacebookShareButton url={hostname}>
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>
                </div>
                <div className="body_icon">
                  <LineShareButton url={hostname}>
                    <LineIcon size={40} round={true} />
                  </LineShareButton>
                </div>
                <div className="body_icon no_margin">
                  <TwitterShareButton url={hostname}>
                    <TwitterIcon size={40} round={true} />
                  </TwitterShareButton>
                </div>
              </div>
            </Modal>
          </>
        )}
      </SummaryNoSymtopmContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  summaryNoSymptom: state.summaryNoSymptom,
  configProjectRedux: state.configProjectRedux,
  authRedux: state.auth,
});

const domainList = [
  'well-pinkalert-beta.invitrace.app',
  'well-phyathai-beta.invitrace.app',
  'well-fwd-beta.invitrace.app',
  'well-beta.invitrace.app',
  false,
];

export default connect(mapStateToProps)(withRouter(SummaryNoSymtopmContainer));

import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { SurveyResultsContainerStyled } from './styled';
import { iconImages } from 'assets';
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth } from 'actions';
import Loader from 'react-loader-spinner';
import { userService } from 'apiService';
import moment from 'moment';
import { Modal, Icons } from 'components';
import { SendEmailForm } from 'forms';

class SurveyResultsContainer extends React.Component {
  state = {
    summary_detail: {},
    scheme: this.props.configProjectRedux.scheme === 'herwill' && 'herwill',
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = async () => {
    const { surveyResults, configProjectRedux } = this.props;
    let res = await userService.GET_HERWILL_SUMMARY(surveyResults?.id);
    if (res && res.success) {
      console.log();
      this.setState({
        summary_detail: res.data,
      });

      ReactGA.set({
        cancerProbLevel: surveyResults?.summary?.level,
      });
      // Tracking user event
      ReactGA.event({
        category: 'screening_result',
        action: 'view_' + configProjectRedux.scheme + '_result_' + surveyResults?.summary?.level,
      });
    }
  };

  handleRenderLevel = (key) => {
    // Track result level
    const { authRedux } = this.props;
    const { summary_detail, scheme } = this.state;
    // console.log("summary_detail", summary_detail);
    switch (key) {
      case 'NONE':
        return (
          <ReportResult
            authRedux={authRedux}
            summary_detail={summary_detail}
            color="green"
            label1="ความเกี่ยวข้องกับมะเร็งเต้านม"
            label2="ไม่มีความเกี่ยวข้อง"
            label3="ควรตรวจสุขภาพประจำปี"
            handleClickNext={this.handleClickNext}
            scheme={scheme}
            handleClickSendEmail={this.handleClickSendEmail}
          />
        );
      case 'LOW':
        return (
          <ReportResult
            authRedux={authRedux}
            summary_detail={summary_detail}
            color="green"
            scheme={scheme}
            label1={
              <>
                {/* คำตอบของคุณไม่มีความเกี่ยวข้องกับปัจจัยเสี่ยง
                ในการเกิดมะเร็งเต้านมใน 5 ปีข้างหน้า */}
                จากผลการทดสอบปัจจัยเบื้องต้น
              </>
            }
            label2={
              <>
                คุณมี<div>ความเสี่ยงต่ำ</div>
              </>
            }
            label3="เราแนะนำให้คุณควร"
            label4="และคุณอาจหมั่นตรวจมะเร็งเต้านมด้วยตัวเอง"
            label5="หมั่นตรวจสุขภาพประจำปี"
            handleClickNext={this.handleClickNext}
            handleClickSendEmail={this.handleClickSendEmail}
          />
        );
      case 'MEDIUM':
        return (
          <ReportResult
            authRedux={authRedux}
            summary_detail={summary_detail}
            color="blue"
            scheme={scheme}
            label1={
              <>
                {/* คำตอบของคุณมีความเกี่ยวข้องกับ
                <span className="hight_light">ปัจจัยเสี่ยง</span>
                ในการเกิดมะเร็งเต้านมใน 5 ปีข้างหน้า */}
                จากผลการทดสอบปัจจัยเบื้องต้น
              </>
            }
            label2={
              <>
                มีความเสี่ยง<div>ปานกลาง</div>
              </>
            }
            label3="เราแนะนำให้คุณควร"
            label5="หมั่นตรวจมะเร็งเต้านมด้วยตัวเอง หรือเริ่มตรวจคัดกรองที่โรงพยาบาล"
            handleClickNext={this.handleClickNext}
            handleClickSendEmail={this.handleClickSendEmail}
          />
        );
      case 'HIGH':
        return (
          <ReportResult
            authRedux={authRedux}
            summary_detail={summary_detail}
            color="blue"
            scheme={scheme}
            label1={
              <>
                {/* คำตอบของคุณมีความเกี่ยวข้องกับ
                <span className="hight_light">ปัจจัยเสี่ยง</span>
                ในการเกิดมะเร็งเต้านมใน 5 ปีข้างหน้า */}
                จากผลการทดสอบปัจจัยเบื้องต้น
              </>
            }
            label2={
              <>
                มีความเสี่ยง<div>ปานกลาง</div>
              </>
            }
            label3="เราแนะนำให้คุณควร"
            label4="หรือปรึกษาแพทย์ในการตรวจสุขภาพครั้งถัดไป"
            label5="เริ่มตรวจคัดกรองมะเร็งที่โรงพยาบาล"
            handleClickNext={this.handleClickNext}
            handleClickSendEmail={this.handleClickSendEmail}
          />
        );
      case 'VERYHIGH':
        return (
          <ReportResult
            authRedux={authRedux}
            summary_detail={summary_detail}
            color="blue_dark"
            scheme={scheme}
            label1={
              <>
                จากผลการทดสอบปัจจัยเบื้องต้น
                {/* คำตอบของคุณมีความเกี่ยวข้องกับ
                <span className="hight_light">ปัจจัยเสี่ยง</span>
                ในการเกิดมะเร็งเต้านมใน 5 ปีข้างหน้า */}
              </>
            }
            label2={
              <>
                มีปัจจัย<div>เกี่ยวข้องมาก</div>
              </>
            }
            label3="คุณอาจมีปัจจัยที่เกี่ยวข้องหลายด้านควร"
            label4="หรือปรึกษาแพทย์เพื่อขอคำแนะนำ"
            label5="เริ่มตรวจคัดกรองมะเร็งที่โรงพยาบาลทันที"
            handleClickNext={this.handleClickNext}
            handleClickSendEmail={this.handleClickSendEmail}
          />
        );
      default:
        break;
    }
  };

  handleClickSendEmail = async () => {
    this.setState({
      isModal: true,
      renderModal: (
        <div className="md_container">
          <div className="close_wrap" onClick={this.handleClickCloseModal}>
            <Icons.CloseHerWill />
          </div>
          <SendEmailForm handleClickSendEmail={this.sendEmail} />
        </div>
      ),
    });
  };

  handleClickNext = () => {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
      this.props.handleChangePath(ROUTE_PATH.SHARE);
    }, Math.floor(Math.random() * 600) + 100);
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  handleChangeEmail = (e) => {
    // console.log('d', e.target.value);
    this.setState({
      email: e.target.value,
    });
  };

  sendEmail = async (callback) => {
    const { surveyResults } = this.props;
    const { values } = this.props.reduxForm;
    let params = {
      ...values,
    };
    // console.log('values', this.props.reduxForm, values);
    let res = await userService.POST_HERWILL_SEND_EMAIL(
      surveyResults?.id,
      params
    );
    if (res && res.success) {
      callback(res.data);
      this.handleClickCloseModal();
    } else {
      callback(false);
    }
  };

  render() {
    const { surveyResults } = this.props;
    const { loading, isModal, renderModal } = this.state;
    return (
      <SurveyResultsContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            />
          </div>
        ) : (
          <>{this.handleRenderLevel(surveyResults?.summary?.level)}</>
        )}
        <Modal
          theme_modal_standard
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          {renderModal}
        </Modal>
      </SurveyResultsContainerStyled>
    );
  }
}

const ReportResult = ({
  color,
  label1,
  label2,
  label3,
  label4,
  label5,
  handleClickSendEmail,
  handleClickNext,
  authRedux,
  summary_detail,
  scheme,
}) => (
  <div>
    <div className="header_title">
      <div className="txt_title">รายงานผลการตรวจคัดกรอง</div>
      <div className="txt_title">ปัจจัยเสี่ยงมะเร็งเต้านม</div>
      <div className="txt_title_2">คุณ {authRedux?.firstname}</div>
      <div className="date_show">
        {summary_detail &&
          `เมื่อวันที่ ${moment(summary_detail.created_at).format(
            'MM/DD/YYYY'
          )}`}
      </div>
    </div>
    <div className="box_detail">
      <div className="txt_title_box">{label1}</div>
      <div className="circle_box">
        <div className={`circle ${color}`}>
          <img
            className="icon_btn"
            src={iconImages['w_logo.png']}
            alt="logo well"
          />
          <div className={`txt_circle`}>{label2 && label2}</div>
        </div>
      </div>
      {/* <div className="txt_detail mt">
        จากผลการ<span className="hight_light">ทดสอบปัจจัยเบื้องต้น</span>
      </div> */}
      <div className="txt_detail">{label3 && label3}</div>
      <div className="txt_detail2">{label5 && label5}</div>
      <div className="txt_end">{label4 && label4}</div>
    </div>
    <div className="remark_show">
      <div>*</div>
      <div className="text_detail">
        การประเมินนี้ไม่ใช่การวินิจฉัยหรือแทนคำแนะนำทาง
        การแพทย์และคำตอบของคุณไม่มีความเกี่ยวข้องกับ
        ปัจจัยเสี่ยงในการเกิดมะเร็งเต้านมในอีก 5 ปีข้างหน้า
      </div>
    </div>
    <div className="result_detail">
      <div className="txt_title_3">ปัจจัยเสี่ยงที่ตรวจพบ</div>
      {summary_detail?.answers?.map((e, i) => (
        <div key={i} className="item_list">
          <img
            className="img_logo"
            src={iconImages['img_dot_circle.png']}
            alt=""
          />
          <div className="text_list">
            {e?.question_description + ' ' + e?.answer_label}{' '}
          </div>
        </div>
      ))}
      <div className="text_list">
        คำตอบดังกล่าวนี้ ถูกคำนวนโดย A.I. ร่วมกับประวัติ พฤติกรรม
      </div>
      <div className="text_list">
        ปัจจัยเสี่ยงและอาการร่วมอื่นๆที่เกี่ยวข้อง
      </div>
      {summary_detail?.gateway?.answers?.length > 0 && (
        <div>
          <div className="txt_title_3">อาการนำของคุณเบื้องต้น</div>
          {summary_detail.gateway.answers.map((e, i) => (
            <div key={i} className="item_list">
              <img
                className="img_logo"
                src={iconImages['img_dot_circle.png']}
                alt=""
              />
              <div className="text_list">
                {e?.answer_prefix + ' ' + e?.answer_label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>

    {scheme === 'herwill' && (
      <div style={{ marginLeft: '5%', marginRight: '5%', marginTop: 16 }}>
        <button className={`btn_next ${scheme}`} onClick={handleClickSendEmail}>
          ส่งไปที่ E-mail ของคุณ 
        </button>
      </div>
    )}
    <div style={{ marginLeft: '5%', marginRight: '5%', marginTop: 16, marginBottom: '5%' }}>
      <button className={`btn_next ${scheme}`} onClick={handleClickNext}>
        ถัดไป
      </button>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.SendEmailForm,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyResultsContainer);

import styled from 'styled-components';
import { COLOR } from 'assets';

export const TrueWalletFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background: ${COLOR.WHITE_1};
  width: 300px;
  padding: 15px;
  border: 0.5px solid #d0d6e2;
  border-radius: 10px;
  .title {
    margin-bottom: 10px;
  }
  button {
    margin-top: 10px;
    cursor: pointer;
    font-size: 16px;
    // background: #ffd11a;
    color: #0059b3;
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #d0d3cd;
    font-weight: 600;
    border-radius: 5px;
  }
`;

import React from 'react';
import { BannerPartnerStyled } from './styled';
import { iconLogo } from 'assets';

const BannerPartner = () => {
  return (
    <BannerPartnerStyled>
      <div className="line_box">
        <div className="line" />
        <div className="line_txt">In partnership with</div>
      </div>
      <img
        className="img_partner"
        src={iconLogo['partner-group.png']}
        alt="well partner"
      />
    </BannerPartnerStyled>
  );
};

export default BannerPartner;

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { WellRegisterFormStyled } from './styled';
import {
  InputText,
  Button,
  DateTime,
  OptionBox,
  DropDownCustom,
  LogoLoadingFade3,
} from 'components';
import { iconImages } from 'assets';
import { VALIDATE } from 'helpers';
import { userService } from 'apiService';

class WellRegisterForm extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchCompanyList();
  }

  fetchCompanyList = async () => {
    let res = await userService.GET_COMPANY_LIST();
    if (res && res.success) {
      // console.log('company', res);
      this.setState({
        companyOption: res.data.map((e) => ({
          label: e.name,
          value: e.id,
        })),
      });
    }
  };

  render() {
    const {
      step,
      handleChangeStep,
      registerName,
      handleSetStep,
      handleSuccesRegister,
      is_corporate,
      loading,
      isOldUser,
    } = this.props;
    const { companyOption } = this.state;
    return (
      <WellRegisterFormStyled>
        {step === 1 && (
          <div className="move_show">
            <div className="load_move_form">
              {loading ? (
                <LogoLoadingFade3 />
              ) : (
                <img
                  className="img_show"
                  src={iconImages['well_logo_white.png']}
                  alt="icon show"
                />
              )}
            </div>
            <div style={{ fontSize: '24px' }}>สวัสดีค่ะ</div>
            <div style={{ marginBottom: '20px', fontSize: '24px' }}>
              คุณชื่ออะไร?
            </div>
            <div>
              <div className="group_input">
                <div className="label_name">กรุณากรอกชื่อ</div>
                <Field
                  theme_well
                  name="firstname"
                  component={InputText}
                  width="100%"
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              <div className="group_input">
                <div className="label_name">กรุณากรอกนามสกุล</div>
                <Field
                  theme_well
                  name="lastname"
                  component={InputText}
                  width="100%"
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              <div className="btn_layer">
                <div className="show_button">
                  <Button
                    theme_well_button
                    handleClickButton={handleChangeStep}
                  >
                    นี่คือชื่อและนามสกุลของฉัน
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="move_show">
            <div className="load_move_form">
              {loading ? (
                <LogoLoadingFade3 />
              ) : (
                <img
                  className="img_show"
                  src={iconImages['well_logo_white.png']}
                  alt="icon show"
                />
              )}
            </div>
            <div style={{ fontSize: '24px' }}>
              ยินดีต้อนรับ
              <span style={{ fontWeight: 'bold', marginLeft: '7px' }}>
                {`คุณ ${registerName}`}
              </span>
            </div>
            <div style={{ marginTop: '30px' }}>
              <div>Well จะเก่งและแม่นยํามากขึ้น</div>
              <div>หากเรารู้จักคุณมากขึ้น...</div>
            </div>
            <div className="btn_layer2">
              <div className="show_button">
                <Button theme_well_button handleClickButton={handleChangeStep}>
                  นี่คือชื่อและนามสกุลของฉัน
                </Button>
              </div>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="move_show">
            <div className="load_move_form">
              {loading ? (
                <LogoLoadingFade3 />
              ) : (
                <img
                  className="img_show"
                  src={iconImages['well_logo_white.png']}
                  alt="icon show"
                />
              )}
            </div>
            <div style={{ marginBottom: '20px', fontSize: '24px' }}>
              คุณเกิดวันที่...
            </div>
            <div>
              <div className="group_input">
                <Field
                  theme_line
                  component={DateTime}
                  name="birthday"
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              <div className="group_input text_center">
                ( การตรวจปัจจัยเสี่ยงไม่เหมาะสมกับบุคคลที่อายุ ต่ำกว่า 16 ปี )
              </div>
              <div className="btn_layer">
                <div className="show_button">
                  <Button
                    theme_well_button
                    handleClickButton={handleChangeStep}
                  >
                    นี่คือวันเกิดของฉัน
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 4 && (
          <div className="move_show">
            <div className="load_move_form">
              {loading ? (
                <LogoLoadingFade3 />
              ) : (
                <img
                  className="img_show"
                  src={iconImages['well_logo_white.png']}
                  alt="icon show"
                />
              )}
            </div>
            <div style={{ marginBottom: '160px', fontSize: '24px' }}>
              เพศของคุณ...
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ width: '70%' }}>
                <Field
                  theme_standard_opt
                  name="gender"
                  data={[
                    {
                      label: 'หญิง',
                      value: 'female',
                    },
                    {
                      label: 'ชาย',
                      value: 'male',
                    },
                  ]}
                  onChangeCustom={handleChangeStep}
                  component={OptionBox}
                  // validate={VALIDATE.REQUIRE}
                />
              </div>
            </div>
          </div>
        )}
        {step === 5 && (
          <div className="move_show">
            <div className="load_move_form">
              {loading ? (
                <LogoLoadingFade3 />
              ) : (
                <img
                  className="img_show"
                  src={iconImages['well_logo_white.png']}
                  alt="icon show"
                />
              )}
            </div>
            <div style={{ marginBottom: '110px', fontSize: '24px' }}>
              น้ำหนักของคุณ...
            </div>
            <div>
              <div className="group_input">
                <div className="label_name">กรุณากรอกน้ำหนักของคุณ</div>
                <div className="input_box">
                  <Field
                    theme_well
                    name="weight"
                    component={InputText}
                    width="100%"
                    validate={VALIDATE.NUMBER}
                  />
                  <span className="back_label">กิโลกรัม</span>
                </div>
              </div>
              <div className="btn_layer4">
                <div className="show_button">
                  <Button
                    theme_well_button
                    handleClickButton={handleChangeStep}
                  >
                    ถัดไป
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 6 && (
          <div className="move_show">
            <div className="load_move_form">
              {loading ? (
                <LogoLoadingFade3 />
              ) : (
                <img
                  className="img_show"
                  src={iconImages['well_logo_white.png']}
                  alt="icon show"
                />
              )}
            </div>
            <div style={{ marginBottom: '110px', fontSize: '24px' }}>
              ส่วนสูงของคุณ......
            </div>
            <div>
              <div className="group_input">
                <div className="label_name">กรุณากรอกส่วนสูงของคุณ</div>
                <div className="input_box">
                  <Field
                    theme_well
                    name="height"
                    component={InputText}
                    width="100%"
                    validate={VALIDATE.NUMBER}
                  />
                  <span className="back_label">เซนติเมตร</span>
                </div>
              </div>
              <div className="btn_layer">
                <div className="show_button">
                  <Button
                    theme_well_button
                    handleClickButton={handleChangeStep}
                  >
                    ถัดไป
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 7 && (
          <div className="move_show">
            <div className="load_move_form">
              {loading ? (
                <LogoLoadingFade3 />
              ) : (
                <img
                  className="img_show"
                  src={iconImages['well_logo_white.png']}
                  alt="icon show"
                />
              )}
            </div>
            <div style={{ marginBottom: '160px', fontSize: '24px' }}>
              คุณสูบบุหรี่หรือไม่...
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ width: '70%' }}>
                <Field
                  theme_standard_opt
                  name="is_smoke"
                  data={[
                    {
                      label: 'สูบ',
                      value: true,
                    },
                    {
                      label: 'ไม่สูบ',
                      value: false,
                    },
                  ]}
                  component={OptionBox}
                  onChangeCustom={handleChangeStep}
                  // validate={VALIDATE.REQUIRE}
                />
              </div>
            </div>
          </div>
        )}
        {step === 8 && (
          <div className="move_show">
            <div className="load_move_form">
              {loading ? (
                <LogoLoadingFade3 />
              ) : (
                <img
                  className="img_show"
                  src={iconImages['well_logo_white.png']}
                  alt="icon show"
                />
              )}
            </div>
            <div style={{ marginBottom: '160px', fontSize: '24px' }}>
              คุณดื่มเครื่องดื่มมีแอลกอฮอล์หรือไม่...
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ width: '70%' }}>
                <Field
                  theme_standard_opt
                  name="is_alcohol"
                  data={[
                    {
                      label: 'ดื่ม',
                      value: true,
                    },
                    {
                      label: 'ไม่ดื่ม',
                      value: false,
                    },
                  ]}
                  component={OptionBox}
                  onChangeCustom={handleChangeStep}
                  // validate={VALIDATE.REQUIRE}
                />
              </div>
            </div>
          </div>
        )}
        {is_corporate ? (
          <>
            {step === 9 && (
              <div className="move_show">
                <div className="load_move_form">
                  {loading ? (
                    <LogoLoadingFade3 />
                  ) : (
                    <img
                      className="img_show"
                      src={iconImages['well_logo_white.png']}
                      alt="icon show"
                    />
                  )}
                </div>
                <div
                  style={{
                    marginBottom: '10px',
                    marginTop: '20px',
                    fontSize: '24px',
                  }}
                >
                  คุณอยู่บริษัทอะไร
                </div>
                <div>
                  <div className="group_input">
                    <div className="label_show" style={{ fontSize: '14px' }}>
                      กรุณาเลือกบริษัท
                    </div>
                    <Field
                      theme_well
                      label=""
                      width="100%"
                      name="company_id"
                      options={companyOption}
                      alignItems="center"
                      fontSize="23px"
                      // onChangeCustom={(e) => {
                      //   this.handleSelectedProvince(e);
                      // }}
                      component={DropDownCustom}
                      validate={VALIDATE.REQUIRE}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: '10px',
                    marginTop: '20px',
                    fontSize: '24px',
                  }}
                >
                  ตำแหน่ง
                </div>
                <div>
                  <div className="group_input">
                    <div className="label_show" style={{ fontSize: '14px' }}>
                      กรุณากรอกตำแหน่งที่คุณอยู่ปัจจุบัน
                    </div>
                    <Field
                      theme_well
                      name="position"
                      component={InputText}
                      width="100%"
                      validate={VALIDATE.REQUIRE}
                    />
                  </div>
                </div>
                <div className="btn_layer">
                  <div className="show_button">
                    <Button
                      theme_well_button
                      handleClickButton={handleChangeStep}
                    >
                      ถัดไป
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {step === 10 && (
              <div className="move_show">
                <div className="load_move_form">
                  {loading ? (
                    <LogoLoadingFade3 />
                  ) : (
                    <img
                      className="img_show"
                      src={iconImages['well_logo_white.png']}
                      alt="icon show"
                    />
                  )}
                </div>
                <div style={{ marginBottom: '20px', fontSize: '24px' }}>
                  คุณมีโรคประจำตัวหรือไม่
                </div>
                <div>
                  <div className="group_input margin_bottom1">
                    <div className="label_name">
                      กรุณากรอกชื่อโรคประจำตัว
                    </div>
                    <Field
                      theme_well
                      name="disease"
                      component={InputText}
                      width="100%"
                      // validate={VALIDATE.REQUIRE}
                    />
                  </div>
                  <div className="btn_layer5 no_margin_top">
                    <div className="show_button">
                      <Button
                        theme_well_button
                        handleClickButton={handleChangeStep}
                      >
                        ยืนยัน
                      </Button>
                    </div>
                  </div>
                  <div className="btn_layer5">
                    <div className="show_button">
                      <Button
                        theme_well_button
                        handleClickButton={() => {
                          if (localStorage.getItem('tokenList')) {
                            handleSetStep(10);
                          } else {
                            handleChangeStep();
                          }
                        }}
                      >
                        ไม่มีโรคประจำตัว
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isOldUser === false && step === 11 && (
              <div className="move_show">
                <div className="load_move_form">
                  {loading ? (
                    <LogoLoadingFade3 />
                  ) : (
                    <img
                      className="img_show"
                      src={iconImages['well_logo_white.png']}
                      alt="icon show"
                    />
                  )}
                </div>
                <div style={{ marginBottom: '10px', fontSize: '24px' }}>
                  ข้อมูลในการเข้าใช้ระบบ
                </div>
                <div>
                  <div className="group_input">
                    <div className="label_name">กรุณากรอกอีเมล์ของคุณ</div>
                    <div className="input_box">
                      <Field
                        theme_well
                        name="email"
                        component={InputText}
                        width="100%"
                        validate={VALIDATE.EMAIL}
                      />
                    </div>
                  </div>
                  <div className="group_input">
                    <div className="label_name">รหัสผ่าน</div>
                    <div className="input_box">
                      <Field
                        theme_well
                        type="password"
                        name="password"
                        component={InputText}
                        width="100%"
                        validate={[VALIDATE.REQUIRE, VALIDATE.MIN_PW_4_LENGTH]}
                      />
                    </div>
                  </div>
                  <div className="group_input">
                    <div className="label_name">ยืนยันรหัสผ่าน</div>
                    <div className="input_box">
                      <Field
                        theme_well
                        type="password"
                        name="confirm_password"
                        component={InputText}
                        width="100%"
                        validate={[VALIDATE.REQUIRE, VALIDATE.PASSWORD_MATCH]}
                      />
                    </div>
                  </div>
                  <div className="btn_layer4">
                    <div className="show_button">
                      <Button
                        theme_well_button
                        handleClickButton={handleChangeStep}
                      >
                        ถัดไป
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isOldUser === false
              ? step === 12 && (
                  <div className="move_show">
                    <div className="load_move_form">
                      {loading ? (
                        <LogoLoadingFade3 />
                      ) : (
                        <img
                          className="img_show"
                          src={iconImages['well_logo_white.png']}
                          alt="icon show"
                        />
                      )}
                    </div>
                    <div style={{ fontSize: '24px' }}>
                      <div>
                        ขอบคุณมากค่ะ
                        <span style={{ fontWeight: 'bold', marginLeft: '7px' }}>
                          {`คุณ${registerName}`}
                        </span>
                      </div>
                      <div>ตอนนี้ Well รู้จักคุณมากขึ้นแล้ว</div>
                      <div style={{ marginTop: '30px' }}>
                        <div>งั้นเรามาเริ่มต้นใช้งาน</div>
                        <div>Well กันเถอะ...</div>
                      </div>
                      <div className="btn_layer">
                        <div className="show_button full_width">
                          <Button
                            theme_well_button_2
                            handleClickButton={handleSuccesRegister}
                          >
                            ถัดไป
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              : step === 11 && (
                  <div className="move_show">
                    <div className="load_move_form">
                      {loading ? (
                        <LogoLoadingFade3 />
                      ) : (
                        <img
                          className="img_show"
                          src={iconImages['well_logo_white.png']}
                          alt="icon show"
                        />
                      )}
                    </div>
                    <div style={{ fontSize: '24px' }}>
                      <div>
                        ขอบคุณมากค่ะ
                        <span style={{ fontWeight: 'bold', marginLeft: '7px' }}>
                          {`คุณ${registerName}`}
                        </span>
                      </div>
                      <div>ตอนนี้ Well รู้จักคุณมากขึ้นแล้ว</div>
                      <div style={{ marginTop: '30px' }}>
                        <div>งั้นเรามาเริ่มต้นใช้งาน</div>
                        <div>Well กันเถอะ...</div>
                      </div>
                      <div className="btn_layer">
                        <div className="show_button full_width">
                          <Button
                            theme_well_button_2
                            handleClickButton={handleSuccesRegister}
                          >
                            ถัดไป
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
          </>
        ) : (
          <>
            {step === 9 && (
              <div className="move_show">
                <div className="load_move_form">
                  {loading ? (
                    <LogoLoadingFade3 />
                  ) : (
                    <img
                      className="img_show"
                      src={iconImages['well_logo_white.png']}
                      alt="icon show"
                    />
                  )}
                </div>
                <div style={{ marginBottom: '20px', fontSize: '24px' }}>
                  คุณมีโรคประจำตัวหรือไม่
                </div>
                <div>
                  <div className="group_input margin_bottom1">
                    <div className="label_name">กรุณากรอกชื่อโรคประจำตัว</div>
                    <Field
                      theme_well
                      name="disease"
                      component={InputText}
                      width="100%"
                      // validate={VALIDATE.REQUIRE}
                    />
                  </div>
                  <div className="btn_layer5 no_margin_top">
                    <div className="show_button">
                      <Button
                        theme_well_button
                        handleClickButton={handleChangeStep}
                      >
                        ยืนยัน
                      </Button>
                    </div>
                  </div>
                  <div className="btn_layer5">
                    <div className="show_button">
                      <Button
                        theme_well_button
                        handleClickButton={() => {
                          if (localStorage.getItem('tokenList')) {
                            handleSetStep(10);
                          } else {
                            handleChangeStep();
                          }
                        }}
                      >
                        ไม่มีโรคประจำตัว
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isOldUser === false && step === 10 && (
              <div className="move_show">
                <div className="load_move_form">
                  {loading ? (
                    <LogoLoadingFade3 />
                  ) : (
                    <img
                      className="img_show"
                      src={iconImages['well_logo_white.png']}
                      alt="icon show"
                    />
                  )}
                </div>
                <div style={{ marginBottom: '10px', fontSize: '24px' }}>
                  ข้อมูลในการเข้าใช้ระบบ
                </div>
                <div>
                  <div className="group_input">
                    <div className="label_name">กรุณากรอกอีเมล์ของคุณ</div>
                    <div className="input_box">
                      <Field
                        theme_well
                        name="email"
                        component={InputText}
                        width="100%"
                        validate={VALIDATE.EMAIL}
                      />
                    </div>
                  </div>
                  <div className="group_input">
                    <div className="label_name">รหัสผ่าน</div>
                    <div className="input_box">
                      <Field
                        theme_well
                        type="password"
                        name="password"
                        component={InputText}
                        width="100%"
                        validate={[VALIDATE.REQUIRE, VALIDATE.MIN_PW_4_LENGTH]}
                      />
                    </div>
                  </div>
                  <div className="group_input">
                    <div className="label_name">ยืนยันรหัสผ่าน</div>
                    <div className="input_box">
                      <Field
                        theme_well
                        type="password"
                        name="confirm_password"
                        component={InputText}
                        width="100%"
                        validate={[VALIDATE.REQUIRE, VALIDATE.PASSWORD_MATCH]}
                      />
                    </div>
                  </div>
                  <div className="btn_layer4">
                    <div className="show_button">
                      <Button
                        theme_well_button
                        handleClickButton={handleChangeStep}
                      >
                        ถัดไป
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isOldUser === false
              ? step === 11 && (
                  <div className="move_show">
                    <div className="load_move_form">
                      {loading ? (
                        <LogoLoadingFade3 />
                      ) : (
                        <img
                          className="img_show"
                          src={iconImages['well_logo_white.png']}
                          alt="icon show"
                        />
                      )}
                    </div>
                    <div style={{ fontSize: '24px' }}>
                      <div>
                        ขอบคุณมากค่ะ
                        <span style={{ fontWeight: 'bold', marginLeft: '7px' }}>
                          {`คุณ${registerName}`}
                        </span>
                      </div>
                      <div>ตอนนี้ Well รู้จักคุณมากขึ้นแล้ว</div>
                      <div style={{ marginTop: '30px' }}>
                        <div>งั้นเรามาเริ่มต้นใช้งาน</div>
                        <div>Well กันเถอะ...</div>
                      </div>
                      <div className="btn_layer">
                        <div className="show_button full_width">
                          <Button
                            theme_well_button_2
                            handleClickButton={handleSuccesRegister}
                          >
                            ถัดไป
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              : step === 10 && (
                  <div className="move_show">
                    <div className="load_move_form">
                      {loading ? (
                        <LogoLoadingFade3 />
                      ) : (
                        <img
                          className="img_show"
                          src={iconImages['well_logo_white.png']}
                          alt="icon show"
                        />
                      )}
                    </div>
                    <div style={{ fontSize: '24px' }}>
                      <div>
                        ขอบคุณมากค่ะ old
                        <span style={{ fontWeight: 'bold', marginLeft: '7px' }}>
                          {`คุณ${registerName}`}
                        </span>
                      </div>
                      <div>ตอนนี้ Well รู้จักคุณมากขึ้นแล้ว</div>
                      <div style={{ marginTop: '30px' }}>
                        <div>งั้นเรามาเริ่มต้นใช้งาน</div>
                        <div>Well กันเถอะ...</div>
                      </div>
                      <div className="btn_layer">
                        <div className="show_button full_width">
                          <Button
                            theme_well_button_2
                            handleClickButton={handleSuccesRegister}
                          >
                            ถัดไป
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
          </>
        )}
      </WellRegisterFormStyled>
    );
  }
}

export default reduxForm({
  form: 'WellRegisterForm', // a unique identifier for this form
  enableReinitialize: true,
})(WellRegisterForm);

import styled from 'styled-components';
import { SCREEN, FONT, COLOR } from 'assets';
import { iconImages } from 'assets';

export const LoginFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/

  input.input {
  }
  .pc_show {
    display: block;
    .form_layout {
      margin: 30px 0px;
      margin-bottom: 15px;
      .layout_top {
        display: flex;
        flex-direction: column;
        margin-top: 45px;
        justify-content: center;
        .txt_login {
          color: #003564;
          font-size: 24px;
        }
        .btn_line {
          cursor: pointer;
          width: 320px;
          height: 45px;
          margin-top: 17px;
          background-color: #00b900;
          font-size: 14px;
          font-weight: 500;
          border-radius: 9px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: none;
          margin-left: auto;
          margin-right: auto;
          .img_line {
            margin-right: 10px;
            width: 19px;
            height: 19px;
          }
        }
        .btn_fb {
          cursor: pointer;
          width: 320px;
          height: 45px;
          margin-top: 7px;
          background-color: #3061a1;
          font-size: 14px;
          font-weight: 500;
          border-radius: 9px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: none;
          margin-left: auto;
          margin-right: auto;
          .img_facebook {
            margin-right: 10px;
            width: 19px;
            height: 19px;
          }
        }
        .line_line {
          cursor: pointer;
          ._1RLww {
            ._RU-K2 {
              margin: 0px;
              width: 320px;
              height: 45px;
              margin-top: 17px;
              background-color: #00b900;
              border-radius: 9px;
              display: flex;
              align-items: center;
              justify-content: center;
              outline: none;
              margin-left: auto;
              margin-right: auto;
              background-image: none;
              font-size: 14px;
              font-weight: 500;
              color: white;
            }
            ._RU-K2:before {
              content: 'ลงทะเบียนด้วย Line ID';
              background-image: url(${iconImages['line_logo2.png']});
              background-repeat: no-repeat;
              background-position: 0 1px;
              padding-left: 30px;
              background-size: 19px;
              position: relative;
              white-space: nowrap;
            }
          }
        }
      }
      .txt_middle {
        display: flex;
        justify-content: center;
        color: #3061a1;
        font-size: 12px;
        margin: 20px;
      }
      .group_input {
        text-align: left;
        margin-bottom: 10px;
        .label_name {
          color: #3061a1;
          margin: 10px 0;
          font-weight: 600;
          font-size: 13px;
          display: inline-block;
          line-height: 1.5;
        }
      }
      .check_input {
        margin-top: 20px;
        font-size: 13px;
      }
    }
    .btn_next {
      width: 320px;
      height: 45px;
      font-weight: 700;
      cursor: pointer;
      margin-top: 10px;
      background-color: #3b96b7;
      font-size: 13px;
      border: none;
      border-radius: 30px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
    }
    .btn_regis {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 320px;
      height: 45px;
      border-radius: 30px;
      color: white;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 14px;
      &.facebook {
        background-color: #3061a1;
        .img_facebook {
          margin-right: 8px;
        }
      }
      &.email {
        // background-color: #3b96b7;
        border: 1px solid #3b96b7;
        color: #3b96b7;
        margin-top: 6.5px;
        .img_email {
          margin-right: 8px;
        }
      }
    }
  }
  .mobile_show {
    display: none;
    width: 100%;
    color: ${COLOR.BLUE_2};
    .txt_login {
          color: #003564;
          font-size: 24px;
          margin-top: 2.5rem;
          margin-bottom: 1rem;
    }
    .m_title {
      font-size: ${FONT.SIZE_24};
      font-weight: 500;
      text-align: center;
      margin-top: 2.5rem;
      margin-bottom: 10px;
    }
    .line_line {
          cursor: pointer;
          ._1RLww {
            ._RU-K2 {
              margin: 0px;
              width: 100%;
              height: 50px;
              margin-top: 17px;
              background-color: #00b900;
              border-radius: 9px;
              display: flex;
              align-items: center;
              justify-content: center;
              outline: none;
              margin-left: auto;
              margin-right: auto;
              background-image: none;
              font-size: 14px;
              font-weight: 500;
              color: white;
            }
            ._RU-K2:before {
              content: 'ลงทะเบียนด้วย Line ID';
              background-image: url(${iconImages['line_logo2.png']});
              background-repeat: no-repeat;
              background-position: 0 1px;
              padding-left: 30px;
              background-size: 19px;
              position: relative;
              white-space: nowrap;
            }
          }
        }
      .btn_fb {
        cursor: pointer;
          width: 100%;
          height: 50px;
          margin-top: 7px;
          background-color: #3061a1;
          font-size: 14px;
          font-weight: 500;
          border-radius: 9px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: none;
          margin-left: auto;
          margin-right: auto;
          .img_facebook {
            margin-right: 10px;
            width: 19px;
            height: 19px;
          }
      }
    .txt_middle {
      display: flex;
      justify-content: center;
      color: #3061a1;
      font-size: 12px;
        margin: 20px;
    }
    .box_email {
      padding-bottom: 46px;
      margin-bottom: 24px;
      border-bottom: 1px solid rgb(152, 152, 152, 0.1);
      .group_input {
        text-align: left;
        margin-bottom: 10px;
        .label_name {
          color: #3061a1;
          margin: 10px 0;
          font-weight: 600;
          font-size: 13px;
          display: inline-block;
          line-height: 1.5;
        }
      }
      .check_input {
        margin-top: 20px;
        font-size: 13px;
      }
      .show_button {
        margin-top: 30px;
        &.mt_7 {
          margin-top: 7px;
        }
      }
    }
    .other_login {
      .o_title {
        font-size: ${FONT.SIZE_14};
        font-weight: 500;
        text-align: center;
        margin-bottom: 16px;
      }
      
      .btn_line {
        margin-top: 10px;
        background-color: #00b900;
        padding: 12px;
        font-size: ${FONT.SIZE_14};
        font-weight: 500;
        border-radius: 22px;
        color: ${COLOR.WHITE_1};
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        width: 100%;
        .img_line {
          margin-right: 10px;
          width: 19px;
          height: 19px;
        }
      }
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    width: 100%;
    .pc_show {
      display: none;
    }
    .mobile_show {
      display: block;
    }
    // .form_layout {
    //   .layout_top {
    //     .txt_login {
    //       color: #003564;
    //       font-size: 24px;
    //       margin-left: 55px;
    //       margin-bottom: 10px;
    //     }
    //     .btn_fb {
    //       width: 270px;
    //       height: 40px;
    //       margin-left: auto;
    //       margin-right: auto;
    //       font-weight: 700;
    //     }
    //   }
    //   .group_input {
    //     width: 260px;
    //     margin-left: auto;
    //     margin-right: auto;
    //   }
    //   .check_input {
    //     margin-left: 55px;
    //   }
    // }
    // .btn_next {
    //   width: 270px;
    //   height: 40px;
    //   font-weight: 700;
    //   margin-left: auto;
    //   margin-right: auto;
    // }
  }
`;

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { LoginFormStyled } from './styled';
import { VALIDATE } from 'helpers';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { InputText, CheckBox, Button } from 'components';
import { iconImages } from 'assets';
import { LineLogin } from 'reactjs-line-login';
import 'reactjs-line-login/dist/index.css';

class LoginForm extends React.Component {
  state = {};

  render() {
    const {
      responseFacebook,
      responseLine,
      handleSubmit,
      handleClickLoginEmail,
      handleClickRigsiter,
    } = this.props;
    return (
      <LoginFormStyled>
        <form onSubmit={handleSubmit(handleClickLoginEmail)}>
          {/* mobile show */}
          <div className="pc_show">
            <div className="form_layout">
              <div className="layout_top">
                <div className="txt_login">เข้าสู่ระบบ</div>
                {/* <div className="btn_line">
                  <img
                    className="img_line"
                    src={iconImages['line_logo2.png']}
                    alt="logo facebook"
                  />
                    ลงทะเบียนด้วย Line ID
                </div> */}
                <div className="line_line">
                  <LineLogin
                    clientID={process.env.REACT_APP_LINE_APP_ID}
                    clientSecret="b758b60836fde18fdc37e275e54574b2"
                    state="b41c8fd15b895f0fc28bfwb9d7da89054d931e7s"
                    nonce="d78a51235f6ee189e831q9c68523cfa336917ada"
                    redirectURI={window.location.hostname + '?provider=line'}
                    scope="profile openid email"
                    setPayload={responseLine}
                    setIdToken={(idToken) => console.log('idToken:', idToken)}
                  />
                </div>
                <FacebookLogin
                  autoLoad={false}
                  appId={process.env.REACT_APP_FB_APP_ID}
                  callback={responseFacebook}
                  // redirectUri={process.env.REACT_APP_SELF_ENDPOINT}
                  redirectUri={window.location.hostname}
                  render={(renderProps) => (
                    <div className="btn_fb" onClick={renderProps.onClick}>
                      <img
                        className="img_facebook"
                        src={iconImages['icon_face.png']}
                        alt="logo facebook"
                      />
                      ลงทะเบียนด้วย Facebook
                    </div>
                  )}
                />
              </div>
              <div className="txt_middle"> หรือ </div>
              <div className="group_input">
                <div className="label_name">อีเมล์</div>
                <Field
                  theme_standard
                  name="email"
                  width="100%"
                  placeHolder="you@example.com"
                  component={InputText}
                  validate={VALIDATE.EMAIL}
                />
              </div>
              <div className="group_input">
                <div className="label_name">Password</div>
                <Field
                  type="password"
                  theme_standard
                  width="100%"
                  name="password"
                  placeHolder="Password"
                  component={InputText}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              <div className="check_input">
                <Field
                  theme_standard
                  name="isRemember"
                  label="จดจำบัญชีของฉัน"
                  component={CheckBox}
                />
              </div>
            </div>
            <button className="btn_next" type="submit">
              ยืนยัน
            </button>
            <div className="btn_regis email" onClick={handleClickRigsiter}>
              ลงทะเบียนใหม่
            </div>
          </div>

          {/* mobile show */}
          <div className="mobile_show">
            <div className="txt_login">เข้าสู่ระบบ</div>
            <div className="line_line">
              <LineLogin
                clientID={process.env.REACT_APP_LINE_APP_ID}
                clientSecret="b758b60836fde18fdc37e275e54574b2"
                state="b41c8fd15b895f0fc28bfwb9d7da89054d931e7s"
                nonce="d78a51235f6ee189e831q9c68523cfa336917ada"
                redirectURI={
                  process.env.REACT_APP_SELF_ENDPOINT + '?provider=line'
                }
                scope="profile openid email"
                setPayload={responseLine}
                setIdToken={(idToken) => console.log('idToken:', idToken)}
              />
            </div>
            <FacebookLogin
                autoLoad={false}
                isMobile={true}
                disableMobileRedirect={true}
                appId={process.env.REACT_APP_FB_APP_ID}
                callback={responseFacebook}
                redirectUri={process.env.REACT_APP_SELF_ENDPOINT}
                render={(renderProps) => (
                  <div className="btn_fb" onClick={renderProps.onClick}>
                    <img
                      className="img_facebook"
                      src={iconImages['icon_face.png']}
                      alt="logo facebook"
                    />
                    ลงทะเบียนด้วย Facebook
                  </div>
                )}
              />
            <div className="txt_middle"> หรือ </div>
            <div className="box_email">
              <div className="group_input">
                <div className="label_name">อีเมล์</div>
                <Field
                  theme_standard_2
                  name="email"
                  width="100%"
                  placeHolder="you@example.com"
                  component={InputText}
                  validate={VALIDATE.EMAIL}
                />
              </div>
              <div className="group_input">
                <div className="label_name">Password</div>
                <Field
                  type="password"
                  theme_standard_2
                  width="100%"
                  name="password"
                  placeHolder="Password"
                  component={InputText}
                  validate={VALIDATE.REQUIRE}
                />
              </div>
              <div className="check_input">
                <Field
                  theme_standard_2
                  name="isRemember"
                  label="จดจำบัญชีของฉัน"
                  component={CheckBox}
                />
              </div>
              <div className="show_button">
                <Button
                  theme_well_login
                  handleClickButton={handleClickLoginEmail}
                >
                  ยืนยัน
                </Button>
              </div>
              <div className="show_button mt_7">
                <Button
                  theme_well_login_border
                  handleClickButton={handleClickRigsiter}
                >
                  ลงทะเบียนใหม่
                </Button>
              </div>
            </div>
          </div>
        </form>
      </LoginFormStyled>
    );
  }
}

export default reduxForm({
  form: 'LoginForm', // a unique identifier for this form
  enableReinitialize: true,
})(LoginForm);

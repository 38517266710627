import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { BuyProductContainerStyled } from './styled';
import { userService } from 'apiService';
import { ROUTE_PATH, calAge } from 'helpers';
import {
  setReduxUserAuth,
  setReduxOrderProduct,
  setReduxAddressShipping,
} from 'actions';
import {
  ConfirmOrder,
  ModalCustom,
  BoxMember,
  BoxLink,
  LogoLoadingFade,
  ButtonIcon,
} from 'components';
import { toast } from 'react-toastify';
import { ConfirmOrderForm } from 'forms';
import { PaymentPopup } from 'widgets/PaymentsSystem';

class BuyProductContainer extends React.Component {
  state = { loading: true, isModal: false };

  componentDidMount() {
    this.fetchProductData();
    this.scrollToTop();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchProductData = async () => {
    const { authRedux, orderProductRedux } = this.props;
    if (orderProductRedux.orderProduct === null) {
      toast.error('กรุณาเลือกทำรายการสั่งซื้อด้วย');
      this.props.history.push(ROUTE_PATH.EXAMINE_TEST_KIT);
    } else {
      this.setState({
        dataOrderProduct: orderProductRedux.orderProduct,
        dataProfile: authRedux,
        initialValues: {
          email:
            authRedux?.loginWith !== 'line' && authRedux.email
              ? authRedux.email
              : '',
          phone_number: authRedux.phone_number && authRedux.phone_number,
        },
      });
      setTimeout(() => {
        this.setState({
          loading: false,
        });
      }, 300);
    }
  };

  handleChoosePayment = () => {
    this.setState({
      isModal: true,
      renderModal: (
        <div className="show_member payment">
          <div className="title_show payment">
            กรุณาเลือกวิธีชำระเงิน
            <PaymentPopup
              handleClickCloseModal={this.handleClickConfirmPayment}
            />
          </div>
        </div>
      ),
    });
  };

  handleOtherPersonClick = async () => {
    const { authRedux } = this.props;

    let res = await userService.GET_PROFILE_FAILIES();
    if (res && res.success) {
      this.setState({
        families: res.data,
        isModal: true,
        renderModal: (
          <>
            {res.data.length > 0 ? (
              <div className="show_member_container">
                <div className="title_show">รายชื่อสมาชิก</div>
                <div className="group_box">
                  <div className="obj_item">
                    <BoxMember
                      theme_standard
                      divKey={authRedux.id}
                      name={`${authRedux.firstname} ${authRedux.lastname} (ตัวเอง)`}
                      sex={authRedux.gender}
                      age={calAge(authRedux.birthday)}
                      smoking={authRedux.is_smoke === 0 ? 'ไม่สูบ' : 'สูบ'}
                      height={authRedux.height}
                      drink={authRedux.is_alcohol === 0 ? 'ไม่สูบ' : 'สูบ'}
                      weight={authRedux.weight}
                      handleClick={() =>
                        this.handleSelectOtherPerson(authRedux)
                      }
                    />
                  </div>
                  {res.data.map((e, i) => (
                    <div className="obj_item">
                      <BoxMember
                        theme_standard
                        divKey={e.id}
                        name={`${e.firstname} ${e.lastname}${
                          e.relationship && ` (${e.relationship.name})`
                        }`}
                        sex={e.gender}
                        age={calAge(e.birthday)}
                        smoking={e.is_smoke === 0 ? 'ไม่สูบ' : 'สูบ'}
                        height={e.height}
                        drink={e.is_alcohol === 0 ? 'ไม่สูบ' : 'สูบ'}
                        weight={e.weight}
                        handleClick={() => this.handleSelectOtherPerson(e)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="no_person">
                <div className="no_person_title">รายชื่อสมาชิก</div>
                ยังไม่มีข้อมูลบุคคลอื่น
              </div>
            )}
            {/* <div
              onClick={() =>
                this.props.history.push(
                  `${ROUTE_PATH.PROFILE_FAMILY}?redirectCallbackPath=${ROUTE_PATH.BUY_PRODUCT}`
                )
              }
            >
              PROFILE_FAMILY
            </div> */}
            <div className="btn_add_family">
              <ButtonIcon
                theme_normal
                lable1Size="18px"
                label1="+ เพิ่มสมาชิก"
                handleOnClick={() =>
                  this.props.history.push(
                    `${ROUTE_PATH.PROFILE_FAMILY}?redirectCallbackPath=${ROUTE_PATH.BUY_PRODUCT}`
                  )
                }
              />
            </div>
          </>
        ),
      });
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  handleSelectOtherPerson = (e) => {
    this.setState({
      isModal: false,
      dataProfile: e,
    });
  };

  handleClickConfirmPayment = async () => {
    const {
      reduxForm: { values, syncErrors },
      // setReduxAddressShipping,
    } = this.props;
    const { dataOrderProduct, dataProfile } = this.state;
    // console.log("yuessssssssssssssssss", values, syncErrors, dataProfile, authRedux)
    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบ');
    } else {
      let params = {
        product_id: dataOrderProduct.product_id,
        amount: dataOrderProduct.amount,
        ...values,
      };
      let res = await userService.POST_PRODUCT_ORDER(dataProfile.id, params);
      if (res && res.success) {
        // Tracking user event
        ReactGA.event({
          category: 'product_order',
          action: 'confirm_product_order',
          value: dataOrderProduct.amount,
        });
        // setReduxAddressShipping({ addressShipping: res.data, dataProfile });
        this.props.history.push(`${ROUTE_PATH.SUCCESS_ORDER}/${res.data.id}`);
      }
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.QUESTION_BEFORE_BUY);
  };

  render() {
    const {
      dataOrderProduct,
      dataProfile,
      loading,
      isModal,
      renderModal,
      initialValues,
    } = this.state;

    return (
      <BuyProductContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
          </div>
        ) : (
          <>
            <div
              className="link_back"
              onClick={() => this.handleClickBackFirstPage()}
            >
              <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
            </div>
            <div className="container">
              <div className="left_order">
                <ConfirmOrder
                  theme_standard
                  handleClick={() => this.handleOtherPersonClick()}
                  dataOrderProduct={dataOrderProduct}
                  dataProfile={dataProfile}
                />
              </div>
              <div className="right_order">
                <ConfirmOrderForm
                  initialValues={initialValues}
                  handleClickConfirmPayment={() =>
                    this.handleClickConfirmPayment()
                  }
                />
              </div>
            </div>
          </>
        )}
        <ModalCustom
          theme_ModalCustom_standard
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
          cusStyBody={{ maxHeight: '70vh', overflow: 'auto' }}
        >
          {renderModal}
        </ModalCustom>
      </BuyProductContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  orderProductRedux: state.orderProduct,
  addressShippingRedux: state.addressShipping,
  reduxForm: state.form.ConfirmOrderForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxOrderProduct: (data) => dispatch(setReduxOrderProduct(data)),
  setReduxAddressShipping: (data) => dispatch(setReduxAddressShipping(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyProductContainer);

import React, { useEffect, useState } from 'react';
import { MyAddressLocationStyled } from './styled';
import { useSelector } from 'react-redux';
import { MdCancel } from 'react-icons/md';
import { userService } from 'apiService';
import { AddressNow, DropDownCustom } from 'components';

const MyAddressLocation = ({ onGetCurrentPosition, onChangeAmphoe }) => {
  const { auth: authRedux } = useSelector((state) => state);

  const [addressCurrent, setAddressCurrent] = useState();
  const [isEnableAddressInput, setIsEnableAddressInput] = useState();
  const [provinceData, setProvinceData] = useState();
  const [provinceIDSelected, setProvinceIDSelected] = useState();
  const [amphoeData, setAmphoeData] = useState();
  const [amphoeIDSelected, setAmphoeIDSelected] = useState();

  useEffect(() => {
    fetchProvinceList();
    if (authRedux && authRedux.amphoe) {
      setAddressCurrent({
        province: authRedux?.amphoe?.changwat?.name_th,
        amphoe: authRedux?.amphoe?.name_th,
      });
      onEventParent('onGetCurrentPosition', {
        type: 'amphoeID',
        amphoeID: authRedux.amphoe.id,
        status: true,
      });
      setProvinceIDSelected(authRedux?.amphoe?.changwat?.id);
      handleSelectedProvince(
        authRedux?.amphoe?.changwat?.id,
        authRedux?.amphoe?.id
      );
    } else {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          if (position) {
            let res = await fetchNearestAmphoe(
              position.coords.latitude,
              position.coords.longitude
            );
            if (res) {
              setAddressCurrent({
                province: res?.province,
                amphoe: res?.amphoe,
              });
            }
            onEventParent('onGetCurrentPosition', {
              type: 'latln',
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              status: true,
            });
          } else {
          }
        },
        (error) => {
          setAddressCurrent({
            province: authRedux?.amphoe?.changwat?.name_th,
            amphoe: authRedux?.amphoe?.name_th,
          });
          setIsEnableAddressInput(true);
          handleSelectedProvince(10);
          onEventParent('onGetCurrentPosition', {
            status: false,
            msg: 'not allowed get position',
          });
        },
        { enableHighAccuracy: true, timeout: 30000 }
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProvinceList = async () => {
    let res = await userService.GET_CHANGWAT_LIST();
    if (res && res.success) {
      setProvinceData([
        ...res.data.map((e) => ({
          label: e.name_th,
          value: e.id,
        })),
      ]);
    }
  };

  const fetchAmphoeList = async (
    provinceID = provinceIDSelected,
    defaultAmphoeID
  ) => {
    let res = await userService.GET_AMPHOES_LIST(provinceID);
    if (res && res.success) {
      setAmphoeData(
        res.data.map((e) => ({
          label: e.name_th,
          value: e.id,
        }))
      );
      setAmphoeIDSelected(defaultAmphoeID);
    }
  };

  const fetchNearestAmphoe = async (lat, lng) => {
    let res = await userService.GET_AMPHOES_NEAREST_LIST(lat, lng);
    if (res && res.success) {
      handleSelectedProvince(res.data?.changwat?.id, res.data?.id);
      return {
        province: res.data?.changwat?.name_th,
        amphoe: res.data?.name_th,
      };
    } else {
      return { province: lat, amphoe: lng };
    }
  };

  const handleSelectedProvince = (e, defaultAmphoeID) => {
    fetchAmphoeList(e, defaultAmphoeID);
    setProvinceIDSelected(e);
  };

  const handleSelectedAmphoe = async (e) => {
    let findProvince = provinceData.find(
      (f) => f.value === Number(provinceIDSelected)
    );
    let findAmphoe = amphoeData.find((f) => f.value === Number(e));
    setIsEnableAddressInput(false);
    setAmphoeIDSelected(e);
    setAddressCurrent({
      province: findProvince?.label,
      amphoe: findAmphoe?.label,
    });
    onEventParent('onChangeAmphoe', e);
  };

  //Enable/Disable Input Form
  //===========================
  //===========================
  const handleClickEditAddress = () => {
    setIsEnableAddressInput(true);
  };
  const handleDisableAddressInput = () => {
    setIsEnableAddressInput(false);
  };

  //onEvent Parent
  const onEventParent = (key, value) => {
    switch (key) {
      case 'onGetCurrentPosition':
        onGetCurrentPosition && onGetCurrentPosition(value);
        break;
      case 'onChangeAmphoe':
        onChangeAmphoe && onChangeAmphoe(value);
        break;
      default:
        break;
    }
  };

  return (
    <MyAddressLocationStyled>
      <div className="my_address_location_container">
        <div className="address_current_wrap">
          <AddressNow
            isEdit
            province={addressCurrent?.province}
            amphoe={addressCurrent?.amphoe}
            handleClickEditAddress={handleClickEditAddress}
          />
        </div>
        {isEnableAddressInput && (
          <div className="address_filter_container">
            <div
              className="icon_close_filter"
              onClick={handleDisableAddressInput}
            >
              <MdCancel fontSize="1.75rem" color="#cf1a71" />
            </div>
            {provinceData && provinceData.length > 0 && (
              <div className="input_dropdown_wrap">
                <DropDownCustom
                  them_pink
                  options={provinceData}
                  width="100%"
                  initialvalue={provinceIDSelected}
                  onChangeCustom={(e) => {
                    handleSelectedProvince(e);
                  }}
                />
              </div>
            )}
            <div className="input_dropdown_wrap">
              <DropDownCustom
                them_pink
                options={amphoeData && amphoeData.length > 0 && amphoeData}
                labelShow="เลือก อำเภอ / เขต"
                initialvalue={amphoeIDSelected}
                width="100%"
                onChangeCustom={(e) => {
                  handleSelectedAmphoe(e);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </MyAddressLocationStyled>
  );
};

export default MyAddressLocation;

import React from 'react';
import { connect } from 'react-redux';
import { PinkAlertMainLayoutStyled } from './styled';
import { Route, Switch } from 'react-router-dom';
import pinkAlertRoutes from 'routes/pinkAlertRoutes';
import { PinkAppWidgets } from 'widgets';
import { ROUTE_PATH } from 'helpers';

class PinkAlertMainLayout extends React.Component {
  renderNavbarCondition = () => {
    const routeName = this.props.location.pathname;

    if (this.props.configProjectRedux.scheme === 'herwill') {
      if (
        routeName !== ROUTE_PATH.REGISTER &&
        routeName !== ROUTE_PATH.TERM_OF_USE &&
        routeName !== ROUTE_PATH.SHARE &&
        routeName !== ROUTE_PATH.PINK_HOSPITAL_CHOOSE &&
        routeName !== ROUTE_PATH.PINK_INSURANCE_PACKAGE &&
        routeName !== ROUTE_PATH.LOCATION_NEAR &&
        routeName !== ROUTE_PATH.CONSULT_DOCTOR
      ) {
        return <PinkAppWidgets.NavbarHerWill />; 
      } else {
        return <></>;
      }
    } else {
      if (
        routeName !== ROUTE_PATH.REGISTER &&
        routeName !== ROUTE_PATH.TERM_OF_USE
      ) {
        return <PinkAppWidgets.Navbar />;
      } else {
        return <></>;
      }
    }
  };

  render() {
    return (
      <PinkAlertMainLayoutStyled>
        {this.renderNavbarCondition()}
        <Switch>
          {pinkAlertRoutes &&
            pinkAlertRoutes.map((e, i) => {
              return !e.sub ? (
                <Route
                  key={i}
                  exact={e.exact}
                  path={e.path}
                  component={e.component}
                />
              ) : (
                e.subComponent.map((e2, i2) => (
                  <Route
                    key={i2}
                    exact={e2.exact}
                    path={e2.path}
                    component={e2.component}
                  />
                ))
              );
            })}
        </Switch>
      </PinkAlertMainLayoutStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PinkAlertMainLayout);

export const ROUTE_PATH = {
  LOGIN_NEW: 'login',
  AUTO_SIGNUP: '/signup',

  //PINK APP
  HOME_PATH: '/pinkalert',
  TERM_OF_USE: '/pinkalert/termofuse',
  QUESTIONNAIRE: '/pinkalert/questionnaire',
  SHARE: '/pinkalert/share',
  QUANLIFICATION_EXAMINATION: '/pinkalert/quanlificationexamination',
  // LOGIN: '/pinkalert/login',
  LOGIN: '/login',
  REGISTER: '/pinkalert/register',
  SIGNUP: '/pinkalert/signup',
  LOCATION_NEAR: '/pinkalert/locationnear',
  CONSULT_DOCTOR: '/pinkalert/consult-doctor',
  PINK_HOSPITAL_CHOOSE: '/pinkalert/hospital-choose',
  PINK_INSURANCE_PACKAGE: '/pinkalert/service-package',
  PINK_PROFILE_SCREENING: '/pinkalert/profile',
  HEALTH_INSURANCER: '/pinkalert/health-insurancer',

  //WELL SCREEN
  // WELL_LOGIN: '/wellscreening/login',
  WELL_LOGIN: '/login',
  WELL_SIGNUP: '/wellscreening/signup',
  WELL_REGISTER: '/wellscreening/register',
  HOME_SCREENING: '/wellscreening/home',
  WELL_TERM_OF_USE_REGISTRY: '/wellscreening/term-registry',
  TERM: '/wellscreening/term',
  SELECT_SUBJECT: '/wellscreening/subject',
  PRE_CHECK: '/wellscreening/pre-check',
  SELECT_SYMPTOM: '/wellscreening/select-symptom',
  INTERESTED_CENCER: '/wellscreening/interested-cancer',
  SUMMARY: '/wellscreening/summary',
  SUMMARY_HISTORY: '/wellscreening/summary/history',
  SUMMARY_NO_SYMPTOM: '/wellscreening/summary/no-symptom',

  SERVICE: '/wellscreening/service',
  INSURANCE_PACKAGE: '/wellscreening/insurance-package',
  INSURANCE_PLAN: '/wellscreening/insurance-plan',
  CONSULT_GENETIC: '/wellscreening/consult-genetic',
  CONSULT_CANCER: '/wellscreening/consult-cancer',
  EXAMINE_TEST_KIT: '/wellscreening/examine-testkit',
  CART: '/wellscreening/cart',
  QUESTION_BEFORE_BUY: '/wellscreening/question-before-buy',
  THANK_YOU_INTEREST: '/wellscreening/thankyou-interest',
  SUCCESS_ORDER: '/wellscreening/product_orders',
  SUCCESS_ORDER_DETAIL: '/wellscreening/product_orders/:orderID',
  PROFILE_FAMILY: '/wellscreening/profile-family',
  PROFILE_SCREENING: '/wellscreening/profile',
  BUY_PRODUCT_HISTORY: '/wellscreening/buy-product-history',
  SUMMARY_SERVICE_HISTORY: '/wellscreening/summary-service-history',
  ADVICE_DOCTOR: '/wellscreening/advice-doctor',
  BUY_PRODUCT: '/wellscreening/buy-product',
  SUSPECTED_CANCER: '/wellscreening/suspected-cancer',
  NO_SYMPTOM_QUESTION: '/wellscreening/no-symptom-question',
  GATEWAY: '/wellscreening/gateway-question',
  GATEWAY_NO_SYMPTOM: '/wellscreening/gateway/no-symptom',
};

export const VALIDATE = {
  REQUIRE: (value) =>
    value || typeof value === 'number' ? undefined : 'Required',
  NUMBER: (value) =>
    value
      ? isNaN(Number(value))
        ? 'Must be a number'
        : undefined
      : 'Must be a number',
  NUMBER_ONLY: (value) =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined,
  EMAIL: (value) =>
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'email invalid'
      : undefined,
  MIN_LENGTH: (value) =>
    value
      ? value.length < 6
        ? `Must be 6 characters or more`
        : undefined
      : `Must be 6 characters or more`,
  MIN_PW_LENGTH: (value) =>
    value
      ? value.length < 8
        ? `Must be 8 characters or more`
        : undefined
      : `Must be 8 characters or more`,
  MIN_PW_4_LENGTH: (value) =>
    value
      ? value.length < 4
        ? `Must be 4 characters or more`
        : undefined
      : `Must be 4 characters or more`,
  PASSWORD_MATCH: (value, allValues) =>
    value !== allValues.password ? "Passwords don't match" : undefined,
};

export const IMAGE_PATH = 'http://localhost:8089/static/';
// export const IMAGE_PATH = "https://backend.test.com/static/"

import React from "react";
import { connect } from "react-redux";
import { InterestedCancerContainerStyled } from "./styled";
import { iconImages } from "assets";
import { iconSelectCancer } from "assets";
import { Button, BoxLink, LogoLoadingFade } from "components";
import { ROUTE_PATH } from "helpers";

class InterestedCancerContainer extends React.Component {
  state = {
    btn_select: [],
    loading: true,
    loading_question:true,
    question: {
      title: "คุณกำลังสงสัยว่าตนเองตั้งครรภ์หรือไม่",
      btn1: "ใช่",
      btn2: "ไม่ใช่",
      state: 1,
    },
  };

  componentDidMount = () => {
    const { authRedux } = this.props;
    this.scrollToTop();
    this.setLoadder();

    if (authRedux === "male") {
      this.props.history.push(ROUTE_PATH.SELECT_SYMPTOM);
    }
  };

  setLoadder = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 300);
    setTimeout(() => {
      this.setState({
        loading_question: false,
      });
    }, 1500);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleClickButtonYes = () => {
    this.setState({
      question: {
        title:
          "Well ต้องขออภัยตอนนี้คุณกำลังตั้งครรภ์ และ Well ไม่สามารถให้บริการคุณได้",
        title2: "(เพราะอาจเกิดความคลาดเคลื่อนในการหาแนวโน้ม)",
        btn1: "ยืนยัน",
        state: 2,
      },
    });
  };

  handleClickToHome = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  handleClickToSelectSymptom = () => {
    this.props.history.push(ROUTE_PATH.SELECT_SYMPTOM);
  };

  handleClickToggle = (id) => {
    const { btn_select } = this.state;
    const select = [...btn_select];
    if (select.find((ev) => ev === id)) {
      for (let index = 0; index < select.length; index++) {
        if (select[index] === id) {
          select.splice(index, 1);
        }
      }
    } else {
      select.push(id);
    }
    this.setState({
      btn_select: select,
    });
  };

  handleClickNext = () => {
    const { handleClickNext } = this.props;
    handleClickNext();
  };

  handleClickBackPage = () => {
    const { historyLink } = this.props

    historyLink.push(ROUTE_PATH.HOME_SCREENING)
  }

  render() {
    const { btn_select, loading ,loading_question} = this.state;
    return (
      <InterestedCancerContainerStyled>
        {
          loading ? (
            <div className="loader_layout">
              <LogoLoadingFade />
            </div>
          ) :
            (
              <>
                <div
                  className="link_back"
                  onClick={() => this.handleClickBackPage()}
                >
                  <BoxLink theme_standard_box_link label="กลับสู่หน้าหลัก" />
                </div>
                <div className="group_layout">
                  <div className="layout_detail">
                  {loading_question ? (
                  <LogoLoadingFade />
                ) : (
                  <img
                    className="img_logo"
                    src={iconImages["logo_question.png"]}
                    alt="logo well"
                  />
                )}
                    <div className="title">
                      ในการตรวจในครั้งนี้คุณสงสัยมะเร็งชนิดไหนเป็นพิเศษหรือไม่
                    </div>
                    <div className="title2">(เลือกได้มากกว่า 1 ข้อ)</div>
                    <div className="question_layout">
                      {data &&
                        data.map((e, i) => (
                          <div key={i}>
                            <Button
                              width="190px"
                              // height="45px"
                              theme_cancer
                              style={{ marginTop: 20 }}
                              font="14px"
                              active={btn_select.find((ev) => ev === e.value)}
                              handleClickButton={() => this.handleClickToggle(e.value)}
                            >
                              <div className="column">
                                <img src={e.img} alt="" />
                                <div className="text_button">{e.label}</div>
                              </div>
                            </Button>
                          </div>
                        ))}
                    </div>
                    <Button
                      width="400px"
                      // height="50px"
                      font="14px"
                      height="auto"
                      padding='10px'
                      backgroundColor="#5495B4"
                      theme_standard
                      style={{ marginTop: 20 }}
                      className="btn_bottom"
                      handleClickButton={this.handleClickNext}
                    >
                      ต่อไป
                    </Button>
                    <Button
                      width="400px"
                      // height="50px"
                      font="14px"
                      height="auto"
                      padding='10px'
                      backgroundColor="#ffffff"
                      color="#000000"
                      theme_standard
                      style={{ marginTop: 10 }}
                      className="btn_bottom"
                      handleClickButton={this.handleClickNext}
                    >
                      ไม่มีโรคที่สนใจเป็นพิเศษ
                    </Button>
                  </div>
                </div>
              </>
            )
        }
      </InterestedCancerContainerStyled>
    );
  }
}

const data = [
  {
    label: "โรคมะเร็งเต้านม",
    img: iconSelectCancer["breast.png"],
    value: 1,
  },
  {
    label: "โรคมะเร็งลำไส้",
    img: iconSelectCancer["liver.png"],
    value: 2,
  },
  {
    label: "โรคมะเร็งตับ",
    img: iconSelectCancer["stomach.png"],
    value: 3,
  },
  {
    label: "โรคมะเร็งท่อน้ำดี",
    img: iconSelectCancer["duct.png"],
    value: 4,
  },
  {
    label: "โรคมะเร็งปากมดลูก",
    img: iconSelectCancer["cervix.png"],
    value: 5,
  },
  {
    label: "โรคมะเร็งปอด",
    img: iconSelectCancer["lung.png"],
    value: 6,
  },
];

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

export default connect(mapStateToProps)(InterestedCancerContainer);

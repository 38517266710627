import styled from 'styled-components';
import { SCREEN } from 'assets';

export const CheckBoxStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .container_wrap {
      width: ${(props) =>
        props.width_container ? props.width_container : '300px'};
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      padding-left: 28px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .txt_label {
        color: #3061a1;

        font-size: 13px;
        padding-top: 2px;
      }
    }

    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 18px;
      width: 18px;
      border: 2px solid #3da3c1;
      border-radius: 5px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #3da3c1;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 1px;
      left: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .theme_standard_2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .container_wrap {
      width: ${(props) =>
        props.width_container ? props.width_container : '300px'};
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      padding-left: 28px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .txt_label {
        color: #3061a1;

        font-size: 13px;
        padding-top: 2px;
      }
    }

    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 18px;
      width: 18px;
      border: 2px solid #3da3c1;
      border-radius: 5px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #3da3c1;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 1px;
      left: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .theme_circle {
    display: flex;
    margin-left: 40px;
    /* flex-wrap: wrap; */
    .container_wrap {
      width: 100px;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      padding-left: 28px;
      margin-bottom: 0px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      z .txt_label {
        color: #3061a1;

        font-size: 13px;
        padding-top: 3px;
      }
    }

    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: white;
      border: 2px solid #3da3c1;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: white;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 1.5px;
      left: 1.6px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #3da3c1;
    }
  }
  .theme_circle_column {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    .container_wrap {
      width: 100px;
      margin-bottom: 5px;
    }

    /* The container */
    .container {
      display: block;
      position: relative;
      padding-left: 28px;
      margin-bottom: 0px;
      cursor: pointer;
      font-size: ${({ theme }) => theme.FONTS.SIZE_3};
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .txt_label {
        color: #3061a1;

        font-size: 13px;
        padding-top: 3px;
      }
    }

    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: white;
      border: 2px solid #3da3c1;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: white;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 1.5px;
      left: 1.6px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #3da3c1;
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .theme_circle {
      /* The container */
      .container {
        .txt_label {
          color: #3061a1;

          font-size: 12px;
          padding-top: 3px;
        }
      }
      .checkmark {
        height: 12px;
        width: 12px;
      }
      .container .checkmark:after {
        top: 1px;
        left: 1.1px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #3da3c1;
      }
    }
    .theme_circle_column {
      .container {
        .txt_label {
          color: #3061a1;

          font-size: 12px;
          padding-top: 3px;
        }
      }
      .checkmark {
        height: 12px;
        width: 12px;
      }
      .container .checkmark:after {
        top: 0.9px;
        left: 1.1px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #3da3c1;
      }
    }
    .theme_standard {
      .container {
        .txt_label {
          color: #3061a1;

          font-size: 12px;
          // padding-top: 3px;
        }
      }
      .checkmark {
        height: 12px;
        width: 12px;
      }
      .container .checkmark:after {
        top: 0.1px;
        left: 1.5px;
        background-color: #3da3c1;
      }
    }
  }
`;

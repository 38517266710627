import React from "react";
import { connect } from "react-redux";
import { TermOfUseContainerStyled } from "./styled";
// import { userService } from 'apiService';
import { ROUTE_PATH } from "helpers";
import { setReduxUserAuth } from "actions";
import { } from "components";
import Loader from "react-loader-spinner";
class TermOfUseContainer extends React.Component {
  state = {
    isAcceptConditon: false,
    loading: false,
    scheme: this.props.configProjectRedux.scheme === 'herwill' && 'herwill',
  };

  handleClickNext = () => {
    const { isAcceptConditon } = this.state;

    if (isAcceptConditon) {
      this.setState({
        loading: true,
      });
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        // this.props.history.push(ROUTE_PATH.QUANLIFICATION_EXAMINATION);
        this.props.history.push(ROUTE_PATH.REGISTER);
      }, Math.floor(Math.random() * 500) + 100);
    }
  };

  render() {
    const { isAcceptConditon, loading, scheme } = this.state;
    return (
      <TermOfUseContainerStyled>
        {loading ? (
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={500}
            className="lodding"
          />
        ) : (
          <div className="box_layout">
            <div className="title">ข้อตกลงการให้บริการ</div>
            <div className="sub_title">Term of use</div>
            <div className="content_container">
              <div className="desciption">
                แบบสอบถามนี้เป็นแบบสอบถามที่อ้างอิงจาก Gail Model
                (สถาบันมะเร็งของประเทศอเมริกา) เป็นแบบจำลองเพื่อ
                คำนวนความเสี่ยงของการเกิดมะเร็งเต้านมภายในห้าปีข้างหน้า
              </div>
              <div className="title_question">คำเตือนเกี่ยวกับคำถามชุดนี้</div>
              <div className="question_container">
                <div className="question_item">
                  1.ไม่สามารถทำนายมะเร็งเต้านมชนิดที่เกี่ยวข้องกับยีน
                  <span className={`text_highlight ${scheme}`}> BRCA1 , BRCA2</span>
                </div>
                <div className="question_item">
                  2.ไม่สามารถทำนายใน
                  <span className={`text_highlight ${scheme}`}>
                    บุคคลที่เคยมีประวัติมะเร็งเต้านมมาแล้ว
                  </span>
                </div>
                <div className="question_item">
                  3.ไม่สามารถทำนายผู้หญิงในกลุ่มที่ทำงานสัมผัสสารก่อมะเร็ง เช่น
                  <span className={`text_highlight ${scheme}`}>
                    ทำงานเกี่ยวกับโรงงานเครื่องหนัง สารระเหย และ ปิโตรเคมี
                  </span>
                </div>
              </div>
              <div className='text_mid'>
                แบบประเมินนี้ไม่ใช่คำวินิจฉัยทางการแพทย์
              </div>
              <div className="desciption">
               แบบประเมินนี้ เหมาะสำหรับผู้หญิงที่อายุ 35 ขึ้นไป
              </div>
              <div className="desciption">
               โดยการเก็บ น้ำหนัก ส่วนสูง พฤติกรรมการสูบบุหรี่ ดื่มแอลกอฮอล์ ที่อยู่ เพื่อคำแนะนำบริการสุขภาพส่วนตัวของคุณปัจจัยเหล่านี้ ไม่ได้ถูกมาคำนวณความเสี่ยงในการเป็นมะเร็งเต้านมในอนาคต
              </div>
              <div className="desciption">
               การเก็บอีเมล์ของท่านเพื่อเป็นการส่งข่าวสารข้อมูลที่ท่านสนใจ หากท่านไม่สนใจ ไม่จำเป็นต้องกรอกอีเมล์เพื่อรับข้อมูล
              </div>
              <div className="accept_container">
                <div className="input_group">
                  <input
                    id="accept_id"
                    type="checkbox"
                    value={isAcceptConditon}
                    onChange={(e) => {
                      this.setState({ isAcceptConditon: !isAcceptConditon });
                    }}
                  />
                  <label className={`${scheme}`} htmlFor="accept_id">ฉันยอมรับข้อตกลง</label>
                </div>
                <div className="accept_detail">
                  <span className="text_highlight">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.invitracehealth.com/privacy-policy"
                      className={`${scheme}`}
                    >
                      นโยบายความเป็นส่วนตัว
                    </a>
                  </span>{" "}
                  และ{" "}
                  <span className="text_highlight">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.invitracehealth.com/terms-of-use-1"
                      className={`${scheme}`}
                    >
                      เงื่อนไขการใช้งาน
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <button className={`btn_accept ${scheme}`} onClick={this.handleClickNext}>
              รับทราบและยินยอม
            </button>
          </div>
        )}
      </TermOfUseContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermOfUseContainer);

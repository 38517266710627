import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ExaminationTestKitContainerStyled } from './styled';
import { userService } from 'apiService';
import { iconImages, iconBackground } from 'assets';
import { ROUTE_PATH } from 'helpers';
import { setReduxUserAuth, setReduxOrderProduct } from 'actions';
import {
  BoxDetail,
  BoxQA,
  IconCart,
  ButtonIcon,
  BoxLink,
  LogoLoadingFade,
} from 'components';
import { CartForm } from 'forms';
// import Loader from "react-loader-spinner";
import { toast } from 'react-toastify';

class ExaminationTestKitContainer extends React.Component {
  state = {
    showDot: false,
    loading: true,
  };

  componentDidMount() {
    this.fetchDataDefaut();
    this.fetchProductData();
    this.fetchOrderStatus();
    this.scrollToTop();
    this.disableLoader();
    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  disableLoader = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  fetchDataDefaut = () => {
    const { setReduxOrderProduct } = this.props;
    setReduxOrderProduct({ orderProduct: null });
  };

  fetchProductData = async () => {
    let res = await userService.GET_HPV_TESTKIT_PRODUCT_LIST();
    // console.log("resssssssssss ", res)
    if (res && res.success) {
      this.setState({
        dataProduct: res.data[0],
        initialValues: {
          product_id: res.data[0].id,
          name: res.data[0].name,
          final_price: res.data[0].final_price,
          full_price: res.data[0].full_price,
        },
      });
    }
  };

  fetchOrderStatus = async () => {
    const { authRedux } = this.props;

    let res = await userService.GET_PRODUCT_ORDER_LIST_BY_STATUS(
      authRedux.id,
      'pending'
    );
    if (res && res.success) {
      this.setState({
        showDot: res.data.length > 0 ? true : false,
      });
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.GREETING);
  };

  handleClikCart = () => {
    this.props.history.push(ROUTE_PATH.CART);
  };

  handleClickBuy = () => {
    const {
      reduxForm: { values },
      setReduxOrderProduct,
    } = this.props;

    if (values.amount < 1 || values.amount === undefined) {
      toast.error('กรุณาใส่จำนวนให้ถูกต้อง');
    } else {
      // Tracking user event
      ReactGA.event({
        category: 'product_order',
        action: 'interest_product_order',
        value: values.amount,
      });
      setReduxOrderProduct({ orderProduct: values });
      this.props.history.push(ROUTE_PATH.QUESTION_BEFORE_BUY);
    }
  };

  handleClickBackFirstPage = () => {
    this.props.history.push(ROUTE_PATH.HOME_SCREENING);
  };

  render() {
    const { dataProduct, showDot, loading, initialValues } = this.state;
    return (
      <ExaminationTestKitContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <LogoLoadingFade />
            {/* <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={500}
              className="lodding"
            /> */}
          </div>
        ) : (
          <>
            <img
              className="dot_img"
              src={iconBackground['bg-dot.jpg']}
              alt="dot-bg"
            />
            <div className="mb_show">
              <img
                className="bg_img"
                src={iconBackground['buy_bg.png']}
                alt="background"
              />
            </div>
            <div
              className="link_back"
              onClick={() => this.handleClickBackFirstPage()}
            >
              <BoxLink theme_standard_box_link showArrow label="ย้อนกลับ" />
            </div>
            <div className="container">
              <div className="group_top">
                <div className="left_box_top">ชุดตรวจมะเร็งปากมดลูก</div>
                <div className="right_box_top" onClick={this.handleClikCart}>
                  <IconCart theme_standard showDot={showDot} />
                </div>
              </div>
              <div className="group_obj">
                {/* <BoxCart
                                theme_standard
                                data={dataProduct}
                                handleClickBuy={this.handleClickBuy}
                            /> */}
                <CartForm
                  data={dataProduct}
                  handleClickBuy={this.handleClickBuy}
                  initialValues={initialValues}
                />

                <BoxDetail
                  theme_standard
                  data={dataProduct && dataProduct.description}
                />
              </div>
              <BoxQA theme_standard data={dataProduct && dataProduct.detail} />
              <div className="bottom_detail">
                <div className="detail_2">
                  <div>หากท่านไม่ได้รับสินค้า ภายใน 7 วัน </div>
                  <div>หรือสินค้ามีปัญหาใดๆ โปรดติดต่อ</div>
                  <div>บริษัท อินไวเทรส จำกัด</div>
                  <div style={{ marginTop: 10 }}>
                    <img
                      className="img_show"
                      src={iconImages['phone_gray.png']}
                      alt="icon show"
                    />
                    061-717-1222
                    <img
                      className="img_show2"
                      src={iconImages['line_icon.png']}
                      alt="icon show"
                    />
                    @medlinecheckup
                  </div>
                </div>
                <div className="detail_3">
                  (เวลาทำการ จ. - ศ. :7.00 - 17.00)
                </div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.tgcsthai.com/expertise.php?code=CG"
                >
                  <div className="detail_4">
                    ดูรายชื่อแพทย์มะเร็งนรีเวชใกล้คุณได้ที่นี่
                  </div>
                </a>
                <div className="btn2">
                  <ButtonIcon
                    theme_gray
                    lable1Size="16px"
                    label1="กลับสู่หน้าแรก"
                    handleOnClick={() => this.handleClickBackFirstPage()}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </ExaminationTestKitContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  orderProductRedux: state.orderProduct,
  reduxForm: state.form.CartForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxOrderProduct: (data) => dispatch(setReduxOrderProduct(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExaminationTestKitContainer);

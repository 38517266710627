import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { SignUpFormStyled } from './styled';
import { VALIDATE } from 'helpers';
import { InputText } from 'components';

class SignUpForm extends React.Component {
  state = {};

  render() {
    const { handleSubmit, handleClickSignUp } = this.props;
    return (
      <SignUpFormStyled>
        <form onSubmit={handleSubmit(handleClickSignUp)}>
          <div className="form_layout">
            <div className="layout_top">
              <div className="txt_login">ลงทะเบียนด้วยอีเมล์</div>
            </div>
            <div className="group_input">
              <div className="label_name">ชื่อ</div>
              <Field
                theme_standard
                name="name"
                placeHolder="ชื่อ"
                component={InputText}
                validate={VALIDATE.REQUIRE}
              />
            </div>
            <div className="group_input">
              <div className="label_name">อีเมล์</div>
              <Field
                theme_standard
                name="email"
                placeHolder="you@example.com"
                component={InputText}
                validate={VALIDATE.EMAIL}
              />
            </div>
            <div className="group_input">
              <div className="label_name">Password</div>
              <Field
                type="password"
                theme_standard
                name="password"
                placeHolder="Password"
                component={InputText}
                validate={VALIDATE.REQUIRE}
              />
            </div>
            <div className="group_input">
              <div className="label_name">Confirm Password</div>
              <Field
                type="password"
                theme_standard
                name="confirm_password"
                placeHolder="Password"
                component={InputText}
                validate={VALIDATE.PASSWORD_MATCH}
              />
            </div>
          </div>
          <button className="btn_next" type="submit">
            ยืนยัน
          </button>
        </form>
      </SignUpFormStyled>
    );
  }
}

export default reduxForm({
  form: 'SignUpForm', // a unique identifier for this form
  enableReinitialize: true,
})(SignUpForm);
